import {useState, useEffect} from 'react'
import {UserContext} from '../contexts'
import {userUseCase} from '../factories'
import httpRequest from "../services/http-request";

const INTERVAL_TIME = 10 * 60 * 1000;// 10 minutes in milliseconds
const LAST_REQUEST_KEY = 'lastCatalogueTimestamp';

function UserProvider(props) {
    const [user, setUser] = useState()
    const {children} = props
    const {post} = httpRequest()
    const [count, setCount] = useState(0);

    useEffect(() => {
        const clickHandler = () => {
            setCount(1);
        };

        document.addEventListener('click', clickHandler);
        document.addEventListener('keypress', clickHandler);
        document.addEventListener('mousemove', clickHandler);
        document.addEventListener('scroll', clickHandler);

        return () => {
            document.removeEventListener('click', clickHandler);
            document.removeEventListener('keypress', clickHandler);
            document.removeEventListener('mousemove', clickHandler);
            document.removeEventListener('scroll', clickHandler);
        };
    }, []);

    useEffect(() => {
        let timer = null;

        if (count === 1) {
            timer = setTimeout(() => {
                post('update-time')
                setCount(0);
            }, 180000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [count]);

    useEffect(() => {
        const fetchData = () => {
            userUseCase
                .getSignedInUser()
                .then((e) => {
                    setUser(e.data.data)
                    localStorage.setItem(LAST_REQUEST_KEY, Date.now());
                })
                .catch(() => {
                    setUser(null)
                    localStorage.removeItem('token')
                    localStorage.removeItem('refresh_token')
                })
        };

        const lastRequestTime = localStorage.getItem(LAST_REQUEST_KEY);
        const now = Date.now();

        if (!lastRequestTime || now - lastRequestTime >= INTERVAL_TIME) {
            fetchData();
            setInterval(fetchData, INTERVAL_TIME);
        } else {
            const timeUntilNextRequest = INTERVAL_TIME - (now - lastRequestTime);
            const initialTimeout = setTimeout(() => {
                fetchData();
                setInterval(fetchData, INTERVAL_TIME);
            }, timeUntilNextRequest);

            return () => clearTimeout(initialTimeout);
        }
    }, []);

    useEffect(() => {
        userUseCase
            .getSignedInUser()
            .then((e) => {
                setUser(e.data.data)
            })
            .catch(() => {
                setUser(null)
                localStorage.removeItem('token')
                localStorage.removeItem('refresh_token')
            })
    }, [])

    return (
        <UserContext.Provider value={{user, setUser,}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
