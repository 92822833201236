import {
    Box,
    Button, Input,
    InputGroup, InputLeftElement,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text
} from "@chakra-ui/react";
import {Checkbox, Icon} from "../../components/Theme";
import styles from "../Projects/styles";
import {ReactComponent as Plus} from "../../assets/plus.svg";
import AddNewCompanyModal from "./addNewCompanyModal";


const CompaniesNavbar = ({handleCheckbox, history, isOpen, onClose, setSearch}) => (
    <>
        <Popover>
            <PopoverTrigger>
                <Button
                    leftIcon={<Icon type="filter"/>}
                    variant="lightBlue"
                    mr={6}
                    height='40px'
                    minW="139px">
                    <Text
                        ml={2}
                        fontSize="sm">
                        Filter
                    </Text>
                </Button>
            </PopoverTrigger>
            <PopoverContent
                bg="white"
                zIndex="500"
                p={6} w="139px"
                pr={0} flexDirection="row"
                _focus={{outline: 'none'}}>
                <PopoverArrow/>
                <PopoverBody
                    display="flex"
                    p={0}
                    flexDirection="column">
                    <Checkbox
                        key='Real Estate'
                        css={styles.checkboxReu}
                        onChange={(e) => handleCheckbox(e.target.name)}
                        name='Real Estate'
                        mb={1}>
                        <Box
                            ml={2}
                            fontSize="14px"
                            as="span">
                            Real Estate
                        </Box>
                    </Checkbox>
                    <Checkbox
                        key='Suppliers'
                        css={styles.checkboxSu}
                        onChange={(e) => handleCheckbox(e.target.name)}
                        name='Supplier'
                        mb={1}>
                        <Box
                            ml={2}
                            fontSize="14px"
                            as="span">
                            Suppliers
                        </Box>
                    </Checkbox>
                    <Checkbox key='Electricians' css={styles.checkboxEu}
                              onChange={(e) => handleCheckbox(e.target.name)} name='Electrician' mb={1}>
                        <Box ml={2} fontSize="14px" as="span">
                            Electricians
                        </Box>
                    </Checkbox>
                </PopoverBody>
            </PopoverContent>
        </Popover>
        <Popover>
            <PopoverTrigger>
                <Button
                    leftIcon={<Plus/>}
                    mr={12}
                    height='40px'
                    variant="green"
                    minW="139px">
                    <Text
                        fontWeight="700"
                        fontSize="sm">
                        Add New Company
                    </Text>
                </Button>
            </PopoverTrigger>
            <AddNewCompanyModal
                isOpen={isOpen}
                onClose={onClose}/>
            <PopoverContent
                bg="white"
                zIndex="500"
                p={6}
                w="190px"
                pr={0}
                flexDirection="row"
                _focus={{outline: 'none'}}>
                <PopoverArrow/>
                <PopoverBody
                    p={0}
                    display="flex"
                    flexDirection="column">
                    <Button
                        onClick={() => history.push(`/companies/create-company/reu`)}
                        mb={3}
                        textAlign="center"
                        width="140px"
                        height="20px"
                        backgroundColor="#C956FF"
                        borderRadius="4px"
                        color="#222222"
                        fontSize="sm">
                        Real Estate
                    </Button>
                    <Button
                        onClick={() => history.push(`/companies/create-company/su`)}
                        mb={3}
                        width="140px"
                        height="20px"
                        textAlign="center"
                        backgroundColor="#FFD339"
                        borderRadius="4px"
                        color="#222222"
                        fontSize="sm">
                        Supplier
                    </Button>
                    <Button
                        onClick={() => history.push(`/companies/create-company/eu`)}
                        color="#222222"
                        width="140px"
                        height="20px"
                        textAlign="center"
                        borderRadius="4px"
                        backgroundColor="#FF8660"
                        fontSize="sm">
                        Electrician
                    </Button>
                </PopoverBody>
            </PopoverContent>
        </Popover>
        <InputGroup
            mr={8}>
            <InputLeftElement
                height="30px" pointerEvents="none"
                children={(<Icon height="16.5px" width="16.5px" type="search"/>)}/>
            <Input
                onChange={(e) => setSearch(e.target.value)}
                height="30px"
                color="white.100"
                borderRadius="5px"
                border="none"
                bg="blue.200"
                placeholder="Search"/>
        </InputGroup>
    </>
)

export default CompaniesNavbar