import {css} from '@emotion/react'

const styles = {
    checkbox: css`
      margin-bottom: 6px;
      .css-1r69mki {
        stroke-width: 6px;
      }

      &.css-1isk33i[aria-checked=true]:hover, .css-1isk33i[data-checked]:hover, .css-1isk33i[aria-checked=true][data-hover], .css-1isk33i[data-checked][data-hover] {
        background: transparent;
      }

      & > .css-1isk33i[aria-checked=true], .css-1isk33i[data-checked] {
        background: transparent;
        border: 1px solid white;
      }

      & > span:nth-of-type(1) {
        height: 12px;
        width: 12px;
      }
    `,
}

export default styles
