const SUPERADMIN = 'SUPERADMIN'
const SA = 'Sa'
const AU = 'Au'
const REU = 'Reu'
const SU = 'Su'
const EU = 'Eu'
const GENERAL = 'Gu'
const ALL_USER_TYPES = ['Reu', 'Su', 'Sa', 'Au', 'Eu']

const USER_LIST_TYPE_COMPANY = 1
const USER_LIST_TYPE_PROJECT = 2
const USER_LIST_TYPE_COMPANY_USERS = 3
const NOT_VERIFED = 1111


const InitialPreferencesReuSales = {
    messages: false,
    projects: false,
    orders: false,
    shipping: false,
}

const InitialPreferencesAu = {
    messages: false,
    catalogues: false,
    orders: false,
    shipping: false,
}

const InitialPreferencesSuEu = {
    messages: false,
    bids: false,
    orders: false,
    shipping: false,
}

const InitialPreferencesSuperadmin = {
    messages: false,
    bids: false,
    projects: false,
    shipping: false,
    catalogues: false,
    users: false,
    companies: false,
    orders: false,
}

export {
    USER_LIST_TYPE_COMPANY,
    USER_LIST_TYPE_PROJECT,
    NOT_VERIFED,
    SUPERADMIN,
    SA,
    AU,
    InitialPreferencesReuSales,
    InitialPreferencesAu,
    InitialPreferencesSuEu,
    InitialPreferencesSuperadmin,
    REU,
    SU,
    EU,
    GENERAL,
    ALL_USER_TYPES,
    USER_LIST_TYPE_COMPANY_USERS,
}