import React from "react";
import {Box, Flex, Heading, Text, Input, Grid, GridItem} from "@chakra-ui/react";
import moment from "moment";
import {InputLabel, Textarea} from "../../../components/Theme";
import Luminaires from "./parts/Luminaires";
import styles from "./styles";
import {REU} from "../../../constants/user/userEnum";

const Receipts = ({refererReceipt, estimate, luminaires, subTotal, notes, salesTax, shipping, total, depositAmount, depositDue, remainderDue, remainderAmount, setRemainderDueDate, setDepositPaidDate, user, remainderDueDate, depositPaidDate,}) => {
    return (
        <Box ref={refererReceipt} display={estimate?.receiptDateCreated ? 'block' : 'none'} backgroundColor='white' p={9}>
            <Flex justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt=""/>
                <Flex flexDirection='column'>
                    <Heading m={0} textAlign='end'>Receipt</Heading>
                    <Text>Reference #: {estimate?.reference}</Text>
                    <Text textAlign='end'>Date : {moment(estimate?.receiptDateCreated?.date).format('MM/DD/YYYY')}</Text>
                </Flex>
            </Flex>
            <Flex justifyContent='space-between' fontSize='18px' mt={4}>
                <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                    <Box backgroundColor='#EEEEEE' fontFamily='Titillium Web' width='98%' borderRadius='5px' mb={3} fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Billing Information:
                    </Box>
                    <Box fontSize='xl'>
                        <Text>Focus Earth</Text>
                        <Text>Attn: Pete Musser</Text>
                        <Text>1070 Tunnel Road</Text>
                        <Text>Building 1, Suite 10, #202</Text>
                        <Text>Asheville, NC 28805</Text>
                        <Text>USA</Text>
                        <Text>(925) 323-0053</Text>
                    </Box>
                </Flex>
                <Flex width='50%' flexDirection='column'>
                    <Box backgroundColor='#EEEEEE' fontFamily='Titillium Web' width='100%' borderRadius='5px' mb={3} fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Shipping Information:
                    </Box>
                    <Box fontSize='xl'>
                        <Text>{estimate?.userReu?.company[0]?.name}</Text>
                        <Text>Attn : {estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}</Text>
                        <Text>{estimate?.report?.project?.address}</Text>
                        <Text>{estimate?.report?.project?.address2}</Text>
                        <Text>{estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}</Text>
                        <Text>USA</Text>
                        <Text>{estimate?.userReu?.phone}</Text>
                    </Box>
                </Flex>
            </Flex>
            <Box backgroundColor='#8FCF74' fontFamily='Titillium Web'  width='100%' fontSize='18px' fontWeight='700' color='#222222' pl={4} pt={2} pb={2} borderRadius='5px' mt={10}>
                Order Information
            </Box>
            <Grid templateColumns='repeat(7, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>
                    Description
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Part Number
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    QTY
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Price Per Unit
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Amount
                </GridItem>
            </Grid>
            <Luminaires luminaires={luminaires}/>
            <Flex justifyContent='end' mt={7}>
                <Flex width='50%' fontWeight='700' border='2px solid #8FCF74' height='64px' alignItems='center'
                      justifyContent='space-between' pl={6} pr={2} borderRadius='6px'>
                    <Input readOnly fontWeight='600' border='none' fontFamily='Titillium Web' value='Sub Total:' fontSize='18px'/>
                    <Flex justifyContent='end'>
                        <Input fontFamily='Titillium Web' readOnly border='none' fontSize='14px' fontWeight='600'
                               value={subTotal ? '$' + subTotal?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex fontSize='18px !important' justifyContent='space-between'>
                <Flex width='50%' flexDirection='column'>
                    <Text mb={2} color='#2C628D' fontWeight='700' fontFamily='Titillium Web'>Notes:</Text>
                    <Textarea readOnly value={notes} minH='130px'/>
                </Flex>
                <Flex flexDirection='column' color='#2C628D' mt={7}>
                    <Flex alignItems='center' justifyContent='space-between' minW='230px'>
                        <Text fontFamily='Titillium Web' textAlign='right'>Sales Tax:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder='0'
                                    leftAddon='$'
                                    disabled={user?.type === REU}
                                    readOnly value={salesTax ? Number(salesTax)?.toLocaleString('en-US') : 0}
                                    maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' >Shipping:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder='0'
                                    leftAddon='$'
                                    disabled={user?.type === REU}
                                    readOnly value={shipping ? Number(shipping)?.toLocaleString('en-US') : 0}
                                    maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' fontWeight='700' textAlign='right' minW='70px'>Total:</Text>
                        <InputLabel
                                    placeholder='0'
                                    disabled={user?.type === REU}
                                    readOnly value={total ? '$' + total?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '$'}  style={{'border': '2px solid #8FCF74'}} maxW='137px'/>
                    </Flex>
                </Flex>
            </Flex>

            <Flex justifyContent='space-between' mt={8}>
                <Flex fontSize='24px' width='50%' fontFamily='Titillium Web' fontWeight='700' alignItems='center' justifyContent='center'>
                    Thank you for your business!
                </Flex>
                <Flex maxW='50%' fontSize='18px !important' flexDirection='column' justifyContent='end' color='#2C628D'>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Deposit Amount:</Text>
                        <InputLabel css={styles.centeredInput} readOnly value={depositAmount && Number(depositAmount)?.toLocaleString('en-US')} leftAddon='$' ml={5} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Deposit Paid:</Text>
                        <InputLabel readOnly={user?.type === 'Reu'} value={depositPaidDate && moment(`${depositPaidDate.year}-${depositPaidDate.month}-${depositPaidDate.day}`, 'YYYY-M-D').format('MM.DD.YYYY')} ml={5}  border='1px solid #A9A9A9 !important' maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Remainder Amount:</Text>
                        <InputLabel css={styles.centeredInput} readOnly  ml={5} leftAddon='$' value={remainderAmount && remainderAmount?.toLocaleString('en-US')} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Remainder Paid:</Text>
                        <InputLabel readOnly={user?.type === 'Reu'} value={remainderDueDate && moment(`${remainderDueDate.year}-${remainderDueDate.month}-${remainderDueDate.day}`, 'YYYY-M-D').format('MM.DD.YYYY')} ml={5}  border='1px solid #A9A9A9 !important' maxW='137px'/>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}

export default Receipts