import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Flex } from '@chakra-ui/react'

const ActiveUsersUsageChart = ({ score, width, height,colors }) => {
    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    return score[tooltipItem[0].index]?.userName
                },
                label(tooltipItem, newLabel) {
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}`
                },
            },
        },
        hover: {mode: null},
    }

    const data = {
        datasets: [
            {
                label: '# of Votes',
                data:  score.map((item) => (item.total/60).toFixed(2)),
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    }

    return (
        <Flex
            width={width ? width : "288px"}
            height={height ? height : "288px"}>
            <Pie
                width={1000}
                height={1000}
                options={options}
                data={data}
                redraw />
        </Flex>
    )
}

export default ActiveUsersUsageChart
