import HttpRequest from "./http-request";

const ReportService = () => {
    const {post} = HttpRequest()
    const {get} = HttpRequest()

    const createReport =  (input) => {
        return post('create-report',input)
    }

    const doneReport =  (input) => {
        return post('report-done',input)
    }

    const updateReport = (input) => {
        return post(`update-report/${input.id}`,input)
    }

    const getReport = (id) => {
        return get(`get-report/${id}`)
    }

    const listReports = (variables) => {
        return post('list-records',variables)
    }

    return {
        createReport,
        updateReport,
        doneReport,
        getReport,
        listReports,
    }
}

export default ReportService
