import React from 'react'
import {
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, Text, Stack, Input, Select, Button,
} from '@chakra-ui/react'

import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import styles from "../Users/styles";

const COMPANY_TYPES = [
    'Real Estate',
    'Supllier',
    'Electrican',
]

const AddNewCompanyModal = ({ isOpen, onOpen, onClose }) => {
    return (
        <>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay/>
                <ModalContent
                    overflow="hidden"
                    borderRadius="10px"
                    minW="853px">
                    <ModalHeader
                        bg='green.100'
                        fontSize="3xl"
                        textAlign="center">
                            Add New Company
                    </ModalHeader>
                    <IconButton
                        height="unset"
                        onClick={onClose}
                        _hover={{
                            bg: 'none',
                        }}
                        _active={{
                            bg: 'none',
                        }}
                        bg="none"
                        aria-label="close button"
                        position="absolute"
                        top="1.5rem"
                        right="0.75rem"
                        icon={
                            <Icon type="darkClose"/>
                        }/>
                    <ModalBody
                        overflow="scroll"
                        maxH="600px">
                        <Flex
                            alignItems="center"
                            flexDirection="column">
                            <Text
                                my={12}
                                fontSize="3xl">
                                Create a new company:
                            </Text>
                            <Stack
                                mx="auto"
                                w="418px"
                                spacing={4}>
                                <Input
                                    borderColor="#A9A9A9"
                                    color="rgba(90, 90, 90, 0.87)"
                                    placeholder="Company Name"/>
                                <Select
                                    border="1px solid"
                                    borderColor="#A9A9A9"
                                    color="rgba(90, 90, 90, 0.87)"
                                    width="full"
                                    css={styles.select}
                                    placeholder="Company Type">
                                    {
                                        COMPANY_TYPES.map((user) => (
                                            <option
                                                key={user}
                                                value={user}>
                                                {user}
                                            </option>
                                        ))
                                    }
                                </Select>
                            </Stack>
                            <Button
                                mx="auto"
                                mt={12}
                                mb={20}
                                height="50px"
                                width="254px"
                                fontWeight="700"
                                fontSize="m"
                                large={+true}>
                                Submit
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddNewCompanyModal