import httpRequest from "./http-request";

const airtableService = () => {
    const {post} = httpRequest()

    const listProducts = async (condition) => {
        post('product-list', {condition}).then((res) => {
            return res.data.data
        }).catch(() =>{
            return []
        })
    }

    const getExisting = (input) => {
        return post('get-existing',input)
    }

    return {
        listProducts,
        getExisting,
    }
}

export default airtableService
