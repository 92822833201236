import {Stack} from "@chakra-ui/react";
import Loading from "../../../components/Loading";
import TotalWithChart from "../parts/TotalWithChart";
import DataChart from "../parts/DataChart";
import UsagePart from "../parts/UsagePart";

const Admin = ({data, isFetching, proposals,companyColors, usage}) => {

    if (isFetching) return <Loading />

    return (
        data &&
        <Stack spacing={10}>
            <TotalWithChart
                companyColors={companyColors}
                data={data['companies']}
                gridName={'Administrators'}
                gridNameCounts={'Total Companies'}
                chartTitle={'Companies Managed by Administrator'}
                header={'Total Companies Managed'}
                chartSide={'right'}/>
            <DataChart
                chartLeftSide={'Dollars'}
                header={'Total Active Orders'}
                data={data['orders']}/>
            <TotalWithChart
                companyColors={companyColors}
                data={data['products']}
                gridName={'Administrators'}
                gridNameCounts={'Total Companies'}
                chartTitle={'Companies Managed by Administrator'}
                header={'Total Products Managed'}
                chartSide={'left'}/>
            <DataChart
                bottomText={'Users'}
                chartLeftSide={'Messages Received'}
                header={'Communications Outreach'}
                data={proposals}/>
            <UsagePart
                companyColors={companyColors}
                usage={usage}/>
        </Stack>
    )
}

export default Admin