import React from 'react'
import {
    Accordion,
    AccordionButton, AccordionIcon,
    AccordionItem, AccordionPanel,
    Box,
    Button,
    Grid,
    GridItem,
    Flex,
    Text,
} from '@chakra-ui/react'
import {ReactComponent as DownloadIcon} from "../../assets/download.svg";
import moment from "moment/moment";
import {ESTIMATE, INVOICE, ORDER, ORDER_TYPES, RECEIPT, SHIPPING} from "../../constants/orders/orderEnum";

const Fulfilled = ({estimates, loading, downloadPdf}) => {
    return (
        <Box>
            {
                estimates?.filter((filtered) => filtered?.fulfilledByUserEstimateReu)?.length > 0 ? (
                    <Grid fontSize='xl' fontFamily='Titillium Web' mb={3} fontWeight='600' width='full' templateColumns="repeat(6, 1fr)">
                        <GridItem pl={5}>Reference</GridItem>
                        <GridItem pl={2}>Company</GridItem>
                        <GridItem>Project</GridItem>
                        <GridItem>Fulfilled By</GridItem>
                        <GridItem>Fulfillment date</GridItem>
                    </Grid>
                ) : (
                    <Text fontSize='18px' textAlign='center'>
                        No active fulfilled orders at this time - check back later!
                    </Text>
                )
            }
            {
                estimates?.filter((filtered) => filtered?.fulfilledByUserEstimateReu).sort((a: any, b: any) => moment.utc(b.createdAt.date).diff(moment.utc(a.createdAt.date)))?.map((estimate) => (
                    <Accordion key={estimate?.id} allowToggle mb={3}>
                        <AccordionItem borderRadius='10px' border='1px solid #A9A9A9'>
                            <AccordionButton _focus='' bg='white' borderRadius='10px' border='1px solid #A9A9A9'>
                                <Grid textAlign='start' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem textDecoration='underline'>{estimate?.reference}</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserEstimateReu?.company?.length > 0 ? estimate?.fulfilledByUserEstimateReu?.company[0]?.name : estimate?.fulfilledByUserEstimateReu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserEstimateReu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserEstimateReu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserEstimateReu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserEstimateReu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserEstimateReu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserEstimateReu?.firstName + ' ' + estimate?.fulfilledByUserEstimateReu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={5}>{moment(estimate?.createdAt.date).format('MMM DD, YYYY')}</GridItem>
                                    <GridItem>
                                        <Button
                                            onClick={() => {
                                                if (estimate?.isShipping) {
                                                    for (let i = 0; i < 2; i++) {
                                                        downloadPdf(estimate, ORDER_TYPES[i])
                                                    }
                                                } else if (estimate?.isOrder) {
                                                    downloadPdf(estimate, ESTIMATE)
                                                } else if (estimate?.isInvoice) {
                                                    for (let i = 0; i < 3; i++) {
                                                        downloadPdf(estimate, ORDER_TYPES[i])
                                                    }
                                                } else if (estimate?.isReceipt) {
                                                    for (let i = 0; i < 4; i++) {
                                                        downloadPdf(estimate, ORDER_TYPES[i])
                                                    }
                                                }else if (estimate?.markedFulfilled) {
                                                    for (let i = 0; i < 5; i++) {
                                                        downloadPdf(estimate, ORDER_TYPES[i])
                                                    }
                                                }

                                            }}
                                            isLoading={loading[estimate.reference]}
                                            width='110px' height='30px' leftIcon={<DownloadIcon/>}>Download All</Button>
                                    </GridItem>
                                </Grid>
                                <AccordionIcon/>
                            </AccordionButton>
                            <AccordionPanel pr={9} pb={0}>
                                <Grid mb={2} minH='57px' display={estimate?.fulfilledByUserEstimateReu ? 'grid' : 'none'} textAlign='start' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Estimate</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserEstimateReu?.company?.length > 0 ? estimate?.fulfilledByUserEstimateReu?.company[0]?.name : estimate?.fulfilledByUserEstimateReu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserEstimateReu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserEstimateReu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserEstimateReu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserEstimateReu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserEstimateReu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserEstimateReu?.firstName + ' ' + estimate?.fulfilledByUserEstimateReu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={5}>{moment(estimate?.createdAt?.date).format('MMM DD, YYYY')}</GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, ESTIMATE)} isLoading={loading[estimate.reference]} width='110px' height='30px'><DownloadIcon/></Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserOrderReu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' borderTop='1px solid #A9A9A9' justifyContent='space-between'>
                                    <GridItem>Order</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserOrderReu?.company?.length > 0 ? estimate?.fulfilledByUserOrderReu?.company[0]?.name : estimate?.fulfilledByUserOrderReu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserOrderReu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserOrderReu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserOrderReu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserOrderReu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserOrderReu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserOrderReu?.firstName + ' ' + estimate?.fulfilledByUserOrderReu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={5}>{moment(estimate?.orderDateCreated?.date).format('MMM DD, YYYY')}</GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, ORDER)} isLoading={loading[estimate.reference]} width='110px' height='30px'><DownloadIcon/></Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserShippingReu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' borderTop='1px solid #A9A9A9' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Shipping</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserShippingReu?.company?.length > 0 ? estimate?.fulfilledByUserShippingReu?.company[0]?.name : estimate?.fulfilledByUserShippingReu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserShippingReu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserShippingReu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserShippingReu?.type === "Sa" ? '#A6F484' :
                                                                estimate?.fulfilledByUserShippingReu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserShippingReu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserShippingReu?.firstName + ' ' + estimate?.fulfilledByUserShippingReu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={4}>{moment(estimate?.shippingDateCreated?.date).format('MMM DD, YYYY')}
                                    </GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, SHIPPING)} isLoading={loading[estimate.reference]} width='110px' height='30px'><DownloadIcon/></Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserInvoiceReu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' borderTop='1px solid #A9A9A9' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Invoice</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserInvoiceReu?.company?.length > 0 ? estimate?.fulfilledByUserInvoiceReu?.company[0]?.name : estimate?.fulfilledByUserInvoiceReu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserInvoiceReu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserInvoiceReu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserInvoiceReu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserInvoiceReu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserInvoiceReu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserInvoiceReu?.firstName + ' ' + estimate?.fulfilledByUserInvoiceReu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={4}>{moment(estimate?.invoiceDateCreated?.date).format('MMM DD, YYYY')}</GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, INVOICE)} isLoading={loading[estimate.reference]} width='110px' height='30px'><DownloadIcon/></Button>
                                    </GridItem>
                                </Grid>
                                <Grid display={estimate?.fulfilledByUserReceiptReu ? 'grid' : 'none'} mb={2} minH='57px' textAlign='start' borderTop='1px solid #A9A9A9' templateColumns="repeat(6, 1fr)" alignItems='center' width='full' justifyContent='space-between'>
                                    <GridItem>Receipt</GridItem>
                                    <GridItem>
                                        {estimate?.fulfilledByUserReceiptReu?.company?.length > 0 ? estimate?.fulfilledByUserReceiptReu?.company[0]?.name : estimate?.fulfilledByUserReceiptReu?.associatedToCompany?.name}
                                    </GridItem>
                                    <GridItem>{estimate?.report?.project?.title}</GridItem>
                                    <GridItem pl={2}>
                                        <Flex alignItems='center' display='flex'>
                                            <Text mr={2}
                                                bg={
                                                    estimate?.fulfilledByUserReceiptReu?.type === 'Reu' ? '#C956FF' :
                                                        estimate?.fulfilledByUserReceiptReu?.type === 'Su' ? '#FFD339' :
                                                            estimate?.fulfilledByUserReceiptReu?.type === 'Sa' ? '#A6F484' :
                                                                estimate?.fulfilledByUserReceiptReu?.type === 'Au' ? '#ACDAFF' :
                                                                    estimate?.fulfilledByUserReceiptReu?.type === 'Eu' ? '#FF8660' :
                                                                        'red'
                                                }
                                                width='15px'
                                                height='15px'
                                                border='1px solid black'
                                                borderRadius='100%'/>
                                            {estimate?.fulfilledByUserReceiptReu?.firstName + ' ' + estimate?.fulfilledByUserReceiptReu?.lastName}
                                        </Flex>
                                    </GridItem>
                                    <GridItem pl={4}>{moment(estimate?.receiptDateCreated?.date).format('MMM DD, YYYY')}</GridItem>
                                    <GridItem>
                                        <Button onClick={() => downloadPdf(estimate, RECEIPT)} isLoading={loading[estimate.reference]} width='110px' height='30px'><DownloadIcon/></Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                ))
            }
        </Box>
    )
}

export default Fulfilled