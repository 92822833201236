import React, {useEffect, useState} from 'react'
import {useHistory, Link, useLocation,} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Text, Button, useDisclosure,} from '@chakra-ui/react'
import {useCreateAccessory, useListProducts, useUpload, useUser,} from '../../core/hooks'
import Navbar from '../../components/Navbar/Navbar'
import styles from './styles'
import MainFields from './MainFields'
import SecondaryFields from './SecondaryFields'
import {CompatibleProductsModal} from "../../constants/mainFunctions";

const CreateAccessory = () => {
    const [certifications, setCertifications] = useState([])
    const [orderCodesComp, setOrderCodesComp] = useState([])
    const [compatibleProductOrderCodes, setCompatibleProductOrderCodes] = useState([])
    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            description: '',
            image: '',
            manufacturer: '',
            orderCode: '',
            dimensionsLength: '',
            lifeTime: '',
            installationTime: '',
            dimensionsWidth: '',
            warranty: '',
            deliveryDays: '',
            dimensionsDiameter: '',
            vMin: '',
            pricePerUnit: '',
            dimensionsDepth: '',
            vMax: '',
            volumeTier1MinQty: '',
            powerSupplyType: '',
            dimmability: '',
            volumeTier1PPU: '',
            wattage: '',
            volumeTier2MinQty: '',
            lampsPoweredQTY: '',
            volumeTier2PPU: '',
            productDescription: '',
            productSpecificationsHyperlink: '',
            installationInstructionsHyperlink: '',
            controlType: '',
            accessoryCategory: '',
        }
    })
    const [image, setImage] = useState('')
    const {upload, isLoading: uploadIsLoading,} = useUpload()
    const {createAccessory, isLoading: isCreateLoading} = useCreateAccessory()
    const history = useHistory()
    const {user} = useUser()
    const location = useLocation()
    const type = location.pathname.split('/')[3]
    let condition = {condition: {filter: '', table: type.includes('power') ? 'POWER' : type.includes('control') ? 'CONTROL' : 'ACCESSORY'}}
    const {data: productsResponse} = useListProducts(condition)
    const {isOpen: isOpenProducts, onOpen: onOpenProducts, onClose: onCloseProducts} = useDisclosure()

    // Set compatible product order codes for this user
    useEffect(() => {
        if (!productsResponse) return
        let products = productsResponse.data.data
        let compatibleOrderCodes = []

        for (let i = 0; i < products.length; i++) {
            if (type === 'lamp'){
                compatibleOrderCodes.push(products[i].fields['Order Code (calculated)'])
            } else {
                compatibleOrderCodes.push(products[i].fields['Order Code'])
            }
        }
        setOrderCodesComp(compatibleOrderCodes)
    },[productsResponse])

    const submit = (data) => {
        const input = {
            ...data,
            image,
            certifications: certifications,
            companyId: user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany?.id,
            compatibleOrderCodes: compatibleProductOrderCodes,
            compatibleProductOrderCodes: compatibleProductOrderCodes,
            type: type,
        }
        createAccessory(input,
            {
                onSuccess: (e) => {
                    history.push(`/catalogues/accessory/${ type.includes('power') ? 'power-supplies' : type.includes('control') ? 'controls' : 'accessories'}/${e.data.data}`)
                },
                onError: () => {},
            },
        )
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <CompatibleProductsModal
                isOpen={isOpenProducts}
                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                compatibleProductOrderCodes={compatibleProductOrderCodes}
                onClose={onCloseProducts}
                table={type.includes('power') ? 'Power Supplies' : type.includes('control') ? 'Controls' : 'Accessories'}/>
            <Navbar minW="650px"
                title={(
                    <Flex>
                        <Link to="/catalogues">Catalogues&nbsp;</Link>
                        /&nbsp;{`Create ${type}`}
                    </Flex>)}>
                <Button bg="#C2EF62" color="black" mr={8} ml={12} px={8} isLoading={isCreateLoading || uploadIsLoading} isDisabled={isCreateLoading || uploadIsLoading} w="135px" type="submit" h="40px">
                    <img src="/images/done.png" alt="done"/>
                    <Text fontWeight="700" ml={1} mt="2px">Done</Text>
                </Button>
            </Navbar>
            <Box m="auto"  minW="650px" maxW="1240px">
                <Tabs css={styles.container} bg="#F5F5F5">
                    <TabList mx="-1px" border="none" pt={10} bg="white.100">
                        <Tab fontFamily='Titillium Web' minW="208px" fontSize="18px" _active={{background: 'transparent',}} _selected={{color: 'green.200',borderBottomWidth: '3px', borderColor: 'green.200', boxShadow: 'none',}} color="green.200">
                            Product Details
                        </Tab>
                    </TabList>
                    <TabPanels pt={6} px={10} borderTop="1px solid #DBDADA" borderBottom="none">
                        <TabPanel pb={40}>
                            <MainFields
                                certifications={certifications}
                                setCertifications={setCertifications}
                                user={user}
                                type={type}
                                register={register}
                                errors={errors}
                                image={image}
                                setImage={setImage}
                                upload={upload}/>
                            <SecondaryFields
                                onOpenProducts={onOpenProducts}
                                orderCodesComp={orderCodesComp}
                                compatibleProductOrderCodes={compatibleProductOrderCodes}
                                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                                register={register}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </form>
    )
}

export default CreateAccessory
