import {css} from '@emotion/react'

const styles = {
    tr: css`
      border: none;
      cursor: pointer;
      border-radius: 10px;
      min-height: 57px;
      font-family: Frank Ruhl Libre, serif;

    & > div:first-of-type {
        border-radius: 10px 0 0 10px;

        &:hover {
          text-decoration: underline;
        }
      }

      & > div {
        min-width: 146px;
      }

      & > div:last-of-type {
        border-radius: 0 10px 10px 0;
      }
    
    & > td:first-of-type {
        border-radius: 10px 0 0 10px;

        &:hover {
          text-decoration: underline;
        }
      }

      & > td {
        min-height: 57px;
        min-width: 146px;
      }

      & > td:last-of-type {
        border-radius: 0 10px 10px 0;
      }
    `,
    table: css`
      border-collapse: separate;
      border-spacing: 0 15px;

      th {
        text-transform: capitalize;
        letter-spacing: normal;
        color: black;
        font-size: 16px;
        font-family: Titillium Web, sans-serif;
        font-weight: 600;
        border: none;
      }
    `,
}

export default styles
