import {css} from '@emotion/react'

const styleEuTab = {
    tr: css`
      border: none;
      cursor: default;
      border-radius: 10px;

      & > td:first-of-type {
        font-weight: 500;
        border-radius: 10px 0 0 10px;

        &:hover {
          text-decoration: underline;
        }
      }

      & > td:last-of-type {
        border-radius: 0 10px 10px 0;
      }
    `,
    table: css`
      border-collapse: separate;
      border-spacing: 0 20px;

      th {
        width: 25%;
        color: black;
        text-transform: capitalize;
        font-family: Titillium Web;
        font-weight: 600;
        font-size: 16px;
      }
      td {
        font-size: 18px;
      }
    `,
}

export default styleEuTab