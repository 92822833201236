import {Button, Flex, Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Box, Image} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import React, {useRef, useState} from "react";
import SignatureCanvas from 'react-signature-canvas'
import {ReactComponent as PenIcon} from "../../assets/penIcon.svg";
import {ReactComponent as Upload} from "../../assets/cloud-upload.svg";
import {ReactComponent as Save} from "../../assets/unfinishedIcon.svg";
import {ReactComponent as Close} from "../../assets/closeIconBlack.svg";
import {useUpload, useUser} from "../../core/hooks";
import {S3_BUCKET} from "../../constants";
import {REU, SU} from "../../constants/user/userEnum";

const SignatureModal = ({isOpenSignature, onCloseSignature, setImageURL, setImageUrlAdmin, imageURL, adminRall, imageUrlAdmin}) => {
    const {user} = useUser()
    const [writeSignature, setWriteSignature] = useState(false)
    const sigCanvas = useRef()
    const inputRef = useRef()
    const {upload, isLoading} = useUpload()

    const create = () => {
        const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        if (user.type === SU || user.type === REU){
            setImageURL(URL)
        } else {
            setImageUrlAdmin(URL)
        }
        setWriteSignature(false)
    }

    const handleImage = (e) => {
        const file = e.target.files[0]
        const type = file.type.split('/')[1] || 'jpeg'
        const name = `${new Date().getTime() * Math.random()}.${type}`
        upload(
            {name, file, type},
            {
                onSuccess: (data) => {
                    let URL = S3_BUCKET+data.data.data
                    if (user.type === SU || user.type === REU){
                        setImageURL(URL)
                    } else {
                        setImageUrlAdmin(URL)
                    }
                    setWriteSignature(false)
                },
            },
        )
    }

    return (
        <>
            <Modal
                isCentered
                isOpen={isOpenSignature}
                onClose={() => {
                    onCloseSignature()
                    setWriteSignature(false)
                }}>
                <ModalOverlay/>
                <ModalContent
                    overflow="hidden"
                    borderRadius="10px"
                    minW="853px">
                    <ModalHeader
                        bg='#88BFEB'
                        fontWeight="700"
                        fontSize="24px"
                        as={'h2'}
                        m={0}
                        textAlign="center">
                       Electronic Signature Authorization
                    </ModalHeader>
                    <IconButton
                        height="unset"
                        onClick={() => {
                            onCloseSignature()
                            setWriteSignature(false)
                        }}
                        _hover={{
                            bg: 'none',
                        }}
                        _active={{
                            bg: 'none',
                        }}
                        bg="none"
                        aria-label="close button"
                        position="absolute"
                        top="1.5rem"
                        right="0.75rem"
                        icon={
                            <Icon type="darkClose"/>
                        }/>
                    <ModalBody
                        pt='50px'
                        pl='120px'
                        pr='120px'
                        fontSize='24px'
                        textAlign='center'
                        overflow="scroll"
                        maxH="600px">
                        <Text>
                            Create or upload an electronic signature:
                        </Text>
                        <Flex
                            justifyContent='center'>
                            <Flex
                                flexDirection='column'
                                maxW='400px'>
                                {
                                    !adminRall ? (
                                        <Box
                                            width='400px'
                                            mt={4}
                                            height='50px'
                                            borderRadius='6px'
                                            border='1px solid #A9A9A9'>
                                            {
                                                !writeSignature ? (
                                                    <Flex
                                                        justifyContent='space-between'
                                                        alignItems='center'
                                                        height='100%'
                                                        pl={4}
                                                        pr={4}>
                                                        <Text
                                                            py={2}
                                                            width={imageURL ? '100%': 'auto'}
                                                            height={imageURL ? '100%' : 'auto'}
                                                            color='rgba(90, 90, 90, 0.87)'>
                                                            {
                                                                imageURL ? <Image
                                                                        objectFit='contain'
                                                                        src={imageURL}
                                                                        alt="Electronic signature"
                                                                        />
                                                                    : 'Write Signature'
                                                            }
                                                        </Text>
                                                        <button
                                                            onClick={() => setWriteSignature(true)}>
                                                            <PenIcon/>
                                                        </button>
                                                    </Flex>
                                                ) : (
                                                    <Flex
                                                        justifyContent='space-between'>
                                                        <SignatureCanvas
                                                            ref={sigCanvas}
                                                            penColor='black'
                                                            canvasProps={{width: 300, height: 50, className: 'sigCanvas'}}/>
                                                        <Flex
                                                            pr={4}>
                                                            <button
                                                                onClick={() => {
                                                                    sigCanvas.current.clear()
                                                                    return setImageURL('')
                                                                }}
                                                                style={{'margin-right': '10px'}}>
                                                                <Close
                                                                    width='20px'
                                                                    height='20px'
                                                                    fill='#222222'/>
                                                            </button>
                                                        </Flex>
                                                    </Flex>
                                                )
                                            }
                                        </Box>
                                    ) : (
                                        <Box
                                            width='400px'
                                            mt={4}
                                            height='50px'
                                            borderRadius='6px'
                                            border='1px solid #A9A9A9'>
                                            {
                                                !writeSignature ? (
                                                    <Flex
                                                        justifyContent='space-between'
                                                        alignItems='center'
                                                        height='100%'
                                                        pl={4}
                                                        pr={4}>
                                                        <Text
                                                            width={imageUrlAdmin ? '100%': 'auto'}
                                                            height={imageUrlAdmin ? '100%' : 'auto'}
                                                            color='rgba(90, 90, 90, 0.87)'>
                                                            {
                                                                imageUrlAdmin ? <Image
                                                                        objectFit='contain'
                                                                        src={imageUrlAdmin}
                                                                        height='100%'
                                                                        alt="Electronic signature"
                                                                        width='100%'/>
                                                                    : 'Write Signature'
                                                            }
                                                        </Text>
                                                        <button
                                                            onClick={() => setWriteSignature(true)}>
                                                            <PenIcon/>
                                                        </button>
                                                    </Flex>
                                                ) : (
                                                    <Flex
                                                        justifyContent='space-between'>
                                                        <SignatureCanvas
                                                            ref={sigCanvas}
                                                            penColor='black'
                                                            canvasProps={{width: 300, height: 50, className: 'sigCanvas'}}/>
                                                        <Flex
                                                            pr={4}>
                                                            <button
                                                                onClick={() => {
                                                                    sigCanvas.current.clear()
                                                                    return setImageURL('')
                                                                }}
                                                                style={{'margin-right': '10px'}}>
                                                                <Close
                                                                    width='20px'
                                                                    height='20px'
                                                                    fill='#222222'/>
                                                            </button>
                                                            {/*<button*/}
                                                            {/*    onClick={create}>*/}
                                                            {/*    <Save*/}
                                                            {/*        width='22px'*/}
                                                            {/*        height='22px'/>*/}
                                                            {/*</button>*/}
                                                        </Flex>
                                                    </Flex>
                                                )
                                            }
                                        </Box>
                                    )
                                }

                                <Flex
                                    mt={5}
                                    borderRadius='6px'
                                    border='1px solid #A9A9A9'
                                    justifyContent='space-between'
                                    alignItems='center'
                                    height='50px'
                                    pl={4}
                                    pr={4}>
                                    <Text
                                        color='rgba(90, 90, 90, 0.87)'>
                                        Upload Signature
                                    </Text>
                                    <input
                                        onChange={handleImage}
                                        type="file"
                                        style={{display: 'none'}}
                                        accept="image/*"
                                        ref={inputRef}/>
                                    <button
                                        onClick={() => inputRef.current.click()}>
                                        <Upload
                                            width='22px'
                                            height='22px'/>
                                    </button>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex
                            pb='80px'
                            alignItems='center'
                            flexDirection='column'>
                            <Button
                                mt={10}
                                mb={3}
                                onClick={() => {
                                    create()
                                    onCloseSignature()
                                }}
                                width='254px'
                                height='51px'
                                fontWeight='700'
                                fontSize='18px'
                                backgroundColor='#2C628D'>
                                Authorize
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default SignatureModal