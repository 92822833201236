import React, {useState, useRef, useMemo} from 'react'
import moment from 'moment'
import {
    Flex,
    HStack,
    Image,
    Text,
    Button as ChakraButton,
    Button,
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Stack,
} from '@chakra-ui/react'
import {
    useCreateActivity,
    useListProjects,
    useUpdateProject,
    useUpdateUser,
    useUploadMultiple,
    useUser
} from '../../core/hooks'
import DragDrop from './UploadDragDrop'
import {ReactComponent as Search} from '../../assets/search.svg'
import {ReactComponent as Download} from '../../assets/download.svg'
import Navbar from '../../components/Navbar'
import styles from '../CreateProject/styles'

const ProjectUpload = () => {
    const [newProject, setNewProject] = useState()
    const [loading, setLoading] = useState(false)
    const inputRef = useRef()
    const {user, setUser} = useUser()
    const {data: projects} = useListProjects({filter: {userId: {eq: user?.id}}})
    const {mutate: updateProject} = useUpdateProject()
    const {mutate: updateUser} = useUpdateUser()
    const {createActivity} = useCreateActivity()
    const {upload, isLoading, error,} = useUploadMultiple()
    const [success, setSuccess] = useState(false)

    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        const activityInput = {
            userName: `${user?.firstName} ${user?.lastName}`,
            userType: user?.type,
            action: 'Project-Upload Activity',
        }
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type})
            },
        )
        upload(filesToUpload,
            {
                onSuccess: () => {
                    const files = user.files ? [...user?.files, ...dataToPreview] : [...dataToPreview]
                    updateUser(
                        {id: user?.id, files},
                        {onSuccess: () => setUser({...user, files})}
                    )
                    setSuccess(true)
                },
            })
        createActivity(activityInput)
    }

    useMemo(() => {
        if (!newProject) return
        setLoading(true)
        const currentProject = projects?.data.listProjects?.items.filter((item) => item.id === newProject.id)[0]
        let newFiles
        if (currentProject.files && currentProject.files.length > 0) {
            newFiles = [...currentProject.files, {...newProject.file}]
        } else {
            newFiles = [{...newProject.file}]
        }
        updateProject(
            {id: currentProject.id, files: newFiles,},
            {onSuccess: () => setLoading(false)}
        )
    }, [newProject])

    return (
        <>
            <Navbar minW="850px" title="Upload"/>
            <Box mx="auto" bg="#f5f5f5" p="2rem" style={{minHeight: 'calc(100vh - 60px)'}} maxW="1240px">
                <HStack spacing="13rem" mt="40px" mb="58px">
                    <Text fontSize="18px" fontWeight="400">
                        Upload pertinent property files by dragging them into the gray area (below) or browse your
                        device (blue button).
                    </Text>
                    <input onChange={handleImage} type="file" multiple style={{display: 'none'}} ref={inputRef}/>
                    <ChakraButton isLoading={loading} leftIcon={<Search/>} onClick={() => inputRef.current.click()}
                                  minW="251px" fontSize="xl" size="lg" variant="blue">
                        Browse For Files
                    </ChakraButton>
                </HStack>
                <DragDrop isCreatingLoading={isLoading} success={success} error={error} handleFile={handleImage}
                          isLoading={isLoading}/>
                {user?.files?.length > 0 && (
                    <Text my="6" fontSize="20px" fontWeight="500">
                        Uploaded Files
                    </Text>
                )}
                <Flex mt="6" w="full" flexDirection="column">
                    {
                        user?.files?.map(
                            (file) => (
                                <Stack>
                                    <Flex css={styles.file}>
                                        <Flex alignItems="center">
                                            <Image src="/icons/drag.svg" alt="Drag"/>
                                            <Text overflow="scroll" w="300px" ml="10" whiteSpace="nowrap"
                                                  fontWeight="500">
                                                {file.name}
                                            </Text>
                                        </Flex>
                                        <Text ml="10" fontWeight="500">
                                            {moment(file.createdAt).format('MMM DD, YYYY')}
                                        </Text>
                                        <Flex key={file.key} justifyContent="space-between">
                                            <Flex alignItems="center">
                                            </Flex>
                                            <Menu>
                                                <MenuButton as={Button} variant="blue">
                                                    Add To Project
                                                </MenuButton>
                                                <MenuList>
                                                    {projects?.data.data.items.map((item) => (
                                                        <MenuItem key={item.id}
                                                                  onClick={() => setNewProject({id: item.id, file})}
                                                                  value={item.id}>{item.title}</MenuItem>
                                                    ))}
                                                </MenuList>
                                            </Menu>
                                        </Flex>
                                        <Button mr={8} leftIcon={<Download/>} bg="#FFAF8D" color="black" height="20px"
                                                fontSize="12px">
                                            Download
                                        </Button>
                                    </Flex>
                                </Stack>
                            ))}
                </Flex>
            </Box>
        </>
    )
}

export default ProjectUpload
