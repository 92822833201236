import React, {useState, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {
    Container,
    Flex,
    Text,
    Button as TextButton,
    Box,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import Main from './Main'
import {useCreateUser, useSignUp} from '../../../core/hooks'
import {Button} from '../../../components/Theme'
import Interests from '../../../components/Register/Interests'
import Features from '../../../components/Register/Features'
import Done from '../../../components/Register/Done'
import AllSetGuests from '../../../components/AllSetGuests'
import authService from "../../../core/services/authService";
import useCheckEmail from "../../../core/services/requests";

const Gu = () => {
    const {checkEmail} = useCheckEmail()
    const {createUser, isLoading: isUserCreatingLoading} = useCreateUser()
    const [error, setError] = useState('')
    const [done, setDone] = useState(false)
    const [page, setPage] = useState(1)
    const {handleSubmit} = useForm()
    const history = useHistory()
    const {isLoading} = useSignUp();
    const main = useRef({
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        password: '',
        repeatPassword: '',
        checkbox: false,
    })
    const interests = useRef([])
    const features = useRef([])
    const hearFromUs = useRef(null)
    const handleBack = () => {
        if (page === 1) {
            return history.push('/register')
        }
        setPage(page - 1)
    }

    const validateEmail = (email) => {
        if (
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
                email,
            )
        ) {
            return true
        }
        return false
    }

    const submit = async (data) => {
        if (page === 1 && (!main.current.lastName || !main.current.firstName || !main.current.email || !main.current.phone || !main.current.password || !main.current.repeatPassword)) {
            return setError('All fields are required')
        }
        if (page === 1 && !main.current.checkbox){
            return setError('Agree the terms of use to continue')
        }
        if (page === 1 && !validateEmail(main.current?.email)) {
            return setError('Wrong Email format')
        }
        if (page === 2 && interests.current.length < 1) {
            return setError('Select at least one')
        }
        if (page === 3 && features.current.length < 1) {
            return setError('Select at least one')
        }
        const input = {
            firstName: main.current.firstName,
            lastName: main.current.lastName,
            email: main.current.email.toLowerCase(),
            password: main.current.password,
            phone: main.current.phone,
            interests: interests.current,
            features: features.current,
            hearFromUs: hearFromUs.current,
            type: 'Gu',
        }
        const mail = main.current.email.toLowerCase()
        if (page === 1) {
            return await checkEmail(mail)
                .then(function (result) {
                    setError('')
                    return setPage(page + 1)
                })
                .catch(function (err) {
                    return setError(err.response.data.errorMessage)
                })
        }
        if (page === 4) {
            createUser(
                input,
                {
                    onSuccess: () => {
                        setDone(true)
                    }, onError: (e) => {
                    }
                },
            )
        }
        setError('')
        setPage(page + 1)
    }

    return (
        <Container position="relative" display="flex" alignItems="center" flexDirection="column" mx={4} py={12} mt={10} pt={-10} minH="484px" maxW="6xl" borderRadius="10px" boxShadow="0px 4px 10px rgba(34, 34, 34, 0.25)" bg="rgba(255, 255, 255, 0.8)">
            {done ? (<AllSetGuests/>) : (
                    <>
                        <TextButton onClick={handleBack} variant="transparent" position="absolute" top="30px" left="30px">
                            <img width="25px" src="/images/back_register.png" alt="back arrow"/>
                            <Text ml={1} fontSize="18px" fontWeight='600'>BACK</Text>
                        </TextButton>
                        <form onSubmit={handleSubmit(submit)}>
                            <Flex flexDirection="column" justifyContent="center">
                                {page === 1 ? (<Main main={main} error={error}/>)
                                        : page === 2 ? (<Interests interests={interests}/>)
                                            : page === 3 ? (<Features features={features}/>) : <Done hearFromUs={hearFromUs}/>
                                }
                                {
                                    error && (
                                        <Text
                                            mt={2}
                                            textAlign="center"
                                            color="red.100"
                                            fontSize="m">
                                            {error}
                                        </Text>
                                    )
                                }
                                <Flex
                                    justifyContent="center"
                                    alignItems="center"
                                    mt={8}>
                                    <Text
                                        color="black"
                                        fontSize="lg">
                                        STEP&nbsp;
                                        <Text color="black" as="span" fontWeight="700">
                                            {page}&nbsp;
                                        </Text>
                                        OF&nbsp;
                                        <Text color="black" as="span" fontWeight="700">
                                            4
                                        </Text>
                                    </Text>
                                    <Flex mx={6}>
                                        {
                                            [1, 2, 3, 4].map((item: number) => (
                                                <Box
                                                    key={item}
                                                    mx={1}
                                                    width="20px"
                                                    height="20px"
                                                    backgroundColor={item <= page ? 'green.200' : 'grey.200'}
                                                    border="2px solid"
                                                    borderColor={item <= page ? 'green.200' : 'grey.100'}
                                                    borderRadius="100%"/>
                                            ))
                                        }
                                    </Flex>
                                    {
                                        page === 4
                                            ? <Button
                                                isLoading={isLoading}
                                                color="white !important"
                                                type={createUser}
                                                height="40px !important"
                                                fontSize="16px !important"
                                                width="467px">
                                                <Text
                                                    mr={3}>
                                                    All done
                                                </Text>
                                                <img
                                                    width='25px'
                                                    src="/images/gu_next_step.png"
                                                    alt="next step"/>
                                            </Button>
                                            : <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                height="40px !important"
                                                width="467px">
                                                <Text
                                                    mr={3}
                                                    fontWeight="700"
                                                    fontSize="18px"
                                                    color="white">
                                                    Next Step
                                                </Text>
                                                <img
                                                    width='25px'
                                                    src="/images/gu_next_step.png"
                                                    alt="next step"/>
                                            </Button>
                                    }
                                </Flex>
                            </Flex>
                        </form>
                    </>
                )
            }
        </Container>

    )
}

export default Gu
