import {Flex, Heading, Stack} from "@chakra-ui/react";
import UsersCountChart from "../DataCharts/UsersCountChart";
import Loading from "../../../components/Loading";
import DataChart from "../parts/DataChart";
import TotalWithChart from "../parts/TotalWithChart";
import UsagePart from "../parts/UsagePart";
import ChartLuminaires from "../DataCharts/ChartLuminaires";

const Overview = ({data, countUsers, isFetching, usage, isLoading, countUsersTotal, companyColors}) => {

    if (isLoading || isFetching) return <Loading/>

    return (
        data && (
            <Stack spacing={10}>
                <Flex borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                    <Heading m={0} fontSize='24px'>Overview</Heading>
                </Flex>
                <Flex mt={9} justifyContent='space-between'>
                    <Flex width='487px' flexDirection='column'>
                        <Flex pl={4} justifyContent='space-between'>
                            <Heading m={0} fontSize='16px' width='33%'>User Type</Heading>
                            <Heading m={0} fontSize='16px' pl={3} width='33%'>User QTY</Heading>
                            <Heading m={0} fontSize='16px' pl={3} width='33%'>EP Usage (hrs)</Heading>
                        </Flex>
                        {
                            Object.keys(countUsers).length > 0 && Object.entries(countUsers).map(([key, item]) => (
                                <Flex
                                    fontSize='18px'
                                    mt={4}
                                    pl={4}
                                    key={key}
                                    alignItems='center'
                                    minH='57px'
                                    bg='#FFFFFF'
                                    boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)'
                                    borderRadius='10px'
                                    justifyContent='space-between'>
                                    <Flex width='33%' pl={3} alignItems='center' height='100%' borderLeft={`12px solid 
                                ${key === 'Reu' ? '#C956FF' : key === 'Su' ? '#FFD439' : key === 'Eu' ? '#FF7F56'
                                        : key === 'Sa' ? '#8FCF74' : '#D1D1D1'}`}>
                                        {key === 'Reu' ? 'Real Estate' : key === 'Su' ? 'Supplier' : key === 'Eu' ? 'Electrician'
                                            : key === 'Sa' ? 'Sales' : 'Administrator'}
                                    </Flex>
                                    <Flex pl={3} width='33%'>{item?.count_c}</Flex>
                                    <Flex pl={3} width='33%'>{item?.sum_total}</Flex>
                                </Flex>
                            ))
                        }
                        <Flex mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07' bg='#FFFFFF'
                              boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px' fontWeight='700'
                              justifyContent='space-between'>
                            <Heading m={0} fontSize='18px' color='#2E8A07' width='33%' pl={3}>
                                Total
                            </Heading>
                            <Heading m={0} fontSize='18px' pl={3} width='33%'>{countUsersTotal?.count_c}</Heading>
                            <Heading m={0} fontSize='18px' pl={3} width='33%'>{countUsersTotal?.sum_total}</Heading>
                        </Flex>
                    </Flex>
                    <Flex>
                        <Flex alignItems='center' flexDirection='column'>
                            <Heading m={0} fontSize='16px'>Total Users: {countUsersTotal?.count_c}</Heading>
                            <UsersCountChart
                                type={1}
                                score={countUsers}/>
                        </Flex>
                        <Flex ml={7} alignItems='center' flexDirection='column'>
                            <Heading m={0} fontSize='16px'>Total EP Usage: {countUsersTotal?.sum_total}</Heading>
                            <UsersCountChart
                                type={2}
                                score={countUsers}/>
                        </Flex>
                    </Flex>
                </Flex>
                <DataChart
                    chartLeftSide={'Hours'}
                    header={'Most Active Companies'}
                    data={data['companies_usage']}/>
                <TotalWithChart
                    companyColors={companyColors}
                    data={data['companies_usage']}
                    gridName={'Company'}
                    gridNameCounts={'Total Users'}
                    chartTitle={'Total Users'}
                    header={'Users Per Company'}
                    chartSide={'left'}/>
                <Flex mt={8} borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
                    <Heading m={0} fontSize='24px'>New Users</Heading>
                </Flex>
                <Flex mt={6} justifyContent='space-evenly' alignItems="center">
                    <Flex fontFamily='Titillium Web' fontSize='16px' style={{writingMode: 'tb-rl'}} justifyContent='center'
                          alignItems='center' transform='rotate(-180deg)'>
                        New Users
                    </Flex>
                    <ChartLuminaires
                        countUsers={countUsers}/>
                </Flex>
                <UsagePart
                    companyColors={companyColors}
                    usage={usage}/>
            </Stack>
        )
    )
}

export default Overview