import { useMutation } from 'react-query'
import { companyUseCase } from '../../factories'

const useCreateCompany = () => {
  const {
    mutate: createCompany, isLoading, data, error,
  } = useMutation(companyUseCase.createCompany)

  return {
    createCompany,
    isLoading,
    error,
    data,
  }
}

export default useCreateCompany
