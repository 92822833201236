import { useMutation } from 'react-query'
import { companyUseCase } from '../../factories'

const useDeleteCompany = () => {
  const {
    mutate: deleteCompany, isLoadingDeleting, data, error,
  } = useMutation(companyUseCase.deleteCompany)

  return {
    deleteCompany,
    isLoadingDeleting,
    error,
    data,
  }
}

export default useDeleteCompany
