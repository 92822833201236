import React from 'react'
import {
  Select,
} from '@chakra-ui/react'
import styled from '@emotion/styled'

const ModifiedSelect = styled(Select)`
    border: 1px solid rgba(169, 169, 169, 0.87);
    border-radius: 5px;
    height: 50px;
    font-size: inherit;
    background: #fff;
    &::placeholder { 
        color: #a9a9a9;
        opacity: 1;
    }
      
    &:-ms-input-placeholder { 
        color: #a9a9a9;
    }
      
    &::-ms-input-placeholder { 
        color: #a9a9a9;
    }
    &:focus {
        box-shadow: none;
        outline: none;
        border-color: unset;
    }
`

const ChakraSelect = React.forwardRef((props, ref) => {
  const {
    placeholder,
    onChange,
    value,
    mr,
    ml,
    children,
    borderColor,
    backgroundColor,
  } = props
  return (
    <ModifiedSelect
      ref={ref}
      value={value}
      ml={ml}
      mr={mr}
      onChange={onChange}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      placeholder={placeholder}
      {...props}>
      {children}
    </ModifiedSelect>

  )
})

export default ChakraSelect
