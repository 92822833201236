import { useQuery } from 'react-query'
import {stateUseCase} from '../../factories'

const useListCities = (variables) => {
    const {
        refetch: refetchCities, isLoading: isLoadingCities, data: cities, error,
    } = useQuery('cities', () => stateUseCase.listCities(variables), {enabled: variables.length > 0})

    return {
        refetchCities,
        isLoadingCities,
        error,
        cities,
    }
}

export default useListCities
