import HttpRequest from "./http-request";

const dataService = () => {
    const {get} = HttpRequest()

    const getUsageMain = () => {
        return get('data/main')
    }

    const getUsage = (type) => {
        return get(`data/usage/${type}`)
    }

    const getDataWithType = (type) => {
        return get(`data/data-type/${type}`)
    }

    return {
        getUsageMain,
        getUsage,
        getDataWithType,
    }
}

export default dataService
