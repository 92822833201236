const stateUseCase = (stateRepo) => {
    const listStates = () => stateRepo.listStates()

    const listCities = (variables) => {
        let states = variables.map(item => item.name).toString()

        return stateRepo.listCities(states)
    }

    return {
        listStates,
        listCities
    }
}

export default stateUseCase
