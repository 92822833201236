import {Box, Heading, HStack, Switch, Text} from "@chakra-ui/react";
import styles from "./styles";
import {SUPERADMIN} from "../../../constants/user/userEnum";

const CommuniactionPreferencesAdmin = ({readOnly = false,userType = SUPERADMIN, setCommunicationPreferences, communicationPreferences}) => (
    <Box pb='150px' width='100%'>
        <Heading fontSize='24px' mb={0}>Communication Preferences</Heading>
        <Text
            fontSize='18px'
            fontFamily='Titillium Web'
            fontWeight='400'>
            Choose when you’d like Focus Earth to send you an email notification:
        </Text>
        <HStack fontSize='18px' justifyContent='space-between' mt='50px'>
            <HStack css={styles.communiactionBox}>
                <Text>Messages</Text>
                <Switch
                    isChecked={communicationPreferences.messages}
                    onChange={(e) => {
                        setCommunicationPreferences({...communicationPreferences, messages: e.target.checked})
                    }}
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>
                    Catalogues
                </Text>
                <Switch
                    isChecked={communicationPreferences.catalogues}
                    onChange={(e) => setCommunicationPreferences({...communicationPreferences, catalogues: e.target.checked})}
                    variant='secondary'
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>
                    Users
                </Text>
                <Switch
                    isChecked={communicationPreferences.users}
                    onChange={(e) => setCommunicationPreferences({...communicationPreferences, users: e.target.checked})}
                    variant='secondary'
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>Companies</Text>
                <Switch
                    isChecked={communicationPreferences.companies}
                    onChange={(e) => setCommunicationPreferences({...communicationPreferences, companies: e.target.checked})}
                    isReadOnly={readOnly}/>
            </HStack>
        </HStack>
        <HStack fontSize='18px' justifyContent='space-between' mt='50px'>
            <HStack css={styles.communiactionBox}>
                <Text>Bids</Text>
                <Switch
                    isChecked={communicationPreferences.bids}
                    onChange={(e) => setCommunicationPreferences({...communicationPreferences, bids: e.target.checked})}
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>Projects</Text>
                <Switch
                    isChecked={communicationPreferences.projects}
                    variant='secondary'
                    onChange={(e) => setCommunicationPreferences({...communicationPreferences, projects: e.target.checked})}
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>Orders</Text>
                <Switch
                    isChecked={communicationPreferences.orders}
                    variant='secondary'
                    onChange={(e) => setCommunicationPreferences({...communicationPreferences, orders: e.target.checked})}
                    isReadOnly={readOnly}/>
            </HStack>
            <HStack css={styles.communiactionBox}>
                <Text>Shipping</Text>
                <Switch
                    isChecked={communicationPreferences.shipping}
                    onChange={(e) => setCommunicationPreferences({...communicationPreferences, shipping: e.target.checked})}
                    isReadOnly={readOnly}/>
            </HStack>
        </HStack>
    </Box>
)

export default CommuniactionPreferencesAdmin