const stateRepo = (stateService) => {
    const listStates = () => stateService.listStates()

    const listCities = (variables) => stateService.listCities(variables)

    return {
        listStates,
        listCities,
    }
}

export default stateRepo
