import React from "react";
import {Box, Flex, Heading, Text, Input, Grid, GridItem} from "@chakra-ui/react";
import moment from "moment";
import {InputLabel, Textarea} from "../../../components/Theme";
import Luminaires from "./parts/Luminaires";
import styles from "./styles";
import {REU} from "../../../constants/user/userEnum";
import DatePicker from "react-modern-calendar-datepicker";

const Invoice = ({refererInvoice, estimate, luminaires, subTotal, notes, salesTax, shipping, total, depositAmount, depositDue, remainderDue, remainderAmount, setRemainderDueDate, setDepositPaidDate, user, remainderDueDate, depositPaidDate,}) => {
    return (
        <Box ref={refererInvoice} display={estimate?.invoiceDateCreated ? 'block' : 'none'} backgroundColor='white' p={9}>
            <Flex justifyContent='space-between'>
                <img src="/images/LogoIcon.svg" alt=""/>
                <Flex flexDirection='column'>
                    <Heading m={0} textAlign='end'>Invoice</Heading>
                    <Text>Reference #: {estimate?.reference}</Text>
                    <Text>Reference #: {estimate?.reference}</Text>
                    <Text textAlign='end'>Date : {moment(estimate?.invoiceDateCreated?.date).format('MM/DD/YYYY')}</Text>
                </Flex>
            </Flex>
            <Flex justifyContent='space-between' fontSize='18px' mt={4}>
                <Flex width='49%' borderRight='2px solid #9C9C9C' flexDirection='column'>
                    <Box backgroundColor='#EEEEEE' fontFamily='Titillium Web' width='98%' borderRadius='5px' mb={3} fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Billing Information:
                    </Box>
                    <Box fontSize='xl'>
                        <Text>Focus Earth</Text>
                        <Text>Attn: Pete Musser</Text>
                        <Text>1070 Tunnel Road</Text>
                        <Text>Building 1, Suite 10, #202</Text>
                        <Text>Asheville, NC 28805</Text>
                        <Text>USA</Text>
                        <Text>(925) 323-0053</Text>
                    </Box>
                </Flex>
                <Flex width='50%' flexDirection='column'>
                    <Box backgroundColor='#EEEEEE' fontFamily='Titillium Web' width='100%' borderRadius='5px' mb={3} fontSize='18px' pl={4} pt={2} pb={2} color='#222222' fontWeight='700'>
                        Shipping Information:
                    </Box>
                    <Box fontSize='xl'>
                        <Text>{estimate?.userReu?.company[0]?.name}</Text>
                        <Text>Attn : {estimate?.userReu?.firstName + ' ' + estimate?.userReu?.lastName}</Text>
                        <Text>{estimate?.report?.project?.address}</Text>
                        <Text>{estimate?.report?.project?.address2}</Text>
                        <Text>{estimate?.report?.project?.city + ', ' + estimate?.report?.project?.state + ', ' + estimate?.report?.project?.zipCode}</Text>
                        <Text>USA</Text>
                        <Text>{estimate?.userReu?.phone}</Text>
                    </Box>
                </Flex>
            </Flex>
            <Box backgroundColor='#8FCF74' fontFamily='Titillium Web'  width='100%' fontSize='18px' fontWeight='700' color='#222222' pl={4} pt={2} pb={2} borderRadius='5px' mt={10}>
                Order Information
            </Box>
            <Grid templateColumns='repeat(7, 1fr)' my={5} px={4}>
                <GridItem colSpan={3} as='h2' m={0}>
                    Description
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Part Number
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    QTY
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Price Per Unit
                </GridItem>
                <GridItem colSpan={1} as='h2' m={0} textAlign='center'>
                    Amount
                </GridItem>
            </Grid>
            <Luminaires luminaires={luminaires}/>
            <Flex justifyContent='end' mt={7}>
                <Flex width='50%' fontWeight='700' border='2px solid #8FCF74' height='64px' alignItems='center'
                      justifyContent='space-between' pl={6} pr={2} borderRadius='6px'>
                    <Input readOnly fontWeight='600' border='none' fontFamily='Titillium Web' value='Sub Total:' fontSize='18px'/>
                    <Flex justifyContent='end'>
                        <Input fontFamily='Titillium Web' readOnly border='none' fontSize='14px' fontWeight='600'
                               value={subTotal ? '$' + subTotal?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0}/>
                    </Flex>
                </Flex>
            </Flex>
            <Flex fontSize='18px !important' justifyContent='space-between'>
                <Flex width='50%' flexDirection='column'>
                    <Text color='#2C628D' fontWeight='700' fontFamily='Titillium Web'>Notes:</Text>
                    <Textarea readOnly fontSize='15px !important' value={notes} minH='130px'/>
                </Flex>
                <Flex flexDirection='column' color='#2C628D' mt={7}>
                    <Flex alignItems='center' justifyContent='space-between' minW='230px'>
                        <Text fontFamily='Titillium Web' textAlign='right'>Sales Tax:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder={0}
                                    leftAddon='$'
                                    disabled={user?.type === REU}
                                    value={salesTax && Number(salesTax)?.toLocaleString('en-US')}
                                    maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' >Shipping:</Text>
                        <InputLabel css={styles.centeredInput}
                                    placeholder={0}
                                    leftAddon='$'
                                    disabled={user?.type === REU}
                                    value={shipping && Number(shipping)?.toLocaleString('en-US')} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='space-between'>
                        <Text fontFamily='Titillium Web' fontWeight='700' textAlign='right' minW='70px'>Total:</Text>
                        <InputLabel
                                    placeholder={0}
                                    readOnly disabled={user?.type === REU}
                                    value={'$' + total?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    style={{'border': '2px solid #8FCF74'}} maxW='137px'/>
                    </Flex>
                </Flex>

            </Flex>
            <Flex justifyContent='space-between'>
                <Flex maxW='50%' flexDirection='column' fontSize='14px'>
                    <Flex fontWeight='700' mt={8}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Payment Terms:</Box>
                        <Text fontWeight='normal' width='55%'>50% Deposit due upon order placement<br/>50% Remainder due upon Delivery</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={8}>
                        <Box width='45%' fontFamily='Titillium Web' fontWeight='700'>Make Checks Payable To:</Box>
                        <Text fontWeight='normal' color='#619C48' width='55%'>Focus Earth</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={5}>
                        <Box width='45%' pr={4} fontFamily='Titillium Web' fontWeight='700'>Mail To:</Box>
                        <Text fontWeight='normal' width='55%'>1070 Tunnel Road Building 1, Suite 10, #202 Asheville, NC 28805</Text>
                    </Flex>
                    <Flex fontWeight='700' mt={5}>
                        <Box width='45%' pr={4} fontFamily='Titillium Web' fontWeight='700'>Electronic Payment:</Box>
                        <Text fontWeight='normal' width='55%'>Email accounting@focusearth.com</Text>
                    </Flex>
                </Flex>
                <Flex maxW='50%' fontSize='18px !important' flexDirection='column' justifyContent='end' color='#2C628D' mt={6}>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Deposit Amount:</Text>
                        <InputLabel css={styles.centeredInput} readOnly value={depositAmount && Number(depositAmount)?.toLocaleString('en-US')} leftAddon='$' ml={5} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end' css={styles.datePicker}>
                        <Text ml={7} fontFamily='Titillium Web'>Deposit Paid:</Text>
                        <DatePicker
                            name="startDate"
                            selected={depositPaidDate}
                            onChange={(date) => setDepositPaidDate(date)}
                            format="YYYY / MM / DD"
                            disabled={user?.type === REU}
                            className='test-class'
                            wrapperClassName='wrapper-class'
                            inputPlaceholder="Select a date"
                            calendarClassName='calendar-class'
                            formatInputText={() => {
                                if (!depositPaidDate) return '';
                                return `${depositPaidDate.month.toString().padStart(2, '0')}.${depositPaidDate.day.toString().padStart(2, '0')}.${depositPaidDate.year}`;
                            }}
                            value={depositPaidDate}
                        />
                    </Flex>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text ml={7} fontFamily='Titillium Web'>Remainder Amount:</Text>
                        <InputLabel readOnly css={styles.centeredInput}   ml={5} leftAddon='$' value={remainderAmount && Number(remainderAmount)?.toLocaleString('en-US')} maxW='137px'/>
                    </Flex>
                    <Flex alignItems='center' justifyContent='end' css={styles.datePicker}>
                        <Text ml={7} fontFamily='Titillium Web'>Remainder Due:</Text>
                        <DatePicker
                            name="startDate"
                            selected={remainderDueDate}
                            onChange={(date) => setRemainderDueDate(date)}
                            format="YYYY / MM / DD"
                            disabled={user?.type === REU}
                            className='test-class'
                            wrapperClassName='wrapper-class'
                            inputPlaceholder="Select a date"
                            calendarClassName='calendar-class'
                            formatInputText={() => {
                                if (!remainderDueDate) return '';
                                return `${remainderDueDate.month.toString().padStart(2, '0')}.${remainderDueDate.day.toString().padStart(2, '0')}.${remainderDueDate.year}`;
                            }}
                            value={remainderDueDate}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    )
}

export default Invoice