import { useQuery } from 'react-query'
import { productUseCase } from '../../factories'

const useListProducts = (variables) => {
  const {
    refetch,
    isLoading,
    isFetching,
    data,
    error,
  } = useQuery('products', () => productUseCase.listProducts(variables),{
    refetchOnWindowFocus: false,
  })

  return {
    refetch,
    isFetching,
    isLoading,
    error,
    data,
  }
}

export default useListProducts
