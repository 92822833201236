import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Flex } from '@chakra-ui/react'

const ChartCompany = ({ score }) => {
    // defaults.global.animation = undefined
    const {
        office,
        industrial,
        multi,
        retail,
        parking,
        hospitality,
        healthcare,
        horticulture,
        other,
        data,
    } = score
    let empty = 100

    const dataLocal = {
        datasets: [
            {
                label: '# of Votes',
                data: Object.values(score)
                    .reduce((total, currentValue) => (
                        total + currentValue), 0) === 0
                    ? [100]
                    : [
                        office,
                        industrial,
                        multi,
                        retail,
                        parking,
                        hospitality,
                        healthcare,
                        horticulture,
                        other,
                        data,
                        empty -= (office + industrial + multi + retail + parking + hospitality + healthcare + horticulture + other + data),
                    ],
                backgroundColor: Object.values(score)
                    .reduce((total, currentValue) => (
                        total + currentValue), 0) === 0
                    ? ['#d9d9d9']
                    : [
                        '#FF8EFA',
                        '#FF7F56',
                        '#C2EF62',
                        '#C1DBFF',
                        '#DD9323',
                        '#5DE2CA',
                        '#4A85B4',
                        '#F0D533',
                        '#2E8A07',
                        '#C956FF',
                        '#d9d9d9',
                    ],
                borderWidth: 0,
            },
        ],
    }
    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    if (Object.values(score)
                        .reduce((total, currentValue) => (
                            total + currentValue), 0) === 0) {
                        return '0'
                    }
                    return Object.keys(score)[tooltipItem[0].index]
                },
                label(tooltipItem, newLabel) {
                    if (Object.values(score)
                        .reduce((total, currentValue) => (
                            total + currentValue), 0) === 0) {
                        return '0'
                    }
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}%`
                },
            },
        },
        hover: { mode: null },
    }

    return (
        <Flex
            width="349px"
            height="349px">
            <Pie
                width={1000}
                height={1000}
                options={options}
                data={dataLocal}
                redraw />
        </Flex>
    )
}

export default ChartCompany
