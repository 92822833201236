import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {Flex, Image, Text, Button,} from '@chakra-ui/react'
import styles from './styles'
import {S3_BUCKET} from '../../constants'
import {ReactComponent as Download} from '../../assets/download.svg'
import {saveAs} from "file-saver"

const EuFiles = ({getValues,}) => {
    const [files, setFiles] = useState([])

    const saveFile = (link,name) => {
        saveAs(link,name);
    };

    useEffect(() => {
        if (!getValues('files')) return
        const newFiles = getValues('files')
        setFiles([...newFiles])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues('files')])

    return (
        <>
            {
                files?.length > 0 && (
                    <Flex my="6" fontSize="20px" fontWeight="500">
                        <Text>Uploaded Files</Text>
                        <Text w='80%' textAlign='center'>Date</Text>
                    </Flex>
                )}
            <Flex mt="6" flexDirection="column">
                {
                    files?.map((file) => (
                            <Flex key={file[0]?.key} css={styles.file}>
                                <Flex alignItems="center" w='40%'>
                                    <Image src="/icons/drag.svg" alt="Drag"/>
                                    <Text ml="10" fontWeight="500">{file[0].name}</Text>
                                </Flex>
                                <Flex w='30%' color='#A9A9A9' fontWeight="700">
                                    {moment(file[0]?.createdAt).format('MMM DD, YYYY')}
                                </Flex>
                                <Flex>
                                    <Button mr={8} width='89px' bg="#2C628D" color="black" onClick={() => saveFile(S3_BUCKET + file[1],file[1])} height="30px">
                                        <Download/>
                                    </Button>
                                </Flex>
                            </Flex>
                        ),
                    )
                }
            </Flex>
        </>
    )
}

export default EuFiles
