import {css} from '@emotion/react'

const styles = {
    tableWrapper: css`
      padding-top: 60px;
      background-color: #F5F5F5;

      .wrapperTotals {
        margin-bottom: 40px;
        padding-left: 20px;
        align-items: center;
        border-radius: 10px;
        height: 57px;
        font-weight: 500;
        font-size: 32px;
        box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
        width: 100%;
        color: white;
        background-color: #2C628D;
      }
    `,

    menu: css`
        max-width: 565px;
      & > div:nth-of-type(2) {
        width: 100%;
      }
    `,

    toast: css`
      animation-name: scroll;
      animation-duration: 4s;
      animation-timing-function: linear;
      @keyframes scroll {
        0% {
          left: 5px;
        }
        100% {
          left: 100%;
        }
      }
    `
}

export default styles
