import {css} from '@emotion/react'

const styles = {
    tr: css`
      height: 53px;

      & > td {
        font-size: 16px;
        border: 6px solid;
        border-color: rgba(255, 255, 255, 1);
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      & > td:not(:first-of-type) {
        text-align: center;
      }
    `,
}

export default styles
