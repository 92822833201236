import React, {useState, useRef, useEffect} from 'react'
import {
    Box,
    Text,
    Flex,
    Button as ChakraButton,
    Image, Button,
} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {DragDrop} from '../../components/Theme'
import styles from './styles'
import {
    useUpload,
} from '../../core/hooks'
import {S3_BUCKET} from '../../constants'
import {ReactComponent as DownloadIcon} from '../../assets/cloud-download.svg'
import {ReactComponent as Search} from '../../assets/search.svg'
import httpRequest from "../../core/services/http-request";
import moment from "moment";
import {ReactComponent as Download} from "../../assets/download.svg";
import {FILE_TYPE} from "../../constants/fileEnum";

const Upload = () => {
    const [success, setSuccess] = useState(false)
    const [files, setFiles] = useState([])
    const {post, getImage} = httpRequest()
    const {isLoading, upload} = useUpload()
    const inputRef = useRef()


    const handleFile = (e) => {
        const {files} = e.target
        // if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        //     return
        // }
        if (files && files[0]) {
            const filesToUpload = []
            const dataToPreview = []
            Object.values(files).forEach(
                (file) => {
                    const type = 'xlsm'
                    const name = `${new Date().getTime() * Math.random()}.${type}`
                    dataToPreview.push({
                        name: file.name,
                        key: name,
                        sortId: files.length,
                        createdAt: new Date().toISOString(),
                    })
                    filesToUpload.push({name, file, type,})
                },
            )
            upload(
                filesToUpload[0],
                {
                    onSuccess: (e) => {
                        post('create-uploads', [e.data.data, dataToPreview, FILE_TYPE.TEMPLATE]).then(() => {
                            getFiles()
                        }).catch((e) => {
                            console.log(e)
                        })
                        setSuccess(true)
                    },
                },
            )
        }
    }

    const getFiles = () => {
        let data = {
            type: FILE_TYPE.TEMPLATE
        }
        post('get-files', data).then((e) => {
            setFiles(e.data.data)
        })
    }

    useEffect(() => {
        getFiles()
    },[])

    return (
        <>
            <Navbar
                minW="850px"
                title="Upload"/>
            <Box
                minW="850px"
                m="auto"
                maxW="1240px"
                bg="#f5f5f5"
                css={styles.container}
                height="100%"
                pt={8}
                px={16}>
                <Flex
                    my={5}
                    alignItems="center"
                    justifyContent="space-between"
                    w="100%">
                    <Text
                        mb={8}
                        fontSize="xl"
                        w='50%'>
                        Download the Focus Earth template (green button). After populating your specifications and
                        pricing, upload the revised file by dragging it into the gray area (below) or browse your device
                        (blue button).
                    </Text>
                    <Flex
                        flexDirection='column'>
                        <a
                            href={`${S3_BUCKET}exel/Focus Earth Catalogue Upload Template - Q4 2024.xlsm`}
                            download>
                            <ChakraButton
                                mb={3}
                                leftIcon={<DownloadIcon/>}
                                minW="251px"
                                fontSize="xl"
                                variant="green"
                                size="lg">
                                Download Template
                            </ChakraButton>
                        </a>
                        <input
                            onChange={handleFile}
                            accept=".xlsm"
                            ref={inputRef}
                            style={{display: 'none'}}
                            type="file"/>
                        <ChakraButton
                            leftIcon={<Search/>}
                            onClick={() => inputRef.current.click()}
                            minW="251px"
                            fontSize="xl"
                            size="lg"
                            variant="blue">
                            Browse Files
                        </ChakraButton>
                    </Flex>
                </Flex>
                <DragDrop
                    success={success}
                    handleFile={handleFile}
                    isLoading={isLoading}/>
                {
                    files?.map(value => (
                        <Flex mt={5} cursor='pointer' css={styles.file} key={value?.files?.key}>
                            <Flex alignItems="center" w='40%'>
                                <Image src="/icons/drag.svg" alt="Drag"/>
                                <Text ml="10" fontWeight="500">{value?.files?.name}</Text>
                            </Flex>
                            <Flex w='30%' color='#A9A9A9' fontWeight="700">{moment(value?.createdAt?.date).format('MMM DD, YYYY')}</Flex>
                            <Flex>
                                <Button onClick={e => {
                                    const newName = value?.files?.name || 'Focus-Earth-Template.xlsx';
                                    const url = S3_BUCKET+value.name;
                                    fetch(url)
                                        .then(response => response.blob())
                                        .then(blob => {
                                            const a = document.createElement('a');
                                            const objectURL = URL.createObjectURL(blob);

                                            a.href = objectURL;
                                            a.download = newName;

                                            document.body.appendChild(a);
                                            a.click();

                                            URL.revokeObjectURL(objectURL);
                                            document.body.removeChild(a);
                                        })
                                }} mr={8} leftIcon={<Download/>} bg="#2C628D" color="white" height="30px" fontSize="12px">
                                    Download
                                </Button>
                            </Flex>
                        </Flex>
                    ))
                }
            </Box>
        </>
    )
}

export default Upload
