import {useMutation} from 'react-query'
import {productUseCase} from '../../factories'

const useUpdatePriceBid = () => {
    const {
        mutate: updatePrice, isLoading, data, error
    } = useMutation(productUseCase.updatePriceBid)

    return {
        updatePrice,
        isLoading,
        error,
        data
    }
}

export default useUpdatePriceBid
