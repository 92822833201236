import {QueryClient, QueryClientProvider} from 'react-query'
import {UserProvider} from './core/providers'
import Routes from './pages'

const queryClient = new QueryClient()

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <UserProvider>
                <Routes/>
            </UserProvider>
        </QueryClientProvider>
    )
}

export default App