import {css} from '@emotion/react'

const styles = {
    container: css`
      min-height: calc(100vh - 60px);
      width: 100%;
      flex-direction: column;
      border-right: 1px solid #DBDADA;
      border-left: 1px solid #DBDADA;
      position: relative;
    `,
    paginator: css`
      & > div {
        display: flex;
        justify-content: center;
      }
    `,
    tr: css`
      border: none;
      cursor: default;
      border-radius: 10px;

      & > td:first-of-type {
        border-radius: 10px 0 0 10px;
      }

      & > td {
        border: none;
        text-align: start;
      }

      & > td:last-of-type {
        border-radius: 0 10px 10px 0;
      }
    `,
    table: css`
      border-collapse: separate;
      border-spacing: 0 20px;

      th {
        text-transform: capitalize;
      }
    `,
    accordionPanel: css`
      border-top: none;

      div:last-of-type {
        border-radius: 0 0 10px 10px;
      }
    `
}

export default styles
