import {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Flex, Drawer, DrawerBody, DrawerFooter, DrawerHeader, Image, DrawerContent, List, ListItem, useDisclosure, Divider, Avatar, Box, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Accordion,} from '@chakra-ui/react'
import {useSignOut, useUser} from '../../core/hooks'
import {Icon, IconButton} from '../Theme'
import {EU, AU, REU, SU, SAU, SA, S3_BUCKET, SUPERADMIN,} from '../../constants'
import styles from './styles'

function Navbar(props) {
    const {children, title, minW, open, editMode} = props
    const [activeTab, setActiveTab] = useState('')
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [icon, setIcon] = useState('')
    const [userType, setUserType] = useState()
    const [expand, setExpand] = useState(false)
    const {signOut} = useSignOut()
    const {user} = useUser()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        if (user?.type === 'Reu') {
            return setUserType(REU)
        }
        if (user?.type === 'Sau') {
            return setUserType(SAU)
        }
        if (user?.type === 'Su') {
            return setUserType(SU)
        }
        if (user?.type === 'Sa') {
            return setUserType(SA)
        }
        if (user?.type === 'Eu') {
            return setUserType(EU)
        }
        if (user?.type === 'Au') {
            return setUserType(AU)
        }
        if (user?.type === 'SUPERADMIN') {
            return setUserType(SUPERADMIN)
        }
    }, [user])

    const handleTab = (name) => {
        if (name.includes(' ')) {
            name = `${name.split(' ')[0].toLocaleLowerCase()}-${name.split(' ')[1].toLocaleLowerCase()}`
        }
        if (location.pathname.includes('report') && location.pathname.includes('create')){
            return open(name)
        }
        if (location.pathname.includes('project-supplier') && editMode){
            return open(name)
        }
        if (name.includes(' ')) {
            const newName = `${name.split(' ')[0].toLocaleLowerCase()}-${name.split(' ')[1].toLocaleLowerCase()}`
            return history.push(`/${newName}`)
        }
        if (user?.type === 'Su' && name === 'Orders') {
            return history.push(`/${name.toLowerCase()}/su`)
        } else if ((user?.type === 'Reu' || user?.type === 'Sa') && name === 'Orders') {
            return history.push(`/${name.toLowerCase()}/reu`)
        }
        history.push(`/${name.toLowerCase()}`)
    }
    useEffect(() => {
        if (location.pathname.includes('catalogues')) {
            return setActiveTab('Catalogues')
        }
        if (location.pathname.includes('upload-files')) {
            return setActiveTab('Upload Files')
        }
        if (location.pathname.includes('projects')) {
            return setActiveTab('Projects')
        }
        if (location.pathname.includes('orders')) {
            return setActiveTab('Orders')
        }
        if (location.pathname.includes('contact')) {
            return setActiveTab('Contact')
        }
        if (location.pathname.includes('recent-activity')) {
            return setActiveTab('Recent Activity')
        }
        if (location.pathname.includes('companies')) {
            return setActiveTab('Companies')
        }
        if (location.pathname.includes('users')) {
            return setActiveTab('Users')
        }
        if (location.pathname.includes('data')) {
            return setActiveTab('Data')
        }
    }, [isOpen, location])

    return (
        <Flex fontFamily='Titillium Web' alignItems="center" justifyContent="space-between" height="60px" width="100%" position="relative">
            <Box zIndex="1" w="100%" minW={minW} height="60px" position="absolute" bg="blue.300"/>
            <Flex zIndex="2" px={9} alignItems="center">
                <IconButton position="relative" aria-label="menu" onClick={onOpen} bg="blue.300" _hover={{background: 'blue.300'}} _active={{background: 'blue.300'}}
                    icon={(
                        <>
                            <Icon height="17px" width="24px" type="menu"/>
                            {user?.notificationReceiver?.filter((filtered) => filtered.seen === false).length > 0 && (
                                    <Box bg="red.100" height="10px" width="10px" right="0" top="0" borderRadius="100%" position="absolute"/>)}
                        </>
                    )}/>
                <Drawer w="372px" isOpen={isOpen} placement="left" onClose={onClose}>
                    <DrawerContent>
                        <DrawerHeader>
                            <Flex justifyContent="space-between">
                                <Image mt={4} maxW="146px" maxH="59px" src="/images/logo.png"/>
                                <IconButton aria-label="close" bg="none" _hover={{background: 'none'}} _active={{background: 'none'}} onClick={onClose} icon={<Icon type="close"/>}/>
                            </Flex>
                        </DrawerHeader>
                        <DrawerBody px={0}>
                            <List fontWeight="500" fontSize="1.125rem" mt="1rem" color='#ffffff' cursor='pointer'>
                                {
                                    userType?.main?.map((item) => (
                                        <ListItem
                                            fontFamily='Titillium Web'
                                            fontWeight='600'
                                            onMouseEnter={() => setIcon(item.name)}
                                            onMouseLeave={() => setIcon('')}
                                            padding="0.75rem 0 0.75rem 2.75rem"
                                            display="flex"
                                            _hover={expand && item.name === 'Orders' && (userType === SUPERADMIN || userType === AU) ? {background: ''} : {background: '#2C628D'}}
                                            alignItems="center"
                                            bg={
                                                activeTab === item.name && activeTab !== 'Orders' ? 'blue.200' :
                                                    activeTab === item.name && activeTab === 'Orders' && (userType !== SUPERADMIN || userType !== AU ) ? 'blue.200' :
                                                        activeTab === item.name && activeTab === 'Orders' && (userType !== SUPERADMIN || userType !== AU ) && !expand ? 'blue.200'
                                                            : 'transparent'}
                                            color={
                                                activeTab === item.name && activeTab !== 'Orders' ? 'white.100' :
                                                    activeTab === item.name && activeTab === 'Orders' && (userType !== SUPERADMIN || userType !== AU )? 'white.100' :
                                                        activeTab === item.name && activeTab === 'Orders' && (userType !== SUPERADMIN || userType !== AU ) && !expand ? 'white.100'
                                                            : 'black.100'}
                                            onClick={() => {
                                                if (item.name === 'Orders' && (userType === SUPERADMIN || userType === AU)) {

                                                } else {
                                                    return handleTab(item.name)
                                                }
                                            }}
                                            key={item.name}>
                                            {
                                                item.name === 'Orders' && (userType === SUPERADMIN || userType === AU) ? (
                                                        <Accordion _focus='' allowToggle>
                                                            <AccordionItem _focus='' border='none' position="relative">
                                                                {({isExpanded}) => (
                                                                    <>
                                                                        {isExpanded ? setExpand(true) : setExpand(false)}
                                                                        <AccordionButton p={0} _focus='' _hover='' fontFamily='Titillium Web' fontWeight='600' fontSize="m">
                                                                            <Box mr="4" as={item.icon}/>
                                                                            Orders
                                                                            <AccordionIcon/>
                                                                        </AccordionButton>
                                                                        <AccordionPanel p={1}>
                                                                            <Flex mt={2} onClick={() =>
                                                                            {
                                                                                if (location.pathname.includes('report') && location.pathname.includes('create')){
                                                                                    return open('orders/su')
                                                                                }
                                                                                if (location.pathname.includes('project-supplier') && editMode){
                                                                                    return open('orders/su')
                                                                                }
                                                                                history.push('/orders/su')}} alignItems='center' ml={4}>
                                                                                <Box fontFamily='Titillium Web' mr={3} borderRadius='100%' width='10px' height='10px' border='1px solid black' backgroundColor='#FFD339'/>
                                                                                Suppliers
                                                                            </Flex>
                                                                            <Flex mt={2} onClick={() => {
                                                                                if (location.pathname.includes('report') && location.pathname.includes('create')){
                                                                                    return open('orders/reu')
                                                                                }
                                                                                history.push('/orders/reu')
                                                                                } }
                                                                                  alignItems='center' ml={4}>
                                                                                <Box fontFamily='Titillium Web' mr={3} borderRadius='100%' width='10px' height='10px' border='1px solid black' backgroundColor='#C956FF'/>
                                                                                Real Estate
                                                                            </Flex>
                                                                        </AccordionPanel>
                                                                    </>
                                                                )}
                                                            </AccordionItem>
                                                        </Accordion>
                                                    ) :
                                                    <>
                                                        <Box mr="4" as={(icon === item.name || activeTab === item.name) ? item.iconWhite : item.icon}/>
                                                        {item.name}
                                                    </>
                                            }
                                            {
                                                item.name === 'Notifications' && user?.notificationReceiver?.filter((filtered) => filtered.seen === false).length > 0 && (
                                                    <Box ml="5" h="10px" w="10px" borderRadius="100%" bg="red.100"/>
                                                )
                                            }
                                        </ListItem>
                                    ))
                                }
                            </List>
                            <Divider opacity="1" borderBottomColor="grey.200" borderBottomWidth="1px" my={6} width="100%" orientation="horizontal"/>
                            <List fontWeight="500" fontSize="1.125rem" mt="1rem" css={styles.list}>
                                {
                                    userType?.bottom?.map((item) => (
                                        <ListItem
                                            fontWeight='600'
                                            fontFamily='Titillium Web'
                                            onMouseEnter={() => setIcon(item.name)}
                                            onMouseLeave={() => setIcon('')}
                                            display="flex"
                                            alignItems="center"
                                            padding="0.75rem 0 0.75rem 2.75rem"
                                            bg={location.pathname.includes(item.name) ? 'blue.200' : 'transparent'}
                                            color={location.pathname.includes(item.name) ? 'white.100' : 'black.100'}
                                            onClick={() => {
                                                if (location.pathname.includes('report') && location.pathname.includes('create')){
                                                    return open('signOut')
                                                }
                                                if (location.pathname.includes('project-supplier') && editMode){
                                                    return open('signOut')
                                                }
                                                item.function === 'signOut' && signOut()
                                            }}
                                            key={item.name}>
                                                <Box mr="4" as={(icon === item.name) ? item.iconWhite : item.icon}/>
                                                {item.name}
                                        </ListItem>
                                    ))
                                }
                            </List>
                        </DrawerBody>
                        <DrawerFooter justifyContent="space-between">
                            <Flex alignItems="center">
                                {user?.avatar ? (<Avatar size="md" src={S3_BUCKET + user.avatar} name="user"/>) : (<Avatar size="md" name="Cooper"/>)}
                                <Box ml={6} color="grey.200" fontSize="xl" as="span">{user?.firstName}&nbsp;{user?.lastName}</Box>
                            </Flex>
                            <Box borderRadius="5px" boxShadow="0px 3px 4px rgba(34, 34, 34, 0.25)">
                                <IconButton _active={{background: 'none',}} _hover={{background: 'none',}} onClick={() => {
                                    if (location.pathname.includes('report') && location.pathname.includes('create')){
                                        return open('profile')
                                    }
                                    if (location.pathname.includes('project-supplier') && editMode){
                                        return open('profile')
                                    }
                                    history.push('/profile')
                                }} aria-label="edit" bg="none" icon={(<Icon height="15px" width="15px" type="create"/>)}/>
                            </Box>
                        </DrawerFooter>
                    </DrawerContent>
                </Drawer>
                <Box fontWeight="600" fontFamily='Titillium Web' as="span" ml={10} color="white.100" fontSize="xl">{title}</Box>
            </Flex>
            <Flex pr="4.75rem" zIndex="2">
                {children}
            </Flex>
        </Flex>
    )
}

export default Navbar
