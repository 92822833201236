import React, {useEffect, useRef, useState} from 'react'
import {
    Flex,
    Box,
    Image,
    FormControl,
    Button,
} from '@chakra-ui/react'
import {S3_BUCKET} from '../../constants'
import {useUpload} from '../../core/hooks'
import {ReactComponent as UploadIcon} from '../../assets/cloud-upload.svg'

const Header = ({image, setValue}) => {
    const [avatar, setAvatar] = useState()
    const {upload, isLoading} = useUpload()
    const inputRef = useRef()

    const handleAvatar = (e) => {
        const file = e.target.files[0]
        const blob = URL.createObjectURL(file)
        const type = file.type.split('/')[1] || 'jpeg'
        const name = `${new Date().getTime() * Math.random()}.${type}`
        setAvatar(blob)
        upload({
                name,
                file,
                type,
            },
            {
                onSuccess: (data) => {
                    setValue('image', data.data.data)
                },
                onError: (e) => {
                    console.log(e)
                }
            }
        )
    }
    useEffect(() => {
        setAvatar(image ? S3_BUCKET + image : '')
    }, [image])

    return (
        <Flex
            justifyContent="space-between"
            alignItems="center"
            w="100%">
            <Flex
                alignItems="center"
                flexDirection="column">
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    overflow="hidden"
                    border="2px solid"
                    borderColor="grey.200"
                    width="85px"
                    height="85px">
                    {avatar && (
                        <Image
                            src={avatar}
                            alt="user avatar"
                            height="100%"
                            width="100%"
                            objectFit="cover"/>
                    )}

                </Flex>
                <Box>
                    <FormControl>
                        <input
                            value={undefined}
                            onChange={handleAvatar}
                            type="file"
                            style={{display: 'none'}}
                            accept="image/*"
                            ref={inputRef}
                        />
                        <Button
                            mt={5}
                            height="23px"
                            leftIcon={<UploadIcon/>}
                            onClick={() => {
                                inputRef.current.click()
                            }}
                            variant="green">
                            {isLoading ? 'Processing...' : 'Upload Photo'}
                        </Button>
                    </FormControl>
                </Box>
            </Flex>
        </Flex>
    )
}

export default Header
