import HttpRequest from "./http-request";

function authService() {
    const {post} = HttpRequest()
    const signIn = ({ email, password }) => {
        return post('login',{username: email,password})
    }

    const signUp = ( {email, password} ) => {
        return post('check-email',{email: email,password})
    }

    const signOut = () => {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
    }

    const changePassword = ({oldPassword, newPassword}) => {
        return post('change-password',{oldPassword, newPassword})
    }

    const forgotPassword = ({email}) => {
        return post('forgot-password', {email})
    }

    const resetPassword = ({email, code, password}) => {
        return post('password-reset',{email, code, password})
    }

    return {
        signIn,
        signUp,
        forgotPassword,
        resetPassword,
        changePassword,
        signOut,
    }
}

export default authService
