import { useQuery } from 'react-query'
import { companyUseCase } from '../../factories'

const useCompanyUsers = (variables) => {
  const {
    refetch: refetchCompanyUsers, isLoading, data: companyUsersList, error,
  } = useQuery('companyUsers', () => companyUseCase.companyUsers(variables))

  return {
    refetchCompanyUsers,
    isLoading,
    error,
    companyUsersList,
  }
}

export default useCompanyUsers
