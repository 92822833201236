import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Flex, Button, Heading} from "@chakra-ui/react";
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import React, {useState} from "react";
import httpRequest from "../../../core/services/http-request";

const DeleteShippingDetailModal = ({isOpen, onClose, setShippingPageStatus, trackId, setEdit, getEstimate}) => {
    const [loading, setLoading] = useState(false)
    const {post} = httpRequest()
    const deleteShippingDetail = (id) => {
        if (!id) return
        setLoading(true)
        post('delete-shipping-detail',id).then((e) => {
            onClose()
            setEdit(false)
            getEstimate()
            return setShippingPageStatus(1)
        })
        setLoading(false)
    }
    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay/>
            <ModalContent
                overflow="hidden"
                borderRadius="10px"
                maxW="520px">
                <ModalHeader
                    bg='#FF9494'
                    fontWeight="700"
                    fontSize="24px"
                    textAlign="center">
                    Delete Shipping Detail
                </ModalHeader>
                <IconButton
                    height="unset"
                    onClick={onClose}
                    _hover=''
                    _active=''
                    bg="none"
                    aria-label="close button"
                    position="absolute"
                    top="1.5rem"
                    right="0.75rem"
                    icon={<Icon type="darkClose"/>}/>
                <ModalBody
                    pb={8}
                    overflow="scroll"
                    maxH="520px">
                    <Heading
                        textAlign='center'
                        fontSize="24px">
                        Are you sure you want to delete this shipping detail?
                    </Heading>
                    <Flex
                        justifyContent='space-between'>
                        <Button
                            onClick={onClose}
                            height='50px'
                            fontSize='14px'
                            width='230px'
                            color='black'
                            bg='#DBDADA'>
                            Nevermind, Let’s Go Back
                        </Button>
                        <Button
                            isLoading={loading}
                            onClick={() => {
                                if (trackId){
                                    deleteShippingDetail(trackId)
                                } else {
                                    setShippingPageStatus(1)
                                }
                            }}
                            height='50px'
                            fontSize='14px'
                            width='230px'
                            color='black'
                            bg='#FB7575'>
                            Yes, Delete File
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default DeleteShippingDetailModal