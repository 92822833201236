import {useQuery} from 'react-query'
import {userUseCase} from '../../factories'

const useFindUser = (id) => {
  const {
    refetch, isLoading, data, error
  } = useQuery('findUser', () => userUseCase.findUser(id))

  return {
    refetch,
    isLoading,
    error,
    data,
  }
}

export default useFindUser
