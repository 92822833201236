import {useQuery} from 'react-query'
import {dataUseCase} from '../../factories'

const useGetUsage = (type) => {
    const {
        refetch, isLoading, data: usage, error,
    } = useQuery('usage', () => dataUseCase.getUsage(type),{refetchOnWindowFocus: false})

    return {
        refetch,
        isLoading,
        error,
        usage,
    }
}

export default useGetUsage
