import {useState} from "react";
import {Box, Flex, Text, Button, useDisclosure} from "@chakra-ui/react";
import DragDrop from "../../CreateProject/UploadDragDrop";
import {useUploadMultiple} from "../../../core/hooks";
import styles from "../../CreateProject/styles";
import moment from "moment/moment";
import {ReactComponent as Download} from "../../../assets/download.svg";
import httpRequest from "../../../core/services/http-request";
import Loading from "../../../components/Loading";
import downloadFile from "../../../constants/mainFunctions";
import {ReactComponent as DeleteIcon} from "../../../assets/del.svg";
import RemoveReceiptModal from "./removeReceiptModal";

const Invoice = ({estimate, refererInvoice, file, loadingFiles, isLoadingOrder,refetch,getFiles}) => {
    const {post} = httpRequest()
    const {upload, data: response, isLoading, error,} = useUploadMultiple()
    const [success, setSuccess] = useState(false)
    const [fileForDelete, setFileForDelete] = useState()
    const {isOpen, onOpen, onClose} = useDisclosure()

    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )
        setSuccess(true)
        upload(
            filesToUpload,
            {
                onSuccess: (e) => {
                    let estimateType = window.location.pathname.includes('su') ? 'su' : 'reu'
                    post('create-uploads',[e[0].data.data, dataToPreview, 3, estimate.id,estimateType]).then((e) => {
                        // getFiles(INVOICE)
                        refetch()
                        setSuccess(false)
                    }).catch((e) => {
                        setSuccess(false)
                    })
                },
            },
        )
    }

    if (isLoadingOrder || success || loadingFiles) return <Loading/>
    return (
        <>
            <DragDrop
                isCreatingLoading={isLoading}
                success={success}
                error={error}
                handleFile={handleImage}
                isLoading={isLoading}/>
            <RemoveReceiptModal
                refetch={refetch}
                fileId={fileForDelete}
                onClose={onClose}
                isOpen={isOpen}/>
            <Box ref={refererInvoice} display={estimate?.invoiceDateCreated ? 'block' : 'none'}
                 pb={2} minH='calc(100vh - 60px)' pt={2}>
                {
                    file?.length > 0 && (
                        <>
                            <Flex my="6" fontSize="20px" fontWeight="500">
                                <Text>Uploaded Files</Text>
                                <Text w='80%' textAlign='center'>Date</Text>
                            </Flex>
                            {
                                file?.map((value, index) => (
                                    <Flex cursor='pointer' bg='white' css={styles.file} key={index} position='relative'>
                                        <Flex p={0} alignItems='center' justifyContent='center' position='absolute'
                                              onClick={() => {
                                                  setFileForDelete(value.id)
                                                  onOpen()
                                              }}
                                              left='-8px'
                                              top='-10px'
                                              cursor='pointer'
                                              borderRadius='5px'
                                              width="20px"
                                              height="20px"
                                              bg='#FB7575'>
                                            <DeleteIcon width='10px' height='10px' fill='black'/>
                                        </Flex>
                                        <Flex alignItems="center" w='40%'>
                                            <Text ml="10" fontWeight="500">{value?.files?.name}</Text>
                                        </Flex>
                                        <Flex w='30%' color='#A9A9A9' fontWeight="700">
                                            {moment(value[0]?.createdAt)?.format('MMM DD, YYYY')}
                                        </Flex>
                                        <Flex>
                                            <Button
                                                onClick={e => downloadFile(e, value?.name)}
                                                mr={8}
                                                leftIcon={<Download/>}
                                                bg="#2C628D"
                                                color="white"
                                                height="30px"
                                                fontSize="12px">
                                                Download
                                            </Button>
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </>
                    )
                }
            </Box>
        </>
    )
}
export default Invoice
