import { useQuery } from 'react-query'
import { orderUseCase } from '../../factories'

const useGetEstimates = (input) => {
    const {
        refetch, isLoading, data, error,
    } = useQuery('estimates', () => orderUseCase.getEstimates(input))

    return {
        refetch,
        isLoading,
        error,
        data,
    }
}

export default useGetEstimates
