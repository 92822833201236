import {extendTheme} from '@chakra-ui/react'
import Button from './Button'
import Switch from "../components/Theme/Switch";

const customTheme = extendTheme({
    components: {
        Button,
        Switch,
    },
    fonts: {
        body: 'Frank Ruhl Libre',
        heading: 'Titillium Web, sans-serif',
        mono: 'Frank Ruhl Libre',
    },
    styles: {
        global: {
            'html, body': {
                fontSize: 'lg',
                color: 'black.100',
                backgroundColor: 'white.200',
                fontFamily: 'Frank Ruhl Libre',
                fontWeight: '400',
            },
            a: {
                textDecoration: 'none !important',
            },
            'h2, h3, h4': {
                fontWeight: '600',
                fontFamily: 'Titillium Web, sans-serif',
                margin: '1em 0',
            },
            'button' : {
                fontWeight: '600',
                fontFamily: 'Titillium Web, sans-serif',
            },
            'p' : {
                fontFamily: 'Frank Ruhl Libre',
                fontWeight: '400',
            },
            '*': {
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
            },
            '*::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },
    fontSizes: {
        xs: '10px',
        sm: '12px',
        md: '14px',
        lg: '16px',
        xl: '18px',
        '2xl': '20px',
        '3xl': '24px',
        '4xl': '32px',
        '5xl': '48px',
    },
    colors: {
        blue: {
            100: '#2b5daa',
            200: '#2C628D',
            300: '#193C5B',
        },
        green: {
            100: '#8FCF74',
            200: '#619C48',
        },
        grey: {
            100: '#DBDADA',
            200: '#a9a9a9',
            300: '#7a7a7a',
        },
        red: {
            100: '#bd4947',
            200: '#E53E3E',
        },
        white: {
            100: '#FFFFFF',
            200: '#f2f2f2',
        },
        black: {
            100: '#222222',
        },
        purple: {
            100: '#C956FF',
        },
        orange: {
            100: '#FF7F56',
        },
        yellow: {
            100: '#FFe68D',
        },
        border: {
            100: '#A9A9A9',
        },
    },
})

export default customTheme
