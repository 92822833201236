const projectRepo = (projectService) => {
  const createProject = (input) => projectService.createProject(input)

  const updateProject = (input) => projectService.updateProject(input)

  const listProjects = (variables) => projectService.listProjects(variables)

  const searchProjects = (variables) => projectService.searchProjects(variables)

  const getProject = (id) => projectService.getProject(id)

  const deleteProject = (input) => projectService.deleteProject(input)

  return {
    createProject,
    updateProject,
    listProjects,
    getProject,
    deleteProject,
    searchProjects,
  }
}

export default projectRepo
