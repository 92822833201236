import React from 'react'
import {
    Stack,
    Text,
    Button,
} from '@chakra-ui/react'
import {InviteModal} from '../../components/Theme'
import {Link} from "react-router-dom";

const Thanks = ({onClose, isOpen}) => (
    <InviteModal
        isOpen={isOpen}
        onClose={onClose}
        bg="#8FCF74"
        fontSize="24px"
        title="Next Steps">
        <Stack
            py="10"
            spacing="10">
            <Stack
                alignItems="center"
                direction="column"
                spacing="5"
                justifyContent="center">
                <Text
                    fontFamily='Frank Ruhl Libre'
                    fontWeight="400"
                    fontSize="24px"
                    textAlign="center">
                    Thanks for updating the project information!
                </Text>
                <Text
                    fontWeight="400"
                    fontSize="24px"
                    fontFamily='Frank Ruhl Libre'
                    textAlign="center">
                    Your Focus Earth consultant will review the changes and contact you with
                    <br/>
                    any questions or updates.
                </Text>
                <Link
                    to={'/projects'}>
                    <Button
                        bg="#2C628D"
                        w="254px"
                        height="51px"
                        fontSize="18px"
                        fontWeight="700"
                        onClick={() => onClose()}>
                        OK
                    </Button>
                </Link>
            </Stack>
        </Stack>
    </InviteModal>
)

export default Thanks
