import {Box, Heading, Text, VStack, Flex, List, ListItem,} from '@chakra-ui/react'
import {ChartConsuption, TableProjectEnergy, TableProjectBig, CustomDivider} from '../../components/Theme'
import Loading from "../../components/Loading";

const Energy = ({
                    loading,
                    existingSystemKW,
                    ledSystemKW,
                    customBidding,
                    ledYearEnergySmall,
                    energySaved,
                    existingYearEnergySmall,
                    ledYearEnergyBig,
                    existingYearEnergyBig
                }) => {
    if (loading) return <Loading/>
    return (
        <>
            <Box>
                <Heading fontSize="3xl">Energy Analysis</Heading>
                <Text fontSize="md">
                    Explore this investment from an energy consumption perspective. The recommended LED system will
                    reduce demand on electric generation and translate directly to lower net operating expenses.
                </Text>
            </Box>
            <CustomDivider border='2px'/>
            <Flex flexWrap="wrap" justifyContent="space-between">
                <Box>
                    <Heading fontSize="xl">Energy Consumption (kW)</Heading>
                    <List>
                        <ListItem fontSize="sm" display="flex" justifyContent="flex-end">
                            Existing System:
                            <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px"
                                  bg={customBidding ? '#A9A9A9':'#FFE68D'}>
                                {existingSystemKW ? existingSystemKW.toLocaleString('en-US') : 0} kW
                            </Flex>
                        </ListItem>
                        <ListItem fontSize="sm" display="flex" justifyContent="flex-end">
                            LED Upgrade:
                            <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px"
                                  bg={customBidding ? '#A9A9A9':'rgba(255, 230, 141, 0.37)'}>
                                {ledSystemKW ? ledSystemKW.toLocaleString('en-US') : 0} kW
                            </Flex>
                        </ListItem>
                        <ListItem fontSize="sm" display="flex" justifyContent="flex-end">
                            Energy Saved:
                            <Flex ml={2} alignItems="center" justifyContent="center" height="23px" width="83px"
                                  bg={customBidding ? '#8A8A8A':'green.100'}>
                                {energySaved ? energySaved.toLocaleString('en-US') : 0} kW
                            </Flex>
                        </ListItem>
                    </List>
                </Box>
                <Box>
                    <Heading fontSize="xl" mb={0}>Energy Consumption Comparison</Heading>
                    <TableProjectEnergy
                        customBidding={customBidding}
                        ledYear={ledYearEnergySmall}
                        existingYear={existingYearEnergySmall}
                        width="572px"/>
                </Box>
            </Flex>
            <CustomDivider border='2px'/>
            <Heading fontSize="lg" mt={0}>Consumption (kWh):</Heading>
            <Flex justifyContent="space-between">
                <VStack mr={8}>
                    <Flex alignItems="center" width='full'>
                        <Box mr={3} bg={customBidding ? "#8A8A8A" :"#FF7F56"} width="18px" height="18px"/>
                        <Heading as="h4" fontSize="sm">Existing System</Heading>
                    </Flex>
                    <Flex alignItems="center" width='full'>
                        <Box mr={3} bg={customBidding ? "#8A8A8A" :"#619C48"} width="18px" height="18px"/>
                        <Heading as="h4" fontSize="sm">LED System</Heading>
                    </Flex>
                </VStack>
                <Flex alignItems="center" flexDirection="column">
                    <ChartConsuption
                        existingYear={existingYearEnergySmall}
                        ledYear={ledYearEnergySmall}
                        />
                    <Box fontWeight="600" as="span">Years</Box>
                </Flex>
            </Flex>
            <CustomDivider border='2px'/>
            <Box>
                <Heading fontSize="lg" as="h3">Energy Expenditure Comparison</Heading>
                <TableProjectBig
                    customBidding={customBidding}
                    width="100%"
                    ledYearEnergyBig={ledYearEnergyBig}
                    existingYearEnergyBig={existingYearEnergyBig}/>
                <Text mt={10} fontSize="md">
                    Note: Based on the average cost per kWh entered in the Property Details area or extrapolated from
                    provided energy bills.
                </Text>
            </Box>
        </>
    )
}

export default Energy
