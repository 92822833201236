import React from 'react'
import {Tab,} from '@chakra-ui/react'

const OwerviewTab = () => {
    return (
        <>
            <Tab
                minW="207px"
                fontSize='18px'
                fontWeight='400'
                fontFamily='Titillium Web'
                fontStyle='normal'
                _active={{
                    background: 'transparent',
                }}
                _selected={{
                    color: 'green.200',
                    borderColor: 'green.200',
                    borderBottomWidth: '3px',
                    boxShadow: 'none',
                    fontWeight: "700",
                }}
                color="green.200">
                Price
            </Tab>
            <Tab
                minW="207px"
                fontSize='18px'
                fontWeight='400'
                fontFamily='Titillium Web'
                _active={{
                    background: 'transparent',
                }}
                _selected={{
                    color: 'green.200',
                    borderColor: 'green.200',
                    boxShadow: 'none',
                    fontWeight: "700",
                    borderBottomWidth: '3px',
                }}
                color="green.200">
                Cost
            </Tab>
            <Tab
                minW="207px"
                fontSize='18px'
                fontWeight='400'
                fontFamily='Titillium Web'
                _active={{
                    background: 'transparent',
                }}
                _selected={{
                    color: 'green.200',
                    borderColor: 'green.200',
                    boxShadow: 'none',
                    fontWeight: "700",
                    borderBottomWidth: '3px',
                }}
                color="green.200">
                Security
            </Tab>
            <Tab
                fontSize='18px'
                fontWeight='400'
                fontFamily='Titillium Web'
                minW="207px"
                _active={{
                    background: 'transparent',
                }}
                _selected={{
                    color: 'green.200',
                    borderColor: 'green.200',
                    boxShadow: 'none',
                    fontWeight: "700",
                    borderBottomWidth: '3px',
                }}
                color="green.200">
                Time
            </Tab>
            <Tab
                minW="207px"
                fontSize='18px'
                fontWeight='400'
                fontFamily='Titillium Web'
                _active={{
                    background: 'transparent',
                }}
                _selected={{
                    color: 'green.200',
                    borderColor: 'green.200',
                    borderBottomWidth: '3px',
                    fontWeight: "700",
                    boxShadow: 'none',
                }}
                color="green.200">
               Human Centric
            </Tab>
        </>
    )
}
export default OwerviewTab
