import {
    FormControl, Checkbox, FormLabel, Menu, MenuButton, Flex, MenuList, Grid, GridItem, Text,
} from '@chakra-ui/react'
import React from 'react'
import {ReactComponent as ArrowDown} from "../../../assets/downArrow.svg";

const CustomSelectMultiple = ({
                          label, value, options, setValue, repeat, width='100%'
                      }) => (
    <FormControl>
        <FormLabel
            fontWeight="600"
            fontSize="md"
            fontFamily='Titillium Web'
            color="blue.200">
            {label}
        </FormLabel>
        <Menu>
            <MenuButton
                as={Flex}
                px={4}
                alignItems="center"
                border="1px solid"
                position='relative'
                width={width}
                overflow="scroll"
                borderColor="rgba(169, 169, 169, 0.87)"
                borderRadius="5px"
                bg="white.100"
                height="50px">
                <Text
                    whiteSpace="nowrap"
                    fontSize="xl">
                    {value?.length > 0 ? value?.join(', ') : "Select"}
                </Text>
                <Flex
                    alignItems='center'
                    height="50px"
                    top='0'
                    right='15px'
                    position='absolute'>
                    <ArrowDown width='10px' height='10px'/>
                </Flex>
            </MenuButton>
            <MenuList
                width={width}
                pl={3}>
                <Grid
                    templateColumns={`repeat(${repeat}, 1fr)`}>
                    {
                        options?.map((item) => (
                            <GridItem
                                fontSize='18px'
                                key={item}
                                colSpan={1}>
                                <Checkbox
                                    _checked={{
                                        "& .chakra-checkbox__control": {
                                            boxShadow: 'none',
                                            borderColor: '#A9A9A9',
                                            outline: 'none'
                                        }
                                    }}
                                    _hover={{
                                        "& .chakra-checkbox__control": {
                                            boxShadow: 'none',
                                            borderColor: '#A9A9A9',
                                            outline: 'none'
                                        }
                                    }}
                                    _focus={{
                                        "& .chakra-checkbox__control": {
                                            borderColor: '#A9A9A9',
                                            boxShadow: 'none'
                                        }
                                    }}
                                    _focusVisible={{
                                        "& .chakra-checkbox__control": {outline: 'none', boxShadow: 'none'}
                                    }}
                                    isChecked={value?.includes(item)}
                                    name={item}
                                    onChange={(e) => {
                                        setValue(prevSelectedItems => {
                                            if (prevSelectedItems.includes(item)) {
                                                return prevSelectedItems.filter(i => i !== item);
                                            } else {
                                                return [...prevSelectedItems, item];
                                            }
                                        });
                                    }}
                                    borderColor='#A9A9A9'
                                    iconColor="black"
                                    colorScheme="whiteAlpha">
                                    <Text fontSize='18px'>
                                        {item}
                                    </Text>
                                </Checkbox>
                            </GridItem>
                        ))
                    }
                </Grid>
            </MenuList>
        </Menu>
    </FormControl>
)

export default CustomSelectMultiple
