import React, {useEffect, useState,} from 'react'
import {SimpleGrid, GridItem, Text, Box,} from '@chakra-ui/react'
import {useUser} from '../../core/hooks'
import HeaderForCatalogues from "./HeaderForCatalogues";
import HeaderForCatalogueAdmins from "./HeaderForCatalogueAdmins";
import {useHistory} from "react-router-dom";
import {ReactComponent as Infinite} from '../../assets/infiniteIcon.svg'
import {ReactComponent as CompanyIcon} from '../../assets/buildingIcon.svg'
import {ReactComponent as FixtureIcon} from '../../assets/streetLampIcon.svg'
import {ReactComponent as LampIcon} from '../../assets/lampsBigIcon.svg'
import {ReactComponent as PSIcon} from '../../assets/batteryBigIcon.svg'
import {ReactComponent as ControlIcon} from '../../assets/timerBigIcon.svg'
import {ReactComponent as AccessoryIcon} from '../../assets/helpBigIcon.svg'
import httpRequest from "../../core/services/http-request";
import Loading from "../../components/Loading";
import {SU} from "../../constants/user/userEnum";

const Catalogues = () => {
    const {user} = useUser()
    const {get} = httpRequest()
    const history = useHistory()
    const [data, setData] = useState({
        Fixtures: 0,
        Lamps: 0,
        'Power Supplies': 0,
        Controls: 0,
        Accessories: 0,
    })
    const [loading, setLoading] = useState(false)

    const handleSearch = (e) => {
        // const arre = [...productsResponse?.data.data, ...accessoriesResponse?.data.data]
        // setData(arre?.filter((item) => item.manufacturer.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    useEffect(() => {
        setLoading(true)
        get('get-records-count').then((e) => {
            setLoading(false)
            if (!e.data.data) return
            setData(e.data.data)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    if (loading) return <Loading />

    return (
        <>
            {
                user?.type === SU ? (
                    <>
                        <HeaderForCatalogues handleSearch={handleSearch} title="Products"/>
                        <SimpleGrid p="20" columns={{base: 1, sm: 2, md: 4,}} spacing="10">
                            <GridItem cursor='pointer' borderRadius='8px' border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF' onClick={() => history.push('/catalogues/all-products')}>
                                <Box mb={5}><Infinite/></Box>
                                <Text fontWeight='600' fontSize='24px'>All Products</Text>
                                <Text>{Number(data['Fixtures'] ?? 0) + Number(data['Lamps'] ?? 0) + Number(data['Controls'] ?? 0) + Number(data['Power Supplies'] ?? 0) + Number(data['Accessories'] ?? 0) + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' onClick={() => history.push('/catalogues/fixtures')} borderRadius='8px' border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><FixtureIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Fixtures</Text>
                                <Text>{data['Fixtures'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' onClick={() => history.push('/catalogues/lamps')} border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><LampIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Lamps</Text>
                                <Text>{data['Lamps'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' onClick={() => history.push('/catalogues/power-supplies')} border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><PSIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Power Supplies</Text>
                                <Text>{data['Power Supplies'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' onClick={() => history.push('/catalogues/controls')} border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><ControlIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Controls</Text>
                                <Text>{data['Controls'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' onClick={() => history.push('/catalogues/accessories')} border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><AccessoryIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Accessories</Text>
                                <Text>{data['Accessories'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                        </SimpleGrid>
                    </>
                ) : (
                    <>
                        <HeaderForCatalogueAdmins handleSearch={handleSearch}/>
                        <SimpleGrid p="20" columns={{base: 1, sm: 2, md: 4,}} spacing="10">
                            <GridItem cursor='pointer' borderRadius='8px' border='1px solid #193C5B' pl={3} pb={3} onClick={() => history.push('/catalogues/all-products')} bg='#EEF7FF'>
                                <Box mb={5}><Infinite/></Box>
                                <Text fontWeight='600' fontSize='24px'>All Products</Text>
                                <Text>{Number(data['Fixtures']) + Number(data['Lamps']) + Number(data['Controls']) + Number(data['Power Supplies']) + Number(data['Accessories']) + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' border='1px solid #193C5B' pl={3} pb={3} onClick={() => history.push('/catalogues/companies')} bg='#EEF7FF'>
                                <Box mb={5}><CompanyIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Companies</Text>
                                <Text>{data['Companies'] + ' Companies'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' border='1px solid #193C5B' pl={3} pb={3} onClick={() => history.push('/catalogues/fixtures')} bg='#EEF7FF'>
                                <Box mb={5}><FixtureIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Fixtures</Text>
                                <Text>{data['Fixtures'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' border='1px solid #193C5B' pl={3} onClick={() => history.push('/catalogues/lamps')} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><LampIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Lamp</Text>
                                <Text>{data['Lamps'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' onClick={() => history.push('/catalogues/power-supplies')} border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><PSIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Power Supply</Text>
                                <Text>{data['Power Supplies'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' onClick={() => history.push('/catalogues/controls')} borderRadius='8px' border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><ControlIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Control</Text>
                                <Text>{data['Controls'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                            <GridItem cursor='pointer' borderRadius='8px' onClick={() => history.push('/catalogues/accessories')} border='1px solid #193C5B' pl={3} pb={3} bg='#EEF7FF'>
                                <Box mb={5}><AccessoryIcon/></Box>
                                <Text fontWeight='600' fontSize='24px'>Accessories</Text>
                                <Text>{data['Accessories'] + ' Products'}</Text>
                                <Text>Updated On: {data['UpdatedAt'] || ''}</Text>
                            </GridItem>
                        </SimpleGrid>
                    </>
                )
            }
        </>
    )
}

export default Catalogues
