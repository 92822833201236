import {useMutation} from 'react-query'
import httpRequest from "../services/http-request";

const useCalculator = (id) => {
    const {get} = httpRequest()
    const {
      mutate: calculate,
      data,
      error,
      isLoading,
    } = useMutation((input: any) => get(`calculate-report/${id}`))

    return {
        calculate,
        data,
        isLoading,
        error,
    }
}

export default useCalculator
