import React, {useState} from "react";
import {Modal, AccordionItem, AccordionButton, AccordionPanel, Accordion as ChakraAccordion, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Flex, Divider, Box,} from "@chakra-ui/react";
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {InputLabel} from "../../Theme";

const InstallationBidSelectModal = ({isOpen, onClose, project, setSelectedBid}) => {
    const [selectInstallation, setSelectInstallation] = useState('')

    const setInstallationEstimate = (e) => {
        setSelectInstallation(e)
        setSelectedBid(e)
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="992px">
                <ModalHeader bg='#FFAF8D' fontWeight="700" fontStyle='normal' fontSize="24px" textAlign="center">
                    Installation Bid: {project[0]?.title}
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='40px' pl='60px' pr='60px' pb='60px' overflow="scroll" maxH="600px">
                    <Flex justifyContent='space-between'>
                        <Divider mt={3} width='300px' borderColor="#DADADA" border="1px solid" orientation="horizontal"/>
                        <Text fontSize='18px' color='#2C628D'>
                            SELECT INSTALLATION BID
                        </Text>
                        <Divider mt={3} width='300px' borderColor="#DADADA" border="1px solid" orientation="horizontal"/>
                    </Flex>
                    <Flex color='#2C628D' justifyContent='space-between' mt={5} fontWeight='600'>
                        <Text>Installation Estimate:</Text>
                        <Text>Company:</Text>
                        <Text width='20%'>Date:</Text>
                    </Flex>
                    <ChakraAccordion mt={4} allowToggle>
                        <AccordionItem>
                            {({isExpanded}) => (
                                <>
                                    <AccordionButton height="52px" _focus={{boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.45)'}} border="1px" borderColor="grey.200">
                                        <Flex minW="100%" alignItems='center' height="52px">
                                            {selectInstallation ? selectInstallation : ''}
                                        </Flex>
                                        <Icon style={{transform: isExpanded ? 'rotate(180deg)' : 'inherit', position: 'relative', right: '14px', transition: 'all .3s', height: '12px', color: '#A9A9A9',}} type="downArrow"/>
                                    </AccordionButton>
                                    <AccordionPanel border='1px solid #A9A9A9' borderRadius='6px' mt={4} p={6}>
                                        <Flex onClick={() => setInstallationEstimate('Sourced Separately')} cursor='pointer' border='1px solid #A9A9A9' height="46px" pl='40px' bg='#EDEDED' fontSize='18px' alignItems='center' borderRadius='6px'>
                                            Sourced Separately
                                        </Flex>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    </ChakraAccordion>
                    <Flex justifyContent='space-between' mt={10}>
                        <Divider mt={3} width='340px' borderColor="#DADADA" border="1px solid" orientation="horizontal"/>
                        <Text fontSize='18px' color='#2C628D'>
                            BID DETAILS
                        </Text>
                        <Divider mt={3} width='340px' borderColor="#DADADA" border="1px solid" orientation="horizontal"/>
                    </Flex>
                    <Flex justifyContent='space-between' mt={10}>
                        <InputLabel maxW='257px' label='Labor' leftAddon="$" disabled style={{backgroundColor: '#F5F5F5',borderColor: '#A9A9A9'}} fontFamily='Titillium Web' backgroundColor='#A9A9A9' color='#5A5A5A'/>
                        <InputLabel disabled style={{backgroundColor: '#F5F5F5',borderColor: '#A9A9A9'}} maxW='257px' leftAddon="$" label='Materials'/>
                        <InputLabel disabled style={{backgroundColor: '#F5F5F5',borderColor: '#A9A9A9'}} maxW='257px' leftAddon="$" label='Permits'/>
                    </Flex>
                    <Flex justifyContent='space-between' mt={7}>
                        <InputLabel disabled maxW='257px' leftAddon="$" style={{backgroundColor: '#F5F5F5',borderColor: '#A9A9A9'}} label='Tax'/>
                        <InputLabel disabled style={{backgroundColor: '#F5F5F5',borderColor: '#A9A9A9'}} maxW='257px' leftAddon="$" label='Recycling/Disposal'/>
                        <Box w='257px'>
                            <Text fontWeight='600' fontSize='14px' color='blue.200'>Availability</Text>
                        </Box>
                    </Flex>
                    <Flex color='#2C628D' fontWeight='600' mt={5}>
                        <Text width='50%'>Uploaded Files</Text>
                        <Text width='50%'>Date</Text>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default InstallationBidSelectModal