import React from 'react'
import {Box} from '@chakra-ui/react'
import {Line} from 'react-chartjs-2'

const ChartForecast = () => {
    const data = {
        labels: ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1'],
        datasets: [
            {
                label: 'Existing System',
                type: 'line',
                fill: false,
                borderColor: '#A9A9A9',
                backgroundColor: '#A9A9A9',
            },
            {
                label: 'LED Upgrade (Financed)',
                type: 'line',
                fill: false,
                backgroundColor: '#A9A9A9',
                borderColor: '#A9A9A9',
            },
            {
                label: 'LED Upgrade (Paid)',
                type: 'line',
                fill: false,
                backgroundColor: '#A9A9A9',
                borderColor: '#A9A9A9',
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    callback(value, _index, values) {
                        return '$50000'
                    },
                },
            }],
        },
    }

    return (
        <Box
            height="550px"
            width="100%">
            <Line
                options={options}
                data={data}/>
        </Box>
    )
}

export default ChartForecast
