import {Flex, SimpleGrid, GridItem} from '@chakra-ui/react'
import {InputLabel} from '../../components/Theme'

const AreaInput = ({areas}) => (
    <Flex>
        <Flex justifyContent="space-between" flexDirection="column" w="100%">
            <SimpleGrid mb="6" columns={{base: 2, md: 4,}} gap={6}>
                <GridItem>
                    <InputLabel
                        disabled height='58px'
                        value={areas['ceilingHeight']}
                        label="Ceiling Height:"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        disabled
                        height='58px'
                        value={areas['dailyUsage']}
                        label="Daily Usage (hours):"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        disabled
                        height='58px'
                        value={areas['areaSqFt']}
                        label="Area (sq.ft.):"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        disabled
                        height='58px'
                        value={areas['application']}
                        label="Application:"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        disabled
                        height='58px'
                        value={areas['voltage']}
                        label="Voltage:"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        disabled
                        height='58px'
                        value={areas['installationSurface']}
                        label="Installation Surface:"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        disabled
                        height='58px'
                        value={areas['controlType']}
                        label="Control Type:"/>
                </GridItem>
                <GridItem>
                    <InputLabel
                        disabled
                        height='58px'
                        value={areas['ingressProtection']}
                        label="Ingress Protection:"/>
                </GridItem>
            </SimpleGrid>
            <InputLabel
                disabled
                label="Notes:"
                height='58px'
                value={areas['notes']}/>
        </Flex>
    </Flex>
)

export default AreaInput
