import {Flex,} from '@chakra-ui/react'
import Header from './Header'

const AuthLayout = ({children}) => (
    <Flex minW="900px" py="80px" backgroundImage="url(/images/fon.png)" backgroundPosition="0px -90px"
          backgroundSize="cover" backgroundRepeat="no-repeat" minH="100vh" position="relative" alignItems="center"
          justifyContent="center">
        <Header/>
        {children}
    </Flex>
)

export default AuthLayout
