const DEFAULT_PROFILE_FEATURES = {
    environment: [],
    valuation: [],
    illumination: [],
    technology: [],
}

const useUserProgress = (
    user
) => {
    const {
        environment,
        valuation,
        illumination,
        technology,
    } = !!user?.profileFeatures ? JSON.parse(user?.profileFeatures) : DEFAULT_PROFILE_FEATURES
    const newArray = [25, 100]
    let interests = 0
    let features = 0
    let avatarScore = 0
    let title = 0

    if (user?.title) {
        title = 10
    } else {
        title = 0
    }

    if (user?.avatar) {
        avatarScore = 15
    } else {
        avatarScore = 0
    }
    if (user?.primaryInterests) {
        if (Object.values(JSON.parse(user?.primaryInterests))
            .reduce((total, currentValue) => (
                total + currentValue), 0) > 0) {
            interests = 25
        } else {
            interests = 0
        }
    }

    if ((Object.values(environment)?.reduce((total, currentValue) => (total + currentValue), 0)
            || Object.values(illumination)?.reduce((total, currentValue) => (total + currentValue), 0)
            || Object.values(valuation)?.reduce((total, currentValue) => (total + currentValue), 0)
            || Object.values(technology)?.reduce((total, currentValue) => (total + currentValue), 0))
        > 0) {
        features = 25
    } else {
        features = 0
    }
    newArray[0] += interests + features + avatarScore + title
    newArray[1] = newArray[1] - interests - features - avatarScore - title - 25
    return newArray
}

export default useUserProgress
