const uploadHistoryRepo = (uploadHistoriesService) => {
  // const createUploadHistory = (input) => uploadHistoriesService.createUploadHistory(input)
  //
  // const updateUploadHistory = (input) => uploadHistoriesService.updateUploadHistory(input)
  //
  // const listUploadHistories = (variables) => uploadHistoriesService.listUploadHistories(variables)
  //
  // const getUploadHistory = (id) => uploadHistoriesService.getUploadHistory(id)
  //
  // const deleteUploadHistory = (input) => uploadHistoriesService.deleteUploadHistory(input)

  return {
    // createUploadHistory,
    // updateUploadHistory,
    // listUploadHistories,
    // getUploadHistory,
    // deleteUploadHistory,
  }
}

export default uploadHistoryRepo
