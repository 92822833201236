import {Box, Flex, Heading, Image} from "@chakra-ui/react";
import {S3_BUCKET} from "../../../constants";
import ActiveUsersUsageChart from "../DataCharts/ActiveUsersUsageChart";


const UsagePart = ({usage, companyColors}) => (
    <Box>
        <Flex mt='60px' borderRadius='10px' pl={4} alignItems='center' minH='57px' bg='#E6E6E6'>
            <Heading m={0} fontSize='24px'>Most Active Users</Heading>
        </Flex>
        <Flex mt={9} justifyContent='space-between'>
            <Flex flexDirection='column'>
                <Flex pl={4} fontWeight='700' justifyContent='space-between'>
                    <Heading m={0} fontSize='16px' width='10%'>Color</Heading>
                    <Heading m={0} fontSize='16px' pl={3} width='20%'>Company</Heading>
                    <Heading m={0} fontSize='16px' pl={3} width='30%'>Users</Heading>
                    <Heading m={0} fontSize='16px' pl={3} width='30%'>Active Hours</Heading>
                </Flex>
                {usage?.map((company, idx) => (
                    <Flex width='594px' fontSize='18px' key={idx} mt={4} pl={4} alignItems='center' minH='57px'
                          bg='#FFFFFF'
                          boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px'
                          justifyContent='space-between'>
                        <Flex width='10%' pl={3} alignItems='center' height='100%'
                              borderLeft={`12px solid ${companyColors[idx]}`}></Flex>
                        <Flex pl={3} alignItems='center' width='20%'>
                            <Image width='50px' height='40px' objectFit='cover' mr={2}
                                   src={company?.companyLogo ? S3_BUCKET + company?.companyLogo : ''}/>
                        </Flex>
                        <Flex alignItems='center' pl={3}
                              width='30%'>{company?.userName?.replace(/(.{15})..+/, "$1…")}</Flex>
                        <Flex pl={3} width='30%'>{(Number(company?.total) / 60).toFixed(2)}</Flex>
                    </Flex>
                ))
                }
                <Flex mt={4} pl={4} alignItems='center' minH='57px' border='2px solid #2E8A07' bg='#FFFFFF'
                      boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)' borderRadius='10px' fontWeight='700'
                      justifyContent='space-between'>
                    <Heading m={0} fontSize='18px' color='#2E8A07' width='70%' pl={3}>Total</Heading>
                    <Heading m={0} fontSize='18px' pl={3} width='30%'>
                        {(() => {
                            let countUsersCompany = 0
                            if (usage?.length > 0) {
                                usage?.map((a) => {
                                    countUsersCompany += (Number(a.total) / 60)
                                })
                                return countUsersCompany.toFixed(2)
                            }
                        })()}
                    </Heading>
                </Flex>
            </Flex>
            <Flex alignItems='center' flexDirection='column'>
                <Heading m={0} fontSize='16px'>Most Active Users</Heading>
                {usage && (
                    <ActiveUsersUsageChart
                        width='519px'
                        height='519px'
                        colors={companyColors}
                        score={usage}/>
                )}
            </Flex>
        </Flex>
    </Box>
)

export default UsagePart