import {Table, Thead, Tbody, Tr, Th, Td,Heading} from '@chakra-ui/react'
import styles from './styles'

const TableProjectBig = ({width, existingYearEnergyBig, ledYearEnergyBig, customBidding}) =>  (
        <Table width={width}>
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>Monthly</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 1</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 3</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 5</Heading></Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Existing System:</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${Math.round(existingYearEnergyBig/12)?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${existingYearEnergyBig?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${Math.round(existingYearEnergyBig*3)?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${Math.round(existingYearEnergyBig*5)?.toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">LED Upgrade:</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${Math.round(ledYearEnergyBig/12)?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${ledYearEnergyBig?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${Math.round(ledYearEnergyBig*3)?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>${Math.round(ledYearEnergyBig*5)?.toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Saved Capital:</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>${Math.round((existingYearEnergyBig/12) - (ledYearEnergyBig/12))?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>${Math.round(existingYearEnergyBig - ledYearEnergyBig)?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>${Math.round((existingYearEnergyBig*3) - (ledYearEnergyBig*3))?.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>${Math.round((existingYearEnergyBig*5) - (ledYearEnergyBig*5))?.toLocaleString('en-US')}</Td>
                </Tr>
            </Tbody>
        </Table>
    )
export default TableProjectBig
