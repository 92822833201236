import { useQuery } from 'react-query'
import {stateUseCase} from '../../factories'
import {AU, EU, SUPERADMIN} from "../../../constants/user/userEnum";

const useListStates = (userType) => {
    const {
        refetch, isLoading, data: states, error,
    } = useQuery('states', () => stateUseCase.listStates(), {refetchOnWindowFocus: false, enabled: userType === EU || userType === SUPERADMIN || userType === AU})

    return {
        refetch,
        isLoading,
        error,
        states,
    }
}

export default useListStates
