import React from 'react'
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,} from '@chakra-ui/react'
import Icon from '../Icon'
import IconButton from '../IconButton'

const InviteModal = ({isOpen, onClose, children, title, bg = 'green.100',headerColor, minW, maxHBody='600px', px=5, py=5}) => (
    <Modal
        isCentered
        isOpen={isOpen}
        onClose={onClose}>
        <ModalOverlay/>
        <ModalContent
            overflow="hidden"
            borderRadius="10px"
            minW={minW ? minW : "853px"}>
            <ModalHeader
                bg={bg}
                color={headerColor ? headerColor : 'black'}
                fontWeight="700"
                fontSize="3xl"
                as='h2'
                m={0}
                textAlign="center">
                {title}
            </ModalHeader>
            <IconButton
                height="unset"
                onClick={onClose}
                _hover={{bg: 'none',}}
                _active={{bg: 'none',}}
                bg="none"
                aria-label="close button"
                position="absolute"
                top="1.5rem"
                right="0.75rem"
                icon={<Icon type="darkClose"/>}/>
            <ModalBody
                px={px}
                py={py}
                overflow="scroll"
                maxH={maxHBody}>
                {children}
            </ModalBody>
        </ModalContent>
    </Modal>
)

export default InviteModal
