import { useMutation } from 'react-query'
import { companyUseCase } from '../../factories'

const useConfirmSyncCompany = () => {
  const {
    mutate: confirmCompany, isLoading, data, error,
  } = useMutation(companyUseCase.confirmCompanySync)

  return {
    confirmCompany,
    isLoading,
    error,
    data,
  }
}

export default useConfirmSyncCompany
