const orderRepo = (orderService) => {

    const getEstimate = (input) => orderService.getEstimate(input)

    const getEstimates = (input) => orderService.getEstimates(input)

    return {
        getEstimate,
        getEstimates,
    }
}

export default orderRepo
