// Data types
export const DATE_ENUMS = {
    MAIN : 1,
    REU : 2,
    SU : 3,
    EU : 4,
    SALES : 5,
    AU : 6,
}


// Company chart score
export const InitialScore = {
    office: 0,
    industrial: 0,
    retail: 0,
    multi: 0,
    hospitality: 0,
    parking: 0,
    horticulture: 0,
    healthcare: 0,
    data: 0,
    other: 0,
}

// User chart scores
export const InitialScoreUser = {
    architecture: 0,
    cyrcadian: 0,
    engineering: 0,
    illumination: 0,
    environment: 0,
    property: 0,
    energy: 0,
    technology: 0,
    education: 0,
    horticulture: 0,
}

export const InitialIlluminationUser = {
    intensity: 0,
    uniformity: 0,
    color_quality: 0,
    color_rendering: 0,
    human_centric: 0,
}

export const InitialPreferences = {
    messages: false,
    projects: false,
    orders: false,
    shipping: false,
}

export const InitialTechnologyUser = {
    efficiency: 0,
    sensors: 0,
    energy_monitoring: 0,
    system_longevity: 0,
    internet_of_things: 0,
}

export const InitialValuationUser = {
    flexible_space: 0,
    expense_reduction: 0,
    ['financing/laas']: 0,
    user_experience: 0,
    asset_resilience: 0,
}

export const InitialEnvironmentUser = {
    clean_energy: 0,
    eco_design: 0,
    grey_energy: 0,
    green_programs: 0,
    sustainability: 0,
}