import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Flex, Button} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";

const LightCountInfoModal = ({
                                 register,
                                 propertySize,
                                 setLightE,
                                 isOpen,
                                 onClose,
                                 getValues,
                                 lightCountEstimate,
                                 setLightCountEstimate,
                                 setValue
                             }) => {
    const [error, setError] = useState('')

    useEffect(() => {
        if (!propertySize) return
        let a = (0.01 * Number(propertySize)).toFixed(2)
        setLightCountEstimate(a)
        setError('')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertySize])

    const saveLightCountEstimate = () => {
        if (!lightCountEstimate) return setError('Write property size')
        setError('')
        setValue('lightCountEstimate', lightCountEstimate)
        setValue('lightCount', "Yes Please")
        setLightE("Yes Please")
        onClose()
    }

    return (
        <>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay/>
                <ModalContent
                    overflow="hidden"
                    borderRadius="10px"
                    minW="853px">
                    <ModalHeader
                        bg='#FFE68D'
                        fontWeight="700"
                        fontStyle='normal'
                        fontSize="24px"
                        textAlign="center">
                        Light Count Estimate
                    </ModalHeader>
                    <IconButton
                        height="unset"
                        onClick={onClose}
                        _hover={{bg: 'none',}}
                        _active={{bg: 'none',}}
                        bg="none"
                        aria-label="close button"
                        position="absolute"
                        top="1.5rem"
                        right="0.75rem"
                        icon={<Icon type="darkClose"/>}/>
                    <ModalBody
                        pt='40px'
                        pl='80px'
                        pr='80px'
                        pb='60px'
                        overflow="scroll"
                        maxH="600px"
                        fontSize='24px'
                        fontWeight='700'
                        textAlign='center'>
                        <Text>
                            Focus Earth can conduct a light count of your property for $0.01/sq.ft. The light count cost
                            is waived from all approved projects upon authorization.
                        </Text><br/>
                        <Text>
                            For your property, the site visit and count estimate is:
                        </Text>
                        {
                            error && (
                                <Text
                                    color="red.100"
                                    fontSize="m">
                                    {error}
                                </Text>
                            )
                        }
                        <Flex
                            justifyContent='center'
                            flexWrap='wrap'>
                            <Flex
                                width='418px'
                                mt={4}
                                border='1px solid'
                                borderColor='#A9A9A9'
                                height='46px'
                                borderRadius='6px'
                                justifyContent='center'
                                alignItems='center'>
                                {lightCountEstimate ? '$' + lightCountEstimate : 0}
                            </Flex>
                            <Flex
                                width='418px'
                                mt={4}
                                justifyContent='space-between'>
                                <Button
                                    width='184px'
                                    bg='#6A6A6A'
                                    onClick={() => {
                                        setValue('lightCountEstimate', 0)
                                        setLightE("No Thanks")
                                        setValue('lightCount', "No Thanks")
                                        onClose()
                                    }}
                                    fontSize='16px'>
                                    Nevermind, Go Back
                                </Button>
                                <Button
                                    bg='#2C628D'
                                    width='184px'
                                    fontSize='16px'
                                    onClick={saveLightCountEstimate}>
                                    OK, Let’s Do it!
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default LightCountInfoModal