import {Box, Heading, Text, VStack, Flex, List, ListItem, Grid} from '@chakra-ui/react'
import {ChartPayback, CustomDivider, TableProjectInvestment} from '../../components/Theme'
import Loading from "../../components/Loading";

const Investment = ({
                        project,
                        customBidding,
                        tax,
                        ledLuminaires,
                        shipping,
                        refInvestment,
                        proposal,
                        ledLuminairesCost,
                        loading,
                        projectTotal,
                        existingLuminairesCost,
                        reportPayback,
                        reportNPV,
                        reportROI,
                        savings10,
                        rebates,
                        lightCountWaiver,
                        yearOneExisting,
                        yearThreeExisting,
                        yearFiveExisting,
                        yearOneLed,
                        yearThreeLed,
                        yearFiveLed,
                    }) => {
    if (loading) return <Loading/>
    return (
        <Box ref={refInvestment}>
            <Box>
                <Heading fontSize="3xl">Investment Overview</Heading>
                <Text fontSize="md">
                    This section illustrates some of the key investment metrics associated with an LED lighting upgrade.
                    Explore the project component breakdown and annual financial implications for the recommended
                    system.
                </Text>
            </Box>
            <CustomDivider border='2px'/>
            <Flex flexWrap="wrap" justifyContent="space-between">
                <Flex flexDirection='column'>
                    <Heading fontSize='lg'>Investment Highlights</Heading>
                    <Grid fontFamily='Titillium Web' ml={16} fontWeight='600' rowGap={8} columnGap={16}
                          templateColumns='repeat(2, 1fr)'>
                        <VStack width='176px' height='93px' bg={customBidding ? '#8A8A8A':'#8FCF74'}>
                            <Text fontFamily='Titillium Web' fontWeight='600' px={3} mt={2} fontSize='md' borderBottom='1px solid black'>Payback (yrs)</Text>
                            <Text fontSize='2xl' mb={4}>{reportPayback}</Text>
                        </VStack>
                        <VStack bg={customBidding ? '#A9A9A9':'#F1D2FF'}>
                            <Text fontFamily='Titillium Web' fontWeight='600' px={3} mt={2} fontSize='md' borderBottom='1px solid black'>NPV</Text>
                            <Text fontSize='2xl' mb={4}>${reportNPV?.toLocaleString('en-US')}</Text>
                        </VStack>
                        <VStack width='176px' height='93px' bg={customBidding ? '#A9A9A9':'#F1D2FF'}>
                            <Text fontFamily='Titillium Web' fontWeight='600' px={3} mt={2} fontSize='md' borderBottom='1px solid black'>ROI (10 yrs)</Text>
                            <Text fontSize='2xl' mb={4}>{reportROI}%</Text>
                        </VStack>
                        <VStack bg={customBidding ? '#8A8A8A':'#8FCF74'}>
                            <Text fontFamily='Titillium Web' fontWeight='600' px={3} mt={2} fontSize='md' borderBottom='1px solid black'>Savings (10 yrs)</Text>
                            <Text fontSize='2xl' mb={4}>${savings10}</Text>
                        </VStack>
                    </Grid>
                </Flex>
                <List width='240px'>
                    <Heading fontSize='lg'>Project Pricing Information</Heading>
                    <ListItem mb={2} fontSize="sm" display="flex" justifyContent="space-between">
                        LED Luminaires:
                        <Flex alignItems="center" justifyContent="center" height="23px" width="83px" bg={customBidding ? "#D6D6D6" : "#C1DBFF"}>
                            $ {ledLuminaires?.toLocaleString('en-US')}
                        </Flex>
                    </ListItem>
                    <ListItem fontSize="sm" display="flex" justifyContent="space-between">Rebates:
                        <Flex mb={2} alignItems="center" justifyContent="center" height="23px" width="83px"
                              bg={customBidding ? "#A9A9A9": "rgba(193, 219, 255, 0.41)"}>
                            {
                                rebates === 0 ? (
                                    'TBD'
                                ) : (
                                    '$(' + rebates?.toLocaleString('en-US')+')'
                                )
                            }
                        </Flex>
                    </ListItem>
                    <ListItem mb={2} fontSize="sm" display="flex" justifyContent="space-between">Light Count Waiver:
                        <Flex alignItems="center" justifyContent="center" height="23px" width="83px" bg={customBidding ? "#D6D6D6" : "#C1DBFF"}>
                            $({lightCountWaiver?.toLocaleString('en-US')})
                        </Flex>
                    </ListItem>
                    <ListItem mb={2} fontSize="sm" display="flex" justifyContent="space-between">
                        Sales Tax:
                        <Flex alignItems="center" justifyContent="center" height="23px" width="83px"
                              bg={customBidding ? "#A9A9A9": "rgba(193, 219, 255, 0.41)"}>
                            ${tax?.toLocaleString('en-US')}
                        </Flex>
                    </ListItem>
                    <ListItem mb={2} fontSize="sm" display="flex" justifyContent="space-between">
                        Shipping:
                        <Flex ml={10} alignItems="center" justifyContent="center" height="23px" width="83px"
                              bg={customBidding ? "#D6D6D6" : "#C1DBFF"}>
                            {
                                shipping === 0 ? (
                                    'TBD'
                                ) : (
                                    '$(' + shipping?.toLocaleString('en-US')+')'
                                )
                            }
                        </Flex>
                    </ListItem>
                    <ListItem mb={2} fontSize="sm" display="flex" justifyContent="space-between">
                        Installation:
                        <Flex alignItems="center" justifyContent="center" height="23px" width="83px"
                              bg={customBidding ? "#A9A9A9": "rgba(193, 219, 255, 0.41)"}>
                            ${proposal?.installation?.total ? proposal?.installation?.total?.toLocaleString('en-US') : 0}
                        </Flex>
                    </ListItem>
                    <ListItem fontSize="sm" display="flex" justifyContent="space-between">
                        Project Total:
                        <Flex fontSize="m" alignItems="center" fontWeight='600' justifyContent="center" height="23px"
                              width="83px" bg={customBidding ? "#8A8A8A" : "#FFBABA"}>
                            ${Math.round(projectTotal)?.toLocaleString('en-US')}
                        </Flex>
                    </ListItem>
                </List>
            </Flex>
            <CustomDivider border='2px'/>
            <Flex mr={8} justifyContent="space-between" flexDirection="column">
                <Flex>
                    <Heading mr={6} fontSize="lg" as="h3">Payback Forecast</Heading>
                    <Flex alignItems="center">
                        <Box bg={customBidding ? "#8A8A8A" : "#F1381F"} width="18px" height="18px"/>
                        <Heading ml={2} as="h4" fontSize="sm">Existing System</Heading>
                    </Flex>
                    <Flex ml={2} alignItems="center">
                        <Box bg={customBidding ? "#D6D6D6" : "#619C48"} width="18px" height="18px"/>
                        <Heading ml={2} as="h4" fontSize="sm">LED System</Heading>
                    </Flex>
                </Flex>
            </Flex>
            <Flex flexDirection="column">
                <ChartPayback
                    customBiding={customBidding}
                    project={project}
                    rebates={rebates}
                    lightCountWaiver={lightCountWaiver}
                    proposal={proposal}
                    led={ledLuminairesCost}
                    existing={existingLuminairesCost}
                    projectTotal={projectTotal}/>
                <Heading fontSize='lg' m={0} textAlign='center'>Years</Heading>
            </Flex>
            <CustomDivider border='2px'/>
            <Heading fontSize='lg' m={0}>Projected Savings</Heading>
            <TableProjectInvestment
                width="800px"
                customBiding={customBidding}
                yearOneExisting={yearOneExisting}
                yearThreeExisting={yearThreeExisting}
                yearFiveExisting={yearFiveExisting}
                yearOneLed={yearOneLed}
                yearThreeLed={yearThreeLed}
                yearFiveLed={yearFiveLed}/>
            <Text fontSize='md' mt={8}>
                Note: Ask your insurance provider how your premiums can be lowered after an LED upgrade. Factors to
                consider include the reduction of electric maintenance, the improvement of visibility in high-traffic
                areas and the creation of a safer property environment.
            </Text>
        </Box>
    )
}

export default Investment
