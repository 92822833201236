import React, {useEffect, useState} from 'react'
import {
    Text,
    Grid,
    GridItem, Box, Heading,
} from '@chakra-ui/react'
import {Checkbox, Input} from '../../../../components/Theme'
import InputMask from "react-input-mask";
import {useForm} from "react-hook-form";


const Main = ({main, error}) => {
    const [mainValues, setMainValues] = useState({...main.current})
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: {errors},
    } = useForm()
    const onChange = (e, name) => {
        let newValue
        if (name !== 'checkbox'){
             newValue = {
                ...mainValues,
                [name]: e.target.value,
            }
        }else{
             newValue = {
                ...mainValues,
                [name]: e,
            }
        }
        setMainValues(newValue)
    }

    useEffect(() => {
        main.current = mainValues
    }, [mainValues, main])
    return (
        <>
            <Heading
                textAlign="center"
                mt={4}
                mb={3}
                as="h2"
                fontSize="4xl">
                Want access to information that interests you?
            </Heading>
            <Grid
                mt={8}
                width="100%"
                templateColumns="repeat(2, 1fr)"
                gap={8}>
                <GridItem colSpan={1}>
                    <label style={{'width': '100%'}}>
                        <Text
                            as="span"
                            fontWeight="600"
                            fontSize="18px"
                            color="#2C628D">
                            First Name:
                        </Text>
                        <Input
                            color="grey.300"
                            isInvalid={error && !mainValues.firstName}
                            value={mainValues.firstName}
                            {...register('firstName', {
                                required: true,
                                minLength: 2,
                            })}
                            onChange={(e) => onChange(e, 'firstName')}
                            errorBorderColor="red.100"
                            name="firstName"/>
                    </label>
                </GridItem>
                <GridItem colSpan={1}>
                    <label style={{'width': '100%'}}>
                        <Text
                            as="span"
                            fontWeight="600"
                            fontSize="18px"
                            color="#2C628D">
                            Last Name:
                        </Text>
                        <Input
                            isInvalid={error && !mainValues.lastName}
                            errorBorderColor="red.100"
                            color="grey.300"
                            value={mainValues.lastName}
                            {...register('lastName', {
                                required: true,
                                minLength: 2,
                            })}
                            onChange={(e) => onChange(e, 'lastName')}
                            name="lastName"/>
                    </label>
                </GridItem>
                <GridItem colSpan={1}>
                    <label style={{'width': '100%'}}>
                        <Text
                            as="span"
                            fontWeight="600"
                            fontSize="18px"
                            color="#2C628D">
                            Email Address:
                        </Text>
                        <Input
                            errorBorderColor="red.100"
                            color="grey.300"
                            isInvalid={error && !mainValues.email}
                            value={mainValues.email}
                            {...register('email', {
                                required: true,
                                minLength: 4,
                            })}
                            onChange={(e) => onChange(e, 'email')}
                            name="email"/>
                    </label>
                </GridItem>
                <GridItem colSpan={1}>
                    <label style={{'width': '100%'}}>
                        <Text
                            as="span"
                            fontWeight="600"
                            fontSize="18px"
                            color="#2C628D">
                            Phone Number:
                        </Text>
                        <Input
                            as={InputMask}
                            mask="999-999-9999"
                            maskChar=""
                            color="grey.300"
                            autoComplete="on"
                            formatChars={{'9': '[0-9]'}}
                            errorBorderColor="red.100"
                            {...register('phone', {
                                required: true,
                                minLength: 9,
                            })}
                            isInvalid={error && !mainValues.phone}
                            onChange={(e) => onChange(e, 'phone')}/>
                    </label>
                </GridItem>
                <GridItem colSpan={1}>
                    <label style={{'width': '100%'}}>
                        <Text
                            as="span"
                            fontWeight="600"
                            fontSize="18px"
                            color="#2C628D">
                            Password:
                        </Text>
                        <Input
                            errorBorderColor="red.100"
                            color="grey.300"
                            value={mainValues.password}
                            isInvalid={error && !mainValues.password}
                            {...register('password', {
                                required: true,
                                minLength: 8,
                            })}
                            onChange={(e) => onChange(e, 'password')}
                            type="password"/>
                    </label>
                </GridItem>
                <GridItem colSpan={1}>
                    <label style={{'width': '100%'}}>
                        <Text
                            as="span"
                            fontWeight="600"
                            fontSize="18px"
                            color="#2C628D">
                            Repeat Password:
                        </Text>
                        <Input
                            errorBorderColor="red.100"
                            color="grey.300"
                            value={mainValues.repeatPassword}
                            isInvalid={!!errors.repeatPassword}
                            {...register('repeatPassword', {
                                required: true,
                                validate: (value) => value === mainValues.password,
                            })}
                            onChange={(e) => onChange(e, 'repeatPassword')}
                            type="password"/>
                    </label>
                </GridItem>
            </Grid>
            <label>
                <Text
                    mt={3}
                    as="span"
                    color="#2C628D"
                    fontSize="18px"
                    fontWeight="600"
                    display="block">
                    Terms & Conditions:
                </Text>
                <Checkbox
                    w='100%'
                    border="1px solid #A9A9A9"
                    borderRadius="6px"
                    p={2}
                    {...register('checkbox', {
                        required: true,
                    })}
                    isChecked={mainValues.checkbox}
                    onChange={(e) => onChange(e.target.checked, 'checkbox')}
                    iconColor="#2C628D"
                    colorScheme="whiteAlpha">
                    <Box
                        color="#222222"
                        fontSize="sm"
                        as="span">
                        Check here to indicate you have read and agree to the terms outlined in the
                        Energy
                        Portal &nbsp;
                        <Text
                            as="span"
                            borderBottom="1px solid #A9A9A9">
                            Terms & Conditions
                        </Text>
                    </Box>
                </Checkbox>
            </label>
        </>
    )
}

export default Main
