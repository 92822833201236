import {css} from '@emotion/react'

const styles = {
    styledList: css`
        .list-item {
            cursor : pointer;
            position : relative;
            display : flex;
            align-items : center;
            &:hover{
                background-color: #A9A9A9;
            }
        }
    `,
}

export default styles
