import React, {useEffect, useState} from 'react'
import {useHistory, Link, useLocation, useParams,} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Text, Button, useDisclosure,} from '@chakra-ui/react'
import {useCreateAccessory, useListProducts, useUpload, useUser} from '../../core/hooks'
import Navbar from '../../components/Navbar/Navbar'
import styles from './styles'
import MainFields from './MainFields'
import SecondaryFields from './SecondaryFields'
import {CompatibleProductsModal} from "../../constants/mainFunctions";
import {PRODUCT_JSON} from "../../constants/products/ProductEnum";

const CreateProduct = () => {
    const [certifications, setCertifications] = useState([])
    const [cctColors, setCctColors] = useState([])
    const [orderCodeError, setOrderCodeError] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [orderCodeHover, setOrderCodeHover] = useState(false)
    const [orderCodesComp, setOrderCodesComp] = useState([])
    const {isOpen: isOpenProducts, onOpen: onOpenProducts, onClose: onCloseProducts} = useDisclosure()
    const [compatibleProductOrderCodes, setCompatibleProductOrderCodes] = useState([])
    const {register, handleSubmit, getValues, formState: {errors}, watch,} = useForm({
        defaultValues: {
            ...PRODUCT_JSON
        }
    })
    const [image, setImage] = useState('')
    const params = useParams()
    const {upload, isLoading: uploadIsLoading,} = useUpload()
    const {createAccessory, isLoading: isCreateLoading} = useCreateAccessory()
    const history = useHistory()
    const location = useLocation()
    const compatibility = watch('compatibility', '')
    const orderCodes = watch('orderCode', '')
    const lampCategory = watch('lampCategory', [])
    const lampBaseCategory = watch('lampBaseCategory', [])
    const {user} = useUser()
    let condition = {condition: {filter: '', table: params.type === 'fixture' ? 'FIXTURES' : 'LAMPS'}}
    const {data: productsResponse} = useListProducts(condition)
    const type = location.pathname.split('/')[3]

    const submit = (data) => {
        if (orderCodeError){
            setOrderCodeHover(true)
            return
        }
        const input = {
            ...data,
            image,
            cct: cctColors,
            certifications: certifications,
            companyId: user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany?.id,
            compatibleProductOrderCodes: compatibleProductOrderCodes,
            type: type,
        }
        createAccessory(input,
            {
                onSuccess: (response) => {
                    history.push(`/catalogues/product/${type}s/${response?.data?.data}`)
                },
            },
        )
    }

    // Set compatible product order codes for this user
    useEffect(() => {
        if (!productsResponse) return
        let products = productsResponse.data.data
        let compatibleOrderCodes = []

        for (let i = 0; i < products.length; i++) {
            if (type === 'lamp'){
                compatibleOrderCodes.push(products[i].fields['Order Code (calculated)'])
            } else {
                compatibleOrderCodes.push(products[i].fields['Order Code'])
            }
        }
        setOrderCodesComp(compatibleOrderCodes)
    },[productsResponse])

    useEffect(() => {
        if (!orderCodes && cctColors?.length === 0) return
        let codes = orderCodes.split(',')
        if (cctColors.length === 0 && codes[codes.length -1] === '') {
            setShowAlert(false)
        } else{
            setShowAlert(true)
        }
        if (codes[codes.length -1] !== '' && codes.length  === cctColors.length){
            setOrderCodeError(false)
        } else{
            setOrderCodeError(true)
        }
        let pattern = /^\s*$/
        if (pattern.test(codes[codes?.length -1])) {
            return setOrderCodeError(true)
        }
    },[cctColors, getValues('orderCode')])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <CompatibleProductsModal
                isOpen={isOpenProducts}
                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                compatibleProductOrderCodes={compatibleProductOrderCodes}
                onClose={onCloseProducts}
                table={params.type === 'fixture' ? 'FIXTURES' : 'LAMPS'}/>
            <Navbar minW="650px"
                    title={(
                        <Flex>
                            <Link to="/catalogues">Catalogues&nbsp;</Link>
                            /&nbsp;{`Create ${location.pathname.split('/')[3]}`}
                        </Flex>
                    )}>
                <Button bg="#C2EF62" color="black" mr={8} ml={12} px={8} isLoading={isCreateLoading || uploadIsLoading} w="135px" type="submit" h="40px">
                    <img src="/images/done.png" alt="done"/>
                    <Text fontWeight="700" ml={1} mt="2px">Submit</Text>
                </Button>
            </Navbar>
            <Box m="auto" minW="650px" maxW="1240px">
                <Tabs css={styles.container} bg="#F5F5F5">
                    <TabList border="none" pt={10} bg="white.100">
                        <Tab fontFamily='Titillium Web' minW="208px" fontSize='18px' _active={{background: 'transparent',}} _selected={{color: 'green.200',borderBottomWidth: '3px', borderColor: 'green.200', boxShadow: 'none',}} color="green.200">
                            Product Details
                        </Tab>
                    </TabList>
                    <TabPanels pt={6} px={10} borderTop="1px solid #DBDADA" borderBottom="none">
                        <TabPanel  pb={40}>
                            <MainFields
                                setOrderCodeHover={setOrderCodeHover}
                                orderCodeHover={orderCodeHover}
                                showAlert={showAlert}
                                supplierUser={user}
                                editMode={false}
                                lampCategory={lampCategory}
                                lampBaseCategory={lampBaseCategory}
                                type={type}
                                orderCodeError={orderCodeError}
                                setCctColors={setCctColors}
                                cctColors={cctColors}
                                setCertifications={setCertifications}
                                certifications={certifications}
                                user={user}
                                getValues={getValues}
                                location={location}
                                register={register}
                                errors={errors}
                                image={image}
                                setImage={setImage}
                                upload={upload}/>
                            <SecondaryFields
                                onOpenProducts={onOpenProducts}
                                editMode={false}
                                type={type}
                                orderCodesComp={orderCodesComp}
                                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                                compatibility={compatibility}
                                compatibleProductOrderCodes={compatibleProductOrderCodes}
                                register={register}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </form>
    )
}

export default CreateProduct