import HttpRequest from "./http-request";

const uploadService = () => {
    const {post} = HttpRequest()
    const uploadSingle = ({name, file, type, info,}) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", name);
        formData.append("type", type);
        formData.append("info", info);
        return post('upload-file', formData)
    }

    const uploadMultiple = (files) => Promise.all(files.map((file) => uploadSingle(file)),)

    const newFile = (variables) => {
        return post('file/new',variables)
    }

    return {
        uploadSingle,
        uploadMultiple,
        newFile,
    }
}

export default uploadService