const productRepo = (productService) => {
  const createProduct = (input) => productService.createProduct(input)

  const updateProduct = (input) => productService.updateProduct(input)

  const listProducts = (variables) => productService.listProducts(variables)

  const getProduct = (variables) => productService.getProduct(variables)

  const getRecommended = (variables) => productService.getRecomended(variables)

  const getCustomBids = (variables) => productService.getCustomBids(variables)

  const updatePriceBid = (input) => productService.updatePriceBid(input)

  return {
    updatePriceBid,
    getRecommended,
    getCustomBids,
    createProduct,
    updateProduct,
    listProducts,
    getProduct,
  }
}

export default productRepo
