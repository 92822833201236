import React from 'react'

const Icon = ({
  style,
  type,
  width,
  height,
}) => (
  <img
    style={style}
    src={`/icons/${type}.svg`}
    alt={`icon-${type}`}
    width={width}
    height={height}/>
)

export default Icon
