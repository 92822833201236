import { SimpleGrid, FormControl, FormLabel,} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import {InputLabel, Input} from '../../../components/Theme'

const Details = ({register, errors, setValue, phone,validateEmail, company}) => (
    <SimpleGrid columns={2} gap={5} w='full'>
        <InputLabel
            errormessage={errors.firstName?.message}
            isInvalid={errors.firstName}
            {...register('firstName', {
                required: 'Please enter your first name',
                minLength: {
                    value: 2,
                    message: 'First name must be at least 2 characters long'
                }})}
            label="First Name:"/>
        <InputLabel
            errormessage={errors.lastName?.message}
            isInvalid={errors.lastName}
            {...register('lastName', {
                required: 'Please enter your last name',
                minLength: {
                    value: 2,
                    message: 'Last name must be at least 2 characters long'
                }
            })}
            label="Last Name:"/>
        <InputLabel
            errormessage={errors.title?.message}
            isInvalid={errors.title}
            label="Title:"
            {...register('title')}/>
        <InputLabel
            errormessage={errors.email?.message}
            isInvalid={errors.email}
            {...register('email', {
                required: 'Please enter email',
                minLength: {
                    value: 5,
                    message: 'Email must be at least 5 characters long'
                },
                validate: (value) => validateEmail(value) || 'Insert valid email',
            })}
            label="Email:"/>
        <FormControl id="Phone">
            <FormLabel fontFamily='Titillium Web' fontWeight="600" fontSize="md" color="blue.200">
                Phone Number:
            </FormLabel>
            <Input
                as={InputMask}
                isInvalid={errors.phone}
                value={phone}
                height='50px'
                mask="999-999-9999"
                onChange={(e) => setValue('phone', e.target.value)}
                maskChar=""
                autoComplete="off"
                formatChars={{'9': '[0-9]'}}
                errorBorderColor="red.100"/>
        </FormControl>
        <InputLabel
            disabled
            value={company?.name}
            label='Company Name'/>
    </SimpleGrid>
)

export default Details
