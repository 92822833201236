import {Button, Flex, Modal, ModalBody, Input, ModalContent, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import IconButton from "../../components/Theme/IconButton";
import Icon from "../../components/Theme/Icon";
import React, {useState} from "react";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import httpRequest from "../../core/services/http-request";

const ShareEstimateModal = ({
                                isOpen,
                                onClose,
                                estimate,
                                loading,
                                refEstimate,
                                refOrder,
                                refShipping,
                                refInvoice,
                                refReceipt,
                                tabIndex,
                                shareEstimate
                            }) => {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [shareErrors, setShareErrors] = useState(false)
    const {post} = httpRequest()

    const sharePDF = () => {
        if (!firstName) {
            return setShareErrors('Set First Name')
        }
        if (!lastName) {
            return setShareErrors('Set Last Name')
        }
        if (!email) {
            return setShareErrors('Set Email Name')
        }
        setShareErrors('')
        let data = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            type: 'share',
            reference: estimate?.reference,
        }
        return shareEstimate(data)
        let input
        let pdfType
        if (tabIndex === 0) {
            if (!estimate?.createdAt) return setShareErrors('Estimate does not exists')
            input = refEstimate.current
            pdfType = 'Estimate'
        } else if (tabIndex === 1) {
            if (!estimate?.orderDateCreated) return setShareErrors('Order does not exists')
            pdfType = 'Order'
            input = refOrder.current
        } else if (tabIndex === 2) {
            if (!estimate?.shippingDateCreated) return setShareErrors('Shipping does not exists')
            input = refShipping.current
            pdfType = 'Shipping'
        } else if (tabIndex === 3) {
            if (!estimate?.invoiceDateCreated) return setShareErrors('Invoice does not exists')
            input = refInvoice.current
            pdfType = 'Invoice'
        } else if (tabIndex === 4) {
            if (!estimate?.receiptDateCreated) return setShareErrors('Receipt does not exists')
            input = refReceipt.current
            pdfType = 'Receipt'
        }
        // setLoadingPdf(true)
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('portrait', 'cm');
                const width = pdf.internal.pageSize.getWidth();
                const height = pdf.internal.pageSize.getHeight() - 10;
                pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
                let blob = pdf.output('blob');

                const type = 'pdf'
                const name = `order${new Date().getTime() * Math.random()}.${type}`
                const formData = new FormData()
                formData.append("file", blob);
                formData.append("name", name);
                formData.append("type", type);


                return post('upload-file', formData).then((e) => {
                    let data = {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        fileName: e.data.data,
                        type: pdfType,
                        reference: estimate?.reference,
                    }
                    post('share-estimate-email', data).then((res) => {
                        console.log(res.data.data)
                    })
                })
            })
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#8FCF74' fontWeight="700" fontSize="24px" as='h2' m={0} textAlign="center">
                    {
                        tabIndex === 0 ? 'Share Estimate' :
                            tabIndex === 1 ? 'Share Order' :
                                tabIndex === 2 ? 'Share Shipping' :
                                    tabIndex === 3 ? 'Share Invoice' :
                                        tabIndex === 4 ? 'Share Receipt' : ''
                    }
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    <Text>Share a PDF version of {tabIndex === 0 ? 'Estimate' :
                        tabIndex === 1 ? 'Order' :
                            tabIndex === 2 ? 'Shipping' :
                                tabIndex === 3 ? 'Invoice' :
                                    tabIndex === 4 ? 'Receipt' : ''} (#{estimate?.reference})</Text>
                    <Flex justifyContent='center'>
                        <Flex flexDirection='column' maxW='400px'>
                            {shareErrors && (
                                    <Text textAlign='center' color="red.100" fontSize="sm">
                                        {shareErrors}
                                    </Text>
                                )}
                            <Input onChange={(e) => setFirstName(e.target.value)} p={5} height='50px !important' fontSize='16px' border='1px solid #A9A9A9 !important' width='400px' mt={4} placeholder='First Name'/>
                            <Input height='50px !important' fontSize='16px' border='1px solid #A9A9A9 !important' onChange={(e) => setLastName(e.target.value)} p={5} mt={4} placeholder='Last Name'/>
                            <Input height='50px !important' type='email' fontSize='16px' border='1px solid #A9A9A9 !important' onChange={(e) => setEmail(e.target.value)} p={5} mt={4} placeholder='Contact Email'/>
                        </Flex>
                    </Flex>
                    <Flex pb='80px' alignItems='center' flexDirection='column'>
                        <Button mt={10} mb={3} onClick={sharePDF} isLoading={loading} width='254px' height='51px' fontWeight='700' fontSize='18px' backgroundColor='#2C628D'>Send</Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ShareEstimateModal