import React from 'react'
import InputMask from 'react-input-mask'
import {Box, Heading, Flex, FormControl, FormLabel, Textarea, Text, Button, useToast,} from '@chakra-ui/react'
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar'
import styles from './styles'
import {useUser} from '../../core/hooks'
import {InputLabel, Input} from '../../components/Theme'
import {ReactComponent as Send} from '../../assets/send.svg'
import {ReactComponent as SendMessage} from '../../assets/cloudMail.svg'
import HttpRequest from "../../core/services/http-request";
import {useForm} from "react-hook-form";
import {useHistory, useParams} from "react-router-dom";

const SubmitQuestion = () => {
    const toast = useToast()
    const {post} = HttpRequest()
    const history = useHistory()
    const params = useParams()
    const {user} = useUser()
    const {formState: {errors}, register, handleSubmit,} = useForm({
        defaultValues: {
            name: user?.firstName + ' ' + user?.lastName,
            phone: user?.phone,
            email: user?.email,
        }
    })

    const onSubmit = (data) => {
        post('new-submit-question', {data})
            .then(function (res) {
                toast({
                    render: () => (
                        <Flex borderRadius='4px 0px 0px 4px' marginTop='80px' color='black' bg='#8FCF74' pl={3} width='530px' fontWeight='600' fontSize='14px' alignItems='center' height='40px'>
                            <SendMessage/>
                            <Text ml={2}>
                                Message Sent! Our team will reach out to you soon...
                            </Text>
                        </Flex>
                    ),
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                })
                setTimeout(() => {
                    history.push('/recent-activity')
                }, 2000)
            })
    }
    return (
        <>
            <Navbar title="Contact"/>
            <Flex margin="auto" maxW="1240px" pb={20} css={styles.containerQuestion} bg="white.200">
                <form style={{width: '100%'}} onSubmit={handleSubmit(onSubmit)}>
                    <Flex margin="auto" alignItems="center" flexDirection="column" px={8} maxW="760px">
                        <Heading as="h2" fontSize="4xl">
                            {user?.type === 'Su' ? 'Contact your Focus Earth representative:' : 'Message one of our Energy Consultants'}
                        </Heading>
                        <Flex w="100%">
                            <Box w="100%" mr={2}>
                                <InputLabel
                                    isInvalid={!!errors.name}
                                    {...register('name', {required: true})}
                                    label="Name:"/>
                            </Box>
                            <Box w="100%" ml={2}>
                                <FormControl id="Phone">
                                    <FormLabel color="blue.100">
                                        Phone Number:
                                    </FormLabel>
                                    <Input
                                        isInvalid={!!errors.phone}
                                        as={InputMask}
                                        {...register('phone', {required: true})}
                                        mask="999-999-9999"
                                        maskChar=""
                                        autoComplete="off"
                                        formatChars={{'9': '[0-9]'}}
                                        errorBorderColor="red.100"/>
                                </FormControl>
                            </Box>
                        </Flex>
                        <InputLabel
                            mt={3}
                            isInvalid={!!errors.email}
                            {...register('email', {required: true})}
                            label="Email:"/>
                        <InputLabel
                            mt={3}
                            autoFocus
                            value={params.title + ' | ' + params.proposalName}
                            isInvalid={!!errors.subject}
                            {...register('subject', {required: true})}
                            label="Subject:"/>
                        <FormControl mt={3} id="message">
                            <FormLabel color="blue.100">
                                Message:
                            </FormLabel>
                            <Textarea
                                isInvalid={!!errors.message}
                                {...register('message', {required: true})}
                                _focus={{boxShadow: 'none',}}
                                bg="white.100"
                                resize="none"
                                height="200px"
                                border="1px solid"
                                borderColor="#A9A9A9"
                                borderRadius="6px"/>
                        </FormControl>
                        <Button leftIcon={<Send/>} type="submit" mt={14} width="254px" variant="blue" size="lg">
                            Submit
                        </Button>
                    </Flex>
                </form>
            </Flex>
        </>
    )
}

export default SubmitQuestion