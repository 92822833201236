import HttpRequest from "./http-request";

const userService = () => {
    const {post} = HttpRequest()
    const {get} = HttpRequest()
    const createUser = (input) => {
        return post('register',input)
    }

    const updateUser = (input) => {
        return post('update-user',input)
    }

    const getUser = () => {
        return post('take-user')
    }

    const findUser = (id) => {
        return get(`find-user/${id}`)
    }

    const listUsers = (variables) => {
        return post('user-list',variables)
    }

    return {
        findUser,
        createUser,
        updateUser,
        getUser,
        listUsers,
    }
}

export default userService
