import React, {useState} from 'react'
import {
    Flex,
    Text,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
} from '@chakra-ui/react'
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import httpRequest from "../../../core/services/http-request";

const RemoveReceiptModal = ({isOpen, onClose, fileId, refetch}) => {
    const {post} = httpRequest()
    const [loading, setLoading] = useState(false)

    const deleteFile = () => {
        setLoading(true)
        let data = {
            id: fileId
        }
        post('delete-file-receipt',data).then(() => {
            setLoading(false)
            refetch()
            onClose()
        }).catch(() => {
            setLoading(false)
        })
    }

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay/>
            <ModalContent
                overflow="hidden"
                borderRadius="10px"
                minW="520px">
                <ModalHeader
                    bg='#FF9494'
                    fontWeight="700"
                    fontSize="3xl"
                    color='black'
                    textAlign="center">
                    Delete File
                </ModalHeader>
                <IconButton
                    height="unset"
                    onClick={onClose}
                    _hover={{bg: 'none',}}
                    _active={{bg: 'none',}}
                    bg="none"
                    aria-label="close button"
                    position="absolute"
                    top="1.5rem"
                    right="0.75rem"
                    icon={<Icon type="darkClose"/>}/>
                <ModalBody
                    pb={6}
                    textAlign='center'
                    overflow="scroll"
                    maxH="600px">
                    <Text
                        fontSize='24px'
                        fontFamily='Titillium Web'
                        my={10}>
                        Are you sure you want to delete this FIle?
                    </Text>
                    <Flex
                        justifyContent='space-between'
                        width='full'>
                        <Button
                            onClick={onClose}
                            minH='50px'
                            color='black'
                            width='230px'
                            bg='#DBDADA'>
                            Cancel
                        </Button>
                        <Button
                            isLoading={loading}
                            onClick={deleteFile}
                            minH='50px'
                            color='black'
                            width='230px'
                            bg='#FB7575'>
                            Yes, Delete File
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default RemoveReceiptModal