import HttpRequest from "./http-request";

const orderService = () => {
    const {get, post} = HttpRequest()

    const getEstimate = (input) => {
        return post(`get-estimate`,input)
    }

    const getEstimates = (input) => {
        return post(`get-estimates`,input)
    }

    return {
        getEstimate,
        getEstimates,
    }
}

export default orderService
