import {
    Button,
    VStack, Text, HStack,
} from '@chakra-ui/react'
import {InviteModal} from "../../components/Theme";

const removeCompanyUserModal = ({isOpen, onClose, removeCompanyFromUser, isLoadingRemoveUser}) => (
    <InviteModal
        isOpen={isOpen}
        onClose={onClose}
        bg="#FF8383"
        title="Sever Company Connection">
        <VStack py={8} maxW='618px' m='auto' spacing={12}>
            <Text fontSize='24px' textAlign='center'>
                Are you sure you want to sever Company for this user?  It will disconnect this user from their existing Projects.
            </Text>
            <HStack pb={8} justifyContent='space-between' w='100%'>
                <Button height='50px' fontSize='xl' color='black' width='254px' bg='#DBDADA' onClick={onClose}>
                    Nevermind go back
                </Button>
                <Button height='50px' color='black' fontSize='xl' width='254px' bg='#8FCF74' onClick={removeCompanyFromUser} isLoading={isLoadingRemoveUser}>
                    Yes
                </Button>
            </HStack>
        </VStack>
    </InviteModal>
)

export default removeCompanyUserModal