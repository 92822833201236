import httpRequest from "../core/services/http-request";
import {Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, FormLabel, Menu, MenuButton, Flex, Grid, GridItem, Checkbox, FormControl, Stack, Radio, RadioGroup, InputLeftElement, Input, InputGroup} from '@chakra-ui/react'
import {IconButton} from "../components/Theme";
import Icon from "../components/Theme/Icon";
import {useListProducts} from "../core/hooks";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import styles from "../components/Theme/CustomSelect/styles";
import {ReactComponent as ArrowDown} from "../assets/downArrow.svg";
import fileSaver from "file-saver/dist/FileSaver";
import {S3_BUCKET} from "./index";

const {post} = httpRequest()

export const RemoveProduct = async (product, table) => {
    let data = {
        id: product,
        table: table
    }
    await post('delete-product', data)
        .then(() => {

            return 1;
        }).catch(() => {

        })
}

const PRODUCT_TYPES = [
    'Fixtures',
    'Lamps',
    'Power Supplies',
    'Controls',
    'Accessories',
]

export const CompatibleProductsModal = ({product, table, isOpen, onClose, setCompatibleProductOrderCodes,compatibleProductOrderCodes }) => {
    let condition = {condition: {filter: '', table: table}}
    const location = useLocation()
    const {data: productsResponse} = useListProducts(condition)
    const [orderCodesCompatible, setOrderCodesCompCompatible] = useState([])
    const [productType, setProductType] = useState()
    const [nullProductError, setNullProductError] = useState()
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [typeError, setTypeError] = useState()

    const getLists = () => {
        if (!search || !productType) return
        let type
        if (productType === 'Fixtures'){
            type = 'FIXTURES'
        } else if (productType === 'Lamps'){
            type = 'LAMPS'
        }else if (productType === 'Power Supplies'){
            type = 'POWER'
        } else if (productType === 'Accessories') {
            type = 'ACCESSORY'
        } else if (productType === 'Controls') {
            type = 'CONTROL'
        }
        let condition = {
            condition: {
                table: type,
                filter: search,
            }
        }
        setLoading(true)
        post('product/list', condition).then((res) => {
            let arr = []
            let data = res?.data?.data['records'] ?? []
            for (let i = 0; i < data?.length; i++) {
                let codes = data[i].fields['Order Code'].split(',')
                for (let j = 0; j < codes?.length; j++) {
                    if (codes[j].includes(search) && !arr.includes(codes[j])){
                        arr.push(codes[j])
                    }
                }
            }
            setOrderCodesCompCompatible(arr)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
            return []
        })
    }

    const CustomChange = (e) => {
        if (!productType) {
            setNullProductError(true)
        }
        setSearch(e)
    }
    useEffect(() => {
        if (search?.length > 2 || search.length === 0){
            setTimeout(() => {
                getLists()
            },1000)
        }
    },[search])

    useEffect(() => {
        if (!productType) return
        setNullProductError(false)
        if (productType === table){
            return setTypeError(true)
        }
        setTypeError(false)
        if (search?.length > 2){
            getLists()
        }
    },[productType])

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="992px">
                <ModalHeader bg='#FFE68D' fontWeight="700" fontSize="24px" textAlign="center">
                    Compatible Products
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody p={10} overflow="scroll" maxH="600px">
                    <FormControl css={styles.menu}>
                        <FormLabel fontWeight="600" fontSize="md" fontFamily='Titillium Web' color="blue.200">
                            Selected order codes:
                        </FormLabel>
                        <Menu>
                            <MenuButton as={Flex} px={4} alignItems="center" border="1px solid" position='relative' overflow="scroll" borderColor="rgba(169, 169, 169, 0.87)" borderRadius="5px" bg="#A9A9A9" height="40px">
                                <Text whiteSpace="nowrap" fontSize="xl">
                                    {compatibleProductOrderCodes.toString()}
                                </Text>
                                <Flex alignItems='center' height="50px" top='-5px' right='15px' position='absolute'>
                                    <ArrowDown width='10px' height='10px'/>
                                </Flex>
                            </MenuButton>
                            {/*<MenuList pl={6}>*/}
                            {/*    <Grid templateColumns={`repeat(8, 1fr)`}>*/}
                            {/*        {compatibleProductOrderCodes?.map((item, index) => (*/}
                            {/*            <GridItem key={index} colSpan={1}>*/}
                            {/*                <Checkbox fontFamily='Titillium Web' name={item} isChecked={compatibleProductOrderCodes?.includes(item)} iconColor="black" colorScheme="whiteAlpha" onChange={(e) => {*/}
                            {/*                    let s = []*/}
                            {/*                    if (e.target.checked) {*/}
                            {/*                        s.push(item);*/}
                            {/*                        s = s.concat(compatibleProductOrderCodes)*/}
                            {/*                    } else {*/}
                            {/*                        let index = compatibleProductOrderCodes.indexOf(item)*/}
                            {/*                        compatibleProductOrderCodes.splice(index, 1)*/}
                            {/*                        s = s.concat(compatibleProductOrderCodes);*/}
                            {/*                    }*/}
                            {/*                    setCompatibleProductOrderCodes(s.sort((a, b) => a - b))}}>*/}
                            {/*                    {item}*/}
                            {/*                </Checkbox>*/}
                            {/*            </GridItem>))*/}
                            {/*        }*/}
                            {/*    </Grid>*/}
                            {/*</MenuList>*/}
                        </Menu>
                    </FormControl>
                    <Text my={5} color='#2C628D' fontSize='14px' fontFamily='Titillium Web'>Choose Compatible Component Type:</Text>
                    <RadioGroup onChange={setProductType} value={productType}>
                        <Stack direction='row' fontFamily='Titillium Web' css={styles.customRadioButton}>
                            {PRODUCT_TYPES.map(item => (
                                <Radio
                                    key={item}
                                    className='radioButton'
                                    _focus={{ boxShadow: "none" }}
                                    value={item}>
                                    <Text textAlign='center' width='175px' borderBottom={productType === item ? "4px solid green" : "none"} fontSize='18px !important'>{item}</Text>
                                </Radio>))}
                        </Stack>
                    </RadioGroup>
                    <Text my={5} color='#2C628D' fontSize='14px' fontFamily='Titillium Web'>Search For And Select Compatible Product Order Codes:</Text>
                    <InputGroup
                        borderRadius="5px"
                        border={!nullProductError && !typeError ? '1px solid #A9A9A9' : '1px solid #B01919'}
                        width='100%'>
                        <InputLeftElement
                            height="48px"
                            pointerEvents="none"
                            children={(<Icon height="16.5px" width="16.5px" type="search"/>)}/>
                        <Input
                            onChange={(e) => CustomChange(e.target.value)}
                            height='48px'
                            fontSize='18px'
                            _focus={{boxShadow: 'none'}}
                            border="none"
                            bg="#F5F5F5"
                            placeholder="Search for a compatible product...."/>
                    </InputGroup>
                    {nullProductError && <Text mt={2} fontSize='14px' color='#B01919' fontFamily='Titillium Web'>
                        * Please choose component type before searching...
                    </Text>}
                    {typeError && <Text mt={2} fontSize='14px' color='#B01919' fontFamily='Titillium Web'>
                        *  Component type should differ from the current product type...
                    </Text>}
                    {orderCodesCompatible.length > 0 && (
                        <Grid templateColumns={`repeat(12, 1fr)`} mt={5}>
                            {orderCodesCompatible?.map((item, index) => (
                                <GridItem key={index} colSpan={1}>
                                    <Checkbox
                                        fontFamily='Titillium Web'
                                        isChecked={compatibleProductOrderCodes.includes(item)}
                                        name={item} iconColor="black" colorScheme="whiteAlpha"
                                        onChange={(e) => {
                                            let s = []
                                            if (e.target.checked) {
                                                s.push(item);
                                                s = s.concat(compatibleProductOrderCodes)
                                            } else {
                                                let index = compatibleProductOrderCodes.indexOf(item)
                                                compatibleProductOrderCodes.splice(index, 1)
                                                s = s.concat(compatibleProductOrderCodes);
                                            }
                                            setCompatibleProductOrderCodes(s.sort((a, b) => a - b))}}>
                                        {item}
                                    </Checkbox>
                                </GridItem>))
                            }
                        </Grid>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default function downloadFile(e, key) {
    fileSaver.saveAs(S3_BUCKET + key, key);
}

const date = new Date()
export const currentDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`
