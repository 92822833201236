import {
    Button,
    VStack, Text, HStack, Heading
} from '@chakra-ui/react'
import {ChakraSelect, InviteModal, TextLabel} from "../../components/Theme";

const CompanySyncUserModal = ({isOpen, user, onClose, setCompanyToSync, companies, syncUserToCompany, loading, error, setError, onOpenRemoveCompany}) => (
    <InviteModal
        isOpen={isOpen}
        onClose={onClose}
        bg="#FFE68D"
        title="Sync User To Company">
        <VStack py={8} maxW='618px' m='auto' spacing={12}>
            <Text fontSize='24px' textAlign='center'>
                Select Company To Sync With Associate:
            </Text>
            <VStack width='418px' spacing={5}>
                <TextLabel
                    pl='30px'
                    bg='#A9A9A9'
                    color='#5A5A5ADE'
                    text={user?.firstName + ' ' + user?.lastName}
                    />
                <ChakraSelect
                    onChange={(e) => {
                        setCompanyToSync(e.target.value)
                        setError('')
                    }}
                    defaultValue={user?.company?.length > 0 ? user?.company?.id : user?.associatedToCompany?.id}
                    style={{paddingInlineStart: '30px', fontSize: '18px'}}
                    fontFamily='Titillium Web, sans-serif'
                    color='#5A5A5ADE'
                    placeholder='Select Company'>
                    {
                        companies && companies?.map(item => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))
                    }
                </ChakraSelect>
                {error && (<Text textAlign="center" color="red.100" fontSize="m" as='h2' m={0}>{error}</Text>)}
            </VStack>
            <HStack pb={8} justifyContent='center' w='100%'>
                <Button height='50px' fontSize='xl' width='254px' bg='#2C628D' onClick={syncUserToCompany} isLoading={loading}>
                    Sync
                </Button>
            </HStack>
            <Heading fontSize='18px' color='#5A5A5A'>
                New company not created?&nbsp;&nbsp;
                <Text as='span' textDecoration='underline' cursor='pointer' color='#8B24BC' onClick={onOpenRemoveCompany}>Sever existing connection here.</Text>
            </Heading>
        </VStack>
    </InviteModal>
)

export default CompanySyncUserModal