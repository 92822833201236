export const APPLICATION = [
    'Indoor',
    'Outdoor',
    'Garage',
]

export const VOLTAGE = [
    '12V',
    '24V',
    '48V',
    '120V',
    '220V',
    '277V',
    '347V',
    '480V',
]
export const DIMMING = [
    'None',
    '1% Dimmable',
    '5% Dimmable',
    '10% Dimmable',
    'Leading Edge (TRIAC)',
    'Trailing Edge (Electronic)',
    '1-10V Dimmable',
    '0-10V Dimmable',
]
export const INSTALATIONSURFACE = [
    'Wood',
    'Drywall',
    'Concrete',
    'Metal',
    'Stone',
]
export const CONTROLTYPE = [
    'Wall Switch',
    'Timer',
    'Photocell',
    'Occupancy',
]

export const INGRESSPROTECTION = [
    'IP 54',
    'IP 55',
    'IP 65',
    'IP 66',
    'IP 67',
    'IP 68',
]