import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Flex, Input, InputGroup, InputLeftElement, Text,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {ReactComponent as Search} from '../../assets/search.svg'

const HeaderForCatalogueAdmins = ({handleSearch, title}) => {
    const location = useLocation()
    return (
        <>
            <Navbar
                title={(
                    <Flex>
                        <Link
                            to="/catalogues">
                            Catalogues
                        </Link>
                        <Text
                            textTransform="capitalize">
                            {
                                title ? (
                                    <>
                                        &nbsp;/
                                        {' '}
                                        {title}
                                    </>
                                ) : (
                                    ''
                                )
                            }
                        </Text>
                    </Flex>
                )}>
                <Flex>
                    {
                        location.pathname === '/catalogues' ? (
                            ''
                        ) : (
                            <InputGroup
                                alignItems="center"
                                height="29px"
                                borderRadius="5px"
                                _focus={{
                                    borderColor: 'blue.200',
                                }}
                                _hover={{
                                    borderColor: 'blue.200',
                                }}
                                borderColor="blue.200"
                                bg="blue.200">
                                <InputLeftElement
                                    height="29px"
                                    pointerEvents="none"
                                    children={
                                        (
                                            <Search
                                                height="16px"
                                                width="16px"/>
                                        )
                                    }/>
                                <Input
                                    onChange={handleSearch}
                                    _focus={{
                                        borderColor: 'blue.200',
                                    }}
                                    _hover={{
                                        borderColor: 'blue.200',
                                    }}
                                    height="29px"
                                    color="white.100"
                                    placeholder="Search"/>
                            </InputGroup>
                        )
                    }
                </Flex>
            </Navbar>
        </>
    )
}
export default HeaderForCatalogueAdmins
