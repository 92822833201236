import {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Flex, Text, Box, Tabs, TabList, Tab, TabPanels, TabPanel, Button} from '@chakra-ui/react'
import Navbar from '../../components/Navbar/Navbar'
import {useGetProject} from '../../core/hooks'
import styles from './styles'
import EuFiles from './EuFiles'
import EuAreas from './EuAreas'
import EuProperty from './EuProperty'
import {ReactComponent as Question} from "../../assets/questionIcon.svg";

const EuIndex = () => {
    const {formState: {errors}, register, reset, setValue, watch, control, getValues,} = useForm({
        defaultValues: {
            image: '',
            areas: [],
            requestLightCount: false,
            files: [],
        },
    })
    const image = watch('image', '')
    const history = useHistory()
    const params = useParams()
    const [propsoals, setProposals] = useState('report')
    const [areas, setAreas] = useState([])

    const {isLoading: isGetProjectLoading, data} = useGetProject(params.id)

    const submitQuestion = () => {
        history.push(`/project/submit-question/${params.id}/${data.data.data[0][0]?.project?.title}/${propsoals}`)
    }

    useEffect(() => {
        if (!data || data.data?.data?.length === 0) return
        let titles = []
        let ares = []
        let proj = data?.data?.data[0][0]?.project
        reset(proj)
        for (let i = 0; i < data?.data?.data[1]?.length; i++) {
            let rep = data?.data?.data[1][i]?.report
            for (let j = 0; j < rep?.proposal?.length; j++) {
                if (!titles.includes(rep?.proposal[j]?.title)){
                    titles.push(rep?.proposal[j]?.title)
                }
                ares.push(rep?.proposal[j])
            }
        }
        const uniqueArray = ares.filter((obj, index, self) =>
                index === self.findIndex((t) => (
                    t.id === obj.id
                ))
        );
        setProposals(titles)
        setAreas(uniqueArray)
    }, [data])

    if (isGetProjectLoading) return <></>

    return (
        <form noValidate>
            <Navbar minW="1240px" title={(
                    <Flex>
                        <Link to="/projects">Projects&nbsp;</Link>
                        <Text as='h2' m={0}>/ {getValues('city') + '/' + getValues('state') + '/' + getValues('zipCode')}</Text>
                    </Flex>)}>
            </Navbar>
            <Box m="auto" minW="1240px" maxW="1240px">
                <Tabs css={styles.container} bg="#F5F5F5">
                    <Flex mr="-1px" bg="white.100" justifyContent="space-between" alignItems="flex-end">
                        <TabList mx="-1px" border="none" pt={10} bg="white.100">
                            <Tab
                                fontSize='18px'
                                minW="208px"
                                fontWeight="700"
                                _active={{
                                    background: 'transparent',
                                }}
                                _selected={{
                                    color: 'green.200',
                                    borderColor: 'green.200',
                                    boxShadow: 'none',
                                }}
                                color="green.200">
                                Property
                            </Tab>
                            <Tab
                                fontSize='18px'
                                minW="208px"
                                fontWeight="700"
                                _active={{
                                    background: 'transparent',
                                }}
                                _selected={{
                                    borderColor: 'green.200',
                                    boxShadow: 'none',
                                }}
                                color={!!errors.areas ? 'red.100' : 'green.200'}>
                                Luminaires
                            </Tab>
                            <Tab
                                fontSize='18px'
                                minW="208px"
                                fontWeight="700"
                                _active={{
                                    background: 'transparent',
                                }}
                                _selected={{
                                    color: 'green.200',
                                    borderColor: 'green.200',
                                    boxShadow: 'none',
                                }}
                                color="green.200">
                                Uploads
                            </Tab>
                        </TabList>
                        <Button bg="#4A4A4A" color="white" w="135px" mb={5} leftIcon={<Question/>} onClick={submitQuestion}>
                            <Text fontWeight="600" ml={1} mt="2px">
                                Submit Question
                            </Text>
                        </Button>
                    </Flex>
                    <TabPanels pt={6} px={10} borderTop="1px solid #DBDADA" borderBottom="none">
                        <TabPanel>
                            <EuProperty
                                setValue={setValue}
                                image={image}
                                register={register}/>
                        </TabPanel>
                        <TabPanel position="relative">
                            <EuAreas
                                register={register}
                                areas={areas}/>
                        </TabPanel>
                        <TabPanel>
                            <EuFiles
                                getValues={getValues}
                                setValue={setValue}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </form>
    )
}

export default EuIndex
