import React from "react";
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";

const BiddingOpenModalAlert = ({isOpen, onClose}) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#FF8383' fontWeight="700" fontSize="24px" textAlign="center">
                    Service Area Not Available
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pb={10} fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    Heads up! Focus Earth does not have any active user electricians servicing that area yet. Don’t wait
                    on installation bids to review proposals...
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default BiddingOpenModalAlert