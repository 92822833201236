import {
  Flex,
  Stack,
  Divider,
  Button,
  Box,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { InviteModal, InputLabel } from '../../../components/Theme'
import { useChangePassword } from '../../../core/hooks'

const ChangePassword = ({
  isOpen,
  onClose,
}) => {
  const [error, setError] = useState()
  const { mutate, isLoading } = useChangePassword()
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm()
  const password = watch('password')

  const submit = (data) => {
    mutate({
      oldPassword: data.oldPassword,
      newPassword: data.password,
    },
    {
      onError: (e) => setError(e.response.data.errorMessage),
      onSuccess: () => onClose(),
    })
  }
  return (
    <InviteModal
      title="Change Password"
      bg="#FF9494"
      isOpen={isOpen}
      onClose={onClose}>
      <Flex
        alignItems="center"
        flexDirection="column">
        <form onSubmit={handleSubmit(submit)}>
          <Stack
            spacing="10"
            pt="40px"
            mx="auto">
            <InputLabel
              type="password"
              {...register('oldPassword', { required: true })}
              isInvalid={!!errors.oldPassword}
              maxW="415px"
              label="Enter Your Current Password:"/>
            <Divider
              w="415px"
              border="3px solid"
              borderColor="#DADADA"
              orientation="horizontal"/>
            <InputLabel
              {...register('password', {
                required: true,
                minLength: 8,
              })}
              isInvalid={!!errors.password}
              type="password"
              maxW="415px"
              label="Enter Your New Password:"/>
            <InputLabel
              {...register('repeatPassword', {
                required: true,
                validate: (value) => value === password,
              })}
              isInvalid={!!errors.repeatPassword}
              type="password"
              maxW="415px"
              label="Re-Enter Your New Password:"/>
            <Box
              textAlign="center"
              as="span"
              fontSize="md"
              color="red.100">
              {!!error && error === 'Incorrect username or password.' ? 'Old password is wrong' : error}
            </Box>
          </Stack>
          <Button
            mx="auto"
            mt={12}
            isLoading={isLoading}
            mb={20}
            type="submit"
            width="415px"
            h="50px"
            color="black"
            bg="#8FCF74"
            large={+true}>
            CONFIRM NEW PASSWORD
          </Button>
        </form>
      </Flex>
    </InviteModal>
  )
}

export default ChangePassword
