const reportRepo = (reportService) => {
  const createReport = (input) => reportService.createReport(input)

  const doneReport = (input) => reportService.doneReport(input)

  const updateReport = (input) => reportService.updateReport(input)

  const listReports = (variables) => reportService.listReports(variables)

  const getReport = (id) => reportService.getReport(id)

  return {
    createReport,
    doneReport,
    updateReport,
    listReports,
    getReport,
  }
}

export default reportRepo
