import {Stack, HStack, Box} from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import {InviteModal, InputLabel, ChakraSelectLabel} from '../../../components/Theme'
import {v4 as uuid} from "uuid";

const Recent = ({type, isOpen,allAreas, onClose, area, setArea, luminaireId, recent, setRecent, getValues}) => {
    const [filters, setFilters] = useState({application: '', style: '', area: '', wattage: '',})
    const [newLuminaire, setNewLuminaire] = useState({id: '',})
    const [areaName, setAreaName] = useState([])
    const [qty, setQty] = useState('')
    const [mountHeight, setMountHeight] = useState('')
    const [allLuminaires, setAllLuminaires] = useState([])

    const createLuminaire = () => {
        if (newLuminaire.id.length === 0) {
            return onClose()
        }
        let dataLed = JSON.parse(newLuminaire.data)
        const newArray = area.luminaires?.map((luminaire) => {
            let newObj = {...luminaire}
            if (newObj.id === luminaireId) {
                for (const key in dataLed) {
                     if (filters.style.length > 0) {
                          if (filters.style === 'fixture' && key.includes('fixture')){
                              newObj[key] = dataLed[key]
                          }
                          if (filters.style === 'lamp' && key.includes('lamp')){
                              newObj[key] = dataLed[key]
                          }
                          if (filters.style === 'control' && key.includes('control')){
                              newObj[key] = dataLed[key]
                          }
                          if (filters.style === 'power' && key.includes('powerSupply')){
                              newObj[key] = dataLed[key]
                          }
                          if (filters.style === 'accessory' && key.includes('accessory')){
                              newObj[key] = dataLed[key]
                          }
                          if (filters.style === 'luminaire'){
                              newObj[key] = dataLed[key]
                          }
                    } else {
                         newObj[key] = dataLed[key];
                    }
                }
                newObj.qty = qty
                newObj.fixtureMountHeight = mountHeight
                newObj.id = uuid()
            }
            return newObj
        })
        setArea({...area, luminaires: newArray})
        setRecent([...newArray])

        onClose()
    }


    const validate = (filter) => {
        if (filters.application.length > 0 && filter.fixtureApplication !== filters.application) return
        if (filters.area.length > 0 && filter?.areaName !== filters.area) return
        if (filters.wattage.length > 0 && filter?.lampWattage?.length > 0){
            if (parseFloat(filter.lampWattage) !== parseFloat(filters.wattage)) return
        }

        if (filters.style.length > 0){
            if (filters.style === 'fixture'){
                return filter?.fixtureDescription?.length > 0
            } else if (filters.style === 'lamp'){
                return filter?.lampDescription?.length > 0
            } else if (filters.style === 'control'){
                return filter?.controlDescription?.length > 0
            } else if (filters.style === 'power'){
                return filter?.powerSupplyDescription?.length > 0
            } else if (filters.style === 'accessory'){
                return filter?.accessoryDescriptionA?.length > 0
            }
        }
        return filter?.fixtureDescription?.length > 0 || filter?.lampDescription?.length > 0 || filter?.controlDescription?.length > 0 || filter?.accessoryDescriptionA?.length > 0 || filter?.powerSupplyDescription?.length > 0

    }

    useEffect(() => {
        let areas = allAreas
        let allAreasNames = []
        const uniqueObjects = new Set();
        for (let i = 0; i < areas?.length; i++) {
            if (areas[i].title !== '') {
                allAreasNames.push(areas[i].title)
            }
            for (let j = 0; j < areas[i]?.luminaires?.length; j++){
                let recent = areas[i]?.luminaires
                if (recent.length > 0) {
                    for (let j = 0; j < recent?.length; j++) {
                        let {id,qty,fixtureMountHeight, ...res} =  recent[j]
                        let lumin = JSON.stringify(res)
                        if (!uniqueObjects.has(lumin)) {
                            uniqueObjects.add(lumin)
                        }
                    }
                }
            }
        }
        let a = Array.from(uniqueObjects).map(JSON.parse)
        setAreaName(allAreasNames)
        setAllLuminaires(a)
    }, [allAreas, recent])

    return (
        <InviteModal isOpen={type === 'recent' && isOpen} onClose={type === 'recent' && createLuminaire} bg="#9CBDD8" title="Recent Luminaires">
            <Stack spacing="5">
                <HStack spacing="5" alignItems="flex-end">
                    <InputLabel maxW="82px" type="number" pattern="[0-9]*" label="QTY" onChange={(e) => setQty(e.target.value)}/>
                    <InputLabel maxW="142px" type="number" pattern="[0-9]*" label="Mount Height" onChange={(e) => setMountHeight(e.target.value)}/>
                    <ChakraSelectLabel placeholder="Select" onChange={(e) => {
                        const data = e.target[e.target.selectedIndex].getAttribute('data-obj')
                        setNewLuminaire({id: e.target.value, data: data})
                    }} label="Luminaire Options">
                        {/* eslint-disable-next-line array-callback-return */}
                        {allLuminaires?.filter((item) => validate(item))?.map((ledRec) => {
                            let description = (ledRec?.fixtureDescription?.length > 0 ? ledRec?.fixtureDescription : '') + (ledRec?.lampDescription?.length > 0 ? ledRec?.lampDescription : '')
                                + (ledRec?.powerSupplyDescription?.length > 0 ? ledRec?.powerSupplyDescription : '') + (ledRec?.controlDescription?.length > 0 ? ledRec?.controlDescription : '') + (ledRec?.accessoryDescriptionA?.length > 0 ? ledRec?.accessoryDescriptionA : '')
                            if (filters.style === 'fixture'){
                                description = (ledRec?.fixtureDescription?.length > 0 ? ledRec?.fixtureDescription : '')
                            } else if (filters.style === 'lamp'){
                                description = (ledRec?.lampDescription?.length > 0 ? ledRec?.lampDescription : '')
                            } else if (filters.style === 'control'){
                                description = (ledRec?.controlDescription?.length > 0 ? ledRec?.controlDescription : '')
                            } else if (filters.style === 'power'){
                                description = (ledRec?.powerSupplyDescription?.length > 0 ? ledRec?.powerSupplyDescription : '')
                            } else if (filters.style === 'accessory'){
                                description = (ledRec?.accessoryDescriptionA?.length > 0 ? ledRec?.accessoryDescriptionA : '')
                            }

                            return (
                                <option key={ledRec.id} value={ledRec.id} data-obj={JSON.stringify(ledRec)}>
                                    {description}
                                </option>
                            )
                        })}
                    </ChakraSelectLabel>
                </HStack>
                <HStack w="full" justifyContent="center" position="relative" alignItems="center" py={5}>
                    <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                    <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                        filters
                    </Box>
                </HStack>
                <HStack spacing="5">
                    <ChakraSelectLabel maxW="203px" placeholder="Select" value={filters.application} onChange={(e) => setFilters({...filters, application: e.target.value})} label="Application">
                        <option value="Indoor">Indoor</option>
                        <option value="Outdoor">Outdoor</option>
                        <option value="Garage">Garage</option>
                    </ChakraSelectLabel>
                    <ChakraSelectLabel maxW="203px" placeholder='Select' label="Area" onChange={(e) => setFilters({...filters, area: e.target.value})}>
                        {areaName?.map((item) => (<option key={item}>{item}</option>))}
                    </ChakraSelectLabel>
                    <ChakraSelectLabel maxW="203px" label="Component Style" placeholder='Select' onChange={(e) => setFilters({...filters, style: e.target.value})}>
                        <option value="luminaire">Luminaire</option>
                        <option value="fixture">Fixture</option>
                        <option value="lamp">Lamp</option>
                        <option value="power">Power Supply</option>
                        <option value="control">Control</option>
                        <option value="accessory">Accessory</option>
                    </ChakraSelectLabel>
                    <InputLabel maxW="203px" label="Wattage" type="number" pattern="[0-9]*" onChange={(e) => setFilters({...filters, wattage: e.target.value})}/>
                </HStack>
            </Stack>
        </InviteModal>
    )
}

export default Recent
