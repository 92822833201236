import {Table, Thead, Tbody, Tr, Th, Td, Heading} from '@chakra-ui/react'
import styles from './styles'

const TableProjectFinancing = ({width, project, totalLoan, yearOneExisting, yearOneLed}) => (
        <Table width={width}>
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>Monthly</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 1</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 3</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize='sm' m={0}>YEAR 5</Heading></Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Loan Amount Due:</Td>
                    <Td bg="#FFE68D">$-{Math.round(totalLoan / project.loanPeriod).toLocaleString('en-US')}</Td>
                    <Td bg='#FFE68D'>${(project.loanPeriod / 12 >= 1 ? '-' : '')}{Math.round((project.loanPeriod / 12 >= 1 ? totalLoan / project.loanPeriod : 0) * 12).toLocaleString('en-US')}</Td>
                    <Td bg='#FFE68D'>${(project.loanPeriod / (12*3) >= 1 ? '-' : '')}{Math.round((project.loanPeriod / (12*3) >= 1 ? totalLoan / project.loanPeriod : 0) * 3 * 12).toLocaleString('en-US')}</Td>
                    <Td bg='#FFE68D'>${(project.loanPeriod / (12*5) >= 1 ? '-' : '')}{Math.round((project.loanPeriod / (12*5) >= 1 ? totalLoan / project.loanPeriod : 0) * 5 * 12).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">LED Upgrade Saved:</Td>
                    <Td ml={4} bg='#8FCF74' fontWeight='600'>${Math.round((yearOneExisting - yearOneLed)/12).toLocaleString('en-US')}</Td>
                    <Td bg='#8FCF74' fontWeight='600'>${Math.round((yearOneExisting - yearOneLed)).toLocaleString('en-US')}</Td>
                    <Td bg='#8FCF74' fontWeight='600'>${(Math.round((yearOneExisting*3 - yearOneLed*3))).toLocaleString('en-US')}</Td>
                    <Td bg='#8FCF74' fontWeight='600'>${(Math.round((yearOneExisting*5 - yearOneLed*5))).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Saved:</Td>
                    <Td bg="#8FCF74" fontWeight='600'>${(-Math.round(totalLoan / project.loanPeriod) + Math.round((yearOneExisting - yearOneLed)/12)).toLocaleString('en-US')}</Td>
                    <Td bg='#8FCF74' fontWeight='600'>${(-Math.round((project.loanPeriod / 12 >= 1 ? totalLoan / project.loanPeriod : 0) * 12) + Math.round(yearOneExisting - yearOneLed)).toLocaleString('en-US')}</Td>
                    <Td bg='#8FCF74' fontWeight='600'>${(-Math.round((project.loanPeriod / (12*3) >= 1 ? totalLoan / project.loanPeriod : 0) * 3 * 12) + Math.round(yearOneExisting*3 - yearOneLed*3)).toLocaleString('en-US')}</Td>
                    <Td bg='#8FCF74' fontWeight='600'>${(-Math.round((project.loanPeriod / (12*5) >= 1 ? totalLoan / project.loanPeriod : 0) * 5 * 12) + Math.round(yearOneExisting*5 - yearOneLed*5)).toLocaleString('en-US')}</Td>
                </Tr>
            </Tbody>
        </Table>
    )

export default TableProjectFinancing
