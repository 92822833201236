const uploadHistoryUseCase = (uploadHistoryRepo) => {
  const createUploadHistory = (input) => uploadHistoryRepo.createUploadHistory(input) // NO VALIDATIONS NEED
  const updateUploadHistory = (input) => {
    if (!input?.id) {
      throw Error('ID is required')
    }
    return uploadHistoryRepo.updateUploadHistory(input)
  }
  const deleteUploadHistory = (input) => {
    if (!input?.id) {
      throw Error('ID is required')
    }
    return uploadHistoryRepo.deleteUploadHistory(input)
  }

  const getUploadHistory = (id) => {
    if (!id) {
      throw Error('ID is required')
    }

    return uploadHistoryRepo.getUploadHistory(id)
  }

  const listUploadHistories = (variables) => uploadHistoryRepo.listUploadHistories(variables)

  return {
    createUploadHistory,
    updateUploadHistory,
    getUploadHistory,
    listUploadHistories,
    deleteUploadHistory,
  }
}

export default uploadHistoryUseCase
