import {Grid, GridItem} from "@chakra-ui/react";
import {ChakraSelectLabel, InputLabel} from "../../../components/Theme";
import {BUY_AMERICA, CERTIFICATIONS,} from "../../../constants/utils";
import CustomSelect from "../../../components/Theme/CustomSelect";
import React from "react";

const MainFieldsAccessory = ({errors, register,certifications, setCertifications,}) => (
    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsLength} {...register('dimensionsLength')} label="Dimensions - Length (in):" />
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.lifeTime} {...register('lifeTime', {valueAsNumber:true})} label="LifeTime (hrs):" />
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.deliveryDays} {...register('deliveryDays', {valueAsNumber:true})} label="Delivery (days):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsWidth} {...register('dimensionsWidth')} label="Dimensions - Width (in):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.warranty} {...register('warranty', {valueAsNumber:true})} label="Warranty (yrs):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='float' isInvalid={!!errors.pricePerUnit} {...register('pricePerUnit', {valueAsNumber:true})} label="Price Per Unit ($):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsDiameter} {...register('dimensionsDiameter')} label="Dimensions - Diameter (in):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <CustomSelect
                repeat={1}
                value={certifications}
                options={CERTIFICATIONS}
                setValue={setCertifications}
                label="Certifications:"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.volumeTier1MinQty} {...register('volumeTier1MinQty')} label="Vol. Tier 1 (min. qty):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.dimensionsDepth} {...register('dimensionsDepth')} label="Dimensions - Depth (in):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <ChakraSelectLabel placeholder='Select' isInvalid={!!errors.buyAmerica} {...register('buyAmerica')} label="Buy America:">
                {BUY_AMERICA.map((item,index) => (
                    <option value={item} key={index}>{item}</option>
                ))}
            </ChakraSelectLabel>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='float' isInvalid={!!errors.volumeTier1PPU} {...register('volumeTier1PPU')} label="Vol. Tier 1 PPU ($):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <ChakraSelectLabel
                label="Category:"
                height='50px'
                isInvalid={!!errors.accessoryCategory}
                {...register('accessoryCategory')}
                placeholder="Select">
                <option value="Mounting Bracket">Mounting Bracket</option>
                <option value="Protective Lens Cover">Protective Lens Cover</option>
                <option value="Light Shield">Light Shield</option>
                <option value="Other">Other</option>
            </ChakraSelectLabel>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.installationTime} {...register('installationTime', {valueAsNumber:true})} label="Installation (minutes):"/>
        </GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='number' isInvalid={!!errors.volumeTier2MinQty} {...register('volumeTier2MinQty')} label="Vol. Tier 2 (min. qty.):"/>
        </GridItem>
        <GridItem colSpan={1}></GridItem>
        <GridItem colSpan={1}></GridItem>
        <GridItem colSpan={1}>
            <InputLabel type='float' isInvalid={!!errors.volumeTier2PPU} {...register('volumeTier2PPU')} label="Vol. Tier 2 PPU ($):"/>
        </GridItem>
    </Grid>
)

export default MainFieldsAccessory