import React, {useState} from 'react'
import {Flex, Box, Select} from '@chakra-ui/react'
import EuArea from './EuArea'

const EuAreas = ({areas, register,}) => {
    const [areaName, setAreaName] = useState('')
    return (
        <>
            <Flex mt="-1rem" mb="10" alignItems="center" w="full" justifyContent="space-between">
                <Flex alignItems="center">
                    <Box as="span" fontWeight="600" fontSize="18px">
                        Area:
                    </Box>
                    <Select ml="8" value={areaName} placeholder="All Areas" height="29px" onChange={(e) => setAreaName(e.target.value)} width="274px">
                        {
                            areas?.map((item) => {
                                if (item.title) {
                                    return (
                                        <option
                                            key={item.id}
                                            value={item.title}>
                                            {item.title}
                                        </option>
                                    )
                                }
                                return null
                            })
                        }
                    </Select>
                </Flex>
            </Flex>
            {
                areas?.map((item, idx) => (
                    <EuArea
                        key={item.id}
                        idx={idx}
                        register={register}
                        data={item}/>
                ))
            }
        </>
    )
}

export default EuAreas
