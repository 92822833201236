import React, {useState} from 'react'
import {Table, Thead, Tbody, Tr, Th, Td, Link, Button, useDisclosure} from '@chakra-ui/react'
import {ReactComponent as Arrow} from "../../../assets/arrowRightUp.svg";
import {ReactComponent as Editing} from "../../../assets/editingIcon.svg";
import {ReactComponent as Accept} from "../../../assets/bidAcceptedIcon.svg";
import styleEuTab from "./styleEuTab";
import InstallationBidModal from "../Modals/InstallationBidModal";
import {useUser} from "../../../core/hooks";
import {useHistory} from "react-router-dom";
import httpRequest from "../../../core/services/http-request";

const EuOverview = ({project, reports}) => {
    const {user} = useUser()
    const history = useHistory()
    const {isOpen, onClose, onOpen} = useDisclosure()
    const {postPdf} = httpRequest()
    const [report, setReport] = useState(null)
    const [reportId, setReportId] = useState(null)
    const [loadingPdf, setLoadingPdf] = useState(null)

    function getCurrentDateFormatted() {
        const currentDate = new Date()
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
        const day = currentDate.getDate().toString().padStart(2, '0')
        const year = currentDate.getFullYear().toString().substr(-2)
        return `${month}.${day}.${year}`
    }

    const downloadScope = (reportId) => {
            let data = {
                reportId: reportId,
            }
            setLoadingPdf(true)
            postPdf('download-scope', data).then((response) => {
                const blob = new Blob([response.data], {type: 'application/pdf'});
                    const url = window.URL.createObjectURL(blob);
                    const formattedDate = getCurrentDateFormatted();

                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `Scope of Work ${(project?.propertyType ? '- '+project?.propertyType : '' )} ${+ ' - ' + project?.city + ', ' + project?.state + ', ' + project?.zipCode} - ${formattedDate}.pdf`;
                    a.click();
                    window.URL.revokeObjectURL(url);
                setLoadingPdf(false)
            }).catch(() => setLoadingPdf(false))
    }

    return (
        <>
            <InstallationBidModal
                reportId={reportId}
                item={report}
                isOpen={isOpen}
                onClose={onClose}
                project={project}/>
            <Table
                css={styleEuTab.table}>
                <Thead>
                    <Tr>
                        <Th>Proposal</Th>
                        <Th>Scope of Work</Th>
                        <Th>Installation Estimate</Th>
                        <Th>Availability</Th>
                        <Th whiteSpace='nowrap'>Bid Status</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        reports?.map((item) => (
                            <Tr
                                key={item.id}
                                css={styleEuTab.tr}
                                bg="white.100"
                                boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)">
                                <Td>
                                    {item.proposalName}
                                </Td>
                                <Td>
                                    <Link
                                        textDecoration='underline !important'
                                        onClick={() => history.push(`/projects/${project?.address}/proposal/${item.proposalName}/${user?.firstName}/${project.id}/${item.id}`)}>
                                        View
                                    </Link> |&nbsp;
                                    <Link
                                        textDecoration='underline !important'
                                        onClick={() => downloadScope(item.id)}>
                                        Download
                                    </Link>
                                </Td>
                                <Td>
                                    {item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] ? '$'+item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].total.slice(0,-3) : item.nstallationEstimate}
                                </Td>
                                <Td>
                                    {item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] ? item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].availability[0] + '-' + item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].availability[1] : item.availability}
                                </Td>
                                <Td>
                                    <Button
                                        w='94px'
                                        h='38px'
                                        onClick={e => {
                                            onOpen();
                                            setReport(item)
                                            setReportId(item)
                                        }}
                                        fontWeight='600'
                                        fontSize='12px'
                                        color='black'
                                        leftIcon={
                                            item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] && item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].bidStatus === 'Edit' ?
                                                <Editing/>
                                                : item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] && item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].bidStatus === 'Accepted' ?
                                                    <Accept/>
                                                    : <Arrow/>
                                        }
                                        bg={
                                            item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] && item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].bidStatus === 'Edit' ? '#FCA68B'
                                                : item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] && item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].bidStatus === 'Accepted' ? '#8FCF74'
                                                    : '#FFE68D'}>
                                        {
                                            item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] && item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].bidStatus === 'Edit' ? 'Edit'
                                                : item?.euBids.filter((filtered) => filtered.user.id === user.id)[0] && item?.euBids.filter((filtered) => filtered.user.id === user.id)[0].bidStatus === 'Accepted' ? 'Accepted'
                                                    : 'Bid'}
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                </Tbody>
            </Table>
        </>
    )
}

export default EuOverview
