import React, {useEffect, useState} from 'react'
import Navbar from '../../components/Navbar/Navbar'
import {useHistory} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {
    Flex,
    Text,
    Box,
    Button,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Stack,
    useDisclosure,
    Image,
    FormControl,
    FormLabel,
    useToast,
    Menu, MenuButton, MenuList, MenuItem,
} from '@chakra-ui/react'
import {
    InputLabel,
    Incrementer,
    ChakraSelectLabel as Select,
    ChartUserComplete, ChakraSelectLabel, Checkbox,
} from '../../components/Theme'
import {S3_BUCKET, SPECIALITIES} from '../../constants'
import {STATES} from "../../constants/serviceableAreas"
import {useUser, useCreateCompany, useUpdateUser, useUserProgress, useGetUser} from '../../core/hooks'
import HeaderCompany from './Header'
import ChartCompany from '../../components/Theme/ChartCompany'
import Input from "../../components/Theme/Input";
import {ReactComponent as Edit_Modal} from "../../assets/edit_modal.svg";
import {ReactComponent as DownIcon} from "../../assets/downArrow.svg";
import ServiceableAreas from "../EditCompany/Modals/ServiceableAreas";
import Interests from "../Profile/Interests";
import InputMask from "react-input-mask";
import {InitialScore} from "../../constants/chartDatas";
import {EU, REU, SU, SUPERADMIN} from "../../constants/user/userEnum";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import DoneButton from "../../components/Theme/Buttons/DoneButton";
import mainStyles from "../../styles/mainStyles";
import {
    COMPANY_OBJECT,
    EU_COMPANY_TYPE,
    REU_COMPANY_TYPE,
    SU_COMPANY_TYPE
} from "../../constants/company/companyEnum";
import styles from "../CompanyEditAdmin/styles";

const CreateCompany = () => {
    const {user, setUser} = useUser()
    const toast = useToast()
    const {getUser} = useGetUser()
    const [error, setError] = useState('')
    const [serviceArea, setServiceArea] = useState([])
    const [companyType, setCompanyType] = useState('')
    const [avatar] = useState(user?.avatar ? S3_BUCKET + user.avatar : '')
    const [complete, setComplete] = useState([])
    const {mutate: updateUser, isLoading: isUpdateLoading} = useUpdateUser()
    const {createCompany, isLoading} = useCreateCompany()
    const history = useHistory()
    const newArray = useUserProgress(user)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [score, setScore] = useState(InitialScore)
    const {
        formState: {errors},
        register,
        handleSubmit,
        setValue,
        watch,
    } = useForm({defaultValues: COMPANY_OBJECT})
    const image = watch('image', '')

    const onSubmit = (data) => {
        if (serviceArea.length < 1 && user.type === EU) {
            return setError('Chose area')
        }
        setError('')
        const input = {
            ...data,
            ownerId: user?.id,
            status: 'review',
            type: companyType,
            serviceArea: [JSON.stringify(serviceArea)],
            propertyPriorities: [JSON.stringify(score)],
        }
        createCompany(
            input,
            {
                onSuccess: ({data}) => {
                    setError('')
                    setUser({
                        ...user,
                        companyId: data.id,
                    })
                    history.push('/profile')
                    toast({
                        render: () => (
                            <ToastComponent bg='#8FCF74' icon={''} title='Your Company information was submitted!'/>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    getUser(
                        localStorage.getItem("token"),
                        {
                            onSuccess: (userResponse) => {
                                setUser(userResponse.data.data)
                            },
                        },
                    )
                },
                onError: (e) => setError(e.response.data.errorMessage),
            },
        )
    }

    useEffect(() => {
        if (!user) return
        setComplete(newArray)
    }, [user])

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            return setError(`Please fill in the required fields to submit Company Details...`)
        }
    }, [errors])

    return (

        <form
            onSubmit={handleSubmit(onSubmit)}>
            <Navbar
                minW="1240px"
                title='Create Company'>
                <DoneButton isLoading={isLoading}/>
            </Navbar>
            <Flex
                css={mainStyles.container}>
                <Flex
                    borderBottom="1px solid #DBDADA"
                    justifyContent="space-between"
                    alignItems="center"
                    px={12}
                    h="139px"
                    w="100%">
                    <Flex
                        alignItems="center">
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            overflow="hidden"
                            border="1px solid"
                            borderColor="grey.200"
                            borderRadius="100%"
                            width="85px"
                            height="85px">
                            {
                                avatar && (
                                    <Image
                                        src={avatar}
                                        alt="user avatar"
                                        height="100%"
                                        width="100%"
                                        objectFit="cover"/>
                                )
                            }
                        </Flex>
                        <Box
                            ml={8}>
                            <Heading
                                m="0"
                                mb="2"
                                fontSize="3xl"
                                as="h2">
                                {user?.firstName}
                                {' '}
                                {user?.lastName}
                            </Heading>
                        </Box>
                    </Flex>
                    <Flex
                        alignItems="center">
                        <Text
                            mr={4}
                            fontSize="xl">
                            {complete[0]}
                            % Completed
                        </Text>
                        <ChartUserComplete
                            complete={complete}/>
                    </Flex>
                </Flex>
                <Tabs defaultIndex={1}>
                    <TabList css={mainStyles.tabNames}>
                        <Tab>Profile</Tab>
                        <Tab>Company</Tab>
                    </TabList>
                    <TabPanels css={mainStyles.tabPanels}>
                        <TabPanel>
                            <Flex
                                w="100%">
                                <InputLabel
                                    disabled
                                    value={user?.firstName}
                                    mr={7}
                                    label="First Name:"/>
                                <InputLabel
                                    disabled
                                    value={user?.lastName}
                                    ml={7}
                                    label="Last Name:"/>
                            </Flex>
                            <Flex
                                w="100%">
                                <InputLabel
                                    disabled
                                    mt="30px"
                                    mr={7}
                                    value={user?.title}
                                    label="Title:"/>
                                <InputLabel
                                    disabled
                                    ml={7}
                                    value={user?.email}
                                    mt="30px"
                                    label="Email:"/>
                            </Flex>
                            <Flex
                                my="30px"
                                w="100%">
                                <FormControl
                                    mr={7}
                                    id="Phone">
                                    <FormLabel
                                        color="blue.100">
                                        Phone Number:
                                    </FormLabel>
                                    <Input
                                        disabled
                                        as={InputMask}
                                        value={user?.phone}
                                        errorBorderColor="red.100"/>
                                </FormControl>
                                <ChakraSelectLabel
                                    ml="7"
                                    disabled
                                    label='Projects'>
                                    <option
                                        value='option1'>
                                        Projects
                                    </option>
                                </ChakraSelectLabel>
                            </Flex>
                            <Interests
                                edit={false}
                                setUser={setUser}
                                user={user}/>
                        </TabPanel>
                        <TabPanel
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            alignItems="center"
                            position="relative"
                            width="100%">
                            {
                                error && (
                                    <Text
                                        mb={10}
                                        color="red.100"
                                        fontSize="m">
                                        {error}
                                    </Text>
                                )
                            }
                            <Stack
                                w="full"
                                spacing="8">
                                <HStack
                                    alignItems='start'
                                    w="full">
                                    <Flex width='55%'>
                                        <HeaderCompany
                                            setValue={setValue}
                                            company={image}/>
                                        <Input
                                            fontSize="24px !important"
                                            isInvalid={!!errors.name}
                                            height='50px'
                                            fontWeight="700"
                                            {...register('name', {required: true})}/>
                                    </Flex>
                                    <Flex width='45%' alignItems='center'>
                                        <Text
                                            pl={5}
                                            mr={5}
                                            whiteSpace='nowrap'
                                            fontSize='xl'>
                                            Company Type:
                                        </Text>
                                        <Menu closeOnSelect={false} width='100%'>
                                            <MenuButton
                                                width='100%'
                                                as={Flex}
                                                px={4}
                                                alignItems="center"
                                                border="1px solid"
                                                overflow="scroll"
                                                borderColor="rgba(169, 169, 169, 0.87)"
                                                borderRadius="5px"
                                                bg="white.100"
                                                justifyContent='space-between'
                                                height="50px">
                                                <Flex justifyContent='space-between' alignItems='center'>
                                                    <Text
                                                        whiteSpace="nowrap"
                                                        fontSize="xl">
                                                        {companyType}
                                                    </Text>
                                                    <DownIcon />
                                                </Flex>
                                            </MenuButton>
                                            <MenuList
                                                inset='-10px auto auto 0'
                                                p={0}
                                                width='350px'>
                                                    {
                                                        user?.type === REU ? (
                                                            Object.entries(REU_COMPANY_TYPE).map(([key, value]) => (
                                                                <MenuItem
                                                                    width='100%'
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    justifyContent="space-between"
                                                                    key={key}
                                                                    colSpan={1}>
                                                                    <Flex
                                                                        justifyContent='space-between'>
                                                                        <Checkbox
                                                                            mr={6}
                                                                            css={styles.checkboxComp}
                                                                            isChecked={companyType === value}
                                                                            onChange={() => setCompanyType(value)}
                                                                            name={value}
                                                                            iconColor="black"
                                                                            colorScheme="whiteAlpha">
                                                                        </Checkbox>
                                                                        <Text
                                                                            fontSize='18px'>
                                                                            {value}
                                                                        </Text>
                                                                    </Flex>
                                                                </MenuItem>
                                                            ))
                                                        ) : user?.type === SU ? (
                                                            Object.entries(SU_COMPANY_TYPE).map(([key, value]) => (
                                                                <MenuItem
                                                                    justifyContent="space-between"
                                                                    key={key}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    colSpan={1}>
                                                                    <Flex
                                                                        justifyContent='space-between'>
                                                                        <Checkbox
                                                                            mr={6}
                                                                            css={styles.checkboxComp}
                                                                            isChecked={companyType === value}
                                                                            onChange={() => setCompanyType(value)}
                                                                            name={value}
                                                                            iconColor="black"
                                                                            colorScheme="whiteAlpha">
                                                                        </Checkbox>
                                                                        <Text
                                                                            fontSize='18px'>
                                                                            {value}
                                                                        </Text>
                                                                    </Flex>
                                                                </MenuItem>
                                                            ))
                                                        ) : user?.type === EU ? (
                                                                Object.entries(EU_COMPANY_TYPE).map(([key, value]) => (
                                                                    <MenuItem
                                                                        onClick={(e) => e.stopPropagation()}
                                                                        justifyContent="space-between"
                                                                        key={key}
                                                                        colSpan={1}>
                                                                        <Flex
                                                                            justifyContent='space-between'>
                                                                            <Checkbox
                                                                                mr={6}
                                                                                css={styles.checkboxComp}
                                                                                isChecked={companyType === value}
                                                                                onChange={() => setCompanyType(value)}
                                                                                name={value}
                                                                                iconColor="black"
                                                                                colorScheme="whiteAlpha">
                                                                            </Checkbox>
                                                                            <Text
                                                                                fontSize='18px'>
                                                                                {value}
                                                                            </Text>
                                                                        </Flex>
                                                                    </MenuItem>
                                                                ))
                                                            ) :
                                                            <MenuItem
                                                                onClick={(e) => e.stopPropagation()}
                                                                justifyContent="space-between"
                                                                colSpan={1}>
                                                                <Flex
                                                                    justifyContent='space-between'>
                                                                    <Checkbox
                                                                        mr={6}
                                                                        css={styles.checkboxComp}
                                                                        isChecked={companyType === 'Admin'}
                                                                        onChange={() => setCompanyType('Admin')}
                                                                        name='Admin'
                                                                        iconColor="black"
                                                                        colorScheme="whiteAlpha">
                                                                    </Checkbox>
                                                                    <Text
                                                                        fontSize='18px'>
                                                                        Admin
                                                                    </Text>
                                                                </Flex>
                                                            </MenuItem>
                                                    }
                                            </MenuList>
                                        </Menu>
                                    </Flex>
                                </HStack>
                                <Divider
                                    my="4.25rem"
                                    borderColor="#DADADA"
                                    border="3px solid"
                                    orientation="horizontal"/>
                                <HStack
                                    w="full"
                                    spacing="6">
                                    <InputLabel
                                        isInvalid={!!errors.address}
                                        {...register('address', {required: true})}
                                        label="Address:"/>
                                    <InputLabel
                                        isInvalid={!!errors.address2}
                                        {...register('address2', {required: false})}
                                        ml={7}
                                        label="Address Line 2:"/>
                                </HStack>
                                <HStack
                                    w="full"
                                    spacing="6"
                                    alignItems="center"
                                    justifyContent="space-between">
                                    <HStack
                                        spacing="6"
                                        alignItems="flex-end"
                                        flex="1">
                                        <InputLabel
                                            isInvalid={!!errors.city}
                                            {...register('city', {required: true})}
                                            label="City:"/>
                                        <Select
                                            isInvalid={!!errors.state}
                                            {...register('state', {required: true})}
                                            label="State:"
                                            maxW="100px">
                                            {
                                                STATES.map((item) => (
                                                    <option
                                                        key={item}
                                                        value={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            }
                                        </Select>
                                        <InputLabel
                                            isInvalid={!!errors.zipCode}
                                            {...register('zipCode', {required: true})}
                                            label="Zip Code:"/>
                                    </HStack>
                                    <Box
                                        flex="1">
                                        <InputLabel
                                            disabled
                                            value="USA"
                                            label="Country:"/>
                                    </Box>
                                </HStack>
                                <Flex
                                    w="100%">
                                    <InputLabel
                                        disabled
                                        label="Associated Users:"/>
                                    <InputLabel
                                        isInvalid={!!errors.website}
                                        {...register('website', {required: true})}
                                        ml={7}
                                        label="Website:"/>
                                </Flex>
                            </Stack>
                            <InputLabel
                                mt={4}
                                isInvalid={!!errors.notes}
                                {...register('notes', {required: false})}
                                placeholder="Type some thoughts for future reference..."
                                label="Notes:"/>
                            <Divider
                                my="2.25rem"
                                borderColor="#DADADA"
                                border="3px solid"
                                orientation="horizontal"/>
                            <Heading
                                mb={5}
                                ml="20px"
                                w="100%"
                                fontSize="xl"
                                as="h2">
                                {
                                    user?.type === REU ? (
                                        'Property Types:'
                                    ) : ('Serviceable Property Types:')
                                }
                            </Heading>
                            <Flex
                                w="full"
                                pb="80px"
                                alignItems="center"
                                justifyContent="space-between">
                                <Grid
                                    templateColumns="repeat(2, 1fr)"
                                    gap={5}>
                                    {
                                        SPECIALITIES.map((interest) => (
                                            <GridItem
                                                key={interest.title}
                                                colSpan={1}>
                                                <Incrementer
                                                    score={score}
                                                    setScore={setScore}
                                                    title={interest.title}
                                                    color={interest.active}/>
                                            </GridItem>
                                        ))
                                    }
                                </Grid>
                                <Flex
                                    mr="10px">
                                    <ChartCompany score={score}/>
                                </Flex>
                            </Flex>
                            <Divider
                                mb="4.25rem"
                                borderColor="#DADADA"
                                border="3px solid"
                                display={user.type === 'Eu' ? 'block' : 'none'}
                                orientation="horizontal"/>
                            <Box
                                w='full'
                                display={user.type === 'Eu' ? 'block' : 'none'}>
                                <Flex
                                    w="100%">
                                    <Text
                                        fontWeight='600'
                                        mb={5}
                                        fontSize='18px'>
                                        Serviceable Areas:
                                    </Text>
                                </Flex>
                                <Flex
                                    w="100%">
                                    <Text
                                        fontWeight='600'
                                        fontSize='14px'
                                        color="#2C628D">
                                        Serviceable Areas - States & Counties
                                    </Text>
                                </Flex>
                                <Flex
                                    w="100%"
                                    border="1px solid #C4C4C4"
                                    borderRadius="5px"
                                    justifyContent="space-between"
                                    p="10px"
                                    mb={20}>
                                    <Text
                                        fontWeight='400'
                                        fontSize='18px'>
                                        {(() => {
                                            if (serviceArea.length > 0) {
                                                let a = serviceArea?.map((item) => item.name + '(' + item.child.filter((i) => i.value === true).map((e) => e.name) + ')-')
                                                let res = a[a.length - 1].slice(0, -1)
                                                a.pop()
                                                a.push(res)
                                                return a.toString().replace(/(.{100})..+/, "$1…")
                                            }
                                        })()}
                                    </Text>
                                    {
                                        user && <ServiceableAreas
                                            userType={user.type}
                                            serviceArea={serviceArea}
                                            setServiceArea={setServiceArea}
                                            isOpen={isOpen}
                                            onClose={onClose}/>
                                    }

                                    <Button
                                        onClick={onOpen}
                                        leftIcon={<Edit_Modal/>}
                                        w="60px"
                                        height="22px"
                                        variant="primary"
                                        backgroundColor="#FFE68D">
                                        <Text
                                            mt={1}
                                            color="black">
                                            Edit
                                        </Text>
                                    </Button>
                                </Flex>
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Flex>
        </form>
    )
}

export default CreateCompany