import {Flex, FormControl, FormLabel, Box,} from '@chakra-ui/react'
import InputMask from 'react-input-mask'
import {InputLabel, Input, TextLabel} from '../../components/Theme'
import {ChakraSelectLabel} from '../../components/Theme'
import {ReactComponent as RightIcon} from "../../assets/Icons/rightIconBig.svg";
import {AU, SA} from "../../constants/user/userEnum";

const Details = ({register,  projects,user, onOpenCompanySync}) => (
    <Box>
        <Flex w="100%" paddingTop={10}>
            <InputLabel
                mr={7}
                {...register('firstName')}
                label="First Name:"/>
            <InputLabel
                ml={7}
                {...register('lastName')}
                label="Last Name:"/>
        </Flex>
        <Flex
            w="100%"
            mt={4}>
            <InputLabel
                mr={7}
                label="Title:"
                {...register('title')}/>
            <TextLabel
                ml={7}
                bg={user?.type === AU || user?.type === SA ? '#C4C4C4' : 'white'}
                pl={4}
                fontSize='14px'
                spacing={3}
                text={user?.company?.length > 0 ? user?.company?.name : user?.associatedToCompany?.name}
                label="Company:"
                rightAddon={user?.type === AU || user?.type === SA ? '' :  <RightIcon style={{cursor: 'pointer'}} onClick={onOpenCompanySync}/>}
                />
        </Flex>
        <Flex
            mt={4}>
            <InputLabel
                mr={7}
                {...register('email')}
                label="Email:"/>
            <FormControl
                ml={7}
                id="Phone">
                <FormLabel color="blue.200"  fontWeight='600' fontFamily='Titillium Web'>Phone Number:</FormLabel>
                <Input
                    height='50px'
                    as={InputMask}
                    {...register('phone')}
                    mask="999-999-9999"
                    maskChar=""
                    autoComplete="off"
                    formatChars={{'9': '[0-9]'}}
                    errorBorderColor="red.100"/>
            </FormControl>
        </Flex>
        <Flex
            w="100%"
            mt={4}
            display={user?.type === AU || user?.type === SA ? 'none' : 'flex'}>
            <ChakraSelectLabel label="Projects:">
                {projects?.map((item) => (
                    <option key={item.id} value={item.title}>{item.title}</option>
                    )
                )}
            </ChakraSelectLabel>
        </Flex>
    </Box>
)

export default Details
