import {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {
    Box,
    Flex,
    Grid,
    GridItem,
    useDisclosure
} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {CompCard} from '../../components/Theme'
import {useListCompanys} from '../../core/hooks'
import Loading from "../../components/Loading";
import mainStyles from "../../styles/mainStyles";
import CompaniesNavbar from "./companiesNavbar";
import styles from "../Projects/styles";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import {ReactComponent as LeftIcon} from "../../assets/leftIconShippingBlack.svg";
import {ReactComponent as RightIcon} from "../../assets/rightIconShippingBlack.svg";
import {EU_COMPANY_TYPES, REU_COMPANY_TYPES, SU_COMPANY_TYPES} from "../../constants/types";

const Companies = () => {
    const [data, setData] = useState([])
    const [filter, setFilter] = useState([])
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(1)
    const theme = createTheme();

    const {data: companies, isLoading, listCompanys} = useListCompanys({
        filter: filter,
        page: page,
        search: search,
    })
    const history = useHistory()
    const {isOpen, onOpen, onClose} = useDisclosure()

    useEffect(() => {
        if (!companies) return
        setData(companies?.data.data['items'])
    }, [companies])

    useEffect(() => {
        if (search?.length > 1) {
            setTimeout(() => {
                listCompanys().then(() => {
                })
            }, 500)
        } else {
            listCompanys()
        }
    }, [search])

    useEffect(() => {
        listCompanys()
    }, [offset])

    useEffect(() => {
        setTimeout(() => {
            listCompanys()
        }, 1000)
    }, [filter])

    const handleCheckbox = (e) => {
        if (filter.includes(e)) {
            return setFilter(filter.filter((name) => name !== e))
        }
        setFilter([...filter, e])
    }

    if (isLoading) return <Loading/>

    return (
        <>
            <Navbar minW="850px" title="Companies">
               <CompaniesNavbar
                   setSearch={setSearch}
                   handleCheckbox={handleCheckbox}
                   history={history}
                   isOpen={isOpen}
                   onClose={onClose}/>
            </Navbar>
            <Flex css={mainStyles.container} minH='90vh' flexDirection='column' justifyContent='space-between' px={14} pt={10}>
                <Grid mt={12} gap={8} templateColumns="repeat(auto-fill, 330px)">
                    {data?.map((company) => (
                            <GridItem colSpan={1} key={company?.id}>
                                <Flex justifyContent="center">
                                    <CompCard
                                        seen={company?.seen}
                                        onClick={() => history.push(`/companies/${company?.id}`)}
                                        image={company?.image}
                                        color={
                                            Object.values(REU_COMPANY_TYPES).find(item => item === company?.type) ? '#C956FF'
                                                : Object.values(SU_COMPANY_TYPES).find(item => item === company?.type) ? '#FFD339'
                                                    : '#FF8660'
                                        }
                                        name={company?.name}/>
                                </Flex>
                            </GridItem>
                        ))}
                </Grid>
                <Box css={styles.paginator}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Pagination
                            limit={9}
                            otherPageColor='black'
                            offset={offset}
                            previousPageLabel={<LeftIcon fill='black'/>}
                            nextPageLabel={<RightIcon/>}
                            total={companies?.data?.data['pagination']?.TotalItemCount}
                            onClick={(e, offset: number, page: number) => {
                                setOffset(offset)
                                setPage(page)
                            }}/>
                    </MuiThemeProvider>
                </Box>
            </Flex>
        </>
    )
}

export default Companies
