import HttpRequest from "./http-request";

const StateService = () => {
    const {get} = HttpRequest()

    const listStates = () => {
        return get('state/list',)
    }

    const listCities = (variables) => {
        return get(`state/cities/list?states=${variables}`,)
    }

    return {
        listCities,
        listStates,
    }
}

export default StateService
