import {css} from '@emotion/react'

const styles = {
    tr: css`
      border: 2px solid #A9A9A9;
      cursor: default;
      background-color: white;

      & > td:first-of-type {
        font-weight: 600;
        border-radius: 10px 0 0 10px;

        &:hover {
          text-decoration: underline;
        }
      }

      & > td:last-of-type {
        border-radius: 0 10px 10px 0;
      }
    `,
    table: css`
      border-collapse: separate;
      border-spacing: 0 20px;

      th {
        text-transform: capitalize;
      }
    `,
}

export default styles
