import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Flex, Button, Input, Select, useToast, Box,} from "@chakra-ui/react";
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import httpRequest from "../../../core/services/http-request";
import styles from "./styles";
import {ReactComponent as ChangeStat} from "../../../assets/changeStatus.svg";
import ToastComponent from "../../../components/toastComponent/ToastComponent";

const ChangeStatusModal = ({isOpen, onClose, project,refetch, value}) => {
    const {post} = httpRequest()
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(project?.status)
    const [error, setError] = useState()

    const changeStatus = () => {
        if (!status) {
            return setError('Choose status')
        }
        setLoading(true)
        setError('')
        let data ={
            status: status,
            projectId: project.id,
            projStatusId: value,
        }
        post('change-project-status', data).then(function () {
            setLoading(false)
            toast({
                render: () => (
                    <ToastComponent bg='#FFE68D' icon={<ChangeStat style={{transform: [{rotate: '180deg'}]}}/>} title={`Project Status Changed To ${status}!`}/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            refetch()
            onClose()
        })
    }

    useEffect(() => {
        if (!project) return
        setStatus(project.status)
    },[project])

    return (
        <>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                    <ModalHeader bg='#FFE68D' fontWeight="700" fontStyle='normal' fontSize="24px" textAlign="center">Change Project Status</ModalHeader>
                    <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                    <ModalBody pt='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                        <Text>Select the status that best reflects the current project</Text>
                        <Flex pb='80px' alignItems='center' flexDirection='column'>
                            <Input mt={10} maxW='481px' h='50px' fontSize='18px' fontFamily='Titillium Web' opacity='1 !important' backgroundColor='#A9A9A9' color='#5A5A5A' disabled value={project?.title}/>
                            <Select mt={3} mb={6} css={styles.select} h='50px' fontSize='18px' color='#5A5A5A' onChange={(e) => setStatus(e.target.value)} value={status} _focus='' backgroundColor='#ECECEC' maxW='481px' placeholder='Open'>
                                <option value="Light Count">Light Count</option>
                                <option value="Proposal Review">Proposal Review</option>
                                <option value="Approved">Approved</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Fulfilled">Fulfilled</option>
                                <option value="Archived">Archived</option>
                                <option value="Hidden">Hidden</option>
                            </Select>
                            {error && (<Text mb='5px !important' textAlign='center' color="red.100" fontSize="sm">{error}</Text>)}
                            <Button width='254px' height='51px' isLoading={loading} fontWeight='700' onClick={changeStatus} fontSize='18px'>
                                Update
                            </Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChangeStatusModal