import React, {useState} from "react";
import {Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, Text, Flex, Button, useDisclosure, useToast,} from "@chakra-ui/react";
import IconButton from "../../Theme/IconButton";
import Icon from "../../Theme/Icon";
import httpRequest from "../../../core/services/http-request";
import BiddingDone from "./BiddingDone";
import {ReactComponent as SendMessage} from "../../../assets/bidClosedIcon.svg";
import ToastComponent from "../../toastComponent/ToastComponent";

const BiddingCloseModal = ({isOpen, onClose, id, refetch}) => {
    const {post} = httpRequest()
    const toast = useToast()
    const [loading, setLoading] = useState(false)
    const {isOpen: isOpenDone, onClose: onCloseDone} = useDisclosure()

    const closeBid = () => {
        setLoading(true)
        post('close-bidd', id).then(function (res) {
            setLoading(false)
            onClose()
            toast({
                render: () => (
                    <ToastComponent title='Installation Bidding Closed...' icon={<SendMessage/>} bg='#FCA68B'/>
                ),
                duration: 4000,
                isClosable: true,
                position: "top-right"
            })
            refetch()
        })
    }

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay/>
            <BiddingDone
                isOpen={isOpenDone}
                onClose={() => {
                    onClose()
                    onCloseDone()
                }}/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#FF8383' fontWeight="700" fontSize="24px" textAlign="center">
                    Close Bidding Process?
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pl='120px' pr='120px' fontSize='24px' textAlign='center' overflow="scroll" maxH="600px">
                    <Text>
                        Are you sure you're ready to close the bidding process?
                        This will notify all EUs in serviceable area...
                    </Text>
                    <Flex pb='80px' alignItems='center' flexDirection='column'>
                        <Button mt={10} mb={3} onClick={closeBid} isLoading={loading} width='254px' height='51px' fontWeight='700' fontSize='18px' backgroundColor='#2C628D'>
                             Yes, Close Bidding
                        </Button>
                        <Button width='254px' height='51px' onClick={onClose} color='black' fontWeight='700' fontSize='18px' border='1px solid black' backgroundColor='white'>
                            No, Go Back
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default BiddingCloseModal