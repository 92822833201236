import {Flex, Grid, GridItem} from "@chakra-ui/react";
import {SPECIALITIES} from "../../../constants";
import {Incrementer} from "../index";
import ChartCompany from "../ChartCompany";

const CompanyPropertyTypes = ({score, setScore, plus}) => (
    <Flex alignItems="center" justifyContent="space-between">
        <Grid templateColumns="repeat(2, 1fr)" gap={5}>
            {SPECIALITIES.map((interest) => (
                <GridItem
                    key={interest.title}
                    colSpan={1}>
                    <Incrementer
                        plus={plus}
                        score={score}
                        setScore={setScore}
                        title={interest.title}
                        color={interest.active}/>
                </GridItem>
            ))}
        </Grid>
        <ChartCompany
            score={score}/>
    </Flex>
)

export default CompanyPropertyTypes