import React from 'react'
import { Text, Image, Flex, Grid, GridItem, Box} from '@chakra-ui/react'
import {Link} from 'react-router-dom'
import {ReactComponent as Unfinished} from "../../../assets/unfinishedIconca.svg";
import {ReactComponent as Ready} from "../../../assets/ready.svg";
import styles from '../styles'
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import httpRequest from "../../../core/services/http-request";

const Overview = ({project, user, history, lists, setLists}) => {
    const {post} = httpRequest()

    const onSortEnd = (e) => {
        let newLists = arrayMove(lists, e.oldIndex, e.newIndex)
        for (let i = 0; i < newLists.length; i++) {
            newLists[i].sortId = newLists.indexOf(newLists[i])
        }
        setLists(newLists)
        post('sort-reports', newLists)
    }

    const SortableItem = SortableElement(({value}) =>
        <Grid templateColumns="repeat(8, 1fr)" key={value.id} css={styles.tr} bg="white.100" boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)" mb={4} minH='57px' alignItems='center' pl={4} _hover={{'background-color': value.status === 'Accepted' ? '#C6FDAF' : '#FFD6C9'}}>
            <GridItem>
                <Image src="/icons/dragBlackIcon.svg" alt="Drag" z-index='1'/>
            </GridItem>
            <GridItem display={user?.type === 'Reu' ? 'none' : 'flex'}>
                <span style={{'display': 'flex',}}>
                    {value.status === 'Unfinished' ? (<Unfinished/>) : (<Ready/>)}
                    <Text ml={3}>
                            <button style={{zIndex: 999,fontWeight: 400, fontFamily: 'Frank Ruhl Libre'}} onClick={() => history.push(`/projects/proposal/${encodeURIComponent(value.proposalName)}/${project.id}/${value.id}`)}>
                                {value.status}
                            </button>
                        </Text>
                </span>
            </GridItem>
            <GridItem>
                <button style={{zIndex: 999,fontWeight: 400, fontFamily: 'Frank Ruhl Libre'}} onClick={() => history.push(`/projects/proposal/${encodeURIComponent(value.proposalName)}/${project.id}/${value.id}`)}>
                    {value.proposalName}
                </button>
            </GridItem>
            <GridItem>
                <button style={{zIndex: 999, fontWeight: 400, fontFamily: 'Frank Ruhl Libre'}} onClick={() => history.push(`/projects/proposal/${encodeURIComponent(value.proposalName)}/${project.id}/${value.id}`)}>
                    {value.systemPrice ? `$${Number(value.systemPrice).toLocaleString('en-US')}` : '-'}
                </button>
            </GridItem>
            <GridItem>
                <button style={{zIndex: 999,fontWeight: 400, fontFamily: 'Frank Ruhl Libre'}} onClick={() => history.push(`/projects/proposal/${encodeURIComponent(value.proposalName)}/${project.id}/${value.id}`)}>
                    {value.costYear ? `$${Number(value.costYear).toLocaleString('en-US')}` : '-'}
                </button>
            </GridItem>
            <GridItem>
                <button style={{zIndex: 999,fontWeight: 400, fontFamily: 'Frank Ruhl Libre'}} onClick={() => history.push(`/projects/proposal/${encodeURIComponent(value.proposalName)}/${project.id}/${value.id}`)}>
                    {value.addedCRI ? `${value.addedCRI}` : '-'}
                </button>
            </GridItem>
            <GridItem>
                <button style={{zIndex: 999,fontWeight: 400, fontFamily: 'Frank Ruhl Libre'}} onClick={() => history.push(`/projects/proposal/${encodeURIComponent(value.proposalName)}/${project.id}/${value.id}`)}>
                    {value.addedLumens ? `${Number(value.addedLumens).toLocaleString('en-US')}` : '-'}
                </button>
            </GridItem>
            <GridItem>
                <button style={{zIndex: 999,fontWeight: 400, fontFamily: 'Frank Ruhl Libre'}} onClick={() => history.push(`/projects/proposal/${encodeURIComponent(value.proposalName)}/${project.id}/${value.id}`)}>
                    {value.ETAdays ? `${Number(value.ETAdays).toLocaleString('en-US')}` : '-'}
                </button>
            </GridItem>
        </Grid>)

    const SortableList = SortableContainer(({lists}) => {
        return (
            <Box>
                {lists?.sort(function (a, b) {return a.sortId - b.sortId}).map((value, index) => (
                        <SortableItem key={`item-${value.id}`} index={index} value={value}/>))}
            </Box>
        )
    })

    return (
        <>
            <Box minH={lists?.length > 0 ? '150px' : '230px'}>
                <Grid ml={lists?.length > 0 ? 0 : 10} pl={6} mb={5} fontSize="16px" fontFamily='Titillium Web' templateColumns="repeat(8, 1fr)">
                    <GridItem color='black' as='h2' m={0}>Rank</GridItem>
                    <GridItem color='black' as='h2' m={0} display={user?.type === 'Reu' ? 'none' : 'flex'}>Status</GridItem>
                    <GridItem color='black' as='h2' m={0}>Report</GridItem>
                    <GridItem color='black' as='h2' m={0}>System Price</GridItem>
                    <GridItem color='black' as='h2' m={0}>Cost/Year</GridItem>
                    <GridItem color='black' as='h2' m={0}>Added CRI</GridItem>
                    <GridItem color='black' as='h2' m={0}>Added Lumens</GridItem>
                    <GridItem color='black' as='h2' m={0}>ETA (days)</GridItem>
                </Grid>
                {
                    lists?.length > 0 ? (
                        <SortableList lists={lists} onSortEnd={onSortEnd} lockAxis="y"/>
                    ) : (
                        <Flex border='3px solid #8FCF74' p={14} mx={14} borderRadius='10px'>
                            <Text>Looks like you don’t have any proposals yet... Click the orange Edit Details button to add more property information or <Link to="/contact">
                                <Text as='span' textDecoration='underline'>click here</Text></Link>
                                <Text as='span'> to contact your Energy Consultant.</Text>
                            </Text>
                        </Flex>
                    )
                }
            </Box>
        </>
    )
}

export default Overview