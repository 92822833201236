import {TabPanel, Tab, Tabs, TabList, TabPanels, Flex, Box, Heading, Text, Divider, Button,} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import {SU} from "../../constants/user/userEnum";
import {useGetCustomBids} from "../../core/hooks";
import Loading from "../../components/Loading";
import {ReactComponent as ArrowRight} from "../../assets/arrowRightUp.svg";
import {ReactComponent as Clock} from "../../assets/Icons/clockIcon.svg";
import {ReactComponent as EstimateIcon} from "../../assets/bidAcceptedIcon.svg";
import {ReactComponent as ReviewIcon} from "../../assets/Icons/reviewIcon.svg";
import CustomBidModal from "./customBidModal";
import httpRequest from "../../core/services/http-request";
import {useHistory} from "react-router-dom";

const SuTabs = ({project, user, onOpen, isOpen, onClose, editMode, refetchProject}) => {
    const [leds, setLeds] = useState([])
    const {post} = httpRequest()
    const [led, setLed] = useState()
    const history = useHistory()
    const {data, isLoading, refetch} = useGetCustomBids(
        {
            condition: {
                type: 'project',
                projectId: project?.id,
                userType: SU,
            }
        }
    )

    const generateOrderPath = (refid) => {
        post('get-order-path', {
            refId: refid
        }).then(item => history.push(`/orders/estimate/su/${item.data.data}/1`))
    }

    useEffect(() => {
        if (!data) return
        setLeds(data.data.data)
    },[data])

    return (
        <Tabs minW="900px" m="auto" maxW="1240px">
            <CustomBidModal
                refetch={refetch}
                refetchProject={refetchProject}
                led={led}
                isOpen={isOpen}
                onClose={onClose}
            />
            <TabList mx="-1px" border="none" pt={5} bg="white.100">
                <Tab
                    fontSize='18px'
                    fontFamily='Titillium Web'
                    minW="201px"
                    fontWeight="700"
                    _active={{background: 'transparent',}}
                    _selected={{
                        color: 'green.200',
                        borderColor: 'green.200',
                        borderBottomWidth: '3px',
                        boxShadow: 'none',
                    }}
                    color="green.200">
                    Scope of Work
                </Tab>
            </TabList>
            <TabPanels bg="#f5f5f5" py={10} px={8} border="1px solid #DBDADA" borderBottom="none">
                <TabPanel m="auto" maxW="1160px" px={0}>
                    {!isLoading ?
                        <Box bg='#FFFFFF' p={10} maxW='913px' m='auto'>
                            <Box>
                                <Heading fontSize="3xl" as="h2" m={0}>
                                   Luminaire Upgrade
                                </Heading>
                                <Text fontSize="md" mt={3}>
                                    This page details the existing lighting components that can be recycled and the
                                    recommended
                                    upgrades that can replace them. Use the following notation to quickly review the
                                    luminaire
                                    descriptions:
                                </Text>
                                <Text mt={5} fontWeight='600'>
                                    [Fixture QTY] Fixture Description (Lamp QTY) Component Descriptions {'{Mounting Height}'}
                                </Text>
                            </Box>
                            <Divider my={4} oriantion="horisontal"/>
                            <Flex mt={10} justifyContent='space-around' mb={4}>
                                <Box w='48%' borderBottomWidth="1px" borderColor="grey.200">
                                    <Heading m={0} pb={2} color="#757575" textAlign='center' fontSize="14px">
                                        EXISTING COMPONENTS
                                    </Heading>
                                </Box>
                                <Box w='48%' borderBottomWidth="1px" borderColor="#8FCF74">
                                    <Heading m={0} color="#428027" textAlign='center' fontSize="14px">
                                        RECOMMENDED COMPONENTS
                                    </Heading>
                                </Box>
                            </Flex>
                            <Flex
                                fontSize='18px'
                                mb={3}
                                mt={0}
                                as='h2'
                                alignItems='center'
                                w='full'
                                color='#193C5B'
                                fontWeight='700'>
                                Totals
                            </Flex>
                            {leds?.map((item, index) => (
                                <Flex maxW="1240px" key={index} mb={2}>
                                    <Flex flex={1} alignItems='center' mr={6} px={3} minH='45px' border="1px solid #C4C4C4" fontSize="12px" color='#757575'>
                                      <Text>
                                          {item['fields']['Existing Description']}
                                      </Text>
                                    </Flex>

                                    <Flex flex={1} justifyContent='space-between' alignItems='center' px={3} minH='45px' border="1px solid #8FCF74" fontSize="12px" color='#428027'>
                                        <Text>
                                            {'('+(item['fields']['New QTY'] ? item['fields']['New QTY'] : item['fields']['QTY'])+') ' + item['fields']['Product Description']}
                                        </Text>
                                        <Button
                                            display={editMode ? 'flex' : 'none'}
                                            backgroundColor={!item['fields']['SU PPU ($)'] && !item['fields']['New QTY'] ? '#FFE68D' : item['fields']['Bid Submitted Date'] ? '#8FCF74' :
                                                item['fields']['New QTY'] ? '#FF9B9B': '#FCA68B'}
                                            leftIcon={!item['fields']['SU PPU ($)'] &&  !item['fields']['New QTY'] ? <ArrowRight width='8px' height='8px'/> : item['fields']['Bid Submitted Date'] ?
                                                <EstimateIcon width='9px' height='9px'/> :
                                                item['fields']['New QTY'] ? <ReviewIcon /> : <Clock />}
                                            onClick={() => {
                                                if  (item['fields']['Bid Submitted Date']) {
                                                    generateOrderPath(item['fields']['Estimate Ref'])
                                                } else {
                                                    setLed(item)
                                                    onOpen()
                                                }
                                            }}
                                            width='76px'
                                            color='black'
                                            height='24px'>
                                            {!item['fields']['SU PPU ($)'] && !item['fields']['New QTY'] ? 'Bid' : item['fields']['Bid Submitted Date'] ? 'Estimate' :
                                                item['fields']['New QTY'] ? 'Review' : 'Edit'}
                                        </Button>
                                    </Flex>
                                </Flex>
                            ))}
                        </Box>
                        : <Loading />
                    }
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}
export default SuTabs
