import React, {useState} from 'react'
import {Box, Flex, useDisclosure, Text, Button, Stack, Checkbox, useToast} from '@chakra-ui/react'
import {InviteModal, Input,} from '../../../components/Theme'
import UserModal from './UserModal'
import {ReactComponent as Pluss} from '../../../assets/Pluss.svg'
import {useCreateNotification, useListUsers, useUser,} from '../../../core/hooks'
import {ReactComponent as SendMessage} from "../../../assets/invitationSendIcon.svg";
import ToastComponent from "../../../components/toastComponent/ToastComponent";
import {USER_LIST_TYPE_COMPANY} from "../../../constants/user/userEnum";

const CompanyModal = ({company, display}) => {
    const [show, setShow] = useState(false)
    const [value, setValue] = useState()
    const {user} = useUser()
    const toast = useToast()
    const {isOpen, onClose, onOpen} = useDisclosure()
    const {createNotification, isLoading} = useCreateNotification()
    const {isOpen: isOpenInvte, onClose: onCloseInvte, onOpen: onOpenInvte} = useDisclosure()
    const {data: usersResponse} = useListUsers({type: USER_LIST_TYPE_COMPANY})
    const [users, setUsers] = useState([])
    const [usersId, setUsersId] = useState([])

    const inviteToCompany = () => {
        if (users.length < 1) return
        createNotification({
                usersId,
                companyId: company.id,
                type: 101,
            },
            {
                onSuccess: () => {
                    toast({
                        render: () => (
                            <ToastComponent title={`Invitation Sent to ${users.map((item) => item.name)}`} icon={<SendMessage/>} bg='#2C628D'/>
                        ),
                        duration: 4000,
                        isClosable: true,
                        position: "top-right"
                    })
                    setValue('')
                    setUsers([])
                    onClose()
                }
            })
    }

    const handleCheckbox = (e, item) => {
        if (e.target.checked) {
            users.push({
                name: item.firstName + ' ' + item.lastName,
            })
            usersId.push(e.target.value)
            setUsersId(usersId)
            setUsers(users)
        } else {
            const index = users.map(item => item.id).indexOf(e.target.value);
            users.splice(index, 1)
        }
        let names = '';
        for (let j = 0; j < users.length; j++) {
            names += users[j].name + (users.length - 1 === j ? '' : ',')
        }
        setValue(names)
    }

    return (
        <Box
            display={display}>
            <Button
                display={company?.status === 'review' ? 'none' : 'flex'}
                mr="25px"
                w="125px"
                color="black"
                bg="#8FCF74"
                onClick={onOpen}
                leftIcon={<Pluss/>}>
                Add User
            </Button>
            <InviteModal
                title="Add User To Company"
                isOpen={isOpen}
                onClose={onClose}>
                <Flex
                    onClick={() => setShow(false)}
                    alignItems="center"
                    flexDirection="column">
                    <Text
                        my={12}
                        fontSize="3xl">
                        Invite a colleague to join your Company:
                    </Text>
                    <Stack
                        mx="auto"
                        w="418px"
                        spacing={4}>
                        <Input
                            disabled
                            height='50px'
                            value={company?.name}
                            backgroundColor="#A9A9A9 !important"
                            autoComplete="off"/>
                        <Box
                            onClick={(e) => e.stopPropagation()}
                            position="relative">
                            <Input
                                height='50px'
                                value={value}
                                type="text"
                                autoComplete="on"
                                onFocus={() => setShow(true)}/>
                            <Stack
                                w="full"
                                left="0"
                                bg="white.100"
                                spacing="4"
                                borderRadius="6px"
                                maxH="200px"
                                zIndex="100"
                                overflow="scroll"
                                border="1px solid"
                                p="4"
                                borderColor="border.100"
                                display={show ? 'flex' : 'none'}
                                position="absolute"
                                top="100%"
                                boxShadow="0 0 4px 5px rgba(0, 0, 0, 0.15)">
                                {
                                    usersResponse?.data.data?.map((item) => (
                                        <Checkbox
                                            onChange={(e) => handleCheckbox(e, item)}
                                            key={item.email}
                                            value={item.id}>
                                            {item.firstName}&nbsp;{item.lastName}
                                        </Checkbox>
                                    ))
                                }
                            </Stack>
                        </Box>
                    </Stack>
                    <Button
                        onClick={inviteToCompany}
                        mx="auto"
                        isLoading={isLoading}
                        mt={12}
                        height='51px'
                        fontSize='18px'
                        mb="2.8rem"
                        borderRadius="5px"
                        width="254px">
                        Invite
                    </Button>
                    <Text
                        mb="4rem"
                        fontSize="18px">
                        Don’t see who you’re looking for?
                        <Button
                            ml={2}
                            fontSize="18px"
                            variant="link"
                            color="#C956FF"
                            onClick={() => {
                                onClose()
                                onOpenInvte()
                            }}>
                            Invite new user
                        </Button>
                    </Text>
                </Flex>
            </InviteModal>
            <UserModal
                onClose={onCloseInvte}
                onOpen={onOpenInvte}
                isOpen={isOpenInvte}/>
        </Box>
    )
}

export default CompanyModal
