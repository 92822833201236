import { useQuery } from 'react-query'
import { activityUseCase } from '../../factories'

const useListActivitys = (variables) => {
  const {
    refetch: listActivitys,
    isLoading,
    data,
    error,
  } = useQuery('activity', () => activityUseCase.listActivitys(variables))

  return {
    listActivitys,
    isLoading,
    error,
    data,
  }
}

export default useListActivitys
