import httpRequest from "./http-request";

const ProjectService = () => {
    const {post} = httpRequest()
    const createProject = (input) => {
        return post('create-project', {input})
    }

    const updateProject = (input) => {
        return post('update-project', {input})
    }
    const deleteProject = (input) => {

    }

    const getProject = (id) => {
        return post('get-project', {id})
    }

    const listProjects = (variables) => {

    }

    const searchProjects = (variables) => {
        return post('search-projects', {variables})
    }

    return {
        createProject,
        updateProject,
        getProject,
        listProjects,
        deleteProject,
        searchProjects,
    }
}

export default ProjectService
