import React, {useState, useRef} from 'react'
import {
    Stack,
    Heading,
    Text,
    Modal,
    useDisclosure,
    Container,
    Flex,
    Button as TextButton,
} from '@chakra-ui/react'
import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {useForgotPassword} from '../../core/hooks'
import {Input, Button} from '../../components/Theme'
import ResetPassword from './ResetPassword'

const ForgotPassword = () => {
    const [error, setError] = useState('')
    const {register, handleSubmit} = useForm()
    const {forgotPassword, isLoading} = useForgotPassword()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const newEmail = useRef('')

    const submit = (data) => {
        newEmail.current = data.email.toLowerCase()
        forgotPassword(
            {email: data.email},
            {
                onSuccess: () => {
                    onOpen()
                    setError('')
                },
                onError: (e) => {
                    setError(e.response.data.errorMessage)
                },
            },
        )
    }

    return (
        <Container position="relative" display="flex" alignItems="center" flexDirection="column" mx={4} py={12} mt={10} pt={-10} maxW="6xl" borderRadius="10px" boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)" bg="rgba(255, 255, 255, 0.8)">
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}>
                <ResetPassword
                    forgotPassword={forgotPassword}
                    onClose={onClose}
                    email={newEmail}/>
            </Modal>
            <form style={{width: '100%'}} onSubmit={handleSubmit(submit)}>
                <Flex alignItems="center" justifyContent="center">
                    <Link to="/login">
                        <TextButton variant="transparent" position="absolute" top="30px" left="30px">
                            <img width="25px" src="/images/back_register.png" alt="back arrow"/>
                            <Text ml={1} fontSize="18px" fontWeight='600'>
                                BACK
                            </Text>
                        </TextButton>
                    </Link>
                    <Stack mb={12} maxW="761px" spacing={5} alignItems="center">
                        <Heading mt={4} as="h2" fontSize="4xl">
                            Energy Portal
                        </Heading>
                        <Heading as="h3" m={0} fontSize="3xl">
                            Forgot Password?
                        </Heading>
                        <Text textAlign="center" color="grey.300">
                            We will send a recovery code to your email to reset your password.
                        </Text>
                        <Input placeholder="Email*" w="100%" color="grey.300"
                            {...register('email')}/>
                        {error && (<Text color="red.100" fontSize="sm">{error}</Text>)}
                        <Button isLoading={isLoading} colorScheme="blue.200" type="submit" height="40px !important" width="467px">
                            <Text color="white" fontWeight="700" fontSize="18px">
                                Submit
                            </Text>
                        </Button>
                    </Stack>
                </Flex>
            </form>
        </Container>
    )
}

export default ForgotPassword
