export const ACTIVITY_ENUM = {
    COMPANY_CREATED : 1,
    PROJECT_CREATED : 2,
    COMPANY_EDITED : 3,
    PROPOSAL_APPROVED : 4,
    PROJECT_EDITED : 5,
    PROPOSAL_CREATED : 6,
    PROPOSAL_EDITED : 7,
    CREATED_BID : 8,
    UPDATED_BID : 9,
    PROPOSAL_DONE :10,
    PROPOSAL_APPROVED_SU :11,
    CREATED_ORDER :12,
    CREATED_SHIPPING_SU :13,
    COMPANY_ACCEPTED :14,
    USER_CREATED :15,
    USER_INVITED_TO_PLATFORM :16,
    PROJECT_JOINED : 17,
    COMPANY_ACCEPTED_BY_ADMIN :20,
    COMPANY_DECLINED_BY_ADMIN :21,
    MESSAGE_CREATED :22,
    UPLOAD_FILE_COMPANY :23,
    PROJECT_STATUS_CHANGED : 24,
    ORDER_AUTHORIZED_REU : 25,
    SHIPPING_DELIVERED : 26,
    UPLOADED_FILE : 27,
    BID_OPENED : 28,
    BID_CLOSED : 29,
    BID_ACCEPTED : 30,
    EU_ESTIMATE_CREATED : 31,
    UPLOADED_TEMPLATE_EXEL : 32,
    CUSTOM_PRICE_INVITE : 33,
    CUSTOM_PRICE_UPDATE_SU : 34,
}
