import {css} from '@emotion/react'

const styles = {
    communiactionBox: css`
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        width: 229px;
        background-color: white;
        height: 50px;
        padding-left: 10px;
        padding-right: 10px;
        justify-content: space-between;
    `,
}

export default styles
