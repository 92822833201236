import {ReactComponent as Users} from '../assets/users.svg'
import {ReactComponent as Companies} from '../assets/companies.svg'
import {ReactComponent as Contact} from '../assets/contact.svg'
import {ReactComponent as Catalogues} from '../assets/catalogue.svg'
import {ReactComponent as Data} from '../assets/data.svg'
import {ReactComponent as Logout} from '../assets/logout.svg'
import {ReactComponent as Projects} from '../assets/projects.svg'
import {ReactComponent as Orders} from '../assets/orders.svg'
import {ReactComponent as Recent} from '../assets/notification.svg'
import {ReactComponent as UsersWhite} from '../assets/usersWhite.svg'
import {ReactComponent as CompaniesWhite} from '../assets/companiesWhite.svg'
import {ReactComponent as ContactWhite} from '../assets/contactWhite.svg'
import {ReactComponent as CataloguesWhite} from '../assets/catalogueWhite.svg'
import {ReactComponent as DataWhite} from '../assets/dataWhite.svg'
import {ReactComponent as LogoutWhite} from '../assets/logoutWhite.svg'
import {ReactComponent as ProjectsWhite} from '../assets/projectsWhite.svg'
import {ReactComponent as OrdersWhite} from '../assets/ordersWhite.svg'
import {ReactComponent as RecentWhite} from '../assets/notificationWhite.svg'
import {ReactComponent as Upload} from '../assets/cloud-upload.svg'
import {ReactComponent as UploadWhite} from '../assets/cloud-uploadWhite.svg'

export const S3_BUCKET = `${process.env.REACT_APP_API_ENDPOINT}images/`

export const data = [
    {
        existing: {
            qty: '320',
            description: "'(2) 4' Fluorescent T8 32W 4000K 80CRI",
            fixture: {
                qty: '320',
                description: 'Recessed, 2’x4’, Acryllic',
            },
            lamp: {
                qty: '640',
                description: 'Fluorescent 4’ T8 32W 4000K 80CRI',
            },
            powerSupply: {
                qty: '320',
                description: 'Lithonia Ballast L794732',
            },
            control: {
                qty: '0',
                description: 'Wall switch',
            },
        },
        recommended: {
            qty: '320',
            description: "(2) 4' LED T8 18W 4000K 85CRI DIM",
            fixture: {
                qty: '0',
                description: 'No Change - Utilize Existing',
            },
            lamp: {
                qty: '640',
                description: 'LED 4’ TypeC 16W 4000K 85CRI Dimmable',
            },
            powerSupply: {
                qty: '320',
                description: 'Meanwell LED Driver M84392',
            },
            control: {
                qty: '320',
                description: 'Occupancy Sensor',
            },
        },
    },
]

export const REU = {
    main: [
        {
            iconWhite: RecentWhite,
            icon: Recent,
            name: 'Recent Activity',
        },
        {
            iconWhite: ProjectsWhite,
            icon: Projects,
            name: 'Projects',
        },
        {
            iconWhite: OrdersWhite,
            icon: Orders,
            name: 'Orders',
        },
        {
            iconWhite: UploadWhite,
            icon: Upload,
            name: 'Upload',
        },
        {
            iconWhite: ContactWhite,
            icon: Contact,
            name: 'Contact',
        },
    ],
    bottom: [
        {
            iconWhite: LogoutWhite,
            icon: Logout,
            name: 'Log Out',
            function: 'signOut',
        },
    ],
}

export const EU = {
    main: [
        {
            iconWhite: RecentWhite,
            icon: Recent,
            name: 'Recent Activity',
        },
        {
            iconWhite: ProjectsWhite,
            icon: Projects,
            name: 'Projects',
        },
        {
            iconWhite: UploadWhite,
            icon: Upload,
            name: 'Upload',
        },
        {
            iconWhite: ContactWhite,
            icon: Contact,
            name: 'Contact',
        },
    ],
    bottom: [
        {
            iconWhite: LogoutWhite,
            icon: Logout,
            name: 'Log Out',
            function: 'signOut',
        },
    ],
}

export const SAU = {
    main: [
        {
            iconWhite: RecentWhite,
            icon: Recent,
            name: 'Recent Activity',
        },
        {
            iconWhite: ProjectsWhite,
            icon: Projects,
            name: 'Projects',
        },
        {
            iconWhite: OrdersWhite,
            icon: Orders,
            name: 'Orders',
        },
        {
            iconWhite: CataloguesWhite,
            icon: Catalogues,
            name: 'Catalogues',
        },
        {
            iconWhite: UsersWhite,
            icon: Users,
            name: 'Users',
        },
        {
            iconWhite: CompaniesWhite,
            icon: Companies,
            name: 'Companies',
        },
        {
            iconWhite: DataWhite,
            icon: Data,
            name: 'Data',
        },
    ],
    bottom: [
        {
            iconWhite: LogoutWhite,
            icon: Logout,
            name: 'Log Out',
            function: 'signOut',
        },
    ],
}

export const SUPERADMIN = {
    main: [
        {
            iconWhite: RecentWhite,
            icon: Recent,
            name: 'Recent Activity',
        },
        {
            iconWhite: ProjectsWhite,
            icon: Projects,
            name: 'Projects',
        },
        {
            iconWhite: OrdersWhite,
            icon: Orders,
            name: 'Orders',
        },
        {
            iconWhite: CataloguesWhite,
            icon: Catalogues,
            name: 'Catalogues',
        },
        {
            iconWhite: UsersWhite,
            icon: Users,
            name: 'Users',
        },
        {
            iconWhite: CompaniesWhite,
            icon: Companies,
            name: 'Companies',
        },
        {
            iconWhite: DataWhite,
            icon: Data,
            name: 'Data',
        },
    ],
    bottom: [
        {
            iconWhite: LogoutWhite,
            icon: Logout,
            name: 'Log Out',
            function: 'signOut',
        },
    ],
}

export const SU = {
    main: [
        {
            iconWhite: RecentWhite,
            icon: Recent,
            name: 'Recent Activity',
        },
        {
            iconWhite: CataloguesWhite,
            icon: Catalogues,
            name: 'Catalogues',
        },
        {
            iconWhite: ProjectsWhite,
            icon: Projects,
            name: 'Projects',
        },
        {
            iconWhite: OrdersWhite,
            icon: Orders,
            name: 'Orders',
        },
        {
            iconWhite: UploadWhite,
            icon: Upload,
            name: 'Upload',
        },
        {
            iconWhite: ContactWhite,
            icon: Contact,
            name: 'Contact',
        },
    ],
    bottom: [
        {
            iconWhite: LogoutWhite,
            icon: Logout,
            name: 'Log Out',
            function: 'signOut',
        },
    ],
}

export const SA = {
    main: [
        {
            iconWhite: RecentWhite,
            icon: Recent,
            name: 'Recent Activity',
        },
        {
            iconWhite: ProjectsWhite,
            icon: Projects,
            name: 'Projects',
        },
        {
            iconWhite: OrdersWhite,
            icon: Orders,
            name: 'Orders',
        },
    ],
    bottom: [
        {
            iconWhite: LogoutWhite,
            icon: Logout,
            name: 'Log Out',
            function: 'signOut',
        },
    ],
}

export const AU = {
    main: [
        {
            iconWhite: RecentWhite,
            icon: Recent,
            name: 'Recent Activity',
        },
        {
            iconWhite: OrdersWhite,
            icon: Orders,
            name: 'Orders',
        },
        {
            iconWhite: CataloguesWhite,
            icon: Catalogues,
            name: 'Catalogues',
        },
        {
            iconWhite: UsersWhite,
            icon: Users,
            name: 'Users',
        },
        {
            iconWhite: CompaniesWhite,
            icon: Companies,
            name: 'Companies',
        },
    ],
    bottom: [
        {
            iconWhite: LogoutWhite,
            icon: Logout,
            name: 'Log Out',
            function: 'signOut',
        },
    ],
}

export const PROPERTY_TYPES = ['Office', 'Industrial', 'Retail', 'Multi-Family', 'Hospitality', 'Parking', 'Horticulture', 'Health Care', 'Data Center', 'Other']

export const SPECIALITIES = [
    {
        logo: 'office',
        active: '#FF8EFA',
        title: 'Office',
    },
    {
        logo: 'industrial',
        active: '#FF7F56',
        title: 'Industrial',
    },
    {
        logo: 'retail',
        active: '#C1DBFF',
        title: 'Retail',
    },
    {
        logo: 'multi',
        active: '#C2EF62',
        title: 'Multi Family',
    },
    {
        logo: 'hospitality',
        active: '#5DE2CA',
        title: 'Hospitality',
    },
    {
        logo: 'parking',
        active: '#DD9323',
        title: 'Parking',
    },
    {
        logo: 'culture',
        active: '#F0D533',
        title: 'Horticulture',
    },
    {
        logo: 'healthcare',
        active: '#4A85B4',
        title: 'Healthcare',
    },
    {
        logo: 'center',
        active: '#C956FF',
        title: 'Data Center',
    },
    {
        logo: 'other',
        active: '#2E8A07',
        title: 'Other',
    },
]

export const PROJECT_PRIORITIES = [
    {
        logo: 'price',
        active: 'rgb(143,207,116)',
        passive: 'rgb(143,207,116)',
        title: 'Price',
    },
    {
        logo: 'time',
        active: 'rgb(255,127,86)',
        passive: 'rgb(255,127,86)',
        title: 'Time',
    },
    {
        logo: 'cost',
        active: 'rgb(255,230,141)',
        passive: 'rgb(255,230,141)',
        title: 'Cost',
    },
    {
        logo: 'security',
        active: 'rgb(74,133,180)',
        passive: 'rgb(74,133,180)',
        title: 'Security',
    },
    {
        logo: 'human',
        active: 'rgb(221,194,255)',
        passive: 'rgb(221,194,255)',
        title: 'Human Centric',
    },
    {
        logo: 'other',
        active: 'rgb(196,196,196)',
        passive: 'rgb(196,196,196)',
        title: 'Other',
    },
]

export const INTERESTS = [
    {
        logo: 'architecture',
        active: 'rgb(230, 176, 11)',
        passive: 'rgb(248, 232, 183)',
        title: 'Architecture',
    },
    {
        logo: 'engineering',
        active: 'rgb(12, 106, 232)',
        passive: 'rgb(198, 212, 231)',
        title: 'Engineering',
    },
    {
        logo: 'environment',
        active: 'rgb(16, 222, 72)',
        passive: 'rgb(139, 206, 157)',
        title: 'Environment',
    },
    {
        logo: 'energy',
        active: 'rgb(222, 185, 14)',
        passive: 'rgb(225, 211, 146)',
        title: 'Energy',
    },
    {
        logo: 'education',
        active: 'rgb(135, 18, 224)',
        passive: 'rgb(199, 157, 231)',
        title: 'Education',
    },
    {
        logo: 'cyrcadian',
        active: 'rgb(222, 59, 14)',
        passive: 'rgb(241, 176, 158)',
        title: 'Cyrcadian Rhythm',
    },
    {
        logo: 'illumination',
        active: 'rgb(210, 218, 33)',
        passive: 'rgb(239, 241, 192)',
        title: 'Illumination',
    },
    {
        logo: 'property',
        active: 'rgb(15, 103, 36)',
        passive: 'rgb(143, 172, 150)',
        title: 'Property',
    },
]

export const PROFILE_INTERESTS = [
    {
        active: '#FF8EFA',
        title: 'Architecture',
    },
    {
        active: '#FF7F56',
        title: 'Cyrcadian Rhythm',
    },
    {
        active: '#C1DBFF',
        title: 'Engineering',
    },
    {
        active: '#C2EF62',
        title: 'Illumination',
    },
    {
        active: '#5DE2CA',
        title: 'Environment',
    },
    {
        active: '#DD9323',
        title: 'Property Value',
    },
    {
        active: '#F0D533',
        title: 'Energy',
    },
    {
        active: '#4A85B4',
        title: 'Technology',
    },
    {
        active: '#C956FF',
        title: 'Education',
    },
    {
        active: '#2E8A07',
        title: 'Horticulture',
    }
]

export const FEATURES = {
    illumination: [
        {
            active: 'rgb(196, 175, 81)',
            passive: 'rgb(211, 197, 133)',
            title: 'Brightness',
        },
        {
            active: 'rgb(189, 169, 79)',
            passive: 'rgb(183, 174, 121)',
            title: 'Uniformity',
        },
        {
            active: 'rgb(164, 148, 74)',
            passive: 'rgb(183, 174, 129)',
            title: 'Color',
        },
        {
            active: 'rgb(138, 127, 70)',
            passive: 'rgb(171, 162, 113)',
            title: 'Color Rendering',
        },
        {
            active: 'rgb(112, 104, 64)',
            passive: 'rgb(153, 145, 107)',
            title: 'Human Centric Design',
        },
    ],
    technology: [
        {
            active: 'rgb(169, 189, 222)',
            passive: 'rgb(191, 206, 229)',
            title: 'Lumens Per Watt',
        },
        {
            active: 'rgb(150, 167, 196)',
            passive: 'rgb(179, 191, 216)',
            title: 'Optics',
        },
        {
            active: 'rgb(122, 133, 152)',
            passive: 'rgb(161, 175, 194)',
            title: 'Smart Controls',
        },
        {
            active: 'rgb(112, 122, 139)',
            passive: 'rgb(160, 174, 193)',
            title: 'System Longevity',
        },
        {
            active: 'rgb(94, 104, 119)',
            passive: 'rgb(136, 151, 171)',
            title: 'Warranty',
        },
    ],
    financial: [
        {
            active: 'rgb(118, 79, 155)',
            passive: 'rgb(156, 129, 184)',
            title: 'ROI',
        },
        {
            active: 'rgb(107, 74, 139)',
            passive: 'rgb(140, 119, 163)',
            title: 'Financing',
        },
        {
            active: 'rgb(96, 69, 123)',
            passive: 'rgb(133, 113, 151)',
            title: 'Energy Reduction',
        },
        {
            active: 'rgb(79, 59, 99)',
            passive: 'rgb(127, 110, 144)',
            title: 'Labor Reduction',
        },
        {
            active: 'rgb(81, 61, 101)',
            passive: 'rgba(108, 95, 118)',
            title: 'Rebates',
        },
    ],
    environment: [
        {
            active: 'rgb(74, 180, 100)',
            passive: 'rgb(124, 199, 143)',
            title: 'Clean Energy',
        },
        {
            active: 'rgb(74, 152, 110)',
            passive: 'rgba(117, 176, 131)',
            title: 'Eco Design',
        },
        {
            active: 'rgb(65, 140, 86)',
            passive: 'rgba(107, 161, 125)',
            title: 'Grey Energy',
        },
        {
            active: 'rgb(57, 123, 84)',
            passive: 'rgba(110, 156, 120)',
            title: 'Green Programs',
        },
        {
            active: 'rgb(49, 105, 77)',
            passive: 'rgba(96, 125, 103)',
            title: 'Sustainability',
        },
    ],
}

export const PROFILEFEATURES = {
    illumination: [
        {
            title: 'Intensity',
        },
        {
            title: 'Uniformity',
        },
        {
            title: 'Color Quality',
        },
        {
            title: 'Color Rendering',
        },
        {
            title: 'Human Centric',
        },
    ],
    technology: [
        {
            title: 'Efficiency',
        },
        {
            title: 'Sensors',
        },
        {
            title: 'Energy Monitoring',
        },
        {
            title: 'System Longevity',
        },
        {
            title: 'Internet of Things',
        },
    ],
    valuation: [
        {
            title: 'Flexible Space',
        },
        {
            title: 'Expense Reduction',
        },
        {
            title: 'Financing/LaaS',
        },
        {
            title: 'User Experience',
        },
        {
            title: 'Asset Resilience',
        },
    ],
    environment: [
        {
            title: 'Clean Energy',
        },
        {
            title: 'Sustainability',
        },
        {
            title: 'Eco Design',
        },
        {
            title: 'Green Programs',
        },
        {
            title: 'Grey Energy',
        },
    ],
}