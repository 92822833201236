import {useState} from 'react'
import {Flex, Button, Box, Select} from '@chakra-ui/react'
import {v4 as uuid} from 'uuid'
import {ReactComponent as AreaIcon} from '../../assets/area.svg'
import Area from './Area'
import {useUser} from "../../core/hooks";

const EMPTY_AREA = {
    image: '',
    title: '',
    ceilingHeight: '',
    dailyUsage: '',
    application: '',
    voltage: '',
    dimming: '',
    areaSqFt: '',
    installationSurface: '',
    controlType: '',
    ingressProtection: '',
    notes: ''
}

const Areas = ({append, getValues,allAreas, areas, watch, register, error, remove, setValue,setErrors}) => {
    const [areaName, setAreaName] = useState('')
    const [recent, setRecent] = useState([])
    const {user} = useUser()

    return (
        <>
            <Flex mt="-1rem" mb="10" alignItems="center" w="full" justifyContent="space-between">
                <Flex alignItems="center">
                    <Box as="span" fontWeight="600" fontSize="18px">Area:</Box>
                    <Select ml="8" value={areaName} placeholder="All Areas" height="29px" onChange={(e) => setAreaName(e.target.value)} width="274px">
                        {areas?.map((item) => {
                                if (item.title) {
                                    return (<option key={item.id} value={item.title}>{item.title}</option>)
                                }
                                return null
                            })}
                    </Select>
                </Flex>
                {!areaName && (
                        <Button leftIcon={<AreaIcon/>} height="29px" display={user?.type === 'Eu' ? 'none' : 'flex'} variant="green"
                            onClick={() => {
                                append({...EMPTY_AREA, id: uuid(),},
                                )}}>
                            Add New Area
                        </Button>
                    )}
            </Flex>
            {areas?.map((item, idx) => (
                    <Area
                        allAreas={areas}
                        watch={watch}
                        error={error}
                        getValues={getValues}
                        user={user}
                        key={item.id}
                        setValue={setValue}
                        idx={idx}
                        remove={remove}
                        register={register}
                        recent={recent}
                        setRecent={setRecent}
                        data={item}
                        areaName={areaName}
                        setAreas={append}/>
                ))}
        </>
    )
}

export default Areas
