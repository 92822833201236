import React, {useEffect, useState} from 'react'
import {Table, Thead, Tbody, Tr, Th, Td, Text, Flex,} from '@chakra-ui/react'
import styles from '../styles'
import {Link} from "react-router-dom";
import {ReactComponent as Unfinished} from "../../../assets/unfinishedIconca.svg";
import {ReactComponent as Ready} from "../../../assets/ready.svg";
import {Box} from "@material-ui/core";

const Price = ({project, history, reports}) => {
    const [minPrice, setMinPrice] = useState()

    useEffect(() => {
        if (!reports) return
        let prices = []
        for (let i = 0; i < reports.length; i++) {
            prices.push(Number(reports[i].systemPrice))
        }
        let min = Math.min(...prices)
        setMinPrice(min)
    }, [reports])

    return (
        <>
            <Table
                css={styles.table}
                variant="simple">
                <Thead>
                    <Tr css={styles.tr}>
                        <Th>Rank</Th>
                        <Th>Report</Th>
                        <Th>Total Price</Th>
                        <Th>Price Above Lowest</Th>
                        <Th>LED Price</Th>
                        <Th>Installation Price</Th>
                        <Th>Taxes, Shipping,Etc.</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        reports?.length > 0 ? reports?.sort((a: any, b: any) => Number(a?.systemPrice?.replace(',', '')) - Number(b?.systemPrice?.replace(',', '')))?.map((item, idx) => (
                            <Tr
                                key={item.id}
                                css={styles.tr}
                                bg="white.100"
                                boxShadow="2px 4px 10px rgba(0, 0, 0, 0.25)"
                                onClick={() => history.push(`/projects/proposal/${encodeURIComponent(item.proposalName)}/${project.id}/${item.id}`)}>
                                <Td>
                                    <Text as='span'  float='left'>
                                        {idx + 1}
                                    </Text>
                                    <Box ml={5}>
                                          {
                                              item.status === 'Unfinished' ? (
                                                  <Unfinished/>
                                              ) : (
                                                  <Ready/>
                                              )
                                          }
                                    </Box>
                                </Td>
                                <Td>
                                    {item.proposalName}
                                </Td>
                                <Td>
                                    {item.systemPrice ? `$${Number(item.systemPrice).toLocaleString('en-US')}` : '$0'}
                                </Td>
                                <Td>
                                    {minPrice ? `$${(Number(item.systemPrice) - minPrice).toLocaleString('en-US')}` : '$0'}
                                </Td>
                                <Td>
                                    {item.ledPrice ? `$${Number(item.ledPrice).toLocaleString('en-US')}` : '$0'}
                                </Td>
                                <Td>
                                    {item.installation && item.installation !== "Sourced Separately" ? `$${Number(item.installation?.total).toLocaleString('en-US')}` : '$0'}
                                </Td>
                                <Td>
                                    ${(Number(item.shipping) + Number(item.tax)).toLocaleString('en-US')}
                                </Td>
                            </Tr>
                        )) : (
                            <Tr>
                                <Td
                                    colSpan='7'>
                                    <Flex
                                        border='3px solid #8FCF74'
                                        p={14}
                                        mx={14}
                                        borderRadius='10px'>
                                        <Text>
                                            Looks like you don’t have any proposals yet... Click the orange Edit Details
                                            button to
                                            add
                                            more property information or <Link to="/contact">
                                            <Text as='span' textDecoration='underline'>click here</Text></Link>
                                            <Text as='span'> to contact your Energy Consultant.</Text>
                                        </Text>
                                    </Flex>
                                </Td>
                            </Tr>
                        )
                    }
                </Tbody>
            </Table>
        </>
    )
}

export default Price
