import React from 'react'
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Heading, Button, Flex} from '@chakra-ui/react'
import Icon from '../../components/Theme/Icon'
import IconButton from '../../components/Theme/IconButton'

const DeleteUserModal = ({isOpen, onClose, deleteUser}) => (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ModalContent overflow="hidden" borderRadius="10px" minW="520px">
            <ModalHeader bg='#FF9494' fontWeight="700" fontSize="24px" textAlign="center">
                Delete User
            </ModalHeader>
            <IconButton height="unset" onClick={onClose} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
            <ModalBody overflow="scroll" maxH="520px" textAlign='center'>
                <Heading fontSize='24px'>
                    Are you sure you want to delete this User?
                </Heading>
                <Flex justifyContent='space-between'>
                    <Button color='black' bg='#DBDADA' width='230px' onClick={onClose}>
                        Cancel
                    </Button>
                    <Button color='black' bg='#FB7575' width='230px' onClick={deleteUser}>
                        Yes, Delete User
                    </Button>
                </Flex>
            </ModalBody>
        </ModalContent>
    </Modal>
)

export default DeleteUserModal