import {css} from '@emotion/react'

const styles = {
    container: css`
      min-height: calc(100vh - 60px);
      width: 100%;
      flex-direction: column;
      border-right: 1px solid #DBDADA;
      border-left: 1px solid #DBDADA;
      position: relative;
    `,
    tr: css`
      border: none;
      cursor: default;
      border-radius: 10px;

      & > td:first-of-type {
        // font-weight: 600;
        border-radius: 10px 0 0 10px;
        // &:hover {
        //     text-decoration: underline;
        // }
      }

      & > td {
        cursor
        border: none;
        text-align: start;
      }

      & > td:last-of-type {
        border-radius: 0 10px 10px 0;
      }

      // &:hover {
      //     box-shadow: 0px 0px 3px 6px rgba(143, 207, 116, 1);
      // }
    `,
    table: css`
      border-collapse: separate;
      border-spacing: 0 20px;

      th {
        text-transform: capitalize;
      }
    `,
}

export default styles
