import {Grid, Box, Flex, Heading, Text, Button, GridItem} from '@chakra-ui/react'
import {InputLabel, Textarea} from "../../Theme";
import CustomSelectNames from "../CustomSelectNames";
import {ReactComponent as Edit_Modal} from "../../../assets/edit_modal.svg";
import {ReactComponent as RemoveIcon} from "../../../assets/deleteUserIcon.svg";
import {ReactComponent as ArrowRight} from "../../../assets/Icons/arrowRightDownSmall.svg";

const Header = ({setReport, edit,recentProposal, areaTitle,vale, setVale, onOpenBiddingClose, onOpenBiddingAccept, selectedBid, onOpenInstallation, report, loading, onOpenDelete }) => (
    <Grid templateColumns='repeat(2, 1fr)' rowGap={4} columnGap={8}>
        <Box position='relative'>
            {edit ? recentProposal?.proposalName && (
                <InputLabel
                    labelFontSize='xl'
                    labelColor='black'
                    fontSize='xl'
                    label='Report Title:'
                    defaultValue={recentProposal?.proposalName}
                    onChange={(e) => setReport({...report, proposal: e.target.value})}/>
            ):
                <InputLabel
                    labelFontSize='xl'
                    labelColor='black'
                    fontSize='xl'
                    label='Report Title:'
                    defaultValue={recentProposal?.proposalName}
                    onChange={(e) => setReport({...report, proposal: e.target.value})}/>}
            <Flex
                cursor='pointer'
                onClick={onOpenDelete}
                position='absolute'
                width='20px'
                alignItems='center'
                justifyContent='center'
                height='20px'
                right='-10px'
                backgroundColor='#E96E6E'
                top='25px'
                borderRadius='5px'>
                <RemoveIcon/>
            </Flex>
        </Box>
        <CustomSelectNames
            repeat={4}
            value={vale}
            options={areaTitle}
            setValue={setVale}
            label="Select Areas:"/>
        <GridItem>
            <Heading  mt={0} mb={2} fontSize="xl">Bidding Options:</Heading>
            <Flex alignItems='center' border='1px solid #A9A9A9' borderRadius='5px' height='50px' px='10px' justifyContent='space-between' backgroundColor='white'>
                <Text>{recentProposal?.bidding ? 'Bidding Opened' : 'Bidding Closed'}</Text>
                <Button
                    leftIcon={loading === true ? '' : <Edit_Modal/>}
                    w="60px"
                    color='black'
                    height="22px"
                    backgroundColor="#FFAF8D"
                    onClick={() => {
                        if (recentProposal?.bidding === true) {
                            return onOpenBiddingClose()
                        }
                        return onOpenBiddingAccept()
                    }}>
                    Edit
                </Button>
            </Flex>
        </GridItem>
        <GridItem rowSpan={2} position='relative'>
            <Heading mb={2} mt={0} fontSize="xl">Notes:</Heading>
            <Textarea
                _focus=''
                defaultValue={recentProposal?.notes}
                border='1px solid #A9A9A9 !important'
                height='147px'
                backgroundColor='white'
                resize='none'
                onChange={(e) => setReport({...report, notes: e.target.value})} />
            <ArrowRight style={{position: 'absolute', right: '5px', bottom: '5px', cursor: 'pointer'}}/>
        </GridItem>
        <GridItem>
            <Heading mt={0} mb={2} fontSize='xl'>Installation Estimate:</Heading>
            <Flex px='10px' borderRadius='5px' alignItems='center' border='1px solid #A9A9A9' height='50px' justifyContent='space-between' backgroundColor='white' position='relative'>
                <Text>
                    {selectedBid?.availability ?
                        `$${selectedBid?.total} | 
                                        ${selectedBid?.companyName} | 
                                    ${selectedBid?.availability[0]} - ${selectedBid?.availability[1]}`
                        : selectedBid === 'Sourced Separately' ? 'Sourced Separately' : ''}
                </Text>
                <Button
                    leftIcon={<Edit_Modal/>}
                    w="60px"
                    color='black'
                    onClick={onOpenInstallation}
                    height="22px"
                    backgroundColor="#FFAF8D">
                    Edit
                </Button>
                <Box
                    display={recentProposal?.euBids?.filter((item) => !item.seen).length > 0 ? 'flex' : 'none'}
                    position='absolute'
                    width='20px'
                    justifyContent='center'
                    alignItems='center'
                    height='20px'
                    fontSize='14px'
                    fontWeight='700'
                    borderRadius='50%'
                    right='-10px'
                    backgroundColor='#E96E6E'
                    top='-13px'>
                    {recentProposal?.euBids?.filter((item) => !item.seen).length}
                </Box>
            </Flex>
        </GridItem>
    </Grid>

)

export default Header