import React, {useState, useRef, useEffect,} from 'react'
import {Box, Text, Flex, Button as ChakraButton,  Image, Button,} from '@chakra-ui/react'
import {DragDrop} from '../../../components/Theme'
import {useUploadMultiple,} from '../../../core/hooks'
import {ReactComponent as Search} from '../../../assets/search.svg'
import httpRequest from "../../../core/services/http-request";
import {MAIN} from "../../../constants/fileTypes";
import styles from "../../Upload/styles";
import moment from "moment";
import downloadFile from "../../../constants/mainFunctions";
import {ReactComponent as Download} from "../../../assets/download.svg";


const EuUploadsBid = () => {
    const [success, setSuccess] = useState(false)
    const [uploadingError, setUploadingError] = useState(false)
    const {upload, isLoading, error,} = useUploadMultiple()
    const {post} = httpRequest()
    const inputRef = useRef()
    const [files, setFiles] = useState([])

    const getFiles = () => {
        let data = {
            type: MAIN
        }
        post('get-files', data).then((e) => {
            setFiles(e.data.data)
        })
    }

    useEffect(() => {
        getFiles()
    },[])

    const handleChange = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        Object.values(e.target.files).forEach(
            (file) => {
                console.log(file)
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    sortId: files.length,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )
        upload(
            filesToUpload,
            {
                onSuccess: (e) => {
                    post('create-uploads',[e[0].data.data, dataToPreview, 1]).then(() => {
                        getFiles()
                    }).catch((e) => {
                        console.log(e)
                    })
                    setSuccess(true)
                },
            },
        )
    }


    return (
        <>
            <Box minW="850px" m="auto" maxW="1240px" bg="#f5f5f5" px={6}>
                <Flex my={5} alignItems="center" justifyContent="space-between" w="100%">
                    <Text mb={8} fontSize="xl">
                        Drag and drop pertinent files into the designated area (below) or browse your device (blue button).
                    </Text>
                    <Flex flexDirection='column'>
                        <input onChange={handleChange} multiple ref={inputRef} style={{display: 'none'}} type="file"/>
                        <ChakraButton leftIcon={<Search/>} onClick={() => inputRef.current.click()} minW="251px" fontSize="xl" size="lg" variant="blue">
                            Browse Files
                        </ChakraButton>
                    </Flex>
                </Flex>
                <DragDrop
                    success={success}
                    error={uploadingError}
                    isLoading={isLoading}
                    handleFile={handleChange}/>
                {files.length > 0 && (
                    <Flex my="6" fontSize="20px" fontWeight="500">
                    <Text>Uploaded Files</Text>
                    <Text w='80%' textAlign='center'>Date</Text>
                </Flex>
                )}
                {files?.length > 0 && files.map(value => (
                        <>
                            <Flex mt="2" flexDirection="column">
                                <Flex cursor='pointer' css={styles.file} key={value?.files?.key}>
                                    <Flex alignItems="center" w='40%'>
                                        <Image src="/icons/drag.svg" alt="Drag"/>
                                        <Text ml="10" fontWeight="500">{value?.files?.name}</Text>
                                    </Flex>
                                    <Flex w='30%' color='#A9A9A9' fontWeight="700">{moment(value?.createdAt?.date).format('MMM DD, YYYY')}</Flex>
                                    <Flex>
                                        <Button onClick={e => downloadFile(e, value?.name)} mr={8} leftIcon={<Download/>} bg="#2C628D" color="white" height="30px" fontSize="12px">
                                            Download
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </>
                    ))}
            </Box>
        </>
    )
}

export default EuUploadsBid
