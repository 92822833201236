
const RECOMMENDED_FIXTURE_JSON = {
    recomendedFixtureQty: '',
    globalRecomendedFixtureQty: '',
    fixtureRecomendedDescription: '',
    fixtureRecomendedWattageMinimum: '',
    fixtureRecomendedWattageMaximum: '',
    fixtureRecomendedColorMinimum: '',
    fixtureRecomendedColorMaximum: '',
    fixtureRecomendedLumensMinimum: '',
    fixtureRecomendedLumensMaximum: '',
    fixtureRecomendedPriceMinimum: '',
    fixtureRecomendedPriceMaximum: '',
    fixtureRecomendedCertification: '',
    fixtureRecomendedDimmability: '',
    fixtureRecomendedCustomPriceIdes: [],
    fixtureRecomendedAirtableArray: [],
}

const RECOMMENDED_LAMP_JSON = {
    lampRecomendedAirtable: [],
    recomendedLampQty: '',
    lampRecomendedDescription: '',
    lampRecomendedPrice: '',
    lampRecomendedWattage: '',
    lampRecomendedWattageMinimum: '',
    lampRecomendedWattageMaximum: '',
    lampRecomendedLumensMinimum: '',
    lampRecomendedLumensMaximum: '',
    lampRecomendedColorMinimum: '',
    lampRecomendedColorMaximum: '',
    lampRecomendedPriceMinimum: '',
    lampRecomendedPriceMaximum: '',
    lampRecomendedCertification: '',
    lampRecomendedDimmability: '',
    lampRecomendedCustomPriceIdes: [],
    lampRecomendedAirtableArray: [],
}

const RECOMMENDED_CONTROL_JSON = {
    controlRecomendedQty: '',
    controlRecomendedDescription: '',
    controlRecomendedType: '',
    controlRecomendedManufacturer: '',
    controlRecomendedProductCode: '',
    controlRecomendedModel: '',
    controlRecomendedAirtable: [],
    controlRecomendedCustomPriceIdes: [],
    controlRecomendedAirtableArray: [],
}

const RECOMMENDED_POWER_SUPPLY_JSON = {
    recomendedPowerSupply: [],
    powerSupplyRecomendedQty: '',
    powerSupplyRecomendedDescription: '',
    powerSupplyRecomendedType: '',
    powerSupplyRecomendedModel: '',
    powerSupplyRecomendedProductCode: '',
    powerSupplyRecomendedManufacturer: '',
    powerSupplyRecomendedCustomPriceIdes: [],
    powerSupplyRecomendedAirtableArray: [],
}

const RECOMMENDED_ACCESSORY_JSON = {
    accessoryRecomendedQty: '',
    accessoryRecomendedDescription: '',
    accessoryRecomendedType: '',
    accessoryRecomendedManufacturer: '',
    accessoryRecomendedModel: '',
    accessoryRecomendedProductCode: '',
    accessoryRecomendedCustomPriceIdes: [],
    accessoryRecomendedAirtableArray: [],
    accessoryRecomendedAirtable: [],
}

export {RECOMMENDED_FIXTURE_JSON, RECOMMENDED_LAMP_JSON, RECOMMENDED_CONTROL_JSON, RECOMMENDED_POWER_SUPPLY_JSON, RECOMMENDED_ACCESSORY_JSON}