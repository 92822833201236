import React from 'react'
import {Table, Thead, Tbody, Tr, Th, Td} from '@chakra-ui/react'
import styles from './styles'

const TableProjectFinancing = ({width}) => {
    return (
        <Table width={width}>
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th textAlign="center" color="black.100" fontSize="sm">
                        Monthly
                    </Th>
                    <Th textAlign="center" color="black.100" fontSize="sm">
                        YEAR 1
                    </Th>
                    <Th textAlign="center" color="black.100" fontSize="sm">
                        YEAR 3
                    </Th>
                    <Th textAlign="center" color="black.100" fontSize="sm">
                        YEAR 5
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">
                        Loan Amount Due:
                    </Td>
                    <Td bg="#A9A9A9">
                    </Td>
                    <Td bg='#A9A9A9'>
                    </Td>
                    <Td bg='#A9A9A9'>
                    </Td>
                    <Td bg='#A9A9A9'>
                    </Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">
                        LED Upgrade Saved:
                    </Td>
                    <Td bg="#A9A9A9">
                    </Td>
                    <Td bg='#A9A9A9'>
                    </Td>
                    <Td bg='#A9A9A9'>
                    </Td>
                    <Td bg='#A9A9A9'>
                    </Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">
                        Saved:
                    </Td>
                    <Td bg="#8A8A8A">
                    </Td>
                    <Td bg='#8A8A8A'>
                    </Td>
                    <Td bg='#8A8A8A'>
                    </Td>
                    <Td bg='#8A8A8A'>
                    </Td>
                </Tr>
            </Tbody>
        </Table>
    )
}

export default TableProjectFinancing
