import {css} from '@emotion/react'

const styles = {
    profileHeader: css`
      border-bottom: 1px solid #DBDADA;
      justify-content: space-between;
      align-items: center;
      padding: 0 50px;
      height: 139px;
      width: 100%;

      .header-main {
        align-items: center;

        .header-image {
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border: 1px solid #A9A9A9;
          border-radius: 100%;
          width: 85px;
          height: 85px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        .header-name {
          margin-left: 30px;

          h2 {
            font-size: 24px;
          }
        }
      }

      .header-chart {
        align-items: center;

        p {
          margin-right: 20px;
          font-size: 18px;
        }
      }
      
    `,

    tabPanels: css`
      padding: 30px 20px;
      border-top: 1px solid #DBDADA;
      border-bottom: none;

      .tab-panel {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;

        .details-button {
          position: absolute;
          right: 0;
          top: -90px;
        }
      }
    `,

    file: css`
      display: flex;
      box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 17px 0 17px 22px;
      margin-bottom: 20px;
    `,
}

export default styles
