import React, {useEffect, useState} from 'react'
import {Box, Divider, Table, Tabs, Tbody, Text, Th, Thead, Tr, useDisclosure} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import styles from './styles'
import {useGetUser, useUpdateNotification, useUser} from '../../core/hooks'
import SingleNotification from "../Notifications/Notification";
import moment from "moment";
import MessageModal from "../Notifications/MessageModal";

const RecentActivityEu = () => {
    const {user, setUser} = useUser()
    const [notifications, setNotifications] = useState()
    const {mutate: updateNotification} = useUpdateNotification()
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [notifViewMessage, setNotifViewMessage] = useState('')
    const {getUser} = useGetUser()

    useEffect(() => {
        if (!user) return
        setNotifications(user?.notificationReceiver)
        const notSeen = user?.notificationReceiver?.filter((item) => item.seen === false)
        if (notSeen.length > 0) {
            notSeen.forEach((item) => {
                updateNotification({
                        id: item.id,
                        seen: true,
                    },
                    {
                        onSuccess: (e) => {
                            setNotifications(e.data.data[0].notificationReceiver)
                        }
                    })
            })
        }
    }, [user])

    const getUse = () => {
        getUser(
            localStorage.getItem("token"),
            {
                onSuccess: (userResponse) => {
                    setUser(userResponse.data.data)
                },
            },
        )
    }

    return (
        <>
            <Navbar minW="1050px" title="Recent Activity"/>
            <MessageModal isOpen={isOpen} onClose={onClose} message={notifViewMessage}/>
            <Tabs m="auto" minW="1050px" maxW="1240px" css={styles.container} bg="#F5F5F5">
                <Box p='50px'>
                    <Text fontWeight='600' fontSize='24px' position='relative' color='#2C628D'>
                        Notifications
                        {notifications?.filter((item) => item.seen !== true).length > 0 && (
                                <Box position='absolute' display='inline-flex' bg="#FF3636" alignItems='center' height="20px" width="20px" ml='6px' justifyContent='center' fontSize='10px' color='white' borderRadius="100%">
                                    {notifications?.filter((item) => item.seen !== true).length}
                                </Box>)}
                    </Text>
                    <Table mb={6} sx={{'&': {borderCollapse: 'separate', borderSpacing: '0 20px',},}} variant="simple">
                        <Tbody>
                            {notifications?.sort((a: any, b: any) => moment.utc(b.createdAt.date).diff(moment.utc(a.createdAt.date))).map((product) => (
                                <SingleNotification
                                    getUse={getUse}
                                    setNotifViewMessage={setNotifViewMessage}
                                    onOpen={onOpen}
                                    key={product.id}
                                    product={product}/>
                            ))}
                        </Tbody>
                    </Table>
                    <Divider borderColor="#2C628D" border="2px solid" orientation="horizontal"/>
                    <Text mt={3} fontWeight='600' fontSize='24px' color='#2C628D'>
                        Recent Activity
                    </Text>
                    <Table css={styles.table} variant="simple">
                        <Thead>
                            <Tr border="none">
                                <Th border="none" textAlign="start" fontSize="lg" fontWeight="600">Where</Th>
                                <Th border="none" textAlign="start" fontSize="lg" fontWeight="600">Who</Th>
                                <Th border="none" textAlign="start" fontSize="lg" fontWeight="600">What</Th>
                                <Th textAlign="start" border="none" fontSize="lg" fontWeight="600">When</Th>
                                <Th p={0} textAlign="start" border="none" fontSize="lg" fontWeight="600">How</Th>
                            </Tr>
                        </Thead>
                    </Table>
                </Box>
            </Tabs>
        </>
    )
}

export default RecentActivityEu
