import React from 'react'
import {Table, Thead, Tbody, Tr, Th, Td, Heading} from '@chakra-ui/react'
import styles from './styles'

const TableProjectEnergy = ({width,existingYear,ledYear, customBidding}) => {
    return (
        <Table width={width}>
            <Thead>
                <Tr>
                    <Th></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>Monthly</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 1</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 3</Heading></Th>
                    <Th textAlign="center" color="black"><Heading fontSize="sm" m={0}>YEAR 5</Heading></Th>
                </Tr>
            </Thead>
            <Tbody>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Existing kWh:</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{Math.round(existingYear/12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{existingYear.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{Math.round(existingYear*3).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{Math.round(existingYear*5).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">LED kWh:</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{Math.round(ledYear/12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{ledYear.toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{Math.round(ledYear*3).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? '#A9A9A9':'#FFE68D'}>{Math.round(ledYear*5).toLocaleString('en-US')}</Td>
                </Tr>
                <Tr css={styles.tr}>
                    <Td p={0} textAlign="end" bg="white.100">Saved kWh:</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>{Math.round(existingYear/12 - ledYear/12).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>{Math.round(existingYear - ledYear).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>{Math.round((existingYear*3) - (ledYear*3)).toLocaleString('en-US')}</Td>
                    <Td bg={customBidding ? "#8A8A8A" :"#8FCF74"} fontWeight='600'>{Math.round((existingYear*5) - (ledYear*5)).toLocaleString('en-US')}</Td>
                </Tr>
            </Tbody>
        </Table>
    )
}

export default TableProjectEnergy
