const dataRepo = (dataService) => {
    const getUsageMain = () => dataService.getUsageMain()

    const getUsage = (type) => dataService.getUsage(type)

    const getDataWithType = (type) => dataService.getDataWithType(type)

    return {
        getUsageMain,
        getUsage,
        getDataWithType,
    }
}

export default dataRepo
