import HttpRequest from "./http-request";

const productService = () => {
    const {post, get} = HttpRequest()
    const createProduct = (input) => {
        return post('product/new', input)
    }
    const updateProduct = (input) => {
        return post('product/update', input)
    }
    const getProduct = async (variables) => {
        let response = await get(`product/get?recordId=${variables.id}&type=${variables.type}`)
        if (response) return response?.data?.data
    }
    const listProducts = (variables) => {
        return post('product/list', variables)

    }
    const getRecomended = (variables) => {
        return post('get-recommended', variables)
    }

    const getCustomBids = (variables) => {
        return post('get-custom-bids', variables)
    }

    const updatePriceBid = (input) => {
        return post('update-price-bid', input)
    }

    return {
        updatePriceBid,
        getCustomBids,
        getRecomended,
        createProduct,
        updateProduct,
        getProduct,
        listProducts,
    }
}

export default productService
