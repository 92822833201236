import React, {useEffect, useState} from 'react'
import View from './View'
import {useGetExsiting} from "../../../../core/hooks";

const Lamp = ({description, onClose, setRecent, area, lamp, setLamp, setArea, createKey, setCreateKey, luminaire, luminaireId, setType,}) => {
    const [filters, setFilters] = useState({wattage: '', color: '', type: '', base: '', ledType: false})
    const {refetch} = useGetExsiting( {
        type : 'Lamps',
        category: lamp.lampCategory,
        categoryType: lamp.lampCategoryType,
        baseCategory: lamp.lampBaseCategory,
        baseCategoryType: lamp.lampBaseCategoryType,
    })

    const create = () => {
        if (!lamp.lampCategory && !lamp.lampCategoryType && !lamp.lampBaseCategory && !lamp.lampBaseCategoryType) return
        refetch().then((res) => {
            if (res?.data?.data?.data){
                setLamp({...lamp, lampExisting: res.data.data.data})
            }
            const newArray = area?.luminaires.map((luminaire) => {
                let newObj = {...luminaire}
                if (newObj.id === luminaireId) {
                    newObj = {...newObj, ...lamp, lampExisting: res.data.data.data}
                }
                return newObj
            })
            setArea({...area, luminaires: newArray})
            setRecent([...newArray])
        })
    }

    if (createKey === true) {
        create()
        setCreateKey(false)
        onClose()
    }

    useEffect(() => {
        //Set description width parameters that exists
        const description = (lamp?.ledType ? 'LED ' : '') +(lamp.lampCategory ? lamp.lampCategory + ' ' : '') + (lamp.lampCategoryType ? lamp.lampCategoryType : '')+ (lamp.lampBaseCategory ? " " + lamp.lampBaseCategory : '')  + (lamp.lampBaseCategoryType ? " " + lamp.lampBaseCategoryType : '') +
            (lamp.lampWattage ? " " + lamp.lampWattage + "W" : '') + (lamp.lampColorTemperature ? " " + lamp.lampColorTemperature + "K" : '') + (lamp.lampProductCode ? " " + lamp.lampProductCode  : '')
        setLamp({...lamp, lampDescription: description})
    }, [filters])

    return (
        <View
            setType={setType}
            lamp={lamp}
            description={description}
            area={area}
            setLamp={setLamp}
            filters={filters}
            setFilters={setFilters}
            create={create}/>
    )
}

export default Lamp
