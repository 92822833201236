import {
    Button,
    Container,
    Heading,
    VStack,
    Flex
} from "@chakra-ui/react";
import {ReactComponent as ErrorIcon} from '../../assets/Icons/ErrorRed.svg'
import {ReactComponent as MessageIcon} from '../../assets/Icons/sendMessageSmallIcon.svg'
import Header from "../../container/AuthLayout/Header";

const NotFoundPageLogout = () => (
    <Flex minW="900px" py="80px" backgroundImage="url(/images/fon.png)" backgroundPosition="0px -90px"
          backgroundSize="cover" backgroundRepeat="no-repeat" minH="100vh" position="relative" alignItems="center"
          justifyContent="center">
        <Header/>
        <Container position="relative" display="flex" alignItems="center" height='600px' flexDirection="column" mx={4}
                   mt='110px' maxW="6xl" borderRadius="10px" boxShadow="0px 4px 10px rgba(34, 34, 34, 0.25)"
                   bg="rgba(255, 255, 255, 0.8)">
            <VStack spacing='50px' maxW='780px' m='auto'>
                <Heading m={0} fontSize='36px'>Whoops! Page error...</Heading>
                <ErrorIcon/>
                <Heading m={0} fontSize='24px' lineHeight='37px'>
                    This link seems to have expired or changed since it was originally shared. Try navigating back in
                    your browser, or contact Focus Earth for more help.
                </Heading>
                <Button
                    width='467px'
                    bg='#619C48'
                    onClick={() => window.open('https://www.focusearth.com/contact')}
                    leftIcon={<MessageIcon/>}
                    fontSize='18px'>
                    Contact
                </Button>
            </VStack>
        </Container>
    </Flex>
)

export default NotFoundPageLogout