import {Box} from '@chakra-ui/react'
import {Line} from 'react-chartjs-2'

const ChartPayback = ({led, existing, projectTotal, lightCountWaiver, rebates, customBiding}) => {
    const data = {
        labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
        datasets: [
            {
                label: 'Existing System',
                type: 'line',
                data:customBiding ? []  : [
                    0,
                    Math.round(existing),
                    Math.round(existing * 2),
                    Math.round(existing * 3),
                    Math.round(existing * 4),
                    Math.round(existing * 5),
                    Math.round(existing * 6),
                    Math.round(existing * 7),
                    Math.round(existing * 8),
                    Math.round(existing * 9),
                    Math.round(existing * 10),
                ],
                fill: false,
                borderColor: '#F1381F',
                backgroundColor: '#F1381F',
                pointBorderColor: '#F1381F',
                pointBackgroundColor: '#F1381F',
                pointHoverBackgroundColor: '#F1381F',
                pointHoverBorderColor: '#F1381F',
            },
            {
                label: 'LED system',
                type: 'line',
                data: customBiding ? []  : [
                    Math.round(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led+(projectTotal- Number(rebates) - Number(lightCountWaiver))),
                    Math.round(led * 2)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 3)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 4)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 5)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 6)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 7)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 8)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 9)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                    Math.round(led * 10)+(projectTotal- Number(rebates) - Number(lightCountWaiver)),
                ],
                fill: false,
                backgroundColor: '#71B37C',
                borderColor: '#71B37C',
                hoverBackgroundColor: '#71B37C',
                hoverBorderColor: '#71B37C',
            },
        ],
    }

    const options = {
        legend: {
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    padding: 20,
                    callback(value, _index) {
                        return `$${value.toLocaleString('en-US')}`
                    },
                },
            }],
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                    return '$' + value.toLocaleString('en-US')
                }
            }
        }
    }

    return (
        <Box
            height="314px"
            width="800px">
            <Line
                options={options}
                data={data}/>
        </Box>
    )
}

export default ChartPayback
