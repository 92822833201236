import { useMutation } from 'react-query'
import { uploadUseCase } from '../factories'

const useUpload = () => {
  const {
    mutate: upload,
    isLoading,
    data,
    error,
  } = useMutation(uploadUseCase.uploadSingle)

  return {
    upload,
    isLoading,
    error,
    data,
  }
}

export default useUpload
