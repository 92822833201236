const notificationRepo = (notificationService) => {
    const createNotification = (input) => notificationService.createNotification(input)

    const updateNotification = (input) => notificationService.updateNotification(input)

    const listNotifications = (variables) => notificationService.listNotifications(variables)

    const notificationToActivity = (input) => notificationService.notificationToActivity(input)

    return {
        createNotification,
        updateNotification,
        listNotifications,
        notificationToActivity,
    }
}

export default notificationRepo
