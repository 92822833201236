import {Button, Flex, Text, Grid, GridItem, Box, Image, InputLeftElement, Input, InputGroup} from "@chakra-ui/react";
import {Link, useHistory} from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import {useEffect, useState} from "react";
import {useListCompanys} from "../../core/hooks";
import {S3_BUCKET} from "../../constants";
import {ReactComponent as Download} from '../../assets/uploadNewPhotoIcon.svg'
import {Icon} from "../../components/Theme";
import Loading from "../../components/Loading";
import styles from "../Projects/styles";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import {ReactComponent as RightIcon} from "../../assets/rightIconShippingBlack.svg";
import {ReactComponent as LeftIcon} from "../../assets/leftIconShippingBlack.svg";
import {SU} from "../../constants/user/userEnum";

const CompaniesSu = () => {
    const [companies, setCompanies] = useState([])
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [offset, setOffset] = useState(1)
    const {data, isLoading,listCompanys} = useListCompanys(
        {
            type: SU,
            pagination: true,
            page,
            search,
        })
    const history = useHistory()
    const theme = createTheme();

    useEffect(() => {
        listCompanys()
    }, [offset])

    useEffect(() => {
        if (!data) return
        setCompanies(data.data.data['items'] ?? [])
    }, [data])

    useEffect(() => {
        if (search.length < 2) return
        setTimeout(() => {
            listCompanys()
        },500)
    },[search])

    if (isLoading) return <Loading />

    return (
        <>
            <Navbar minW="1240px" title={(
                    <Flex>
                        <Link as='h2' m={0} to="/catalogues">Catalogues&nbsp;</Link>
                        <Text as='h2' m={0}>/ Companies</Text>
                    </Flex>)}>
                <Flex>
                    <InputGroup width='180px'>
                        <InputLeftElement height="30px" pointerEvents="none" children={(<Icon height="16.5px" width="16.5px" type="search"/>)}/>
                        <Input onChange={(e) => setSearch(e.target.value)} height='30px' color="white.100" borderRadius="5px" border="none" bg="blue.200" placeholder="Search"/>
                    </InputGroup>
                    <Button ml={7} bg="#8FCF74" color="black" leftIcon={<Download/>} type="submit" height="30px">
                        Add New Product
                    </Button>
                </Flex>
            </Navbar>
            <Flex p={10} width='full' justifyContent='space-between' flexDirection='column' minH='95vh'>
                <Grid w='full' gap={10} justifyItems='center' templateColumns={{base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)'}}>
                    {companies && companies?.map((item) => (
                            <GridItem key={item.compnyId} onClick={() => history.push(`/companies/${item.compnyId}`)} cursor='pointer' borderRadius='10px' maxH='180px' maxW='280px'>
                                <Image
                                    objectFit='cover'
                                    width='280px'
                                    height='117px'
                                    borderRadius='10px 10px 0 0'
                                    alt='Company image'
                                    src={item?.image ? S3_BUCKET + item.image : '/images/defaultLamp.jpg'}/>
                                <Box borderRadius='0 0 10px 10px' p={2} bg='#EDEDED'>
                                    <Text fontWeight='600'>{item.name}</Text>
                                    <Text fontSize='14px'>Products QTY: {item?.totalAll}</Text>
                                </Box>
                            </GridItem>
                        ))}
                </Grid>
                <Box css={styles.paginator}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Pagination
                            limit={9}
                            otherPageColor='black'
                            offset={offset}
                            previousPageLabel={<LeftIcon fill='black'/>}
                            nextPageLabel={<RightIcon/>}
                            total={data?.data?.data['pagination']?.TotalItemCount}
                            onClick={(e, offset: number, page: number) => {
                                setOffset(offset)
                                setPage(page)
                            }}/>
                    </MuiThemeProvider>
                </Box>
            </Flex>
        </>
    )
}

export default CompaniesSu