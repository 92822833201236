export const NOTIFICATION_TYPES = {
    COMPANY_INVATION : 1,
    PROJECT_INVATION : 2,
    PROJECT_INVATE_TO_BID : 3,
    PROJECT_INVATE_TO_BID_A : 4,
    ADMIN_INVITE : 5,
    ADMIN_USER_INVITE : 6,
    COMPANY_ACCEPTED : 7,
    COMPANY_DECLINED : 8,
    PROJECT_ACCEPTED : 9,
    BID_ACCEPTED : 10,
    ESTIMATE_INVITE_SU : 11,
    ESTIMATE_APPROVE_REU : 12,
    ORDER_AUTHORIZED : 13,
    ORDER_AUTHORIZED_REU : 14,
    CREATED_SHIPPING : 15,
    CREATED_COMPANY : 16,
    PROJECT_JOINED : 17,
    USER_SYNCED_BY_ADMIN : 18,
    COMPANY_INVATION_FOR_ADMIN : 19,
    UPDATED_COMPANY : 20,
    COMPANY_CONTACT : 21,
    REPORT_DONE : 22,
    PROPOSAL_APPROVED : 23,
    COMPANY_SYNC_USER : 24,
    CUSTOM_PRICE_INVITE : 25,
}

export const NOTIF_TO_ACTIVITY_TYPES = {
    COMPANY : 1,
    PROJECT : 2,
    REPORT : 3,
}