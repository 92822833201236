import Navbar from "../../components/Navbar";
import {Button as TextButton, Flex, Heading, VStack} from "@chakra-ui/react";
import {ReactComponent as LefIcon} from '../../assets/Icons/left.svg'
import {ReactComponent as ErrorIcon} from '../../assets/Icons/Error.svg'
import {ReactComponent as MessageIcon} from '../../assets/Icons/sendMessageSmallIcon.svg'
import {useHistory} from "react-router-dom";

const NotFoundPageLogedIn = () => {
    const history = useHistory()

    return (
        <>
            <Navbar
                minW="1240px"
                title={(
                    <Flex>
                       <Heading m={0} fontSize='18px'>404 Page Error</Heading>
                    </Flex>
                )}
            />
            <TextButton
                mt={7}
                leftIcon={<LefIcon />}
                ml={7}
                onClick={() => history.goBack()}
                mb={7}
                fontSize='24px'
                variant="transparent">
                Go Back
            </TextButton>
            <VStack spacing='70px' maxW='780px' m='auto'>
                <Heading m={0} fontSize='36px'>Whoops! Page error...</Heading>
                <ErrorIcon />
                <Heading m={0} fontSize='24px' lineHeight='37px'>
                    This link seems to have expired or changed since it was originally shared. Try navigating back in your browser, or contact Focus Earth for more help.
                </Heading>
                <TextButton
                    width='467px'
                    onClick={() => history.push('/contact')}
                    bg='#2C628D'
                    leftIcon={<MessageIcon />}
                    fontSize='18px'>
                    Contact
                </TextButton>
            </VStack>
        </>
    )
}

export default NotFoundPageLogedIn