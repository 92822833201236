import React, {useRef} from 'react'
import {Flex, Box, Image, Grid, GridItem, Button,Divider} from '@chakra-ui/react'
import {S3_BUCKET,} from '../../constants'
import {InputLabel,} from '../../components/Theme'
import {ReactComponent as UploadPhoto} from '../../assets/uploadPhoto.svg'
import {ReactComponent as AddImage} from '../../assets/addImage.svg'
import MainFieldsPower from "./AccessoryFields/MainFieldsPower";
import MainFieldsControl from "./ControlFields/MainFieldsControl";
import MainFieldsAccessory from "./PowerFields/MainFieldsAccessory";

const MainFields = ({image, errors, user, upload, setImage, register,type, certifications, setCertifications,}) => {
    const inputRef = useRef()

    const handleImage = (e) => {
        const file = e.target.files[0]
        const blob = URL.createObjectURL(file)
        const type = 'image'
        const name = `${new Date().getTime() * Math.random()}.${type}`
        setImage(blob)
        upload({name, file, type,},
            {onSuccess: (data) => setImage(data.data.data),})
    }

    return (
        <>
            <Flex>
                <Box mr={12}>
                    <Flex bg="white.100" alignItems="center" justifyContent="center" overflow="hidden" borderRadius='5px' border="1px solid #DBDADA" width="264px" height="172px">
                        {image ? (<Image  height="100%" width="100%" src={!!image ? `${S3_BUCKET}${image}` : ' '} alt="Image" objectFit="cover"/>
                        ) : (<AddImage />)}
                    </Flex>
                    <input onChange={handleImage} type="file" style={{display: 'none'}} accept="image/*" ref={inputRef}/>
                    <Button mt={8} leftIcon={<UploadPhoto/>} onClick={() => inputRef.current.click()} width="264px" height="54px" fontFamily='Titillium Web' variant="green">
                        Upload New Photo
                    </Button>
                </Box>
                <Grid width='100%' templateColumns="repeat(2, 1fr)" gap={6}>
                    <GridItem colSpan={2}>
                        <InputLabel disabled isInvalid={!!errors.description} {...register('description')} label="Product Description:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel disabled value={user?.company?.length > 0 ? user?.company[0]?.name : user?.associatedToCompany?.name} label="Supplier:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel disabled value={type?.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase())} label="Product Type:"/>
                    </GridItem>
                    <GridItem colSpan={1}>
                        <InputLabel isInvalid={!!errors.manufacturer} {...register('manufacturer')} label="Manufacturer:"/>
                    </GridItem>
                    <GridItem colSpan={1} position='relative'>
                        <InputLabel isInvalid={!!errors.orderCode} {...register('orderCode')} label="Order Code:"/>
                    </GridItem>
                </Grid>
            </Flex>
            <Divider my={10} borderColor="#A9A9A9"/>
            {type === 'control' ? (
                <MainFieldsControl
                    register={register}
                    errors={errors}
                    setCertifications={setCertifications}
                    certifications={certifications}/>
            ) : type.includes('accessory') ?
                <MainFieldsAccessory
                    register={register}
                    errors={errors}
                    setCertifications={setCertifications}
                    certifications={certifications}/>
                : <MainFieldsPower
                    register={register}
                    errors={errors}
                    setCertifications={setCertifications}
                    certifications={certifications}/>
            }
            <Divider my="10" borderColor="#A9A9A9"/>
        </>
    )
}

export default MainFields
