import {useEffect, useState} from 'react'
import View from './View'
import Loading from "../../../../Loading";
import {useGetCustomBids, useGetRecommended} from "../../../../../core/hooks";
import {LAMP_FILTER, LAMP_FILTER_MIN, PRODUCT_TYPES} from "../../../../../constants/types";
import {SU} from "../../../../../constants/user/userEnum";
import {setLedsForAll, updateProductType} from "../../../../../constants/utils";

const Lamp = ({
                  description,
                  lamp,
                  setCustomPricing,
                  customPricing,
                  setLamp,
                  fromAll,
                  project,
                  type,
                  onClose,
                  reportId,
                  setRecent,
                  createKey,
                  setCreateKey,
                  area,
                  luminaire,
                  setArea,
                  luminaireId,
                  setType
              }) => {
    const [fetchedDataLamp, setFetchedDataLamp] = useState([])
    const [lampDisabled, setLampDisabled] = useState(false)
    const [selectAll, setSelectAll] = useState(false);
    const [lampAirtable, setLampAirtable] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [buyAmerican, setBuyAmerican] = useState(false);
    const [filters, setFilters] = useState({
        wattageMin: '',
        wattageMax: '',
        colorMin: '',
        colorMax: '',
        lumensMin: '',
        lumensMax: '',
        priceMin: '',
        priceMax: '',
        certification: '',
        dimmability: '',
    })
    const [customBids, setCustomBids] = useState([]);
    const [certifications, setCertifications] = useState([])

    const {data, isLoading, isFetching,refetch} = useGetRecommended(
        {
            condition: {
                table: PRODUCT_TYPES["2"],
                filter: LAMP_FILTER(luminaire),
                filterMin: LAMP_FILTER_MIN(luminaire),
                projectId: project?.id
            }
        }
    )
    const {data: dataCustomBids, isLoading: isLoadingBids,isFetching:isFetchingBids, refetch: refetchBids} = useGetCustomBids(
        {
            condition: {
                type: 'report',
                projectId: project?.id,
                productType: 'lamp',
                reportId: reportId,
                userType: SU,
            }
        }
    )

    useEffect(() => {
        if (type === 'lamp') {
            refetch({
                condition: {
                    table: PRODUCT_TYPES["2"],
                    filter: LAMP_FILTER(luminaire),
                    filterMin: LAMP_FILTER_MIN(luminaire),
                    projectId: project?.id
                }
            })
        }
    }, [type])

    const validate = ({fields}) => {
        let bool = true

        if (buyAmerican && bool){
            !!fields['Buy America'] ? bool = fields['Buy America'] === 'Yes' : bool = false
        }
        if (!!filters.wattageMin && !!filters.wattageMax && bool && fields['Wattage']) {
            !!fields['Wattage'] ? bool = parseInt(filters.wattageMin) <= parseInt(fields['Wattage']) && parseInt(filters.wattageMax) >= parseInt(fields['Wattage']) : bool = false
        }
        if (certifications.length > 0 && bool){
            !!fields['Certifications'] ? bool = certifications.includes(fields['Certifications']) : bool = false
        }
        if (!!filters.colorMin && !!filters.colorMax && bool && fields['Color']) {
            !!fields['Color'] ? bool = parseInt(filters.colorMin) <= parseInt(fields['Color']) && parseInt(filters.colorMax) >= parseInt(fields['Color']?.replace(/\D/g, '')) : bool = false
        }
        if (!!filters.priceMin && !!filters.priceMax && bool && fields['Price Per Unit ($)']) {
            !!fields['Price Per Unit ($)'] ? bool = parseInt(filters.priceMin) < parseInt(fields['Price Per Unit ($)']?.replace(/[^0-9.]/g, '')) && parseInt(filters.priceMax) > parseInt(fields['Price Per Unit ($)']?.replace(/[^0-9.]/g, '')) : bool = false
        }
        if (!!filters.lumensMin && !!filters.lumensMax && bool && fields['Lumens']) {
            !!fields['Lumens'] ? bool = parseInt(filters.lumensMin) < parseInt(fields['Lumens']) && parseInt(filters.lumensMax) > parseInt(fields['Lumens']) : bool = false
        }
        if (!!filters.dimmability && bool) {
            !!fields['Dimmable'] ? bool = fields['Dimmable'].includes(filters.dimmability) : bool = false
        }
        if (customPricing.lamp && bool) {
            (!fields.hasOwnProperty('Price Per Unit ($)') || !fields['Price Per Unit ($)'] || Number(fields['Price Per Unit ($)']?.replace(/[^\d.]/g, '')) == 0) ? bool = true : bool = false
        }
        if (!customPricing.lamp && bool){
            if (!fields.hasOwnProperty('Price Per Unit ($)') && !fields.hasOwnProperty('SU PPU ($) Custom')){
                bool = false
            } else if (fields['Price Per Unit ($)'] && !fields['SU PPU ($) Custom'] && Number(fields['Price Per Unit ($)']?.toString()?.replace(/[^\d.]/g, '')) === 0){
                bool = false
            } else {
                bool = true
            }
        }
        return bool
    }

    const create = () => {
        let obj = setLedsForAll(area, luminaire);
        const updatedArea = updateProductType(area, obj, 'lamp', lamp, fromAll, luminaireId);

        setArea(updatedArea);
    }


    useEffect(() => {
        if (createKey === true) {
            create()
            setCreateKey(false)
            onClose()
        }
    },[createKey])

    useEffect(() => {
        if (!data) return
        setFetchedDataLamp(data?.data?.data)
        setLamp({
            ...lamp,
            recomendedLampQty: luminaire.recomendedLampQty ? luminaire.recomendedLampQty  : (luminaire.lampPerFixture ? luminaire.lampPerFixture : '')
        })
        if (luminaire?.fixtureRecomendedAirtableArray?.length > 0) {
            let recom = luminaire?.fixtureRecomendedAirtableArray[0]['fields']
            setLampDisabled(!!recom['Wattage'])
        } else {
            setLampDisabled(false)
        }
        setSelectedItems(lamp?.lampRecomendedCustomPriceIdes?.length > 0 ? lamp.lampRecomendedCustomPriceIdes : luminaire?.lampRecomendedCustomPriceIdes?.length > 0 ? luminaire.lampRecomendedCustomPriceIdes : [])
        setLampAirtable(lamp?.lampRecomendedAirtableArray?.length > 0 ? lamp.lampRecomendedAirtableArray : luminaire?.lampRecomendedAirtableArray?.length > 0 ? luminaire.lampRecomendedAirtableArray : [])
        if (dataCustomBids){
            setCustomBids(dataCustomBids.data.data)
        }
        data.data.data.forEach(item1 => {
            item1.fields['Bid Status'] = 'review'
            if (dataCustomBids){
                dataCustomBids.data.data.forEach(item2 => {
                    if (item1.id === item2.fields['Product ID']) {
                        if (item2.fields['SU PPU ($)']){
                            item1.fields['SU PPU ($) Custom'] = item2.fields['SU PPU ($)'];
                            item1.fields['SU PPU ($) Custom FE'] = item2.fields['FE PPU ($)'];
                            item1.fields['Bid Status'] = 'accepted'
                        } else {
                            item1.fields['Bid Status'] = 'pending'
                        }
                    }
                });
            }
        });
    }, [data, dataCustomBids])

    const handleSelect = (item) => {
        setSelectedItems(prevSelectedItems => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(i => i !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            setSelectedItems(fetchedDataLamp?.filter((item) => validate(item) === true).map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        if (selectedItems.length > 0) {
            if (customPricing.lamp) {
                setLamp({
                    ...lamp,
                    lampRecomendedCustomPriceIdes: selectedItems,
                    lampRecomendedAirtable: lampAirtable?.length > 0 && JSON.stringify(lampAirtable[0].fields),
                })
            } else {
                setLamp({
                    ...lamp,
                    lampRecomendedCustomPriceIdes: [],
                })
            }
        }  else {
            setLamp({
                ...lamp,
                lampRecomendedCustomPriceIdes: [],
            })
        }
    }, [selectedItems])

    useEffect(() => {
        if (lampAirtable.length > 0){
            setLamp({
                ...lamp,
                lampRecomendedAirtable: JSON.stringify(lampAirtable[0].fields),
                lampRecomendedAirtableArray: lampAirtable,
                lampRecomendedDescription: lampAirtable[0].fields['FE Product Description'],
                lampRecomendedPrice: lampAirtable[0].fields['Price Per Unit ($)'],
                lampRecomendedWattage: lampAirtable[0].fields['Wattage']
            })
        } else {
            setLamp({
                ...lamp,
                lampRecomendedAirtable: [],
                lampRecomendedAirtableArray: [],
                lampRecomendedDescription: '',
                lampRecomendedPrice: '',
                lampRecomendedWattage: ''
            })
        }
    },[lampAirtable])

    if (isLoading) return <Loading height='100%'/>

    return (
        <View
            isFetchingBids={isFetchingBids}
            isFetching={isFetching}
            setBuyAmerican={setBuyAmerican}
            buyAmerican={buyAmerican}
            setIsOpen={setIsOpen}
            certifications={certifications}
            isOpen={isOpen}
            projectId={project?.id}
            refetch={refetch}
            selectedItems={selectedItems}
            customPricing={customPricing}
            setLampAirtable={setLampAirtable}
            handleSelectAll={handleSelectAll}
            selectAll={selectAll}
            handleSelect={handleSelect}
            fromAll={fromAll}
            lampAirtable={lampAirtable}
            setCustomPricing={setCustomPricing}
            lampDisabled={lampDisabled}
            filters={filters}
            setFilters={setFilters}
            validate={validate}
            luminaire={luminaire}
            setSelectedItems={setSelectedItems}
            setType={setType}
            fetchedDataLamp={fetchedDataLamp}
            lamp={lamp}
            description={description}
            setLamp={setLamp}
            create={create}/>
    )
}

export default Lamp
