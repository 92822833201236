import {Flex, Heading, Image} from '@chakra-ui/react'

const LoadingSmall = ({height, width}) => (
    <Flex minH={height && height} alignItems='center' justifyContent='center' width='100%'>
        <Flex alignItems='center' width='100%' justifyContent='center'>
            <Image src="/images/loading.gif" width='50px' height='50px' alt='loading...'/>
            <Heading m={0} fontSize='26px' ml='10px' fontWeight='600'>
                Loading ...
            </Heading>
        </Flex>
    </Flex>
)

export default LoadingSmall
