const accessoryUseCase = (accessoryRepo) => {
  const createAccessory = (input) => accessoryRepo.createAccessory(input) // NO VALIDATIONS NEED
  const updateAccessory = (input) => {
    if (!input?.id) {
      throw Error('ID is required')
    }
    return accessoryRepo.updateAccessory(input)
  }

  const getAccessory = (id) => {
    if (!id) {
      throw Error('ID is required')
    }

    return accessoryRepo.getAccessory(id)
  }

  const listAccessories = (variables) => accessoryRepo.listAccessories(variables)

  return {
    createAccessory,
    updateAccessory,
    getAccessory,
    listAccessories,
  }
}

export default accessoryUseCase
