import React from 'react'
import {Box, Heading, Text, Divider, Flex, List, ListItem,} from '@chakra-ui/react'
import TableProjectFinancing from "../../components/Theme/TableProjectFinancingInactive";
import ChartForecastInactive from "../../components/Theme/ChartForecastInactive";

const Financing = ({ref8}) => {
    return (
        <Box
            ref={ref8}>
            <Box
                >
                <Heading
                    fontSize="3xl"
                    as="h2">
                    Financing Estimates
                </Heading>
                <Text
                    fontSize="md">
                    Financing estimate will only generate if you populate the Finance input fields.
                </Text>
            </Box>
            <Divider
                my={8}
                bg="#DBDADA"
                height="100%"
                oriantion="horisontal"/>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                >
                <List>
                    <ListItem
                        fontSize="sm"
                        display="flex"
                        justifyContent="flex-end">
                        Investment Total:
                        <Flex
                            ml={2}
                            alignItems="center"
                            justifyContent="center"
                            height="23px"
                            width="83px"
                            bg="#A9A9A9"/>
                    </ListItem>
                    <ListItem
                        fontSize="sm"
                        display="flex"
                        justifyContent="flex-end">
                        Deposit:
                        <Flex
                            ml={2}
                            alignItems="center"
                            justifyContent="center"
                            height="23px"
                            width="83px"
                            bg="#A9A9A9">
                        </Flex>
                    </ListItem>
                    <ListItem
                        fontSize="sm"
                        display="flex"
                        justifyContent="flex-end">
                        Principle Loan Account:
                        <Flex
                            ml={2}
                            alignItems="center"
                            justifyContent="center"
                            height="23px"
                            width="83px"
                            bg="#A9A9A9">
                        </Flex>
                    </ListItem>
                    <ListItem
                        fontSize="sm"
                        display="flex"
                        justifyContent="flex-end">
                        Rate:
                        <Flex
                            ml={2}
                            alignItems="center"
                            justifyContent="center"
                            height="23px"
                            width="83px"
                            bg="#A9A9A9">
                        </Flex>
                    </ListItem>
                    <ListItem
                        fontSize="sm"
                        display="flex"
                        justifyContent="flex-end">
                        Duration:
                        <Flex
                            ml={2}
                            alignItems="center"
                            justifyContent="center"
                            height="23px"
                            width="83px"
                            bg="#A9A9A9">
                        </Flex>
                    </ListItem>
                    <ListItem
                        fontSize="sm"
                        display="flex"
                        justifyContent="flex-end">
                        Total Loan Amount:
                        <Flex
                            ml={2}
                            alignItems="center"
                            justifyContent="center"
                            height="23px"
                            width="83px"
                            bg="#8A8A8A">
                        </Flex>
                    </ListItem>
                </List>
                <TableProjectFinancing
                    width="572px"/>
            </Flex>
            <Divider
                my={8}
                bg="#DBDADA"
                height="100%"
                oriantion="horisontal"/>
            <Box>
                <Heading
                    fontSize="lg"
                    as="h3">
                    Payback Forecast
                </Heading>
                <Flex
                    height="100%"
                    width="100%"
                    alignItems="center"
                    flexDirection="column">
                    <ChartForecastInactive/>
                    <Box
                        fontWeight="600"
                        as="span">
                        Years
                    </Box>
                    <Text>
                        Note: Loan forecasts represent estimates calculated to better facilitate illumination system
                        upgrades.
                    </Text>
                </Flex>
            </Box>
        </Box>
    )
}

export default Financing
