import { Input as ChakraInput } from '@chakra-ui/react'
import styled from '@emotion/styled'

const Input = styled(ChakraInput)`
    border: 1px solid rgba(169, 169, 169, 0.87);
    border-radius: 5px;
    font-size: inherit;
    background: #fff;
    &::placeholder { 
        color: #a9a9a9;
        opacity: 1;
    }
      
    &:-ms-input-placeholder { 
        color: #a9a9a9;
    }
      
    &::-ms-input-placeholder { 
        color: #a9a9a9;
    }
    &:focus {
        box-shadow: none;
        outline: none;
        border-color: unset;
    }
`
export default Input
