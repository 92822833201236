import {Stack, HStack, Box,} from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import {InviteModal, InputLabel, ChakraSelectLabel} from '../../../../components/Theme'

const Recent = ({type, isOpen, onClose, area, setArea, luminaireId, recent, setRecent}) => {
    const [filters, setFilters] = useState({application: '', style: '', area: '', wattage: '',})
    const [newLuminaire, setNewLuminaire] = useState({id: '',})
    const [qty, setQty] = useState('')
    const [mountHeight, setMountHeight] = useState('')

    // useEffect(() => {
    //     let areas = allAreas
    //     let allAreasNames = []
    //     const uniqueObjects = new Set();
    //     for (let i = 0; i < areas?.length; i++) {
    //         if (areas[i].title !== '') {
    //             allAreasNames.push(areas[i].title)
    //         }
    //         for (let j = 0; j < areas[i]?.luminaires?.length; j++){
    //             let recent = areas[i]?.luminaires
    //             if (recent.length > 0) {
    //                 for (let j = 0; j < recent?.length; j++) {
    //                     let {id,qty,fixtureMountHeight, ...res} =  recent[j]
    //                     let lumin = JSON.stringify(res)
    //                     if (!uniqueObjects.has(lumin)) {
    //                         uniqueObjects.add(lumin)
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     let a = Array.from(uniqueObjects).map(JSON.parse)
    //     setAreaName(allAreasNames)
    //     setAllLuminaires(a)
    // }, [allAreas, recent])

    const createLuminaire = () => {
        if (newLuminaire.id.length === 0) {
            return onClose()
        }
        let index = 0;
        let dataLed = JSON.parse(newLuminaire.data)
        const newArray = area?.map((e, i) => e?.luminaires.map((luminaire) => {
            let newObj = {...luminaire}
            if (newObj.id === luminaireId) {
                for (const key in dataLed) {
                    if (key.includes('Recomended')) {
                        if (filters.style.length > 0) {
                            if (filters.style === 'fixture' && key.includes('fixtureRecomended')){
                                newObj[key] = dataLed[key]
                            }
                            if (filters.style === 'lamp' && key.includes('lampRecomended')){
                                newObj[key] = dataLed[key]
                            }
                            if (filters.style === 'control' && key.includes('controlRecomended')){
                                newObj[key] = dataLed[key]
                            }
                            if (filters.style === 'power' && key.includes('powerSupplyRecomended')){
                                newObj[key] = dataLed[key]
                            }
                            if (filters.style === 'accessory' && key.includes('accessoryRecomended')){
                                newObj[key] = dataLed[key]
                            }
                            if (filters.style === 'luminaire' && key.includes('Recomended')){
                                newObj[key] = dataLed[key]
                            }
                        } else {
                            newObj[key] = dataLed[key];
                        }
                    }
                }
                newObj.recomendedFixtureQty = qty
                newObj.fixtureMountHeight = mountHeight
                index = i
            }
            return newObj
        }))
        setFilters({application: '', style: '', area: '', wattage: '',})
        area[index].luminaires = newArray[index];
        setArea(area)
        setRecent([...newArray])
        onClose()
    }

    const filter = (filter) => {
        if (filters.application.length > 0 && filter.fixtureApplication !== filters.application) return
        if (filters.area.length > 0 && filter.areaName !== filters.area) return
        if (filters.wattage.length > 0 && filter?.lampRecomendedWattage?.length > 0){
            if (parseFloat(filter.lampRecomendedWattage) !== parseFloat(filters.wattage)) return
        }
        if (filters.style.length > 0){
            if (filters.style === 'fixture'){
                return filter?.fixtureRecomendedDescription?.length > 0
            } else if (filters.style === 'lamp'){
                return filter?.lampRecomendedDescription?.length > 0
            } else if (filters.style === 'control'){
                return filter?.controlRecomendedDescription?.length > 0
            } else if (filters.style === 'power'){
                return filter?.powerSupplyRecomendedDescription?.length > 0
            } else if (filters.style === 'accessory'){
                return filter?.accessoryRecomendedDescription?.length > 0
            }
        }
        return filter?.fixtureRecomendedDescription?.length > 0 || filter?.lampRecomendedDescription?.length > 0 || filter?.controlRecomendedDescription?.length > 0 || filter?.accessoryRecomendedDescription?.length > 0 || filter?.powerSupplyRecomendedDescription?.length > 0
    }
    return (
        <InviteModal isOpen={type === 'recent' && isOpen} onClose={type === 'recent' && createLuminaire} bg="#9CBDD8" title="Recent Luminaires">
            <Stack py="10" spacing="10">
                <HStack spacing="5" alignItems="flex-end">
                    <InputLabel maxW="82px" type="number" pattern="[0-9]*" label="QTY" onChange={(e) => setQty(e.target.value)}/>
                    <InputLabel maxW="142px" type="number" pattern="[0-9]*" label="Mount Height" onChange={(e) => setMountHeight(e.target.value)}/>
                    <ChakraSelectLabel placeholder="Select" onChange={(e) => {
                        const data = e.target[e.target.selectedIndex].getAttribute('data-obj')
                        setNewLuminaire({id: e.target.value, data: data})
                    }} label="Luminaire Options">
                        {/* eslint-disable-next-line array-callback-return */}
                        {recent?.map((item => item?.filter((filtered) => filter(filtered))?.map((ledRec) => {
                            let description = (ledRec?.fixtureRecomendedDescription?.length > 0 ? ledRec?.fixtureRecomendedDescription : '') + (ledRec?.lampRecomendedDescription?.length > 0 ? ledRec?.lampRecomendedDescription : '')
                                + (ledRec?.powerSupplyRecomendedDescription?.length > 0 ? ledRec?.powerSupplyRecomendedDescription : '') + (ledRec?.controlRecomendedDescription?.length > 0 ? ledRec?.controlRecomendedDescription : '') + (ledRec?.accessoryRecomendedDescription?.length > 0 ? ledRec?.accessoryRecomendedDescription : '')
                            if (filters.style === 'fixture'){
                                description = (ledRec?.fixtureRecomendedDescription?.length > 0 ? ledRec?.fixtureRecomendedDescription : '')
                            } else if (filters.style === 'lamp'){
                                description = (ledRec?.lampRecomendedDescription?.length > 0 ? ledRec?.lampRecomendedDescription : '')
                            } else if (filters.style === 'control'){
                                description = (ledRec?.controlRecomendedDescription?.length > 0 ? ledRec?.controlRecomendedDescription : '')
                            } else if (filters.style === 'power'){
                                description = (ledRec?.powerSupplyRecomendedDescription?.length > 0 ? ledRec?.powerSupplyRecomendedDescription : '')
                            } else if (filters.style === 'accessory'){
                                description = (ledRec?.accessoryRecomendedDescription?.length > 0 ? ledRec?.accessoryRecomendedDescription : '')
                            }
                            return (
                                <option key={ledRec.id} value={ledRec.id} data-obj={JSON.stringify(ledRec)}>
                                    {description}
                                </option>
                            )
                            }
                        )))}
                    </ChakraSelectLabel>
                </HStack>
                <HStack w="full" justifyContent="center" position="relative" alignItems="center">
                    <Box position="absolute" bg="#A9A9A9" w="full" h="1px"/>
                    <Box px="2" position="relative" bg="white" textTransform="uppercase" fontWeight="400" fontSize="xl" color="#2C628D" as="span">
                        filters
                    </Box>
                </HStack>
                <HStack spacing="5">
                    <ChakraSelectLabel maxW="203px" placeholder="Select" value={filters.application} onChange={(e) => setFilters({...filters, application: e.target.value})} label="Application">
                        <option value="Indoor">Indoor</option>
                        <option value="Outdoor">Outdoor</option>
                        <option value="Garage">Garage</option>
                    </ChakraSelectLabel>
                    <ChakraSelectLabel maxW="203px" placeholder='Select' label="Area" onChange={(e) => setFilters({...filters, area: e.target.value})}>
                        {/*{area?.map((item) => (<option key={item.title}>{item.title}</option>))}*/}
                    </ChakraSelectLabel>
                    <ChakraSelectLabel maxW="203px" label="Component Style" placeholder='Select' onChange={(e) => setFilters({...filters, style: e.target.value})}>
                        <option value="luminaire">Luminaire</option>
                        <option value="fixture">Fixture</option>
                        <option value="lamp">Lamp</option>
                        <option value="power">Power Supply</option>
                        <option value="control">Control</option>
                        <option value="accessory">Accessory</option>
                    </ChakraSelectLabel>
                    <InputLabel maxW="203px" label="Wattage" type="number" pattern="[0-9]*" onChange={(e) => setFilters({...filters, wattage: e.target.value})}/>
                </HStack>
            </Stack>
        </InviteModal>
    )
}

export default Recent
