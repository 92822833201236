import {Box, Flex, Tab, TabList as ChakraList, Image, Heading} from "@chakra-ui/react";

const TabList = ({estimate, tabIndex}) => (
    <ChakraList border="none" pt={10} bg="white.100">
        <Tab
            minW="248px"
            fontWeight='400'
            _active={{background: 'transparent',}}
            _selected={{
                color: 'green.200',
                borderBottomWidth: '4px',
                borderColor: 'green.200',
                boxShadow: 'none',
                fontWeight: '700',
            }}
            position='relative'
            color="green.200">
            <Flex>
                <Heading
                    m={0}
                    fontWeight={tabIndex === 0 ? '700' : 'normal'}
                    fontSize='18px'
                    pr={2}>
                    Estimate
                </Heading>
                {estimate?.isEstimate && !estimate?.archived && (
                    <Box
                        borderRadius='50%'
                        backgroundColor='#41A916'
                        height='6px'
                        width='6px'/>
                )}
            </Flex>
            <Image
                display={estimate?.createdAt && !estimate?.isEstimate && !estimate?.archived ? 'block' : 'none'}
                top='10px'
                right='0'
                position='absolute'
                src='/images/RightIcon.svg'
                alt='icon'/>
        </Tab>
        <Tab
            minW="248px"
            fontWeight={estimate?.isOrder && !estimate?.archived ? '700' : '400'}
            _active={{background: 'transparent',}}
            _selected={{
                color: 'green.200',
                borderBottomWidth: '4px',
                borderColor: 'green.200',
                boxShadow: 'none',
            }}
            position='relative'
            color="green.200">
            <Flex>
                <Heading
                    m={0}
                    fontSize='18px'
                    fontWeight={tabIndex === 1 ? '700' : '400'}
                    pr={2}>
                    Order
                </Heading>
                {estimate?.isOrder && !estimate?.archived && (
                    <Box
                        borderRadius='50%'
                        backgroundColor='#41A916'
                        height='6px'
                        width='6px'/>
                )}
            </Flex>
            <Image
                display={estimate?.orderDateCreated && !estimate?.isOrder && !estimate?.archived ? 'block' : 'none'}
                top='10px'
                right='0'
                position='absolute'
                src='/images/RightIcon.svg'
                alt=''/>
        </Tab>
        <Tab
            minW="248px"
            fontWeight={estimate?.isShipping && !estimate?.archived ? '700 !important' : 'normal'}
            _active={{background: 'transparent',}}
            _selected={{
                color: 'green.200',
                borderBottomWidth: '4px',
                borderColor: 'green.200',
                boxShadow: 'none',
            }}
            position='relative'
            color="green.200">
            <Flex>
                <Heading
                    m={0}
                    fontSize='18px'
                    fontWeight={tabIndex === 2 ? '700 !important' : 'normal'}
                    pr={2}>
                    Shipping
                </Heading>
                {estimate?.isShipping && !estimate?.archived && (
                    <Box
                        borderRadius='50%'
                        backgroundColor='#41A916'
                        height='6px'
                        width='6px'/>
                )}
            </Flex>
            <Image
                display={estimate?.shippingDateCreated && !estimate?.isShipping && !estimate?.archived ? 'block' : 'none'}
                top='10px'
                right='0'
                position='absolute'
                src='/images/RightIcon.svg'
                alt=''/>
        </Tab>
        <Tab
            minW="248px"
            fontWeight={estimate?.isInvoice && !estimate?.archived ? '700 !important' : 'normal'}
            _active={{background: 'transparent',}}
            _selected={{
                color: 'green.200',
                borderBottomWidth: '4px',
                borderColor: 'green.200',
                boxShadow: 'none',
            }}
            position='relative'
            color="green.200">
            <Flex>
                <Heading
                    m={0}
                    fontSize='18px'
                    fontWeight={tabIndex === 3 ? '700' : 'normal'}
                    pr={2}>
                    Invoice
                </Heading>
                {estimate?.isInvoice && !estimate?.archived && (
                    <Box
                        borderRadius='50%'
                        backgroundColor='#41A916'
                        height='6px'
                        width='6px'/>
                )}
            </Flex>
            <Image
                display={estimate?.invoiceDateCreated && !estimate?.isInvoice && !estimate?.archived ? 'block' : 'none'}
                top='10px'
                right='0'
                position='absolute'
                src='/images/RightIcon.svg'
                alt=''/>
        </Tab>
        <Tab
            minW="248px"
            _active={{background: 'transparent',}}
            _selected={{
                color: 'green.200',
                borderBottomWidth: '4px',
                borderColor: 'green.200',
                boxShadow: 'none',
            }}
            position='relative'
            color="green.200">
            <Flex>
                <Heading
                    m={0}
                    fontSize='18px'
                    fontWeight={tabIndex === 4 ? '700' : 'normal'}
                    pr={2}>
                    Receipt
                </Heading>
                {estimate?.isReceipt && !estimate?.archived && (
                    <Box
                        borderRadius='50%'
                        backgroundColor='#41A916'
                        height='6px'
                        width='6px'/>
                )}
            </Flex>
            <Image
                display={estimate?.receiptDateCreated && !estimate?.isReceipt && !estimate?.archived ? 'block' : 'none'}
                top='10px'
                right='0'
                position='absolute'
                src='/images/RightIcon.svg'
                alt=''/>
        </Tab>
    </ChakraList>
)

export default TabList