import React, {useEffect, useState} from 'react'
import {TabPanel, Tab, Tabs, TabList, TabPanels,} from '@chakra-ui/react'
import Overview from './Tabs/Overview'
import Price from './Tabs/Price'
import Cost from './Tabs/Cost'
import Security from './Tabs/Security'
import Time from './Tabs/Time'
import HumanCentric from './Tabs/HumanCentric'
import OwerviewTab from './OwerviewTab'
import {useHistory} from "react-router-dom";

const AllTabPanels = ({project, user, reports}) => {
    const [lists, setLists] = useState(reports);
    const history = useHistory()

    useEffect(() => {
        if (!project) return
        setLists(reports)
    }, [project])

    return (
        <>
            <Tabs minW="900px" m="auto" maxW="1240px">
                <TabList mx="-1px" border="none" pt={5} bg="white.100">
                    <Tab fontSize='18px' minW="207px" fontWeight='400' _active={{background: 'transparent',}} _selected={{color: '#619C48', borderColor: '#619C48', borderBottomWidth: '3px', boxShadow: 'none',fontWeight: '700'}} color="#619C48">
                        Overview
                    </Tab>
                    <OwerviewTab/>
                </TabList>
                <TabPanels bg="#f5f5f5" py={5} px={8} border="1px solid #DBDADA" borderBottom="none">
                    <TabPanel m="auto" maxW="1180px" pt={6} px={0}>
                        <Overview
                            project={project}
                            lists={lists}
                            setLists={setLists}
                            user={user}
                            reports={reports}
                            history={history}/>
                    </TabPanel>
                    <TabPanel m="auto" maxW="1180px" pt={0} px={0}>
                        <Price
                            project={project}
                            lists={lists}
                            setLists={setLists}
                            user={user}
                            reports={reports}
                            history={history}/>
                    </TabPanel>
                    <TabPanel m="auto" maxW="1180px" pt={0} px={0}>
                        <Cost
                            project={project}
                            lists={lists}
                            setLists={setLists}
                            user={user}
                            reports={reports}
                            history={history}/>
                    </TabPanel>
                    <TabPanel m="auto" maxW="1180px" pt={0} px={0}>
                        <Security
                            project={project}
                            lists={lists}
                            setLists={setLists}
                            user={user}
                            reports={reports}
                            history={history}/>
                    </TabPanel>
                    <TabPanel m="auto" maxW="1180px" pt={0} px={0}>
                        <Time
                            project={project}
                            lists={lists}
                            setLists={setLists}
                            user={user}
                            reports={reports}
                            history={history}/>
                    </TabPanel>
                    <TabPanel m="auto" pt={0} maxW="1180px" px={0}>
                        <HumanCentric
                            project={project}
                            lists={lists}
                            setLists={setLists}
                            user={user}
                            reports={reports}
                            history={history}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}
export default AllTabPanels
