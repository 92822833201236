import React, {useState, useRef, useEffect} from 'react'
import moment from 'moment'
import {Flex, HStack, Image, Text, Button as ChakraButton, Button, Box} from '@chakra-ui/react'
import {useUploadMultiple, useUser} from '../../core/hooks'
import styles from '../CreateProject/styles'
import {S3_BUCKET} from '../../constants'
import DragDrop from '../CreateProject/UploadDragDrop'
import {ReactComponent as Search} from '../../assets/search.svg'
import {ReactComponent as Download} from '../../assets/download.svg'
import {ReactComponent as DownloadIcon} from "../../assets/cloud-download.svg";
import fileSaver from "file-saver/dist/FileSaver";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";

const Files = ({setValue, getValues,}) => {
    const inputRef = useRef()
    const {user} = useUser()
    const {upload, data: response, isLoading, error,} = useUploadMultiple()
    const [files, setFiles] = useState([])
    const [success, setSuccess] = useState(false)

    const handleImage = (e) => {
        const filesToUpload = []
        const dataToPreview = []
        Object.values(e.target.files).forEach(
            (file) => {
                const type = file.type.split('/')[1] || 'jpeg'
                const name = `${new Date().getTime() * Math.random()}.${type}`
                dataToPreview.push({
                    name: file.name,
                    key: name,
                    sortId: files.length,
                    createdAt: new Date().toISOString(),
                })
                filesToUpload.push({name, file, type,})
            },
        )
        upload(
            filesToUpload,
            {
                onSuccess: (e) => {
                    setFiles([...files, [...dataToPreview, e[0].data.data]])
                    setValue('files', [...files, [...dataToPreview, e[0].data.data]])
                    setSuccess(true)
                },
            },
        )
    }

    function downloadFile(e, key) {
        fileSaver.saveAs(S3_BUCKET + key, key);
    }

    const onSortEnd = (e) => {
        let newFiles = arrayMove(files, e.oldIndex, e.newIndex)
        for (let i = 0; i < newFiles?.length; i++) {
            newFiles[i][0].sortId = newFiles.indexOf(newFiles[i])
        }
        setFiles(newFiles)
    }
    const SortableItem = SortableElement(({value}) =>
        <Flex
            cursor='pointer'
            css={styles.file}
            key={value[0]?.sortId}>
            <Flex
                alignItems="center"
                w='40%'>
                <Image
                    src="/icons/drag.svg"
                    alt="Drag"/>
                <Text
                    ml="10"
                    fontWeight="500">
                    {value[0]?.name}
                </Text>
            </Flex>
            <Flex
                w='30%'
                color='#A9A9A9'
                fontWeight="700">
                {moment(value[0]?.createdAt).format('MMM DD, YYYY')}
            </Flex>
            <Flex>
                <Button
                    onClick={e => downloadFile(e, value[1])}
                    mr={8}
                    leftIcon={<Download/>}
                    bg="#2C628D"
                    color="white"
                    height="30px"
                    fontSize="12px">
                    Download
                </Button>
            </Flex>
        </Flex>)

    const SortableList = SortableContainer(({lists}) => {
        return (
            <Box>
                {
                    lists?.sort(function (a, b) {
                        return a[0]?.sortId - b[0]?.sortId
                    }).map((value, index) => (
                        <SortableItem
                            key={value[0]?.key}
                            index={index}
                            value={value}/>
                    ))
                }
            </Box>
        )
    })

    useEffect(() => {
        if (!getValues('files')) return
        const newFiles = getValues('files')
        setFiles([...newFiles])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getValues('files')])

    return (
        <>
            <HStack
                spacing="10rem"
                mt="20px"
                mb="58px"
                justifyContent='space-between'>
                <Text
                    fontSize="18px"
                    fontWeight="400"
                    maxW='551px'>
                    {
                        user?.type === 'Reu' || user?.type === 'SUPERADMIN' || user?.type === 'Sa' ? (
                            'Upload pertinent company files by dragging them into the designated area (below) or browse your device (blue button).  Whether it’s a reflected ceiling plan, insurance documentation or building schematics - the more information we have, the better we can assist!'
                        ) : (
                            "Download the Focus Earth template (green button). After populating your specifications and pricing,upload the revised file by dragging it into the gray area (below) or browse your device (bluebutton)."
                        )
                    }
                </Text>
                <Flex
                    flexDirection='column'>
                    {
                        user?.type !== 'Reu' && user?.type !== 'SUPERADMIN' && user?.type !== 'Sa' && (
                            <a
                                target='_blank'
                                href={`${S3_BUCKET}upload-template.xlsx`}
                                download>
                                <ChakraButton
                                    mb={3}
                                    leftIcon={<DownloadIcon/>}
                                    minW="251px"
                                    fontSize="xl"
                                    variant="green"
                                    size="lg">
                                    Download Template
                                </ChakraButton>
                            </a>
                        )
                    }
                    <input
                        onChange={handleImage}
                        type="file"
                        multiple
                        style={{display: 'none'}}
                        ref={inputRef}/>
                    <ChakraButton
                        leftIcon={<Search/>}
                        onClick={() => inputRef.current.click()}
                        minW="251px"
                        fontSize="xl"
                        size="lg" variant="blue">
                        Browse For Files
                    </ChakraButton>
                </Flex>
            </HStack>
            <DragDrop
                isCreatingLoading={isLoading}
                success={success}
                error={error}
                handleFile={handleImage}
                isLoading={isLoading}/>
            {
                files.length > 0 && (
                    <Flex
                        my="6"
                        fontSize="20px"
                        fontWeight="500">
                        <Text>
                            Uploaded Files
                        </Text>
                        <Text
                            w='80%'
                            textAlign='center'>
                            Date
                        </Text>
                    </Flex>
                )
            }

            <Flex
                mt="6"
                flexDirection="column">
                {
                    !isLoading && (
                        <SortableList
                            lists={files}
                            onSortEnd={onSortEnd}
                            lockAxis="y"/>
                    )
                }
            </Flex>
        </>
    )
}

export default Files