import {Box} from '@chakra-ui/react'
import {Line} from 'react-chartjs-2'

const ChartForecast = ({existing, led, ledFinanced, projectTotal}) => {
    const data = {
        labels: [0,1,2,3,4,5,6,7,8,9,10],
        datasets: [
            {
                label: 'Existing System',
                data: [
                    0,
                    Math.round(existing),
                    Math.round(existing * 2),
                    Math.round(existing * 3),
                    Math.round(existing * 4),
                    Math.round(existing * 5),
                    Math.round(existing * 6),
                    Math.round(existing * 7),
                    Math.round(existing * 8),
                    Math.round(existing * 9),
                    Math.round(existing * 10)
                ],
                fill: false,
                borderColor: '#F1381F',
                backgroundColor: '#F1381F',
                pointBorderColor: '#F1381F',
                pointBackgroundColor: '#F1381F',
                pointHoverBackgroundColor: '#F1381F',
                pointHoverBorderColor: '#F1381F',
            },
            {
                label: 'LED Upgrade (Financed)',
                data: ledFinanced,
                fill: false,
                backgroundColor: '#FFD439',
                borderColor: '#FFD439',
                hoverBackgroundColor: '#FFD439',
                hoverBorderColor: '#FFD439',
            },
            {
                label: 'LED Upgrade (Paid)',
                type: 'line',
                data: [
                    projectTotal,
                    Math.round(projectTotal+led),
                    Math.round(projectTotal+(led*2)),
                    Math.round(projectTotal+(led*3)),
                    Math.round(projectTotal+(led*4)),
                    Math.round(projectTotal+(led*5)),
                    Math.round(projectTotal+(led*6)),
                    Math.round(projectTotal+(led*7)),
                    Math.round(projectTotal+(led*8)),
                    Math.round(projectTotal+(led*9)),
                    Math.round(projectTotal+(led*10))
                ],
                fill: false,
                backgroundColor: '#71B37C',
                borderColor: '#71B37C',
                hoverBackgroundColor: '#71B37C',
                hoverBorderColor: '#71B37C',
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    callback(value, _index, values) {
                        return `$${value.toLocaleString('en-US')}`
                    },
                },
            }],
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    return '$' + value.toLocaleString('en-US'); // Prepend a dollar sign ($) to the tooltip label
                }
            }
        }
    }

    return (
        <Box
            height="450px"
            width="100%">
            <Line
                options={options}
                data={data}/>
        </Box>
    )
}

export default ChartForecast
