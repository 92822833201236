import {useEffect,useState} from 'react'
import Navbar from '../../components/Navbar'
import {Box, Tab, TabList, TabPanel, TabPanels, Tabs, Button, Text, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverBody,} from "@chakra-ui/react";
import {ReactComponent as DownloadIcon} from "../../assets/download.svg";
import Overview from "./TabPanles/Overview";
import RealEstate from "./TabPanles/RealEstate";
import {Checkbox} from "../../components/Theme";
import styles from "./styles";
import Supplier from "./TabPanles/Supplier";
import Electrician from "./TabPanles/Electrician";
import Sales from "./TabPanles/Sales";
import Admin from "./TabPanles/Admin";
import mainStyles from "../../styles/mainStyles";
import {useGetDataMain, useGetDataWithType, useGetUsage} from "../../core/hooks";
import {DATE_ENUMS} from "../../constants/chartDatas";

const companyColors = ['#4A85B4', '#C2EF62', '#2E8A07', '#5DE2CA', '#C956FF', '#FF7F56']

const Data = () => {
    let score = {RealEstate: 0, Supplier: 0, Sales: 0, Electrician: 0, Administrator: 0,}
    const [countUsers, setCountUsers] = useState(score)
    const [countUsersTotal, setCountUsersTotal] = useState([])
    const [usersUsage, setUsersUsage] = useState([])
    const [tabIndex, setTabIndex] = useState(0)
    const {mainData, isLoading} = useGetDataMain()
    const {usage, refetch: refetchUsage} = useGetUsage(tabIndex+1)
    const {dataWithType, refetch: refetchData, isLoading: isLoadingTypeData, isFetching} = useGetDataWithType(tabIndex+1)
    const [pageData, setPageData] = useState([])

    useEffect(() => {
        if (!mainData) return
        let dt = mainData.data.data
        setCountUsers(dt['users_count'])
        let totalCon = Object.values(dt['users_count']).reduce((acc, item) => {
            acc.count_c += parseFloat(item.count_c) || 0;
            acc.sum_total += parseFloat(item.sum_total) || 0;
            return acc;
        }, { count_c: 0, sum_total: 0 })
        setCountUsersTotal(totalCon)
    }, [mainData])

    useEffect(() => {
        if (!usage) return
        setUsersUsage(usage.data.data)
    },[usage])

    useEffect(() => {
        if (!dataWithType) return
        let dt = dataWithType.data.data
        setPageData(dt)
    },[dataWithType])

    useEffect(() => {
        refetchData()
        refetchUsage()
    },[tabIndex])

    return (
        <>
            <Navbar title="Data">
                <Popover>
                    <PopoverTrigger>
                        <Button leftIcon={<DownloadIcon/>} bg='#2C628D' mr={20} color='white' height='30px' minW="139px">
                            <Text ml={2} fontSize="sm">Download</Text>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent zIndex="500" p={2} w="139px" bg='#132E46' flexDirection="row" _focus={{outline: 'none'}}>
                        <PopoverArrow/>
                        <PopoverBody width='full' display="flex" p={0} color='white' flexDirection="column">
                            <Checkbox pl={2} borderRadius='4px' bg='#4A85B4' key='Real Estate' _hover='' css={styles.checkbox} name='Real Estate'>
                                <Box textAlign='center' ml={4} width='full' fontSize="14px">PDF</Box>
                            </Checkbox>
                            <Checkbox pl={2} _hover='' borderRadius='4px' bg='#8AB278' key='Suppliers' css={styles.checkbox} name='Supplier'>
                                <Box ml={5} fontSize="14px" as="span">Excel</Box>
                            </Checkbox>
                            <Checkbox pl={2} borderRadius='4px' bg='#FF7F56' key='Electricians' css={styles.checkbox} name='Electrician'>
                                <Box ml={1} fontSize="14px" as="span">Spreadsheet</Box>
                            </Checkbox>
                            <Button height='20px' color='white'>Download</Button>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Navbar>
            <Box m="auto" minW="1240px" maxW="1240px">
                <Tabs
                    index={tabIndex}
                    onChange={(index) => setTabIndex(index)}
                    bg="#F5F5F5">
                    <TabList css={mainStyles.tabNames}>
                        <Tab>Overview</Tab>
                        <Tab>Real Estate</Tab>
                        <Tab>Supplier</Tab>
                        <Tab>Electrician</Tab>
                        <Tab>Sales</Tab>
                        <Tab>Admin</Tab>
                    </TabList>
                    <TabPanels css={mainStyles.tabPanels} px='15px !important'>
                        <TabPanel>
                            <Overview
                                data={mainData && mainData.data.data}
                                isFetching={isFetching}
                                isLoading={isLoading}
                                companyColors={companyColors}
                                countUsersTotal={countUsersTotal}
                                countUsers={countUsers}
                                usage={usersUsage}/>
                        </TabPanel>
                        <TabPanel>
                            <RealEstate
                                isFetching={isFetching}
                                data={pageData}
                                companyColors={companyColors}
                                usage={usersUsage}
                                />
                        </TabPanel>
                        <TabPanel>
                            <Supplier
                                data={pageData}
                                isFetching={isFetching}
                                usage={usersUsage}
                                companyColors={companyColors}
                                />
                        </TabPanel>
                        <TabPanel>
                            <Electrician
                                isFetching={isFetching}
                                data={pageData}
                                isLoadingTypeData={isLoadingTypeData}
                                companyColors={companyColors}
                                usage={usersUsage}
                                countUsers={countUsers}/>
                        </TabPanel>
                        <TabPanel>
                            <Sales
                                isFetching={isFetching}
                                data={pageData}
                                isLoadingTypeData={isLoadingTypeData}
                                companyColors={companyColors}
                                usage={usersUsage}/>
                        </TabPanel>
                        <TabPanel>
                            <Admin
                                isFetching={isFetching}
                                data={pageData}
                                isLoadingTypeData={isLoadingTypeData}
                                companyColors={companyColors}
                                usage={usersUsage}
                                countUsers={countUsers}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    )
}

export default Data
