import React, {useEffect, useState} from 'react'
import {
    Heading,
    Text,
    GridItem,
    SimpleGrid,
} from '@chakra-ui/react'
import {Card} from '../../components/Theme'
import HeaderForCatalogueAdmins from "./HeaderForCatalogueAdmins";
import {useUser} from "../../core/hooks";
import Loading from "../../components/Loading";
import httpRequest from "../../core/services/http-request";

const InterierLightingCatalogue = () => {
    const {user} = useUser()
    const {post} = httpRequest()
    const [fetchedData, setFetchedData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        post('product-list',{
            "condition": {
                "table": "All",
            }
        }).then((e) => {
            setLoading(false)
            setFetchedData(e?.data?.data)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    const handleSearch = (e) => {
        // setFetchedData(data.records?.filter((item) => (item.fields['Description']).toLowerCase().includes(e.target.value.toLowerCase())))
    }

    if (loading) return <Loading />

    return (
        <>
            <HeaderForCatalogueAdmins
                handleSearch={handleSearch}
                title=' LEDs Inc. / Interior'/>
            <SimpleGrid
                p="12"
                w="full"
                columns={{
                    base: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                }}
                spacing="16">
                {
                    fetchedData?.map((product) => (
                        <GridItem
                            colSpan={1}
                            key={product.id}>
                            <Card
                                user={user}
                                image={product.fields['Image'] ? product.fields['Image'][0]?.url : ''}>
                                <Heading
                                    fontSize="md"
                                    as="h3">
                                    {product.fields['Description']}
                                </Heading>
                                <Text
                                    fontSize="sm">
                                    {product.fields['Variable Formula']}
                                </Text>
                            </Card>
                        </GridItem>
                    ))
                }
            </SimpleGrid>
        </>
    )
}

export default InterierLightingCatalogue