import {
    Box,
    Flex,
    Text,
    Button,
    Modal,
    ModalOverlay,
    Checkbox,
    ModalContent,
    ModalHeader,
    ModalBody,
    Divider, FormLabel, Menu, MenuButton, MenuList, Grid, GridItem, FormControl,
} from '@chakra-ui/react'
import React, {useCallback,useEffect, useMemo, useState} from "react";
import Icon from "../../../components/Theme/Icon";
import IconButton from "../../../components/Theme/IconButton";
import {useListCities, useListStates} from "../../../core/hooks";
import styles from "./styles";
import {ReactComponent as DownArrow} from '../../../assets/downArrow.svg'

const ServiceableAreas = ({isOpen, onClose,userType, onOpen, serviceArea, setServiceArea,}) => {
    const [areas, setAreas] = useState(serviceArea)
    const [stateNames, setStateNames] = useState(serviceArea)
    const {isLoading, states} = useListStates(userType)
    const {isLoadingCities, cities, refetchCities} = useListCities(stateNames)

    useEffect(() => {
        if (serviceArea?.length > 0){
            setAreas(serviceArea)
        }
    }, [serviceArea]);

    const handleSelectAllStates = ((e) => {
        const checked = e.target.checked;
        if (!checked) return setAreas([])
        if (!states) return
        setAreas(states.data.data.map(item => ({
            ...item,
            isClose: true,
            checkedAll: true,
        })))
    });

    const handleSelectState = ((stateIndex, checked, state) => {
        setAreas((prevAreas) => {
            const updatedAreas = [...prevAreas];
                updatedAreas[stateIndex].isClose = !checked;
                updatedAreas[stateIndex].checkedAll = checked;
                if (updatedAreas[stateIndex]?.child?.length > 0){
                    updatedAreas[stateIndex].child = updatedAreas[stateIndex].child.map(city => ({
                        ...city,
                        value: checked
                    }));
                }

            return updatedAreas;
        });
        setStateNames((prevState) => {
            if (prevState.some(v => v.name === state.name)) {
                return [...prevState];
            } else {
                return [...prevState, state];
            }
        });
    });

    const handleCityChange = ((stateIndex, cityIndex, checked) => {
        setAreas((prevAreas) => {
            const updatedAreas = [...prevAreas];
            updatedAreas[stateIndex].child[cityIndex].value = checked;
            return updatedAreas;
        });
    });

    useEffect(() => {
        if (stateNames.length > 0) {
           refetchCities()
        }
    }, [stateNames]);

    useEffect(() => {
        if (!cities) return
        setAreas((prevState) => {
            return prevState.map((item) => {
                Object.entries(cities.data.data).forEach(([key, value]) => {
                    if (item.name === key) {
                        if (item.checkedAll){
                            item.child = value.map(item => ({
                                ...item,
                                value : true
                            }))
                        } else {
                            if (!item?.child) item.child = [...value];
                        }
                    }
                });

                return item
            });
        });
    },[cities])

    return (
        <Box>
            <Modal
                isCentered
                isOpen={isOpen}
                onClose={onClose}>
                <ModalOverlay/>
                <ModalContent
                    overflow="hidden"
                    borderRadius="10px"
                    minW="587px">
                    <ModalHeader
                        backgroundColor="#FFE68D"
                        height="70px"
                        textAlign="center">
                        <Text
                            fontWeight="700"
                            fontSize="24px"
                            m={0}
                            as="h2">
                            Serviceable Areas
                        </Text>
                    </ModalHeader>
                    <IconButton
                        height="unset"
                        onClick={onClose}
                        _hover={{
                            bg: 'none',
                        }}
                        _active={{
                            bg: 'none',
                        }}
                        bg="none"
                        aria-label="close button"
                        position="absolute"
                        top="1.5rem"
                        right="0.75rem"
                        icon={<Icon type="darkClose"/>}/>
                    <ModalBody
                        overflow="scroll"
                        maxH="600px">
                        <Flex
                            mt={6}
                            justifyContent="flex-end">
                            <Button
                                type="submit"
                                onClick={() => {
                                    setServiceArea(
                                        areas
                                            .filter(item => (item?.child?.length > 0 && item.child.some(city => city.value) || item.checkedAll))
                                            .map(item => ({
                                                ...item,
                                                child: item?.child?.length > 0 ? item.child.filter(city => city.value) : []
                                            }))
                                    )
                                    onClose()
                                }}
                                w="135px"
                                h="40px"
                                backgroundColor="#8FCF74">
                                <Text
                                    fontWeight="700"
                                    fontSize="12px"
                                    ml={1}
                                    color="black"
                                    mt="2px">
                                    SAVE
                                </Text>
                            </Button>
                        </Flex>
                        <Text
                            as="h2"
                            color="#2C628D">
                            Serviceable Areas - Counties
                        </Text>
                        <Box
                            minH='450px'
                            border="1px solid"
                            borderRadius="6px 6px 0px 0px"
                            p="20px"
                            borderColor="#A9A9A9">
                            <Box
                                mb={3}>
                                <Checkbox
                                    isChecked={areas?.length > 0 && areas.every(item => item?.checkedAll === true)}
                                    onChange={handleSelectAllStates}>
                                    Select All Counties For All States
                                </Checkbox>
                            </Box>
                            {
                                states &&
                                <FormControl css={styles.menu}>
                                    <FormLabel fontWeight="600" fontSize="md" color="blue.200">
                                        Select Areas
                                    </FormLabel>
                                    <Menu>
                                        <MenuButton as={Flex} px={4} alignItems="center" position='relative' border="1px solid" overflow="scroll" borderColor="rgba(169, 169, 169, 0.87)" borderRadius="5px" bg="white.100" height="40px">
                                            <Text whiteSpace="nowrap" fontSize="xl">
                                                {areas.length > 0 ?
                                                    areas.map((item) => item.name).join(', ').slice(0, 50) +
                                                    (areas.map((item) => item.name).join(', ').length > 50 ? '...' : '')
                                                    : ''
                                                }
                                                <Flex position='absolute' right='15px' top='15px'>
                                                    <DownArrow />
                                                </Flex>
                                            </Text>
                                        </MenuButton>
                                        <MenuList pl={6}>
                                            <Grid templateColumns={`repeat(3, 1fr)`}>
                                                {
                                                    states.data.data.map((item, index) => (
                                                        <GridItem key={index} colSpan={1}>
                                                            <Checkbox
                                                                isChecked={areas.some(selectedItem => selectedItem.name === item.name)}
                                                                name={item?.name}
                                                                onChange={(e) => {
                                                                    let updatedValue;
                                                                    if (e.target.checked) {
                                                                        item.isClose = true
                                                                        updatedValue = [...areas, item];
                                                                    } else {
                                                                        updatedValue = areas.filter(v => v.name !== item.name);
                                                                    }
                                                                    setAreas(updatedValue);
                                                                }}
                                                                iconColor="black"
                                                                colorScheme="whiteAlpha">
                                                                {item.name}
                                                            </Checkbox>
                                                        </GridItem>
                                                    ))
                                                }
                                            </Grid>
                                        </MenuList>
                                    </Menu>
                                </FormControl>
                            }

                            {
                                areas?.length > 0 && areas.map((state, stateIndex) => (
                                    <Box key={state.name}>
                                        <Flex mt={5}>
                                            <Checkbox
                                                isChecked={!!state?.checkedAll}
                                                onChange={(e) => handleSelectState(stateIndex, e.target.checked, state)}
                                            />
                                            <Text
                                                w='max-content'
                                                fontWeight="700"
                                                ml={1}
                                                fontSize="18px"
                                                cursor="pointer"
                                                onClick={() => {
                                                    const updatedAreas = [...areas];
                                                    updatedAreas[stateIndex].isClose = !updatedAreas[stateIndex].isClose;
                                                    setAreas(updatedAreas);
                                                    setStateNames((prevState) => {
                                                        if (prevState.some(v => v.name === state.name)) {
                                                            return [...prevState];
                                                        } else {
                                                            return [...prevState, state];
                                                        }
                                                    });
                                                }}>
                                                {state.name}
                                            </Text>
                                            <Divider
                                                mt={3}
                                                ml={5}
                                                borderColor="#000000"
                                                border="1px solid"
                                                orientation="horizontal"/>
                                        </Flex>
                                        <Flex
                                            display={state.isClose ? 'none' : 'flex'}
                                        >
                                            <Divider
                                                mt={3}
                                                orientation="vertical"
                                                height='auto'
                                                borderColor="#000000"
                                                borderLeft="2px solid"
                                            />
                                            <Flex
                                                ml={5}
                                                mt={3}
                                                flexDirection="column"
                                                justifyContent="space-between">
                                                <Checkbox
                                                    isChecked={!!state?.checkedAll}
                                                    onChange={(e) => handleSelectState(stateIndex, e.target.checked, state)}
                                                >
                                                    Select All Counties For {state.name}
                                                </Checkbox>
                                                {
                                                    state?.child?.map((city, cityIndex) => (
                                                        <Checkbox
                                                            key={city.name}
                                                            value={city.name}
                                                            isChecked={city.value}
                                                            onChange={(e) => handleCityChange(stateIndex, cityIndex, e.target.checked)}>
                                                            {city.name}
                                                        </Checkbox>
                                                    ))
                                                }
                                            </Flex>
                                        </Flex>
                                    </Box>
                                ))
                            }
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}
export default ServiceableAreas