import React, {useEffect, useState} from "react";
import {Modal, ModalBody,Flex,Button, ModalContent, ModalHeader, ModalOverlay, Text,Select} from "@chakra-ui/react";
import IconButton from "../../../components/Theme/IconButton";
import Icon from "../../../components/Theme/Icon";
import {useListCompanys} from "../../../core/hooks";
import {REU} from "../../../constants/user/userEnum";

const SyncCompany = ({isOpen, onClose, setCompany, company}) => {
    const [companies, setCompanies] = useState([])
    const {data, isLoading} = useListCompanys({type: REU})

    useEffect(() => {
        if (!data) return
        setCompanies(data.data.data)
    },[data])

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay/>
            <ModalContent overflow="hidden" borderRadius="10px" minW="853px">
                <ModalHeader bg='#FF9A7A' fontWeight="700" fontSize="24px" textAlign="center" as='h2' m={0}>
                    Sync Company
                </ModalHeader>
                <IconButton height="unset" onClick={onClose} _hover={{bg: 'none',}} _active={{bg: 'none',}} bg="none" aria-label="close button" position="absolute" top="1.5rem" right="0.75rem" icon={<Icon type="darkClose"/>}/>
                <ModalBody pt='50px' pb='50px' pl='120px' pr='120px' fontSize='24px' overflow="scroll" maxH="600px">
                    <Text mb={10} textAlign='center'>Select a company for this project:</Text>
                    <Flex maxW='418px' m='auto'>
                        <Select
                            fontFamily='Titillium Web'
                            height='50px'
                            color='#5A5A5ADE'
                            fontSize='18px'
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            placeholder='Select Company'>
                            {companies?.length > 0 && companies?.map((item,index) => (
                                <option
                                    key={index}
                                    value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </Flex>
                    <Flex fontFamily='Titillium Web' width='100%' justifyContent='center' mt={10}>
                        <Button fontSize='18px' bg='#2C628D' width='254px' height='50px' onClick={onClose}>
                            Sync
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default SyncCompany