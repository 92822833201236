import React from "react";
import {Flex,Button, Text,} from "@chakra-ui/react";
import {useHistory} from "react-router-dom";
import {InviteModal} from "../../components/Theme";
import {useSignOut} from "../../core/hooks";

const ExitBidModal = ({isOpen, onClose, route, submitBid}) => {
    const history = useHistory()
    const {signOut} = useSignOut()
    return (
        <InviteModal
            isOpen={isOpen}
            onClose={onClose}
            minW='853px'
            bg="#FF8383"
            title="Leaving So Soon?">
                    <Text fontSize='24px' p='40px 70px' textAlign='center'>
                        Custom Bid Price has not been saved!  To proceed, select Disregard Bid or Submit Bid.
                    </Text>
                    <Flex fontFamily='Titillium Web' width='100%' justifyContent='space-between' mt={10} mb={10} px='80px'>
                        <Button fontSize='18px' bg='#B01919' color='white' width='254px' height='50px' onClick={() => {
                            if (route === 'signOut') {
                                return signOut()
                            }
                            history.push(`/${route.toLowerCase()}`)
                        }}>
                            Disregard Bid
                        </Button>
                        <Button fontSize='18px' bg='#FFE68D' color='black' width='254px' height='50px' onClick={() => {
                            onClose()
                            submitBid()
                        }}>
                            Submit Bid
                        </Button>
                    </Flex>
        </InviteModal>
    )
}
export default ExitBidModal