import { useMutation } from 'react-query'
import httpRequest from "../../services/http-request";

const useInviteUser = () => {
  const {post} = httpRequest()
  const {
    mutate: inviteUser,
    data,
    isLoading,
    error,
  } = useMutation(({ sender, receiver, senderName, senderLastName, url }) => post('invite-user', { sender, receiver, senderName, senderLastName, url }))

  return {
    inviteUser,
    data,
    isLoading,
    error,
  }
}

export default useInviteUser
