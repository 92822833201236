import React, { useState } from 'react'
import {
    Heading,
    Text,
    Flex,
    Grid,
    GridItem,
    Divider,
    Box,
} from '@chakra-ui/react'
import {
    Incrementer,
    ChartInterests,
    IncrementerBubble,
    ChartBubble,
} from '../../components/Theme'
import { PROFILE_INTERESTS, PROFILEFEATURES } from '../../constants'

const Interests = ({ user, setUser, edit = true }) => {
    const initialScore = user.primaryInterests ? JSON.parse(user.primaryInterests) : {
        architecture: 0,
        cyrcadian: 0,
        engineering: 0,
        illumination: 0,
        environment: 0,
        property: 0,
        energy: 0,
        technology: 0,
        education: 0,
        horticulture: 0,
    }

    const initialIllumination = user.profileFeatures ? JSON.parse(user.profileFeatures).illumination : {
        intensity: 0,
        uniformity: 0,
        color_quality: 0,
        color_rendering: 0,
        human_centric: 0,
    }

    const initialTechnology = user.profileFeatures ? JSON.parse(user.profileFeatures).technology : {
        efficiency: 0,
        sensors: 0,
        energy_monitoring: 0,
        system_longevity: 0,
        internet_of_things: 0,
    }
    const initialValuation = user.profileFeatures ? JSON.parse(user.profileFeatures).valuation : {
        flexible_space: 0,
        expense_reduction: 0,
        ['financing/laas']: 0,
        user_experience: 0,
        asset_resilience: 0,
    }
    const initialEnvironment = user.profileFeatures ? JSON.parse(user.profileFeatures).environment : {
        clean_energy: 0,
        eco_design: 0,
        grey_energy: 0,
        green_programs: 0,
        sustainability: 0,
    }
    const [score, setScore] = useState(initialScore)
    const [illumination, setIllumination] = useState(initialIllumination)
    const [technology, setTechnology] = useState(initialTechnology)
    const [valuation, setValuation] = useState(initialValuation)
    const [environment, setEnvironment] = useState(initialEnvironment)

    return (
        <>
            <Heading
                m={0}
                fontSize="xl"
                as="h2">
                Which Subjects Interest You?
            </Heading>
            <Text
                mb={10}
                fontSize="xl">
                Add/subtract 5% increments to build a pie chart profile of your subject matter focus. Focus Earth will share news and reports based on your priorities.
            </Text>
            <Flex
                alignItems="center"
                justifyContent="space-between">
                    <Grid
                        templateColumns="repeat(2, 1fr)"
                        gap={5}>
                        {
                            PROFILE_INTERESTS.map((interest) => (
                                <GridItem
                                    key={interest.title}
                                    colSpan={1}>
                                    <Incrementer
                                        plus={false}
                                        score={score}
                                        setScore={setScore}
                                        title={interest.title}
                                        color={interest.active} />
                                </GridItem>
                            ))
                        }
                    </Grid>
                <ChartInterests score={score} />
            </Flex>
            <Divider
                my={20}
                borderColor="#DADADA"
                border="3px solid"
                orientation="horizontal" />
                <Heading
                    m={0}
                    fontSize="xl"
                    as="h2">
                    Which Elements Of LED Lighting Would You Like To Learn More About?
                </Heading>
                <Text
                    mb={10}
                    fontSize="xl">
                    Add/subtract 5% increments to build a bubble chart of your broader LED interests. In which areas would you most like to contribute or expand your understanding?
                </Text>
                <Flex
                    flexWrap="nowrap"
                    w="100%">
                    <Flex flex={1}>
                        <Flex
                            flex={1}
                            flexDirection="column">
                            <Heading
                                textAlign="center"
                                as="h2"
                                fontSize="xl">
                                Illumination
                            </Heading>
                            {
                                PROFILEFEATURES.illumination.map((feature, index) => (
                                    <IncrementerBubble
                                        plus={false}
                                        type="illumination"
                                        score={illumination}
                                        setScore={setIllumination}
                                        key={feature.title}
                                        title={feature.title}
                                        index={index} />
                                ))
                            }
                        </Flex>
                        <Flex
                            flex={1}
                            flexDirection="column">
                            <Heading
                                textAlign="center"
                                as="h2"
                                fontSize="xl">
                                Technology
                            </Heading>
                            {
                                PROFILEFEATURES.technology.map((feature, index) => (
                                    <IncrementerBubble
                                        plus={false}
                                        type="technology"
                                        score={technology}
                                        setScore={setTechnology}
                                        bl="1px solid rgba(90, 90, 90, 0.87)"
                                        br="1px solid rgba(90, 90, 90, 0.87)"
                                        key={feature.title}
                                        title={feature.title}
                                        index={index} />
                                ))
                            }
                        </Flex>
                    </Flex>
                    <Flex
                        flex={1}>
                        <Flex
                            flex={1}
                            flexDirection="column">
                            <Heading
                                textAlign="center"
                                as="h2"
                                fontSize="xl">
                                Valuation
                            </Heading>
                            {
                                PROFILEFEATURES.valuation.map((feature, index) => (
                                    <IncrementerBubble
                                        plus={false}
                                        type="finance"
                                        score={valuation}
                                        setScore={setValuation}
                                        br="1px solid rgba(90, 90, 90, 0.87)"
                                        key={feature.title}
                                        title={feature.title}
                                        index={index} />
                                ))
                            }
                        </Flex>
                        <Flex
                            flex={1}
                            flexDirection="column">
                            <Heading
                                textAlign="center"
                                as="h2"
                                fontSize="xl">
                                Environment
                            </Heading>
                            {
                                PROFILEFEATURES.environment.map((feature, index) => (
                                    <IncrementerBubble
                                        plus={false}
                                        type="environment"
                                        score={environment}
                                        setScore={setEnvironment}
                                        key={feature.title}
                                        title={feature.title}
                                        index={index} />
                                ))
                            }
                        </Flex>
                    </Flex>
                </Flex>

            <Flex
                mb={24}
                mt={12}
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                w="100%">
                <Flex
                    w="50%"
                    justifyContent="space-between">
                    <Heading
                        mb={6}
                        as="h3"
                        fontSize="xl">
                        Illumination
                    </Heading>
                    <Heading
                        mb={6}
                        as="h3"
                        fontSize="xl">
                        Technology
                    </Heading>
                </Flex>
                <Box
                    height="503px"
                    width="857px">
                    <ChartBubble
                        illumination={illumination}
                        technology={technology}
                        financial={valuation}
                        environment={environment} />
                </Box>
                <Flex
                    w="50%"
                    justifyContent="space-between">
                    <Heading
                        mt={0}
                        as="h3"
                        fontSize="xl">
                        Valuation
                    </Heading>
                    <Heading
                        mt={0}
                        as="h3"
                        fontSize="xl">
                        Environment
                    </Heading>
                </Flex>
            </Flex>
        </>
    )
}

export default Interests
