import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Flex, Text, Box, Avatar, Button, Heading, Grid, GridItem,} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {S3_BUCKET} from '../../constants'
import {ChartInterests, CustomDivider, TextLabel} from '../../components/Theme'
import {ReactComponent as Edit} from "../../assets/edit.svg";
import Interests from "./Interests";
import Loading from "../../components/Loading";
import {useFindUser, useListUsers} from "../../core/hooks";
import mainStyles from "../../styles/mainStyles";
import CommuniactionPreferences from "../../components/User/CommuniactionPreferences";
import {
    AU,
    EU, GENERAL,
    InitialPreferencesAu,
    InitialPreferencesReuSales,
    InitialPreferencesSuEu, InitialPreferencesSuperadmin,
    REU,
    SA,
    SU, USER_LIST_TYPE_COMPANY_USERS
} from "../../constants/user/userEnum";

const UserDetail = () => {
    const [user, setUser] = useState()
    const params = useParams()
    const [users, setUsers] = useState()
    const {data, isLoading} = useFindUser(params.id)
    const [communicationPreferences, setCommunicationPreferences] = useState(InitialPreferencesReuSales)
    const {data: usersResponse} = useListUsers({
        userId: params.id,
        type: USER_LIST_TYPE_COMPANY_USERS,
    })


    useEffect(() => {
        if (!data) return
        setUser(data?.data.data)
        if (data?.data.data?.type === AU){
            setCommunicationPreferences(data?.data.data?.communicationPreferences ? JSON.parse(data?.data.data.communicationPreferences) : InitialPreferencesAu)
        } else if (data?.data.data?.type === REU || data?.data.data?.type === SA){
            setCommunicationPreferences(data?.data.data?.communicationPreferences ? JSON.parse(data?.data.data.communicationPreferences) : InitialPreferencesReuSales)
        }else if (data?.data.data?.type === SU || data?.data.data?.type === EU){
            setCommunicationPreferences(data?.data.data?.communicationPreferences ? JSON.parse(data?.data.data.communicationPreferences) : InitialPreferencesSuEu)
        } else {
            setCommunicationPreferences(data?.data.data?.communicationPreferences ? JSON.parse(data?.data.data.communicationPreferences) : InitialPreferencesSuperadmin)
        }
    }, [data])

    useEffect(() => {
        if (!usersResponse) return
        setUsers(usersResponse.data.data)
    },[usersResponse])

    if (isLoading) return <Loading/>

    return (
        <>
            <Navbar
                minW="1050px"
                title={(
                    <Flex>
                        <Link to="/users">Users</Link>
                        <Text as='h2' m={0}>&nbsp;/&nbsp;{`${user?.firstName} ${user?.lastName}`}</Text>
                    </Flex>
                )}/>
            <Box css={mainStyles.container} px={14} pt={8}>
                <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                        <Avatar
                            size="xl"
                            src={S3_BUCKET + user?.avatar}/>
                        <Box ml={8}>
                            <Heading fontSize="3xl">{user?.firstName}{' '}{user?.lastName}</Heading>
                            <Box mb={6} width="418px">
                                <TextLabel
                                    pl={5}
                                    color='#5A5A5ADE'
                                    bg='transparent'
                                    text={
                                        <Heading m={0} fontSize='xl'>
                                            {user?.type === REU ? 'Real Estate'
                                                : user?.type === SU ? 'Supplier'
                                                    : user?.type === EU ? 'Electrician'
                                                        : user?.type === AU ? 'Admin'
                                                            : user?.type === GENERAL ? 'General User'
                                                                : 'Sales'}
                                        </Heading>}
                                />
                            </Box>
                        </Box>
                    </Flex>
                    <Flex mb={10}>
                        <Link to={`/users/profile/${user?.id}`}>
                            <Button
                                leftIcon={<Edit/>}
                                w="135px"
                                variant="pink">
                                Edit Profile
                            </Button>
                        </Link>
                    </Flex>
                </Flex>
                <CustomDivider/>
                <Flex justifyContent='space-between' alignItems='center'>
                    <Grid templateColumns='repeat(2, 1fr)' gap={8}>
                        <TextLabel
                            minW='300px'
                            bg='transparent'
                            text={user?.firstName}
                            label="First Name:"/>
                        <TextLabel
                            bg='transparent'
                            text={user?.lastName}
                            label="Last Name:"/>
                        <TextLabel
                            bg='transparent'
                            text={user?.title ? user?.title : ''}
                            label="Title:"/>
                        <TextLabel
                            bg='transparent'
                            text={user?.company?.length > 0 ? user?.company[0]?.name : user?.associatedToCompany ? user?.associatedToCompany?.name : ''}
                            label="Company:"/>
                        <TextLabel
                            bg='transparent'
                            text={user?.email ? user?.email : ''}
                            label="Email:"/>
                        <TextLabel
                            bg='transparent'
                            text={user?.phone ? user?.phone : ''}
                            label="Phone:"/>
                        {
                            user?.type === REU || user?.type === EU || user?.type === SA  ? (
                                <GridItem colSpan={2}>
                                    <TextLabel
                                        bg='transparent'
                                        text={user?.invitedToProjects?.map((item) => item.title)}
                                        label="Projects:"/>
                                </GridItem>
                            ) : user?.type === SU ? (
                                <GridItem colSpan={2}>
                                    <TextLabel
                                        bg='transparent'
                                        text={user?.accessory?.filter((fil) => fil.type).map((item) => item.type)}
                                        label="Catalogues:"/>
                                </GridItem>
                            ) : user?.type === AU && users ? (
                                <GridItem colSpan={2}>
                                    <TextLabel
                                        bg='transparent'
                                        text={users?.map((item) => item.username)}
                                        label="Suppliers:"/>
                                </GridItem>
                            ) : ''
                        }
                    </Grid>
                    <Flex alignItems="center" flexDirection="column">
                        {user?.primaryInterests && (
                            <>
                                <Heading fontSize="xl">Primary Interests:</Heading>
                                <ChartInterests
                                    score={JSON.parse(user?.primaryInterests)}/>
                            </>)}
                    </Flex>
                </Flex>
                <CustomDivider/>
                {user?.profileFeatures && (
                    <Interests
                        user={user}
                        setUser={setUser}/>
                )}
                <CustomDivider/>
                <CommuniactionPreferences
                    userType={user?.type}
                    communicationPreferences={communicationPreferences}
                    setCommunicationPreferences={setCommunicationPreferences}
                    readOnly={true}/>
            </Box>
        </>
    )
}

export default UserDetail
