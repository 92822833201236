import {css} from '@emotion/react'

const styles = {
    centeredInput: css `
      & span {
        left: 18px;
      }
      &  input {
        padding-left: 30px;
      }
    `,
    datePicker: css`
        .wrapper-class, .calendar-class {
            margin-top: 7px;
            margin-left: 20px;
            border-radius: 5px;
            height: 50px;
            & input{
                outline: none;
                height: 50px;
                border: 1px solid #A9A9A9;
                font-size: 18px;
                width: 137px;
                border-radius: 6px;
            }
        }
    `
}

export default styles