import React from 'react'
import {Box} from '@chakra-ui/react'
import {Bar} from 'react-chartjs-2'
const colors = [
    '#FF3939',
    '#93D500',
    '#FCFF56',
    '#56FFE1',
    '#5671FF',
    '#193C5B',
]
const ChartLuminaires = ({countUsers}) => {
    const data = {
        labels: ['', '', '', '', '', ''],
        datasets: [
            {
                label: 'LED system',
                // type: 'line',
                data: [
                    countUsers.Eu?.count_c,
                    countUsers.Reu?.count_c,
                    countUsers.Su?.count_c,
                    countUsers.Sa?.count_c,
                    countUsers.Au?.count_c
                ],
                fill: false,
                backgroundColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                borderColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                hoverBackgroundColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                hoverBorderColor: [
                    '#FF3939',
                    '#93D500',
                    '#FCFF56',
                    '#56FFE1',
                    '#5671FF',
                    '#193C5B',
                ],
                barPercentage: 0.3,
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback(value, _index, values) {
                        return value.toLocaleString('en-US')
                    },
                },
            }],
        },
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label(tooltipItem) {
                    return tooltipItem.yLabel
                },
            },
        },
    }

    return (
        <Box
            height="632px"
            width="1037px">
            <Bar
                redraw={true}
                plugins={[
                    {
                        id: 'pluginId',
                        afterDatasetsDraw: (chart) => {
                            const xAxis = chart.scales['x-axis-0'];

                            // Remove existing divs
                            const existingDivs = document.querySelectorAll('.chart-custom-div');
                            existingDivs.forEach(div => div.remove());
                            ['Electricians', 'Real Estate', 'Suppliers', 'Sales', 'Admin', 'General'].forEach((tick, index) => {
                                const x = xAxis.getPixelForTick(index);
                                const chartArea = chart.chartArea;

                                // Create the div
                                const containerDiv = document.createElement('div');
                                containerDiv.className = 'chart-custom-div';
                                containerDiv.style.position = 'absolute';
                                containerDiv.style.left = `${chart.canvas.offsetLeft + x - 50}px`; // Adjust horizontal position
                                containerDiv.style.top = `${chart.canvas.offsetTop + chartArea.bottom + 10}px`; // Adjust vertical position
                                containerDiv.style.display = 'flex';
                                containerDiv.style.alignItems = 'center';

                                // Create the inner HTML content
                                containerDiv.innerHTML = `
                                  <div style="width: 12px; height: 12px;border: ${colors[index] === '#FCFF56' ? '1px solid black' : 'none'}; background: ${colors[index]}; margin-right: 5px; border-radius: 50%"></div>
                                  <div style="color: #000;">${tick}</div>
                                      `;

                                // Append the container div to the chart container
                                chart.canvas.parentNode.appendChild(containerDiv);
                            })
                        },
                    },
                ]}
                options={options}
                data={data}/>
        </Box>
    )
}

export default ChartLuminaires
