const airtableUseCase = (airtableRepo) => {
  const listProducts = async (filter) => {
    const { data } = await airtableRepo.listProducts(filter)
    return data
  }

  const getExisting = (input) => airtableRepo.getExisting(input)

  return {
    listProducts,
    getExisting,
  }
}

export default airtableUseCase
