import { useQuery } from 'react-query'
import { companyUseCase } from '../../factories'

const useListCompanysForSync = (variables) => {
  const {
    refetch: syncListCompanys, isLoading, data, error,
  } = useQuery('companysToSync', () => companyUseCase.syncListCompanys(variables), {enabled: !!variables.companyId})

  return {
    syncListCompanys,
    isLoading,
    error,
    data,
  }
}

export default useListCompanysForSync
