import React, {useEffect, useState} from 'react'
import {useParams, Link, useLocation, useHistory,} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Text, Button, useDisclosure,} from '@chakra-ui/react'
import {
    useUpload,
    useUser,
    useListProducts, useUpdateProduct, useGetProduct,
} from '../../core/hooks'
import Navbar from '../../components/Navbar/Navbar'
import styles from './styles'
import Loading from "../../components/Loading";
import MainFields from "../CreateProduct/MainFields";
import SecondaryFields from "../CreateProduct/SecondaryFields";
import RemoveProjectModal from "./RemoveProductModal";
import {CompatibleProductsModal} from "../../constants/mainFunctions";
import httpRequest from "../../core/services/http-request";

const EditProduct = () => {
    const [certifications, setCertifications] = useState([])
    const [cctColors, setCctColors] = useState([])
    const [orderCodeError, setOrderCodeError] = useState(true)
    const [orderCodesComp, setOrderCodesComp] = useState([])
    const [compatibleProductOrderCodes, setCompatibleProductOrderCodes] = useState([])
    const {register, handleSubmit, getValues,reset, formState: {errors}, watch,} = useForm()
    const [orderCodeHover, setOrderCodeHover] = useState(false)
    const [image, setImage] = useState('')
    const params = useParams()
    const {post} = httpRequest()
    const {upload, isLoading: uploadIsLoading,} = useUpload()
    const location = useLocation()
    const compatibility = watch('compatibility', '')
    const orderCodes = watch('orderCode', '')
    const lampCategory = watch('lampCategory', [])
    const lampBaseCategory = watch('lampBaseCategory', [])
    const {user} = useUser()
    const history = useHistory()
    const [offset, setOffset] = useState(null)
    const [offsetPage, setOffsetPage] = useState(1)
    const [page, setPage] = useState(1)
    const {data: productsResponse} = useListProducts({ condition: {
            filter: '',
            table: params.type === 'fixtures' ? 'FIXTURES' : 'LAMPS',
            page,
            offset,
        }})
    const {updateProduct, isLoading: isLoadingUpdate} = useUpdateProduct(params.id)
    const type = location.pathname.split('/')[3].includes('lamps') ? 'lamp' : location.pathname.split('/')[3]
    const [supplierUser, setSupplierUser] = useState({})
    const [isDeleting, setIsDeleting] = useState(false)
    const {onOpen, isOpen, onClose} = useDisclosure()
    const {isOpen: isOpenProducts, onOpen: onOpenProducts, onClose: onCloseProducts} = useDisclosure()
    const {data: productResponse, isLoading} = useGetProduct({id: params.id, type: params.type})

    const submit = (data) => {
        if (orderCodeError){
            setOrderCodeHover(true)
            return
        }
        const input = {
            ...data,
            image,
            cct: cctColors,
            recordId: params.id,
            certifications: certifications,
            companyId: user?.company?.length > 0 ? user?.company[0]?.id : user?.associatedToCompany?.id,
            compatibleProductOrderCodes: compatibleProductOrderCodes,
            type: params.type === 'fixtures' ? 'fixture' : 'lamp',
        }
        updateProduct(input,
            {
                onSuccess: () => {
                    history.push(`/catalogues/product/${params.type}/${params.id}`)
                },
            },
        )
    }

    // Set compatible product order codes for this user
    useEffect(() => {
        if (!productsResponse) return
        let products = productsResponse.data.data['records'] ?? []
        let compatibleOrderCodes = []

        for (let i = 0; i < products.length; i++) {
            if (type === 'lamp'){
                compatibleOrderCodes.push(products[i].fields['Order Code (calculated)'])
            } else {
                compatibleOrderCodes.push(products[i].fields['Order Code'])
            }
        }
        setOrderCodesComp(compatibleOrderCodes)
    },[productsResponse])

    useEffect(() => {
        if (!productResponse) return
        let product = productResponse[0] ?? []
        setSupplierUser(productResponse[1] ?? {})
        if (product['Image']){
            setImage(product['Image'][0].url)
        }
        if (product['Certifications']){
            setCertifications(product['Certifications'])
        }
        if (product['CCT (K˚)']){
            setCctColors(product['CCT (K˚)']?.map(s => s + "K"))
        }
        reset({
            description: product['FE Product Description'] ? product['FE Product Description']?.toString()?.replace(/(.{70})..+/, "$1…") : "",
            notes: product['Notes'] ? product['Notes'] : '',
            manufacturer: product['Manufacturer'] ? product['Manufacturer'] : '',
            orderCode: product['Order Code'] ? product['Order Code'] : '',
            dimensionsLength: product['Length (inches)'] ? product['Length (inches)'] : '',
            wattage: product['Wattage'] ? product['Wattage'] : '',
            thd: product['THD (%)'] ? product['THD (%)'] : '',
            dimensionsWidth: product['Width (inches)'] ? product['Width (inches)'] : '',
            lumens: product['Lumens'] ? product['Lumens'] : '',
            beamAngle: product['Beam Angle'] ? product['Beam Angle'] : '',
            dimensionsDiameter: product['Diameter (inches)'] ? product['Diameter (inches)'] : '',
            optics: product['Optics'] ? product['Optics'] : '',
            dimensionsDepth: product['Depth (inches)'] ? product['Depth (inches)'] : '',
            cri: product['CRI'] ? product['CRI'] : '',
            application: product['Application'] ? product['Application'].toString() : '',
            lifetime: product['L70 Lifetime (hours)'] ? product['L70 Lifetime (hours)'] : '',
            buyAmerica: product['Buy America'] ? product['Buy America'].toString() : '',
            fixtureStyle: product['Style'] ? product['Style'].toString() : '',
            fixtureWarranty: product['Warranty (years)'] ? product['Warranty (years)'] : '',
            installationTime: product['Approx. Installation Time (minutes)'] ? product['Approx. Installation Time (minutes)'] : '',
            fixtureMount: product['Mount'] ? product['Mount'].toString() : '',
            vMin: product['Voltage (min)'] ? product['Voltage (min)'] : '',
            approxDeliveryDays: product['Approx. Delivery (days)'] ? product['Approx. Delivery (days)'] : '',
            fixtureShape: product['Shape'] ? product['Shape'].toString() : '',
            vMax: product['Voltage (max)'] ? product['Voltage (max)'] : '',
            pricePerUnit: product['Price Per Unit ($)'] ? product['Price Per Unit ($)'] : '',
            lensType: product['Lens Type'] ? product['Lens Type'].toString() : '',
            dimmability: product['Dimmability'] ? product['Dimmability'].toString() : '',
            volumeTier1MinQty: product['Volume Tier 1 (MIN QTY)'] ? product['Volume Tier 1 (MIN QTY)'] : '',
            ingressProtection: product['Ingress Protection'] ? product['Ingress Protection'].toString() : '',
            colorTunable: product['Color Tunable'] ? product['Color Tunable'].toString() : '',
            volumeTier1PPU: product['Volume Tier 1 PPU ($)'] ? product['Volume Tier 1 PPU ($)'] : '',
            fixtureColor: product['Fixture Color'] ? product['Fixture Color'].toString() : '',
            powerFactor: product['Power Factor'] ? product['Power Factor'] : '',
            volumeTier2MinQty: product['Volume Tier 2 (MIN QTY)'] ? product['Volume Tier 2 (MIN QTY)'] : '',
            trim: product['Trim'] ? product['Trim'].toString() : '',
            cBCP: product['CBCP'] ? product['CBCP'] : '',
            volumeTier2PPU: product['Volume Tier 2 PPU ($)'] ? product['Volume Tier 2 PPU ($)'] : '',
            supplierProductDescription: product['Product Description'] ? product['Product Description'] : '',
            installationInstructionsHyperlink: product['Installation Instructions (hyperlink)'] ? product['Installation Instructions (hyperlink)'] : '',
            productSpecificationsHyperlink: product['Specifications (hyperlink)'] ? product['Specifications (hyperlink)'] : '',
            productImageHyperlink: product['Product Image (hyperlink)'] ? product['Product Image (hyperlink)'].toString() : '',
            compatibleProductOrderCodes: product['Compatible Product Order Codes'] ? product['Compatible Product Order Codes'] : '',
            lampCategory: product['Category'] ? product['Category'] : '',
            lampType: product['Type'] ? product['Type'] : '',
            lampBaseCategory: product['Base Category'] ? product['Base Category'] : '',
            lampBaseCategoryType: product['Base Type'] ? product['Base Type'] : '',
        })
        if (product['Compatible Product Order Codes']){
            setCompatibleProductOrderCodes(product['Compatible Product Order Codes'])
        }
    },[productResponse])

    useEffect(() => {
        let codes = orderCodes.split(',')
        let pattern = /^\s*$/
        if (pattern.test(codes[codes?.length -1])) {
            return setOrderCodeError(true)
        }
        if (codes[codes?.length -1] !== '' && codes?.length  === cctColors?.length){
            setOrderCodeError(false)
        } else{
            setOrderCodeError(true)
        }
    },[cctColors, getValues('orderCode')])

    if (isLoading) return <Loading />

    return (
        <form onSubmit={handleSubmit(submit)}>
            <RemoveProjectModal
                productId={params.id}
                isDeleting={isDeleting}
                history={history}
                setIsDeleting={setIsDeleting}
                table={params.type === 'fixtures' ? 'FIXTURES' : 'LAMPS'}
                isOpen={isOpen}
                onClose={onClose}/>
            <CompatibleProductsModal
                isOpen={isOpenProducts}
                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                compatibleProductOrderCodes={compatibleProductOrderCodes}
                onClose={onCloseProducts}
                table={params.type === 'fixtures' ? 'FIXTURES' : 'LAMPS'}/>
            <Navbar minW="650px"
                title={(
                    <Flex>
                        <Link to="/catalogues">Catalogues&nbsp;</Link>
                        /&nbsp;{`Edit ${location.pathname.split('/')[3]}`}
                    </Flex>)}>
                <Button type="submit" w="135px" h="40px" isLoading={isLoading || isLoadingUpdate} variant="lime">
                    <img src="/images/done.png" alt="done"/>
                    <Text fontWeight="700" fontSize="12px" ml={1} mt="2px">Submit</Text>
                </Button>
            </Navbar>
            <Box pb={40} m="auto" minW="650px" maxW="1240px">
                <Tabs css={styles.container} bg="#F5F5F5">
                    <TabList border="none" pt={10} bg="white.100">
                        <Tab minW="208px" fontWeight="700" _active={{background: 'transparent',}} _selected={{color: 'green.200', borderColor: 'green.200', boxShadow: 'none',}} color="green.200">
                            Product Details
                        </Tab>
                    </TabList>
                    <TabPanels pt={6} px={10} borderTop="1px solid #DBDADA" borderBottom="none">
                        <TabPanel>
                            <MainFields
                                setOrderCodeHover={setOrderCodeHover}
                                orderCodeHover={orderCodeHover}
                                showAlert={true}
                                supplierUser={supplierUser}
                                editMode={true}
                                lampCategory={lampCategory}
                                lampBaseCategory={lampBaseCategory}
                                type={type}
                                orderCodeError={orderCodeError}
                                setCctColors={setCctColors}
                                cctColors={cctColors}
                                setCertifications={setCertifications}
                                certifications={certifications}
                                user={user}
                                getValues={getValues}
                                location={location}
                                register={register}
                                errors={errors}
                                image={image}
                                setImage={setImage}
                                upload={upload}/>
                            <SecondaryFields
                                type={type}
                                editMode={true}
                                onOpen={onOpen}
                                onOpenProducts={onOpenProducts}
                                orderCodesComp={orderCodesComp}
                                setCompatibleProductOrderCodes={setCompatibleProductOrderCodes}
                                compatibility={compatibility}
                                compatibleProductOrderCodes={compatibleProductOrderCodes}
                                register={register}/>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </form>
    )
}

export default EditProduct
