import {Heading,VStack,HStack,Stack, Text, Flex, Grid, GridItem, Box,} from '@chakra-ui/react'
import {Incrementer, ChartInterests, IncrementerBubble, ChartBubble, CustomDivider} from '../../../components/Theme'
import { PROFILE_INTERESTS, PROFILEFEATURES } from '../../../constants'

const Interests = (props) =>  (
    <Stack spacing={16} mt={10}>
        <VStack>
            <VStack>
                <Heading m={0} fontSize="xl" as="h2" w='full'>Which Subjects Interest You?</Heading>
                <Text fontSize="xl">
                    Add/subtract 5% increments to build a pie chart profile of your subject matter focus.Focus Earth will share news and reports based on your priorities.
                </Text>
            </VStack>
            <HStack  w='full' justifyContent='space-between'>
                <Grid mt='20px' templateColumns="repeat(2, 1fr)" gap='5'>
                    {PROFILE_INTERESTS.map((interest) => (
                        <GridItem
                            key={interest.title}
                            colSpan={1}>
                            <Incrementer
                                plus={props.edit}
                                score={props.score}
                                setScore={props.setScore}
                                title={interest.title}
                                color={interest.active} />
                        </GridItem>
                    ))}
                </Grid>
                <ChartInterests score={props.score} />
            </HStack>
        </VStack>
        <CustomDivider />
        <VStack>
            <Heading w='full' m={0} fontSize="xl" as="h2">Which Elements Of LED Lighting Would You Like To Learn More About?</Heading>
            <Text fontSize="xl">
                Add/subtract 5% increments to build a bubble chart of your broader LED interests. In which areas would you most like to contribute or expand your understanding?
            </Text>
            <Flex flexWrap="nowrap" w="100%">
                <Flex flex={1}>
                    <Flex flex={1} flexDirection="column">
                        <Heading textAlign="center" as="h2" fontSize="xl">Illumination</Heading>
                        {PROFILEFEATURES.illumination.map((feature, index) => (
                            <IncrementerBubble
                                plus={props.edit}
                                type="illumination"
                                score={props.illumination}
                                setScore={props.setIllumination}
                                key={feature.title}
                                title={feature.title}
                                index={index} />
                        ))}
                    </Flex>
                    <Flex flex={1} flexDirection="column">
                        <Heading textAlign="center" as="h2" fontSize="xl">Technology</Heading>
                        {PROFILEFEATURES.technology.map((feature, index) => (
                            <IncrementerBubble
                                plus={props.edit}
                                type="technology"
                                score={props.technology}
                                setScore={props.setTechnology}
                                bl="1px solid rgba(90, 90, 90, 0.87)"
                                br="1px solid rgba(90, 90, 90, 0.87)"
                                key={feature.title}
                                title={feature.title}
                                index={index} />
                        ))}
                    </Flex>
                </Flex>
                <Flex flex={1}>
                    <Flex flex={1} flexDirection="column">
                        <Heading textAlign="center" as="h2" fontSize="xl">Valuation</Heading>
                        {PROFILEFEATURES.valuation.map((feature, index) => (
                            <IncrementerBubble
                                plus={props.edit}
                                type="finance"
                                score={props.valuation}
                                setScore={props.setValuation}
                                br="1px solid rgba(90, 90, 90, 0.87)"
                                key={feature.title}
                                title={feature.title}
                                index={index} />
                        ))}
                    </Flex>
                    <Flex flex={1} flexDirection="column">
                        <Heading textAlign="center" as="h2" fontSize="xl">Environment</Heading>
                        {PROFILEFEATURES.environment.map((feature, index) => (
                            <IncrementerBubble
                                plus={props.edit}
                                type="environment"
                                score={props.environment}
                                setScore={props.setEnvironment}
                                key={feature.title}
                                title={feature.title}
                                index={index} />
                        ))}
                    </Flex>
                </Flex>
            </Flex>
        </VStack>
        <Flex mb={24} mt={12} flexDirection="column" alignItems="center" justifyContent="center" w="100%">
            <Flex w="50%" justifyContent="space-between">
                <Heading mb={6} as="h3" fontSize="xl">Illumination</Heading>
                <Heading mb={6} as="h3" fontSize="xl">Technology</Heading>
            </Flex>
            <Box height="503px" width="857px">
                <ChartBubble
                    illumination={props.illumination}
                    technology={props.technology}
                    financial={props.valuation}
                    environment={props.environment} />
            </Box>
            <Flex w="50%" justifyContent="space-between">
                <Heading mt={0} as="h3" fontSize="xl">Valuation</Heading>
                <Heading mt={0} as="h3" fontSize="xl">Environment</Heading>
            </Flex>
        </Flex>
    </Stack>
)

export default Interests
