import Login from './Login'
import Report from './Report'
import Register from './Register'
import MainLayout from '../container/MainLayout'
import AuthLayout from '../container/AuthLayout'
import ForgotPassword from './ForgotPassword'
import Reu from './Register/Reu'
import Su from './Register/Su'
import Eu from './Register/El'
import Gu from './Register/Gu'
import Projects from './Projects'
import Project from './Project'
import CreateProject from './CreateProject'
import Contact from './Contact'
import Profile from './Profile'
import EditProfile from './EditProfile'
import Upload from './Upload'
import Catalogues from './Catalogues'
import RecentActivity from './RecentActivity'
import Users from './Users'
import Companies from './Companies'
import ProjectUpload from './ProjectUpload'
import Data from './Data'
import AllProducts from './Catalogues/AllProducts'
import Accessories from './Catalogues/Accessories'
import Products from './Catalogues/Products'
import CreateAccessory from './CreateAccessory'
import EditAccessory from './EditAccessory'
import Accessory from './Accessory'
import Product from './Product'
import Company from './Company'
import CreateProduct from './CreateProduct'
import Proposal from './Proposal'
import UserDetail from './UserDetail'
import CompanyDetail from './CompanyDetail'
import UsersProfile from './UsersProfile'
import EditProduct from './EditProduct'
import EditProject from './EditProject'
import CreateCompany from './CreateCompany'
import EditCompany from './EditCompany'
import EditCompanyAdmin from './CompanyEditAdmin'
import OverviewReport from "../components/OverviewReport";
import EuScope from "./Project/EuProposalScope/EuScope";
import EuIndex from "./EuProjectPropertyDetails/EuIndex";
import SubmitQuestion from "./EuProjectPropertyDetails/SubmitQuestion";
import RecentActivityEu from "./RecentActivityEu";
import AllSet from "../components/AllSet";
import InterierLightingCatalogue from "./Catalogues/InterierLightingCatalogue";
import AccessoriesCatalogue from "./Catalogues/AccessoriesCatalogue";
import LuminairesCatalogue from "./Catalogues/LuminairesCatalogue";
import EstimateSu from "./EstimateSu";
import EstimateReu from "./EstimateReu";
import CompanyDetailForAccept from "./CompanyDetailForAccept";
import OrdersSu from "./OrdersSu";
import OrdersReu from "./OrdersReu";
import CompanyCreateFromAdmin from "./CompanyCreateFromAdmin";
import CompaniesSu from "./Catalogues/CompaniesSu";
import UploadMain from "./UploadMain/UploadMain";
import SuProject from "./SuProject";

const routes = [
    {
        Component: CompaniesSu,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/companies',
    },
    {
        Component: EditCompanyAdmin,
        Layout: MainLayout,
        secured: true,
        path: '/companies/edit-company/:id',
    },
    {
        Component: SuProject,
        Layout: MainLayout,
        secured: true,
        path: '/project-supplier/:id',
    },
    {
        Component: UsersProfile,
        Layout: MainLayout,
        secured: true,
        path: '/users/profile/:id',
    },
    {
        Component: Report,
        Layout: MainLayout,
        secured: true,
        path: '/report/:id/create',
    },
    {
        Component: OverviewReport,
        Layout: MainLayout,
        secured: true,
        path: '/report/:projectId/:id',
    },
    {
        Component: EuScope,
        Layout: MainLayout,
        secured: true,
        path: '/projects/:adress/proposal/:proposalName/:ElectricanName/:projectId/:reportId',
    },
    {
        Component: Login,
        Layout: AuthLayout,
        secured: false,
        path: '/login',
    },
    {
        Component: EuIndex,
        Layout: MainLayout,
        secured: true,
        path: '/project/project-details/:id',
    },
    {
        Component: SubmitQuestion,
        Layout: MainLayout,
        secured: true,
        path: '/project/submit-question/:id/:title/:proposalName',
    },
    {
        Component: ForgotPassword,
        Layout: AuthLayout,
        secured: false,
        path: '/forgot-password',
    },
    {
        Component: AllSet,
        Layout: AuthLayout,
        secured: false,
        path: '/email-confirmed',
    },
    {
        Component: Register,
        Layout: AuthLayout,
        secured: false,
        path: '/register',
    },
    {
        Component: Reu,
        Layout: AuthLayout,
        secured: false,
        path: '/register/reu',
    },
    {
        Component: Su,
        Layout: AuthLayout,
        secured: false,
        path: '/register/su',
    },
    {
        Component: Gu,
        Layout: AuthLayout,
        secured: false,
        path: '/register/gu',
    },
    {
        Component: Eu,
        Layout: AuthLayout,
        secured: false,
        path: '/register/eu',
    },
    {
        Component: RecentActivity,
        Layout: MainLayout,
        secured: true,
        path: '/recent-activity',
    },
    {
        Component: RecentActivityEu,
        Layout: MainLayout,
        secured: true,
        path: '/recent-activity-eu',
    },
    {
        Component: Projects,
        Layout: MainLayout,
        secured: true,
        path: '/projects',
    },
    {
        Component: CreateProject,
        Layout: MainLayout,
        secured: true,
        path: '/projects/create',
    },
    {
        Component: EditProject,
        Layout: MainLayout,
        secured: true,
        path: '/project/edit/:id',
    },
    {
        Component: Project,
        Layout: MainLayout,
        secured: true,
        path: '/project/:id',
    },
    {
        Component: Users,
        Layout: MainLayout,
        secured: true,
        path: '/users',
    },
    {
        Component: UserDetail,
        Layout: MainLayout,
        secured: true,
        path: '/users/:id',
    },
    {
        Component: OrdersSu,
        Layout: MainLayout,
        secured: true,
        path: '/orders/su',
    },
    {
        Component: OrdersReu,
        Layout: MainLayout,
        secured: true,
        path: '/orders/reu',
    },
    {
        Component: EstimateSu,
        Layout: MainLayout,
        secured: true,
        path: '/orders/estimate/su/:id/:type',
    },
    {
        Component: EstimateReu,
        Layout: MainLayout,
        secured: true,
        path: '/orders/estimate/reu/:id/:type',
    },
    {
        Component: Contact,
        Layout: MainLayout,
        secured: true,
        path: '/contact',
    },
    {
        Component: Profile,
        Layout: MainLayout,
        secured: true,
        path: '/profile',
    },
    {
        Component: EditProfile,
        Layout: MainLayout,
        secured: true,
        path: '/profile/edit',
    },
    {
        Component: EditCompany,
        Layout: MainLayout,
        secured: true,
        path: '/edit-company/:id',
    },
    {
        Component: Upload,
        Layout: MainLayout,
        secured: true,
        path: '/catalogue/upload',
    },
    {
        Component: UploadMain,
        Layout: MainLayout,
        secured: true,
        path: '/upload',
    },

    {
        Component: Catalogues,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues',
    },
    {
        Component: AllProducts,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/all-products',
    },
    {
        Component: Accessories,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/accessories',
    },
    {
        Component: Accessories,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/controls',
    },
    {
        Component: Accessories,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/power-supplies',
    },
    {
        Component: Products,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/fixtures',
    },
    {
        Component: Products,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/lamps',
    },
    {
        Component: InterierLightingCatalogue,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/LEDs Inc/Interior Luminaires',
    },
    {
        Component: AccessoriesCatalogue,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/LEDs Inc./Accessories',
    },
    {
        Component: LuminairesCatalogue,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/LEDs Inc./Luminaires',
    },
    {
        Component: CreateAccessory,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/create-accessory/:type',
    },
    {
        Component: EditAccessory,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/edit-accessory/:type/:id',
    },
    {
        Component: CreateProduct,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/create-product/:type',
    },
    {
        Component: EditProduct,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/edit-product/:type/:id',
    },
    {
        Component: CompanyDetail,
        Layout: MainLayout,
        secured: true,
        path: '/companies/:id',
    },
    {
        Component: CompanyCreateFromAdmin,
        Layout: MainLayout,
        secured: true,
        path: '/companies/create-company/:type',
    },
    {
        Component: CompanyDetailForAccept,
        Layout: MainLayout,
        secured: true,
        path: '/companies/company-details/:id',
    },
    {
        Component: Product,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/product/:type/:id',
    },
    {
        Component: Accessory,
        Layout: MainLayout,
        secured: true,
        path: '/catalogues/accessory/:type/:id',
    },
    {
        Component: Company,
        Layout: MainLayout,
        secured: true,
        path: '/company',
    },
    {
        Component: Companies,
        Layout: MainLayout,
        secured: true,
        path: '/companies',
    },
    {
        Component: Data,
        Layout: MainLayout,
        secured: true,
        path: '/data',
    },
    {
        Component: Proposal,
        Layout: MainLayout,
        secured: true,
        path: '/projects/proposal/:proposalName/:projectId/:reportId',
    },
    {
        Component: ProjectUpload,
        Layout: MainLayout,
        secured: true,
        path: '/upload-files',
    },
    {
        Component: CreateCompany,
        Layout: MainLayout,
        secured: true,
        path: '/create-company',
    },
]

export default routes
