import {
    FormControl, Checkbox, FormLabel, Menu, MenuButton, Flex, MenuList, Box, Text, Divider
} from '@chakra-ui/react'
import React from 'react'
import styles from './styles'
import {ReactComponent as ArrowDown} from '../../../assets/downArrow.svg'

const CustomSelectNames = ({label, value, optionsSa,optionsReu, setValue, setNames, names}) => {
    return (
        <FormControl css={styles.menu}>
            <FormLabel fontWeight="600" fontSize="18px">{label}</FormLabel>
            <Menu>
                <MenuButton as={Flex} px={4} position='relative' alignItems="center" border="1px solid" overflow="scroll" borderColor="rgba(169, 169, 169, 0.87)" borderRadius="5px" bg="white.100" height="50px">
                    <Text whiteSpace="nowrap" fontSize="xl">
                        {(() => {
                                if (value.length > 0) {
                                    let a = []
                                    for (let i = 0; i < value.length; i++) {
                                        a.push(value[i] + ', ')
                                    }
                                    if (a) {
                                        let res = a[a.length - 1].slice(0, -2)
                                        a.pop()
                                        a.push(res)
                                    }
                                    return a
                                }
                            }
                        )()}
                    </Text>
                    <Flex alignItems='center' height="50px" top='0' right='15px' position='absolute'>
                        <ArrowDown />
                    </Flex>
                </MenuButton>
                <MenuList width='100% !important' pl={2}>
                    <Flex flexDirection='column'>
                        <Flex alignItems='center' justifyContent='space-between' width='100% !important'>
                            <Box borderRadius='50%' bg='#C956FF' height='9px !important' width='12px !important'/>
                            <Text fontWeight='300' fontSize='14px' fontFamily='Web Titillium' ml={3} width='40%'>Real Estate</Text>
                            <Divider borderColor='#000000' border='1px solid'/>
                        </Flex>
                        {optionsReu?.map((item) => (
                                <Box width='100% !important' key={item[1]}>
                                    <Checkbox
                                        isChecked={value?.includes(item[0])}
                                        name={item[0]}
                                        onChange={(e) => {
                                            let s = []
                                            let ides = []
                                            if (e.target.checked) {
                                                s.push(item[0]);
                                                ides.push(item[1]);
                                                ides = ides.concat(names)
                                                s = s.concat(value)
                                            } else {
                                                let index = value.indexOf(item)
                                                value.splice(index, 1)
                                                names.splice(index, 1)
                                                s = s.concat(value);
                                                ides = ides.concat(names);
                                            }
                                            setNames(ides)
                                            setValue(s)
                                        }}
                                        iconColor="black"
                                        colorScheme="whiteAlpha">
                                        {item[0]}
                                    </Checkbox>
                                </Box>
                            ))
                        }
                        <Flex mt={1} alignItems='center' justifyContent='space-between' width='100% !important'>
                            <Box borderRadius='50%' bg='#81CD61' height='9px !important' width='12px !important'/>
                            <Text fontWeight='300' fontSize='14px' fontFamily='Web Titillium' ml={3} width='20%'>Sales</Text>
                            <Divider borderColor='#000000' border='1px solid'/>
                        </Flex>
                        {optionsSa?.map((item) => (
                                <Box width='100% !important' key={item[1]}>
                                    <Checkbox
                                        isChecked={value?.includes(item[0])}
                                        name={item[0]}
                                        onChange={(e) => {
                                            let s = []
                                            let ides = []
                                            if (e.target.checked) {
                                                s.push(item[0]);
                                                ides.push(item[1]);
                                                ides = ides.concat(names)
                                                s = s.concat(value)
                                            } else {
                                                let index = value.indexOf(item)
                                                value.splice(index, 1)
                                                names.splice(index, 1)
                                                s = s.concat(value);
                                                ides = ides.concat(names);
                                            }
                                            setNames(ides)
                                            setValue(s)
                                        }}
                                        iconColor="black"
                                        colorScheme="whiteAlpha">
                                        {item[0]}
                                    </Checkbox>
                                </Box>
                            ))
                        }
                    </Flex>
                </MenuList>
            </Menu>
        </FormControl>
    )
}

export default CustomSelectNames
