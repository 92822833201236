import {Button,Flex, Box, Heading, Text, Image,VStack, HStack} from '@chakra-ui/react'
import React, {useRef} from 'react'
import {ChartUserComplete} from '../../../components/Theme'
import {useUser} from '../../../core/hooks'
import {ReactComponent as UploadIcon} from '../../../assets/uploadPhoto.svg'
import {ReactComponent as Password} from '../../../assets/password.svg'
import styles from "../styles";

const Header = ({avatar, setAvatar,setValue, upload, complete, onOpen, processing}) => {
    const {user} = useUser()
    const inputRef = useRef()

    const handleAvatar = (e) => {
        const file = e.target.files[0]
        const blob = URL.createObjectURL(file)
        const type = file.type.split('/')[1] || 'jpeg' || 'png'
        const name = `${new Date().getTime() * Math.random()}.${type}`
        setAvatar(blob)
        upload({
            name,
            file,
            type
        }, {
            onSuccess: (data) => {
                setValue('avatar', data.data.data)
            }
        })
    }

    return (
        <Flex css={styles.profileHeader}>
            <HStack className='header-main' spacing='10'>
                <Flex className='header-image'>
                    {avatar && (
                        <Image src={avatar} alt="user avatar" objectFit="cover"/>
                    )}
                </Flex>
                <VStack justifyContent='space-between'>
                    <Heading w='full' fontSize='3xl' m='0' as="h2">{user?.firstName}{' '}{user?.lastName}</Heading>
                    <Box>
                        <input value={undefined} onChange={handleAvatar} type="file" style={{display: 'none'}} accept="image/*" ref={inputRef}/>
                        <Button
                            color='black'
                            bg='#8FCF74'
                            height='28px'
                            w='150px'
                            leftIcon={<UploadIcon/>}
                            onClick={() => inputRef.current.click()}>
                            {processing ? 'Processing ...' : 'Upload Photo'}
                        </Button>
                        <Button
                            onClick={onOpen}
                            leftIcon={<Password/>}
                            color="black"
                            height='28px'
                            width="150px"
                            bg="#FD746B"
                            ml="10px">
                            Change Password
                        </Button>
                    </Box>
                </VStack>
            </HStack>
            <Flex className='header-chart'>
                <Text>{complete[0]}% Completed</Text>
                <ChartUserComplete complete={complete}/>
            </Flex>
        </Flex>
    )
}

export default Header
