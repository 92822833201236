import {css} from '@emotion/react'

const styles = {
    container: css`
      min-height: calc(100vh - 60px);
      width: 100%;
      flex-direction: column;
      border-right: 1px solid #DBDADA;
      border-left: 1px solid #DBDADA;
      position: relative;
    `,
    menu: css`
      & > div:nth-of-type(2) {
        width: 100%;
      }
    `,
}

export default styles
