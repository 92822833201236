import {useMutation} from 'react-query'
import {uploadUseCase} from '../../factories'

const useCreateFile = () => {
    const {
        mutate: newFile, isLoading: isLoadingFile, data, error,
    } = useMutation(uploadUseCase.newFile)

    return {
        newFile,
        isLoadingFile,
        error,
        data,
    }
}

export default useCreateFile
