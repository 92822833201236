import React from "react";
import {Box} from "@chakra-ui/react";
import ShippingMain from "./ShippingPages/ShippingMain";
import ShippingViewDetails from "./ShippingPages/ShippingViewDetails";

const Shipping = ({
                      refererShipping,
                      shippingPageStatus,
                      setShippingPageStatus,
                      estimate,
                      luminaires,
                      trackId,
                      setEdit,
                      edit,
                      callback,
                      editShippingAllow,
                      setEditShippingAllow,
                      getEstimate,
                      setLoading,
                      notes,
                      onOpenDelivered,
                      setTrackId,
                      companySu,
                      companyReu,
                  }) => {

    return (
        <Box ref={refererShipping} display={estimate?.shippingDateCreated ? 'block' : 'none'} backgroundColor='white'
             p={9}>
            {shippingPageStatus === 3 ? (
                <ShippingViewDetails
                    editShippingAllow={editShippingAllow}
                    setLoading={setLoading}
                    getEstimate={getEstimate}
                    setEditShippingAllow={setEditShippingAllow}
                    edit={edit}
                    setEdit={setEdit}
                    trackId={trackId}
                    callback={(e) => {
                        callback(e)
                    }}
                    setShippingPageStatus={setShippingPageStatus}
                    estimate={estimate}
                    luminaires={luminaires}/>
            ) : <ShippingMain
                notes={notes}
                onOpenDelivered={onOpenDelivered}
                companySu={companySu}
                companyReu={companyReu}
                setTrackId={setTrackId}
                shippingPageStatus={shippingPageStatus}
                setShippingPageStatus={setShippingPageStatus}
                estimate={estimate}
                luminaires={luminaires}/>
            }
        </Box>
    )
}

export default Shipping