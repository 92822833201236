import {InviteModal} from "../../../components/Theme";
import {Button, HStack, Text, VStack} from "@chakra-ui/react";

const DeleteConfirmationModal = ({removeUserFromCompany, isOpen, onClose, userId, loading}) => (
    <InviteModal
        isOpen={isOpen}
        onClose={onClose}
        bg="#FF9494"
        title="Sever Company Connection">
        <VStack py={8} maxW='618px' m='auto' spacing={16}>
            <Text fontSize='3xl' textAlign='center'>
                Are you sure you want to sever Company for this user?  It will disconnect this user from their existing Projects.
            </Text>
            <HStack pb={8} justifyContent='space-between' w='full'>
                <Button onClick={onClose} height='50px' fontSize='xl' width='254px' color='black' bg='#DBDADA'>
                    Nevermind go back
                </Button>
                <Button onClick={() => removeUserFromCompany(userId)} disabled={!userId} isLoading={loading} height='50px' fontSize='xl' width='254px' color='black' bg='#8FCF74'>
                    Yes
                </Button>
            </HStack>
        </VStack>
    </InviteModal>
)

export default DeleteConfirmationModal