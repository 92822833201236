import React from 'react'
import {Link} from 'react-router-dom'
import {Text, Heading, Stack, Button as TextButton, Container} from '@chakra-ui/react'
import {Button} from '../Theme'

const AllSet = () => (
    <Container position="relative" display="flex" alignItems="center" flexDirection="column" mx={4} py={12} mt={10} maxW="6xl" borderRadius="10px" boxShadow="0px 4px 10px rgba(34, 34, 34, 0.25)" bg="rgba(255, 255, 255, 0.8)">
        <Stack mb={10} spacing={8} alignItems="center" flexDirection="column" justifyContent="space-between">
            <Heading textAlign="center" mt={-5} mb={3} as="h2" fontSize="4xl">
                <Link to="/register">
                    <TextButton fontWeight="600" variant="transparent" position="absolute" top="30px" left="30px">
                        <img width="25px" src="/images/back_register.png" alt="back arrow"/>
                        <Text ml={1} fontSize="18px" fontWeight='600'>
                            BACK
                        </Text>
                    </TextButton>
                </Link>
                Ready To Go!
            </Heading>
            <img style={{height: '95px', width: '95px',}} src="/images/register_confirm.png" alt="all set"/>
            <Text textAlign="center" fontWeight="600" fontSize="24px">
                Email verified! Login below to begin exploring....
            </Text>
            <Link to="/login">
                <Button mt={10} colorScheme="blue.200" type="submit" height="40px !important" width="467px">
                    <img width="25px" src="/images/login.svg" alt="Energy"/>
                    <Text ml={1} color="white" fontWeight="700" fontSize="18px">
                        Login
                    </Text>
                </Button>
            </Link>
        </Stack>
    </Container>
)

export default AllSet
