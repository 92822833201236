import { useQuery } from 'react-query'
import { notificationUseCase } from '../../factories'

const useListNotifications = (variables) => {
  const {
    refetch: listNotifications,
    isLoading,
    data,
    error,
  } = useQuery(['notifications', variables], () => notificationUseCase.listNotifications(variables))

  return {
    listNotifications,
    isLoading,
    error,
    data,
  }
}

export default useListNotifications
