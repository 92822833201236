import {Button, HStack, Select, Grid, GridItem, FormControl, FormLabel} from '@chakra-ui/react'
import {InputLabel, TextareaLabel, TextLabel} from "../../../components/Theme";
import {ReactComponent as Edit_Modal} from "../../../assets/edit_modal.svg";
import {STATES} from "../../../constants/serviceableAreas";


const Details = ({companyChanges, errors, register,company, onOpenView}) => (
    <Grid templateColumns='repeat(2, 1fr)' gap={8}>
        <InputLabel
            color={companyChanges?.includes('address') ? 'orange' : ''}
            changeactive={companyChanges?.includes('address')}
            isInvalid={!!errors.address}
            {...register('address', {required: true})}
            label="Address:"/>
        <InputLabel
            color={companyChanges?.includes('address2') ? 'orange' : ''}
            changeactive={companyChanges?.includes('address2')}
            isInvalid={!!errors.address2}
            {...register('address2', {required: true})}
            label="Address Line 2:"/>
        <HStack spacing={8} justifyContent='space-between'>
            <InputLabel
                color={companyChanges?.includes('city') ? 'orange' : ''}
                changeactive={companyChanges?.includes('city')}
                isInvalid={!!errors.city}
                {...register('city', {required: true})}
                label="City:"/>
            <FormControl maxW='105px'>
                <FormLabel fontFamily='Titillium Web' fontWeight="600" fontSize="md" color="blue.200">
                    State:
                </FormLabel>
                <Select
                    color={companyChanges?.includes('state') ? 'orange' : ''}
                    changeactive={companyChanges?.includes('state')}
                    label="State:"
                    height='50px'
                    border="1px solid #C4C4C4 !important"
                    name="state"
                    bg="white" isInvalid={!!errors.state}
                    {...register('state', {required: true})}>
                    {STATES.map((item) => (<option key={item} value={item}>{item}</option>))}
                </Select>
            </FormControl>
            <InputLabel
                maxW='105px'
                color={companyChanges?.includes('zipCode') ? 'orange' : ''}
                changeactive={companyChanges?.includes('zipCode')}
                isInvalid={!!errors.zipCode}
                {...register('zipCode', {required: true})}
                label="Zip Code:"/>
        </HStack>
        <InputLabel
            color={companyChanges?.includes('country') ? 'orange' : ''}
            changeactive={companyChanges?.includes('country')}
            isInvalid={!!errors.country}
            {...register('country', {required: true})}
            label="Country:"/>
        <TextLabel
            labelMargin={'0 0 5px 0'}
            label='Associated Users:'
            rightAddon={ <Button
                w="24px"
                height="24px"
                backgroundColor="#FFE68D"
                onClick={onOpenView}>
                <Edit_Modal/>
            </Button>}
            text={(company?.owner ? company?.owner?.firstName + ' ' + company?.owner?.lastName + ' ' : '') +company?.usersAssociated?.map((item) => item.firstName + ' ' + item.lastName).toString()}/>
        <InputLabel
            color={companyChanges?.includes('website') ? 'orange' : ''}
            changeactive={companyChanges?.includes('website')}
            isInvalid={!!errors.website}
            {...register('website', {required: true})}
            label="Website:"/>
        <GridItem colSpan={2}>
            <TextareaLabel
                color={companyChanges?.includes('notes') ? 'orange' : ''}
                changeactive={companyChanges?.includes('notes')}
                isInvalid={!!errors.notes}
                {...register('notes', {required: true})}
                label="Notes:"/>
        </GridItem>
    </Grid>

)

export default Details