import { HStack, Grid, GridItem,} from '@chakra-ui/react'
import {TextareaLabel} from "../../../components/Theme";
import TextLabel from "../../../components/Theme/TextLabel";

const Details = ({companyChanges, company}) => (
    <Grid templateColumns='repeat(2, 1fr)' gap={8}>
        <TextLabel
            bg='#FFFFFF00'
            color={companyChanges?.includes('address') ? 'orange' : ''}
            changeactive={companyChanges?.includes('address')}
            text={company?.address}
            label="Address:"/>
        <TextLabel
            bg='#FFFFFF00'
            color={companyChanges?.includes('address2') ? 'orange' : ''}
            changeactive={companyChanges?.includes('address2')}
            text={company?.address2}
            label="Address Line 2:"/>
        <HStack spacing={6} justifyContent='space-between'>
            <TextLabel
                w='228px'
                bg='#FFFFFF00'
                color={companyChanges?.includes('city') ? 'orange' : ''}
                changeactive={companyChanges?.includes('city')}
                text={company?.city}
                label="City:"/>
            <TextLabel
                w='105px'
                bg='#FFFFFF00'
                color={companyChanges?.includes('city') ? 'orange' : ''}
                changeactive={companyChanges?.includes('city')}
                text={company?.state}
                label="State:"/>
            <TextLabel
                w='105px'
                bg='#FFFFFF00'
                color={companyChanges?.includes('zipCode') ? 'orange' : ''}
                changeactive={companyChanges?.includes('zipCode')}
                text={company?.zipCode}
                label="Zip Code:"/>
        </HStack>
        <TextLabel
            bg='#FFFFFF00'
            color={companyChanges?.includes('country') ? 'orange' : ''}
            changeactive={companyChanges?.includes('country')}
            text={company?.country}
            label="Country:"/>
        <TextLabel
            bg='#FFFFFF00'
            label='Associated Users:'
            text={(company?.owner ? company?.owner?.firstName + ' ' + company?.owner?.lastName + ', ' : '') + company?.usersAssociated?.map((item) => item.firstName + ' ' + item.lastName).toString()}/>
        <TextLabel
            bg='#FFFFFF00'
            text={company?.website}
            color={companyChanges?.includes('website') ? 'orange' : ''}
            changeactive={companyChanges?.includes('website')}
            label="Website:"/>
        <GridItem colSpan={2}>
            <TextareaLabel
                bg='#FFFFFF00 !important'
                color={companyChanges?.includes('notes') ? 'orange' : ''}
                changeactive={companyChanges?.includes('notes')}
                readOnly={true}
                value={company?.notes}
                label="Notes:"/>
        </GridItem>
    </Grid>

)

export default Details