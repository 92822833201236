import React from 'react'
import {Pie} from 'react-chartjs-2'
import {Flex} from '@chakra-ui/react'

const UsersCountChart = ({score, width, height, type}) => {
    const {
        Reu,
        Su,
        Sa,
        Eu,
        Au,
    } = score

    const options = {
        maintainAspectRatio: false,
        animation: false,
        responsive: true,
        tooltips: {
            callbacks: {
                title(tooltipItem) {
                    if (Object.keys(score)[tooltipItem[0].index] === 'Reu'){
                        return 'Real Estate'
                    } else if (Object.keys(score)[tooltipItem[0].index] === 'Su'){
                        return 'Supplier'
                    }else if (Object.keys(score)[tooltipItem[0].index] === 'Eu'){
                        return 'Electrician'
                    }else if (Object.keys(score)[tooltipItem[0].index] === 'Sa'){
                        return 'Sales'
                    } else {
                        return 'Administrator'
                    }
                },
                label(tooltipItem, newLabel) {
                    return `${newLabel['datasets'][0]['data'][tooltipItem['index']]}`
                },
            },
        },
        hover: {mode: null},
    }
    const data = {
        datasets: [
            {
                label: '# of Votes',
                data: [
                    (type === 1 ? Reu?.count_c : Reu?.sum_total),
                    (type === 1 ? Su?.count_c : Su?.sum_total),
                    (type === 1 ? Sa?.count_c : Sa?.sum_total),
                    (type === 1 ? Eu?.count_c : Eu?.sum_total),
                    (type === 1 ? Au?.count_c : Au?.sum_total),
                ],
                backgroundColor:
                    [
                        '#C956FF',
                        '#FFD439',
                        '#8FCF74',
                        '#FF7F56',
                        '#D1D1D1',
                    ],
                borderWidth: 0,
            },
        ],
    }

    return (
        <Flex
            width={width ? width : "288px"}
            height={height ? height : "288px"}>
            <Pie
                width={1000}
                height={1000}
                options={options}
                data={data}
                redraw/>
        </Flex>
    )
}

export default UsersCountChart
