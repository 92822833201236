
const UploadHistoryService = () => {
  // const createUploadHistory = (input) => API.graphql(
  //   graphqlOperation(createUploadHistorytMutation, { input }),
  // )
  //
  // const updateUploadHistory = (input) => API.graphql(
  //   graphqlOperation(updateUploadHistoryMutation, { input }),
  // )
  // const deleteUploadHistory = (input) => API.graphql(
  //   graphqlOperation(deleteUploadHistoryMutation, { input }),
  // )
  //
  // const getUploadHistory = (id) => API.graphql(
  //   graphqlOperation(getUploadHistoryQuery, { id }),
  // )
  //
  // const listUploadHistories = (variables) => API.graphql(
  //   graphqlOperation(listUploadHistoriesQuery, variables),
  // )

  return {
    // createUploadHistory,
    // updateUploadHistory,
    // getUploadHistory,
    // listUploadHistories,
    // deleteUploadHistory,
  }
}

export default UploadHistoryService
