import {useQuery} from 'react-query'
import {airtableUseCases} from '../../factories'

const useGetExisting = (variables) => {
  const {
    refetch, isLoading, data, error
  } = useQuery('existingLamp', () => airtableUseCases.getExisting(variables), {enabled: false,refetchOnWindowFocus: false})

  return {data, refetch, isLoading, error}
}

export default useGetExisting
