import { useQuery } from 'react-query'
import { companyUseCase } from '../../factories'

const useGetCompany = (id) => {
  const {
    refetch, isLoading, data: company, error,
  } = useQuery('company', () => companyUseCase.getCompany(id))

  return {
    refetch,
    isLoading,
    error,
    company,
  }
}

export default useGetCompany
