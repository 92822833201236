import {Box, Flex, Heading} from "@chakra-ui/react";
import ChartProjects from "../DataCharts/ChartProjects";


const DataChart = (props) => (
    <Box>
        <Flex
            mt={8}
            borderRadius='10px'
            pl={4}
            alignItems='center'
            minH='57px'
            bg='#E6E6E6'>
            <Heading
                m={0}
                fontSize='24px'>
                {props?.header}
            </Heading>
        </Flex>
        <Flex
            mt={6}
            height="100%"
            alignItems="center"
            justifyContent="space-evenly">
            <Flex
                fontFamily='Titillium Web'
                fontSize='16px'
                style={{writingMode: 'tb-rl'}}
                justifyContent='center'
                alignItems='center'
                transform='rotate(-180deg)'>
                {props?.chartLeftSide}
            </Flex>
            <ChartProjects
                bottomText={props?.bottomText ?? 'Companies'}
                projects={props?.data}/>
        </Flex>
    </Box>
)

export default DataChart