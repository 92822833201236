const FIXTURE_TYPE = 1;
const LAMP_TYPE = 2;
const POWER_SUPPLY_TYPE = 3;
const CONTROL_TYPE = 4;
const ACCESSORY_TYPE = 5;

const PRODUCTS_TYPES = {
    [FIXTURE_TYPE] : 'Fixtures',
    [LAMP_TYPE] : 'Lamps',
    [POWER_SUPPLY_TYPE] : 'Power%20Supplies',
    [CONTROL_TYPE] : 'Controls',
    [ACCESSORY_TYPE] : 'Accessories',
}

const PRODUCT_JSON = {
    description: '',
    image: '',
    manufacturer: '',
    orderCode: '',
    dimensionsLength: '',
    wattage: '',
    thd: '',
    dimensionsWidth: '',
    lumens: '',
    beamAngle: '',
    dimensionsDiameter: '',
    optics: '',
    dimensionsDepth: '',
    cri: '',
    application: '',
    lifetime: '',
    buyAmerica: '',
    fixtureStyle: '',
    fixtureWarranty: '',
    installationTime: '',
    fixtureMount: '',
    vMin: '',
    approxDeliveryDays: '',
    fixtureShape: '',
    vMax: '',
    pricePerUnit: '',
    lensType: '',
    dimmability: '',
    volumeTier1MinQty: '',
    ingressProtection: '',
    colorTunable: '',
    volumeTier1PPU: '',
    fixtureColor: '',
    powerFactor: '',
    volumeTier2MinQty: '',
    trim: '',
    cBCP: '',
    volumeTier2PPU: '',
    supplierProductDescription: '',
    installationInstructionsHyperlink: '',
    productSpecificationsHyperlink: '',
    productImageHyperlink: '',
    lampCategory: '',
    lampType: '',
    baseType: '',
    lampBaseCategory: '',
    lampBaseCategoryType: '',
}

export {
    FIXTURE_TYPE,
    LAMP_TYPE,
    POWER_SUPPLY_TYPE,
    CONTROL_TYPE,
    ACCESSORY_TYPE,
    PRODUCTS_TYPES,
    PRODUCT_JSON
}