import React, {useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {
    Text,
    Heading,
    SimpleGrid,
    GridItem,
    Flex,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverBody,
    Box,
    InputGroup,
    InputLeftElement,
    Input,
} from '@chakra-ui/react'
import {useListProducts} from '../../core/hooks'
import {Card, Checkbox, Icon,} from '../../components/Theme'
import {ReactComponent as Search} from "../../assets/search.svg";
import Navbar from "../../components/Navbar";
import LoadingSmall from "../../components/LoadingSmall";
import Loading from "../../components/Loading";
import './styles.css'


const FILTERS = [
    'Fixture',
    'Lamp',
    'Power-Supply',
    'Control',
    'Accessory',
]

const AllProducts = () => {
    const [products, setProducts] = useState()
    const [search, setSearch] = useState('')
    const location = useLocation()
    const [filter, setFilter] = useState([])
    const [filters, setFilters] = useState({
        search: '',
        filter: '',
        page: 1,
        offsets: [],
    })
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory()
    let condition = {
        condition: {
            filter: filters.filter,
            table: filter.length === 0 ? 'All' : filter,
            page: filters.page,
            offsets: filters.offsets,
            search,
        }
    }
    const {data: productsResponse, isLoading: isProductLoading,isFetching, refetch} = useListProducts(condition)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                page: 1,
                offsets: []
            }));
            if (search.length > 2 || search.length === 0) {
                refetch()
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [search, refetch]);

    useEffect(() => {
        if (search?.length > 2 || search.length === 0){
            setFilters((prevFilters) => ({
                ...prevFilters,
                page: 1,
                offsets: []
            }));
            const timer = setTimeout(() => {
                refetch();
            }, 1000);

            return () => clearTimeout(timer);
        }
    },[search])

    const handleCheckbox = (e) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            page: 1,
            offsets: []
        }));
        if (filter.includes(e)) {
            return setFilter(filter.filter((name) => name !== e))
        }
        setFilter([...filter, e])
    }

    useEffect(() => {
        refetch()
    }, [filter, refetch])

    useEffect(() => {
        if (!productsResponse) return
        if (filters.page === 1){
            setProducts(productsResponse.data.data?.records ?? [])
        } else {
            setProducts((prevProducts) => [
                ...prevProducts,
                ...productsResponse.data.data?.records ?? [],
            ]);
        }
        setFilters((prevState) => ({
           ...prevState,
           offsets: productsResponse.data.data?.offsets ?? ""
        }))
        setIsLoading(false);
    }, [productsResponse])

    const loadMoreProducts = () => {
        if (isLoading) return;
        if (filters.page >= 2 && filters.offsets.length === 0) return
        setIsLoading(true);
        refetch()
        setFilters((prevState) => ({
            ...prevState,
            page: prevState.page + 1
        }))
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;

            if (scrollPosition >= pageHeight * 0.5 && !isLoading) {
                loadMoreProducts();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoading]);

    if (isProductLoading) return <Loading />

    return (
        <>
            <Navbar title={(
                <Flex>
                    <Link as='h2' m={0} to="/catalogues">Catalogues</Link>
                    <Text as='h2' m={0} textTransform="capitalize">&nbsp;/{' '}All Products</Text>
                </Flex>
            )}>
                <Flex>
                    {location.pathname === '/catalogues/all-products' && (
                        <Popover>
                            <PopoverTrigger>
                                <Button leftIcon={<Icon type="filter"/>} mr={12} variant="lightBlue" size="sm"
                                        minW="139px">
                                    <Text ml={2} fontSize="sm">Filter</Text>
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent bg="white" zIndex="500" p={6} w="190px" pr={0} flexDirection="row" _focus={{outline: 'none'}}>
                                <PopoverArrow/>
                                <PopoverBody
                                    display="flex"
                                    flexDirection="column">
                                    {FILTERS.map((item, index) => (
                                        <Checkbox isDisabled={isFetching} key={index} onChange={(e) => handleCheckbox(e.target.name)} name={item} colorScheme="black" iconColor="black">
                                            <Box ml={2} fontSize="sm" as="span">{item}</Box>
                                        </Checkbox>
                                    ))}
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    )}
                    <InputGroup alignItems="center" height="29px" borderRadius="5px" _focus={{borderColor: 'blue.200',}} _hover={{borderColor: 'blue.200',}} borderColor="blue.200" bg="blue.200">
                        <InputLeftElement height="29px" pointerEvents="none" children={(<Search height="16px" width="16px"/>)}/>
                        <Input
                            onChange={(e) => setSearch(e.target.value)}
                            _focus={{borderColor: 'blue.200',}} _hover={{borderColor: 'blue.200',}} height="29px" color="white.100" placeholder="Search"/>
                    </InputGroup>
                </Flex>
            </Navbar>
                <SimpleGrid p="12" w="full" columns={{base: 1, sm: 2, md: 3, lg: 4,}} spacing="8">
                    {products?.map((product, index) => (
                        <GridItem
                            onClick={() => history.push(`/catalogues/product/fixtures/${product.id}`)}
                            colSpan={1}
                            key={index}>
                            <Card
                                image={product['fields']['Image'] ? product['fields']['Image'][0]?.url : ''}>
                                <Heading
                                    fontSize="md"
                                    as="h3">
                                    {product['fields']['FE Product Description']}
                                </Heading>
                                <Text
                                    fontSize="sm">
                                    {product['fields']['FE Product Description'] ? product['fields']['FE Product Description'] : product['fields']['Description']}
                                </Text>
                            </Card>
                        </GridItem>
                    ))}
                </SimpleGrid>
            {isLoading && <LoadingSmall />}
        </>
    )
}

export default AllProducts
