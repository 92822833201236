
const COMPANY_REVIEW_STATUS = 1
const COMPANY_ACCEPTED_STATUS = 2
const COMPANY_DECLINED_STATUS = 3

const COMPANY_ADMIN = 10
const COMPANY_REU = 11
const COMPANY_SU = 21
const COMPANY_EU = 31
const REAL_ESTATE_TYPE = 'Real Estate';
const SUPPLIER_TYPE = 'Luminaire Types';
const ELECTRICAL_TYPE = 'Electrical Contractor';

const COMPANY_TYPES = {
    11: 'Real Estate',
    12: 'Property Investment',
    13: 'Property Management',
    14: 'Facilities Management',
    15: 'Architecture',
    16: 'REIT',
    21: 'Supplier',
    22: 'Luminaire Manufacturer',
    23: 'Distributor',
    24: 'Component Manufacturer',
    31: 'Electrical',
    32: 'Electrical Contractor',
    33: 'Electrician',
}

const REU_COMPANY_TYPE = {
    1: 'Real Estate',
    2: 'Property Investment',
    3: 'Property Management',
    4: 'Facilities Management',
    5: 'Architecture',
    6: 'REIT',
}

const SU_COMPANY_TYPE = {
    1: 'Supplier',
    2: 'Luminaire Manufacturer',
    3: 'Distributor',
    4: 'Component Manufacturer',
}

const EU_COMPANY_TYPE = {
    1: 'Electrical',
    2: 'Electrical Contractor',
    3: 'Electrician',
}

const COMPANY_OBJECT = {
    name: '',
    address: '',
    address2: '',
    state: '',
    notes: '',
    image: '',
    propertyPriorities: '',
    serviceArea: '',
    status: '',
    type: '',
    seen: false,
    subType: '',
    country: 'USA',
    ownerId: '',
}


export {
    COMPANY_REVIEW_STATUS,
    COMPANY_ACCEPTED_STATUS,
    COMPANY_DECLINED_STATUS,
    COMPANY_TYPES,
    COMPANY_ADMIN,
    COMPANY_REU,
    COMPANY_SU,
    COMPANY_EU,
    REAL_ESTATE_TYPE,
    SUPPLIER_TYPE,
    ELECTRICAL_TYPE,
    REU_COMPANY_TYPE,
    SU_COMPANY_TYPE,
    EU_COMPANY_TYPE,
    COMPANY_OBJECT,
}