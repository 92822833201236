import {Box, Flex, Heading, Image} from "@chakra-ui/react";
import {S3_BUCKET} from "../../../constants";
import CompanyChart from "../DataCharts/CompanyChart";

const TotalWithChart = (props) => (
    <Box>
        <Flex
            borderRadius='10px'
            pl={4}
            alignItems='center'
            minH='57px'
            bg='#E6E6E6'>
            <Heading
                m={0}
                fontSize='24px'>
                {props?.header}
            </Heading>
        </Flex>
        <Flex
            mt={9}
            justifyContent='space-between'>
            {
                props?.chartSide === 'left' ? (
                    <>
                        <Flex
                            alignItems='center'
                            flexDirection='column'>
                            <Heading
                                fontSize='16px'
                                m={0}>
                                {props?.chartTitle}
                            </Heading>
                            <CompanyChart
                                width='518px'
                                height='518px'
                                colors={props?.companyColors}
                                score={props?.data}/>
                        </Flex>
                        <Flex
                            flexDirection='column'>
                            <Flex
                                width='594px'
                                pl={4}
                                fontWeight='700'
                                justifyContent='space-between'>
                                <Heading
                                    m={0}
                                    fontSize='16px'
                                    width='10%'>
                                    Color
                                </Heading>
                                <Heading
                                    m={0}
                                    fontSize='16px'
                                    pl={3}
                                    width='50%'>
                                    {props?.gridName}
                                </Heading>
                                <Heading
                                    m={0}
                                    fontSize='16px'
                                    pl={3}
                                    width='40%'>
                                    {props?.gridNameCounts}
                                </Heading>
                            </Flex>
                            {props.data?.map((company, idx) => (
                                <Flex
                                    width='594px'
                                    fontSize='18px'
                                    key={idx}
                                    mt={4}
                                    pl={4}
                                    alignItems='center'
                                    minH='57px'
                                    bg='#FFFFFF'
                                    boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)'
                                    borderRadius='10px'
                                    justifyContent='space-between'>
                                    <Flex
                                        width='10%'
                                        pl={3}
                                        alignItems='center'
                                        height='100%'
                                        borderLeft={`12px solid ${props?.companyColors[idx]}`} />
                                    <Flex
                                        pl={3}
                                        alignItems='center'
                                        width='50%'>
                                        <Image
                                            width='50px'
                                            height='40px'
                                            mr={2}
                                            src={company?.image ? S3_BUCKET + company?.image : ' '}/>
                                        {props?.header === 'Product Types Sold' ?  (company?.fTotal !== '0' ? company?.descritpionFixture?.replace(/(.{15})..+/, "$1…") : company?.descritpionLamp?.replace(/(.{15})..+/, "$1…")) : company?.name?.replace(/(.{15})..+/, "$1…")}
                                    </Flex>
                                    <Flex
                                        pl={3}
                                        width='40%'>
                                        {props?.header === 'Product Types Sold' ?  (company?.fTotal !== '0' ? company?.fTotal : company?.lTotal): Number(company?.total)?.toLocaleString()}
                                    </Flex>
                                </Flex>
                            ))
                            }
                            <Flex
                                width='594px'
                                mt={4}
                                pl={4}
                                alignItems='center'
                                minH='57px'
                                border='2px solid #2E8A07'
                                bg='#FFFFFF'
                                boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)'
                                borderRadius='10px'
                                fontWeight='700'
                                justifyContent='space-between'>
                                <Heading
                                    color='#2E8A07'
                                    width='60%'
                                    pl={3}
                                    m={0}
                                    fontSize='16px'>
                                    Total
                                </Heading>
                                <Heading
                                    pl={3}
                                    width='40%'
                                    m={0}
                                    fontSize='16px'>
                                    {props?.data?.reduce((a, b) => a + (Number(b?.total) || 0), 0).toLocaleString()}
                                </Heading>
                            </Flex>
                        </Flex>
                    </>
                ) : (
                    <>
                        <Flex
                            flexDirection='column'>
                            <Flex
                                width='594px'
                                pl={4}
                                fontWeight='700'
                                justifyContent='space-between'>
                                <Heading
                                    m={0}
                                    fontSize='16px'
                                    width='10%'>
                                    Color
                                </Heading>
                                <Heading
                                    m={0}
                                    fontSize='16px'
                                    pl={3}
                                    width='50%'>
                                    {props?.gridName}
                                </Heading>
                                <Heading
                                    m={0}
                                    fontSize='16px'
                                    pl={3}
                                    width='40%'>
                                    {props?.gridNameCounts}
                                </Heading>
                            </Flex>
                            {props.data?.map((company, idx) => (
                                <Flex
                                    width='594px'
                                    fontSize='18px'
                                    key={idx}
                                    mt={4}
                                    pl={4}
                                    alignItems='center'
                                    minH='57px'
                                    bg='#FFFFFF'
                                    boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)'
                                    borderRadius='10px'
                                    justifyContent='space-between'>
                                    <Flex
                                        width='10%'
                                        pl={3}
                                        alignItems='center'
                                        height='100%'
                                        borderLeft={`12px solid ${props?.companyColors[idx]}`} />
                                    <Flex
                                        pl={3}
                                        alignItems='center'
                                        width='50%'>
                                        <Image
                                            width='50px'
                                            height='40px'
                                            mr={2}
                                            src={company?.image ? S3_BUCKET + company?.image : ' '}/>
                                        {props?.header === 'Product Types Sold' ?  (company?.fTotal !== '0' ? company?.descritpionFixture?.replace(/(.{15})..+/, "$1…") : company?.descritpionLamp?.replace(/(.{15})..+/, "$1…")) : company?.name?.replace(/(.{15})..+/, "$1…")}
                                    </Flex>
                                    <Flex
                                        pl={3}
                                        width='40%'>
                                        {props?.header === 'Product Types Sold' ?  (company?.fTotal !== '0' ? company?.fTotal : company?.lTotal): Number(company?.total)?.toLocaleString()}
                                    </Flex>
                                </Flex>
                            ))
                            }
                            <Flex
                                width='594px'
                                mt={4}
                                pl={4}
                                alignItems='center'
                                minH='57px'
                                border='2px solid #2E8A07'
                                bg='#FFFFFF'
                                boxShadow='2px 4px 10px rgba(0, 0, 0, 0.25)'
                                borderRadius='10px'
                                fontWeight='700'
                                justifyContent='space-between'>
                                <Heading
                                    color='#2E8A07'
                                    width='60%'
                                    pl={3}
                                    m={0}
                                    fontSize='16px'>
                                    Total
                                </Heading>
                                <Heading
                                    pl={3}
                                    width='40%'
                                    m={0}
                                    fontSize='16px'>
                                    {props?.data?.reduce((a, b) => a + (Number(b?.total) || 0), 0).toLocaleString()}
                                </Heading>
                            </Flex>
                        </Flex>
                        <Flex
                            alignItems='center'
                            flexDirection='column'>
                            <Heading
                                fontSize='16px'
                                m={0}>
                                {props?.chartTitle}
                            </Heading>
                            <CompanyChart
                                width='518px'
                                height='518px'
                                colors={props?.companyColors}
                                score={props?.data}/>
                        </Flex>
                    </>
                )
            }
        </Flex>
    </Box>
)

export default TotalWithChart