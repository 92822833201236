import {css} from '@emotion/react'


const styles = {
    tabPanel : css`
      max-width: 917px;
      padding: 10px 30px;
      border: 1px solid #DBDADA;
      background-color: #ffffff;
    `,
    tabPanels: css`
      padding: 20px 140px;
      border-top: 1px solid #DBDADA;
      border-bottom: none;
    `,
}

export default styles