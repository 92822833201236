const uploadRepo = (fileService) => {
    const uploadSingle = async ({name, file, type}) => await fileService.uploadSingle({
        name,
        file,
        type,
    })

    const uploadMultiple = (files) => fileService.uploadMultiple(files)

    const newFile = (variables) => fileService.newFile(variables)

    return {
        uploadSingle,
        uploadMultiple,
        newFile,
    }
}

export default uploadRepo
