import {Stack} from "@chakra-ui/react";
import Loading from "../../../components/Loading";
import TotalWithChart from "../parts/TotalWithChart";
import DataChart from "../parts/DataChart";
import UsagePart from "../parts/UsagePart";

const Supplier = ({data, usage, companyColors, isFetching,}) => {

    if (isFetching) return <Loading />

    return (
        data &&
        <Stack spacing={10}>
            <TotalWithChart
                companyColors={companyColors}
                data={data['total_products']}
                gridName={'Company'}
                gridNameCounts={'Products'}
                chartTitle={'Total Products'}
                header={'Total Products'}
                chartSide={'right'}/>
            <DataChart
                chartLeftSide={'Dollars'}
                header={'Total Fulfilled Amount'}
                data={data['total_fulffiled_amount']}
            />
            <TotalWithChart
                companyColors={companyColors}
                data={data['products_sold_types']}
                gridName={'Product Type'}
                gridNameCounts={'Total Luminaires Sold'}
                chartTitle={'Total Product Sold By Type'}
                header={'Product Types Sold'}
                chartSide={'left'}/>
            <DataChart
                chartLeftSide={'QTY'}
                header={'Total Products Sold'}
                data={data['products_sold']}/>
            <UsagePart
                companyColors={companyColors}
                usage={usage}/>
        </Stack>
    )
}

export default Supplier