import { useMutation } from 'react-query'
import { notificationUseCase } from '../../factories'

const useCreateNotification = () => {
    const {
        mutate: createNotification, isLoading, data, error,
    } = useMutation(notificationUseCase.createNotification)

    return {
        createNotification,
        isLoading,
        error,
        data,
    }
}

export default useCreateNotification
