import {Box} from '@chakra-ui/react'
import {Bar} from 'react-chartjs-2'

const ChartConsuption = ({width, height, existingYear, ledYear}) => {
    const data = {
        labels: ['0', '2', '4', '6', '8', '10'],
        datasets: [
            {
                label: 'Existing System',
                // type: 'line',
                data: [
                    existingYear,
                    existingYear * 2,
                    existingYear * 4,
                    existingYear * 6,
                    existingYear * 8,
                    existingYear * 10,
                ],
                fill: '+1',
                borderColor: '#FF7F56',
                backgroundColor: '#FF7F56',
                pointBorderColor: '#FF7F56',
                pointBackgroundColor: '#FF7F56',
                pointHoverBackgroundColor: '#FF7F56',
                pointHoverBorderColor: '#FF7F56',
            },
            {
                label: 'LED system',
                // type: 'line',
                data: [
                    ledYear,
                    ledYear * 2,
                    ledYear * 4,
                    ledYear * 6,
                    ledYear * 8,
                    ledYear * 10,
                ],
                fill: false,
                backgroundColor: '#71B37C',
                borderColor: '#71B37C',
                hoverBackgroundColor: '#71B37C',
                hoverBorderColor: '#71B37C',
            },
        ],
    }
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    callback(value, _index, values) {
                        return value.toLocaleString('en-US')
                    },
                },
            }],
        },
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label(tooltipItem) {
                    return tooltipItem.yLabel.toLocaleString('en-US')
                },
            },
        },
    }

    return (
        <Box
            height={height ? height : "320px"}
            width={width ? width : "620px"}>
            <Bar
                options={options}
                data={data}/>
        </Box>
    )
}

export default ChartConsuption
