import React, {useEffect, useState} from 'react'
import {Button, Flex, Image, Text, Box,} from '@chakra-ui/react'
import {useHistory} from 'react-router-dom'
import {S3_BUCKET} from '../../constants'
import styles from './styles'
import {ReactComponent as Star} from '../../assets/starIconLittle.svg'
import {ReactComponent as Unhide} from '../../assets/UnhideIcon.svg'
import {ReactComponent as DragIcon} from '../../assets/projectDragIcon.svg'
import {ReactComponent as EyeIcon} from '../../assets/bigEyeIcon.svg'
import {ReactComponent as Activate} from '../../assets/activateIcon.svg'
import {useUser} from '../../core/hooks'
import httpRequest from "../../core/services/http-request";
import {ReactComponent as ArrowRight} from "../../assets/arrow_right.svg";
import {SU} from "../../constants/user/userEnum";

const Project = ({project, projectStatus, projectStatusEu, projectId, searchProjects, priority, hidden, archived}) => {
    const {post} = httpRequest()
    const [loading, setLoading] = useState(false)
    const [loadingArchive, setLoadingArchive] = useState(false)
    const {user} = useUser()
    const history = useHistory()
    const [status, setStatus] = useState('')
    const [bg, setBg] = useState('')
    const [name, setName] = useState('')

    const unhideProject = () => {
        setLoading(true)
        let data = {projectId: projectId}
        post('unhide-project', data).then((res) => {
            setStatus(res.data.data.status)
            setLoading(false)
            searchProjects()
        })
    }

    const activateProject = () => {
        let data = {projectId: project?.id}
        setLoadingArchive(true)
        post('activate-project',data).then((res) => {
            setStatus(res.data.data.status)
            setLoadingArchive(false)
            searchProjects()
        })
    }

    useEffect(() => {
        if (!user) return
        if (user.type === 'Eu') {
            setStatus(projectStatusEu)
        } else {
            setStatus(projectStatus)
        }
    }, [searchProjects])

    useEffect(() => {
        if (status) {
            if (hidden) {
                setBg('#6D8DA7')
                return setName('Unhide')
            }  else if (archived) {
                setBg('rgba(109, 141, 167, 1)')
                setName('Activate')
            }else {
                if (status === 'Proposal Review') {
                    setBg('green.100')
                    setName('View Proposal')
                } else if (status === 'Light Count') {
                    setBg('#F0D533')
                    setName('Property Info')
                } else if (status === 'Approved') {
                    setBg('#65E42F')
                    setName('Estimate')
                } else if (status === 'Fulfilled') {
                    setBg('#E69FFF')
                    setName('Invoice')
                } else if (status === 'Shipped') {
                    setBg('#FFB185')
                    setName('Track')
                } else if (status === 'Active Bidding') {
                    setBg('#FFE68D')
                    setName('Bid')
                } else if (status === 'Awaiting Decision') {
                    setBg('#FFB185')
                } else if (status === 'Project Awarded!') {
                    setBg('#8FCF74')
                } else {
                    setBg('black')
                }
            }
        }
    }, [status])

    return (
        <Flex flexDirection="column" css={styles.card} zIndex='1'>
            <Box
                zIndex='9'
                onMouseDown={(e) => {
                    e.stopPropagation();
                    if (user?.type === SU) {
                        history.push(hidden ? "#" : `/project-supplier/${project.id}`)
                    } else {
                        history.push(hidden ? "#" : `/project/${project.id}`)
                    }
                }}
                pl="6" pr="9" fontWeight="600" fontFamily='Titillium Web' position="absolute" fontSize="sm" top="0"
                left="0" borderRadius="10px 0 30px 0"
                color={archived ? 'white' : 'black'}
                bg={hidden ? '#DADADA' : archived ? '#636363' : bg}>
                {hidden ? 'Hidden' : archived ? 'Archived' : status}
            </Box>
            <Box position='relative'>
                <Image
                    cursor='pointer' onMouseDown={(e) => {
                        e.stopPropagation();
                        if (user?.type === SU) {
                            history.push(hidden ? "#" : `/project-supplier/${project.id}`)
                        } else {
                            history.push(hidden ? "#" : `/project/${project.id}`)
                        }
                    }}
                    src={project?.image ? S3_BUCKET + project?.image : '/images/defaultLamp.jpg'}
                    alt={project?.title}
                    objectFit={project?.image ? 'cover' : 'contain'}
                    css={styles.image}/>
                <Flex
                    cursor='pointer'
                    onMouseDown={(e) => {
                        e.stopPropagation();
                        if (user?.type === SU) {
                            history.push(hidden ? "#" : `/project-supplier/${project.id}`)
                        } else {
                            history.push(hidden ? "#" : `/project/${project.id}`)
                        }
                    }}
                    alignItems='center' justifyContent='center' width='100%' height='100%' position='absolute' top='0' left='0' right='0' zIndex='10' backgroundColor={hidden || archived ? 'rgba(34, 34, 34, 0.78)' : 'none'}>
                    <EyeIcon style={{display: hidden || archived ? 'block' : 'none'}}/>
                </Flex>
            </Box>
            <Flex flexDirection="column" css={styles.info}>
                <Flex>
                    <Text mt={1}>{priority ? (<Star fill='#FFD439'/>) : (<Star fill='#C5C5C5'/>)}</Text>
                    <Text
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            if (user?.type === SU) {
                                history.push(hidden ? "#" : `/project-supplier/${project.id}`)
                            } else {
                                history.push(hidden ? "#" : `/project/${project.id}`)
                            }
                        }}
                        ml={1} style={{zIndex: 999}} cursor='pointer'>
                        {
                            user?.type === 'Eu' ? (
                                ((project?.city ? project?.city + ', ' : '') + (project?.state ? project?.state : '') + ' ' + (project?.zipCode ? project?.zipCode : '')).replace(/^,|,$/g, '').trim()
                                ) : project?.address
                        }
                    </Text>
                </Flex>
                <Text ml={5} onMouseDown={(e) => {
                    e.stopPropagation();
                    if (user?.type === SU) {
                        history.push(hidden ? "#" : `/project-supplier/${project.id}`)
                    } else {
                        history.push(hidden ? "#" : `/project/${project.id}`)
                    }
                }}
                      cursor='pointer'>
                    {
                        user?.type === 'Eu' ? (
                            project?.propertyType
                        ) : ((project?.city ? project?.city + ', ' : '') + (project?.state ? project?.state : '') + ' ' + (project?.zipCode ? project?.zipCode : '')).replace(/^,|,$/g, '').trim()
                    }
                </Text>
                <Flex mt={2} alignItems='center' justifyContent='space-between'>
                    <Flex alignItems='center'>
                        <DragIcon id='drag_button' cursor='pointer'/>
                        <Text cursor='pointer' fontSize='12px' mt="1" ml={5} onMouseDown={(e) => {
                            e.stopPropagation();
                            if (user?.type === SU) {
                                history.push(hidden ? "#" : `/project-supplier/${project.id}`)
                            } else {
                                history.push(hidden ? "#" : `/project/${project.id}`)
                            }
                        }}>{project?.title}</Text>
                    </Flex>
                    <Button
                        ml={5}
                        display={archived && user?.type === SU  ? 'none' : 'flex'}
                        width='133px'
                        alignItems='center'
                        leftIcon={hidden ? <Unhide/> : archived ? <Activate width='13px' height='13px'/> : <ArrowRight/>}
                        isLoading={loading || loadingArchive}
                        height='20px'
                        bg={bg}
                        color={hidden || archived ? 'white' : 'black'}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                            if (hidden){
                                return unhideProject()
                            } else if (archived){
                                return activateProject()
                            } {
                                if (user?.type === SU) {
                                    history.push(hidden ? "#" : `/project-supplier/${project.id}`)
                                } else {
                                    history.push(hidden ? "#" : `/project/${project.id}`)
                                }
                            }
                        }}>
                        {name}
                    </Button>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Project
