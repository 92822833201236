import React from 'react'
import {
    Text,
    Heading,
    Stack, Button as TextButton, Box, Flex,
} from '@chakra-ui/react'
import {Button} from '../Theme'
import {Link} from "react-router-dom";

const AllSetGuests = () => (
    <Stack
        spacing={8}
        alignItems="center"
        flexDirection="column"
        justifyContent="space-between">
        <Heading
            textAlign="center"
            mt={7}
            mb={3}
            as="h2"
            fontSize="4xl">
            Ready To Go!
            <Link to="/register">
                <TextButton
                    fontWeight="bold"
                    variant="transparent"
                    position="absolute"
                    top="30px"
                    left="30px">
                    <img
                        width="25px"
                        src="/images/back_register.png"
                        alt="back arrow"/>
                    <Text
                        ml={1}
                        fontSize="18px"
                        fontWeight='600'>
                        BACK
                    </Text>
                </TextButton>
            </Link>
        </Heading>
        <img
            style={{
                height: '95px',
                width: '95px',
            }}
            src="/images/register_confirm.png"
            alt="all set"/>
        <Text
            textAlign="center"
            fontWeight="600"
            fontSize='24px'
            color="black">
            You will receive an email confirming your sign up.
            <br/>
            We&apos;re excited to welcome you to the Focus Earth community!
        </Text>
        <Flex
            pt={20}>
            <Flex
                justifyContent="center"
                alignItems="center"
                mr={5}>
                <Text
                    color="black"
                    fontWeight='400'
                    fontSize="lg">
                    STEP&nbsp;
                    <Text
                        color="black"
                        as="span"
                        fontWeight="700">
                        4&nbsp;
                    </Text>
                    OF&nbsp;
                    <Text
                        color="black"
                        as="span"
                        fontWeight="700">
                        4
                    </Text>
                </Text>
                <Flex
                    mx={6}>
                    {
                        [1, 2, 3, 4].map((item: number) => (
                            <Box
                                key={item}
                                mx={1}
                                width="20px"
                                height="20px"
                                backgroundColor='green.200'
                                border="2px solid"
                                borderColor='green.200'
                                borderRadius="100%"/>
                        ))
                    }
                </Flex>
            </Flex>
            <a
                href="#">
                <Button
                    color="white !important"
                    type="submit"
                    height="40px !important"
                    width="467px">
                    <Text
                        mr={2}
                        mt={1}
                        fontWeight='700'
                        fontSize='18px'>
                        View Our Insights
                    </Text>
                    <img
                        width="25px"
                        src="/images/arrow_right.png"
                        alt="arrow"/>
                </Button>
            </a>
        </Flex>
    </Stack>
)

export default AllSetGuests
