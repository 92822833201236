const activityUseCase = (activityRepo) => {
  const createActivity = (input) => activityRepo.createActivity(input) // NO VALIDATIONS NEED
  const updateActivity = (input) => {
    if (!input?.id) {
      throw Error('ID is required')
    }
    return activityRepo.updateActivity(input)
  }

  const getActivity = (id) => {
    if (!id) {
      throw Error('ID is required')
    }

    return activityRepo.getActivity(id)
  }

  const listActivitys = (variables) => activityRepo.listActivitys(variables)

  return {
    createActivity,
    updateActivity,
    getActivity,
    listActivitys,
  }
}

export default activityUseCase
