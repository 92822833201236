import {Stack,} from "@chakra-ui/react";
import Loading from "../../../components/Loading";
import TotalWithChart from "../parts/TotalWithChart";
import DataChart from "../parts/DataChart";
import UsagePart from "../parts/UsagePart";

const Electrician = ({data, isLoadingTypeData, companyColors, usage}) => {

    if (isLoadingTypeData) return <Loading/>

    return (
        data &&
        <Stack spacing={10}>
            <TotalWithChart
                companyColors={companyColors}
                data={data['reports']}
                gridName={'Company'}
                gridNameCounts={'Total Projects'}
                chartTitle={'Total Projects Awarded'}
                header={'Total Projects Awarded'}
                chartSide={'right'}/>
            <DataChart
                chartLeftSide={'Total Accepted Amount'}
                header={'Project Amount Awarded'}
                data={data['reports_sum']}/>
            <TotalWithChart
                companyColors={companyColors}
                data={data['reports']}
                gridName={'Company Name'}
                gridNameCounts={'Products Installed'}
                chartTitle={'Total Products Installed'}
                header={'Total Products Installed'}
                chartSide={'left'}/>
            <DataChart
                chartLeftSide={'Zip Codes Serviced'}
                header={'Most Serviceable Areas'}
                data={data['areas']}/>
            <UsagePart
                companyColors={companyColors}
                usage={usage}/>
        </Stack>
    )
}

export default Electrician