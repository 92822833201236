import { useQuery } from 'react-query'
import { productUseCase } from '../../factories'

const useGetProduct = (variables) => {
  const {
    refetch, isLoading, data, error,
  } = useQuery(['product', variables?.id], () => productUseCase.getProduct(variables))

  return {
    refetch,
    isLoading,
    error,
    data,
  }
}

export default useGetProduct
