import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Heading, Text, GridItem, SimpleGrid, Box,} from '@chakra-ui/react'
import {useListProducts} from '../../core/hooks'
import {Card} from '../../components/Theme'
import HeaderForCatalogues from './HeaderForCatalogues'
import Loading from "../../components/Loading";
import LoadingSmall from "../../components/LoadingSmall";
import './styles.css'

const Accessories = () => {
    const [accessories, setAccessories] = useState([])
    const [offset, setOffset] = useState(null)
    const [offsetPage, setOffsetPage] = useState(1)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const location = useLocation()
    const history = useHistory()
    let condition = {
        condition: {
            filter: '',
            table: location.pathname.split('/')[2]==='controls' ? 'CONTROL' :location.pathname.includes('power') ? 'POWER' : 'ACCESSORY',
            page,
            offset,
            search,
        }
    }
    const [isLoading, setIsLoading] = useState(false);
    const {refetch,data: accessoriesResponse, isLoading: isLoadingAccessories} = useListProducts(condition)

    const handleSearch = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        if (!accessoriesResponse) return
        if (page === 1){
            setAccessories(accessoriesResponse.data.data?.records ?? [])
        } else {
            setAccessories((prevAccessories) => [
                ...prevAccessories,
                ...accessoriesResponse.data.data?.records ?? [],
            ]);
        }
        setOffset(accessoriesResponse?.data.data?.nextOffset ?? "")
        setIsLoading(false);
    }, [accessoriesResponse])

    const loadMoreProducts = () => {
        if (isLoading) return;

        if (page >= 2 && !offset) return
        setIsLoading(true);
        refetch()
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;

            if (scrollPosition >= pageHeight * 0.5 && !isLoading) {
                loadMoreProducts();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isLoading]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setOffset(null)
            setPage(1)
            if (search.length > 2 || search.length === 0) {
                refetch()
            }
        }, 800);

        return () => clearTimeout(delayDebounceFn);
    }, [search, refetch]);

    if (isLoadingAccessories) return <Loading />

    return (
        <>
            <HeaderForCatalogues
                handleSearch={handleSearch}
                title={location.pathname.split('/')[2]}/>
            <SimpleGrid
                p="12" w="full" columns={{base: 1, sm: 2, md: 3, lg: 4,}} spacing="8">
                {accessories?.map((product) => (
                        <GridItem onClick={() => history.push(`/catalogues/accessory/${location.pathname.split('/')[2]}/${product.id}`)} colSpan={1} key={product.id}>
                            <Card image={product.fields['Image'] ? product.fields['Image'][0]?.url : ''}>
                                <Heading fontSize="md" as="h3">
                                    {product.fields['FE Product Description']}
                                </Heading>
                                <Text fontSize="sm">
                                    {
                                        (product.fields['Length (inches)'] ? product.fields['Length (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                        (product.fields['Width (inches)'] ? product.fields['Width (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                        (product.fields['Diameter (inches)'] ? product.fields['Diameter (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '"x' : ' ' )+
                                        (product.fields['Depth (inches)'] ? product.fields['Depth (inches)']?.toString()?.replace(/(.{15})..+/, "$1…") + '" ' : ' ' )+
                                        (product.fields['Lumens'] ? ', '+product.fields['Lumens']: '') +
                                        (location.pathname.split('/')[2] === 'accessories' ? (product.fields['Product Description'] ? product.fields['Product Description'] : '')
                                            : (product.fields['Lifetime (hours)'] ? ', '+product.fields['Lifetime (hours)']+' hours ' : '') + (product.fields['Warranty (years)'] ? ', '+product.fields['Warranty (years)']+' years ' : ''))
                                    }
                                </Text>
                            </Card>
                        </GridItem>))}
            </SimpleGrid>
            {isLoading && <LoadingSmall />}
        </>
    )
}

export default Accessories
