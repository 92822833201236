import {VStack, Heading, Flex, Box} from '@chakra-ui/react'

const TextLabel = ({label,spacing='2', text, maxW, fullWidth,rightAddon,ml,labelMargin, ...props}) => (
    <VStack spacing={spacing} w={fullWidth ? fullWidth : '100%'} maxW={maxW} ml={ml}>
        {
            label &&
                <Heading
                    w='full'
                    m={labelMargin ? labelMargin : '0'}
                    fontFamily='Titillium Web'
                    fontWeight="600"
                    fontSize="md"
                    color="blue.200">
                    {label}
                </Heading>
        }
        <Flex
            position='relative'
            px='10px'
            alignItems='center'
            w='full'
            fontSize='xl'
            minH='50px'
            border='1px solid #C4C4C4'
            borderRadius='5px'
            bg='white'
            {...props}>
            {text}
            {
                rightAddon
                && (
                    <Box zIndex="10" right="7px" position="absolute" as="span">
                        {rightAddon}
                    </Box>
                )
            }
        </Flex>
    </VStack>
)

export default TextLabel