import HttpRequest from "./http-request";

const NotificationService = () => {
    const {get, post} = HttpRequest()

    const createNotification = (input) => {
        return post('create-notification', {input})
    }

    const updateNotification = (input) => {
        return post('update-notification', {input})
    }

    const listNotifications = (variables) => {
        return get('list-notifications', {variables})
    }

    const notificationToActivity = (input) => {
        return post('notif-to-activ', {input})
    }

    return {
        createNotification,
        updateNotification,
        listNotifications,
        notificationToActivity,
    }
}

export default NotificationService
