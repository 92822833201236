import {useEffect, useState} from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Flex,
    Text,
    Stack,
    Input,
    Button,
    useDisclosure,
    useToast,
} from '@chakra-ui/react'
import Icon from '../../components/Theme/Icon'
import IconButton from '../../components/Theme/IconButton'
import UserModal from '../EditCompany/Modals/UserModal'
import {useCreateNotification, useListUsers} from "../../core/hooks";
import {ReactComponent as SendMessage} from "../../assets/invitationSendIcon.svg";
import CustomSelectForSupplierAdminInvite from "./CustomSelectForSupplierAdminInvite";
import ToastComponent from "../../components/toastComponent/ToastComponent";
import {SA, AU, USER_LIST_TYPE_COMPANY, SU, REU, EU} from "../../constants/user/userEnum";
import {
    COMPANY_EU,
    COMPANY_REU,
    COMPANY_SU,
    SUPPLIER_TYPE,
    REAL_ESTATE_TYPE,
    ELECTRICAL_TYPE
} from "../../constants/company/companyEnum";
import {EU_COMPANY_TYPES, REU_COMPANY_TYPES, SU_COMPANY_TYPES} from "../../constants/types";

const InviteToCompanyModal = ({isOpen, onClose, company}) => {
    const {isOpen: isOpenInvite, onClose: onCloseInvite, onOpen: onOpenInvite} = useDisclosure()
    const {createNotification} = useCreateNotification()
    const [userInvite, setUserInvite] = useState([])
    const [admins, setAdmins] = useState([])
    const [users, setUsers] = useState([])
    const [colleague, setColleague] = useState([])
    const [admin, setAdmin] = useState([])
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const {data: usersResponse} = useListUsers({
        companyId: company?.id,
        type: USER_LIST_TYPE_COMPANY,
        companyType: Object.values(SU_COMPANY_TYPES).find(item => item === company?.type) ? COMPANY_SU
                      : Object.values(REU_COMPANY_TYPES).find(item => item === company?.type) ? COMPANY_REU
                         : COMPANY_EU,
    })

    const sendInvite = () => {
        if (userInvite === '') {
            return setError('Choose user to send notification')
        }
        setError('')
        setLoading(true)
        createNotification({
            type: Object.values(SU_COMPANY_TYPES).find(item => item === company?.type) ? 109 : 110,
            companyId: company?.id,
            userToSync: userInvite,
            admin: admin[0],
        }, {
            onSuccess() {
                setLoading(false)
                toast({
                    render: () => (
                        <ToastComponent icon={<SendMessage/>} title='User Synced' bg='#D5D5D5'/>
                    ),
                    duration: 4000,
                    isClosable: true,
                    position: "top-right"
                })
                onClose()
            },
        })
    }

    useEffect(() => {
       if (!usersResponse) return
        let u = usersResponse.data.data
        let adm = []
        let us = []
        for (let i = 0; i < u.length; i++) {
            if (u[i].type === SA || u[i].type === AU){
                adm.push(u[i])
            } else if (u[i].type === REU || u[i].type === SU || u[i].type === EU){
                us.push(u[i])
            }
        }
        setAdmins(adm)
        setUsers(us)
    },[usersResponse])

    return (
        <Modal
            isCentered
            isOpen={isOpen}
            onClose={onClose}>
            <ModalOverlay/>
            <ModalContent
                overflow="hidden"
                borderRadius="10px"
                minW="853px">
                <ModalHeader
                    bg='#8FCF74'
                    fontSize="3xl"
                    as='h2'
                    fontWeight='700'
                    m={0}
                    textAlign="center">
                    {Object.values(SU_COMPANY_TYPES).find(item => item === company?.type) ? 'Invite User To Supplier Company'
                        : Object.values(REU_COMPANY_TYPES).find(item => item === company?.type) ? 'Invite User to Company'
                          : Object.values(EU_COMPANY_TYPES).find(item => item === company?.type) ? 'Invite User To Electrical Contracting Company'
                            : 'Sync User To Company'}
                </ModalHeader>
                <IconButton
                    height="unset"
                    onClick={onClose}
                    _hover={{
                        bg: 'none',
                    }}
                    _active={{
                        bg: 'none',
                    }}
                    bg="none"
                    aria-label="close button"
                    position="absolute"
                    top="1.5rem"
                    right="0.75rem"
                    icon={
                        <Icon type="darkClose"/>
                    }/>
                <ModalBody
                    overflow="scroll"
                    maxH="600px">
                    <Flex
                        alignItems="center"
                        flexDirection="column">
                        <Text
                            my={12}
                            fontSize="3xl">
                            {Object.values(SU_COMPANY_TYPES).find(item => item === company?.type) ? 'Invite User or Assign Admin to Supplier Company:'
                                : Object.values(EU_COMPANY_TYPES).find(item => item === company?.type) ? 'Invite User or Assign Admin to Electric Contracting Company:'
                                   : 'Invite a user to a Company:'}
                        </Text>
                        {
                            error && (
                                <Text
                                    mb={4}
                                    as='h2'
                                    m={0}
                                    color="red.100"
                                    fontSize="sm">
                                    {error}
                                </Text>
                            )
                        }
                        <Stack
                            mx="auto"
                            w="418px"
                            spacing={4}>
                            <Input
                                borderColor="#A9A9A9"
                                value={company?.name}
                                disabled
                                minH='50px'
                                opacity='1 !important'
                                fontWeight='600'
                                fontSize='xl'
                                backgroundColor='#C4C4C4'
                                color="rgba(90, 90, 90, 0.87)"
                                placeholder="Company Name"/>
                                <CustomSelectForSupplierAdminInvite
                                    companyType={company?.type}
                                    admin={admin}
                                    setAdmin={setAdmin}
                                    names={userInvite}
                                    setNames={setUserInvite}
                                    repeat={1}
                                    value={colleague}
                                    admins={admins}
                                    users={users}
                                    setValue={setColleague}/>
                        </Stack>
                        <Button
                            mx="auto"
                            mt={12}
                            mb={12}
                            onClick={sendInvite}
                            height="50px"
                            width="254px"
                            fontWeight="700"
                            fontSize="xl"
                            isLoading={loading}>
                            Sync
                        </Button>
                        <Text
                            mb="4rem"
                            fontSize="18px">
                            Don’t see the user?
                            <Button
                                ml={1}
                                fontSize="18px"
                                variant="link"
                                onClick={() => onOpenInvite()}
                                color="#C956FF">
                                Invite new user
                            </Button>
                        </Text>
                    </Flex>
                    <UserModal
                        onClose={onCloseInvite}
                        onOpen={onOpenInvite}
                        isOpen={isOpenInvite}/>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
export default InviteToCompanyModal
