const activityRepo = (activityService) => {
  const createActivity = (input) => activityService.createActivity(input)

  const updateActivity = (input) => activityService.updateActivity(input)

  const listActivitys = (variables) => activityService.listActivitys(variables)

  const getActivity = (id) => activityService.getActivity(id)

  return {
    createActivity,
    updateActivity,
    listActivitys,
    getActivity,
  }
}

export default activityRepo
