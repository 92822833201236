import {useEffect, useRef, useState,} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {Flex, Heading, Image, Text, Button, useDisclosure} from '@chakra-ui/react'
import Navbar from '../../components/Navbar'
import {useFindUser, useUpdateUser, useUpload} from '../../core/hooks'
import Details from './Details'
import Interests from './Interests'
import {S3_BUCKET} from '../../constants'
import httpRequest from "../../core/services/http-request";
import {ReactComponent as UploadIcon} from "../../assets/uploadNewPhotoIcon.svg";
import {ReactComponent as DeleteIcon} from "../../assets/deleteUserIcon.svg";
import {useForm} from "react-hook-form";
import DeleteUserModal from "./deleteUserModal";
import Loading from "../../components/Loading";
import {
    InitialEnvironmentUser,
    InitialIlluminationUser, InitialPreferences,
    InitialScoreUser,
    InitialTechnologyUser,
    InitialValuationUser
} from "../../constants/chartDatas";
import DoneButton from "../../components/Theme/Buttons/DoneButton";
import mainStyles from "../../styles/mainStyles";
import {CustomDivider} from "../../components/Theme";
import CompanySyncUserModal from "./companySyncUserModal";
import RemoveCompanyUserModal from "./removeCompanyUserModal";
import useRemoveUserFromCompany from "../../core/hooks/company/useRemoveUserFromCompany";
import CommuniactionPreferences from "../../components/User/CommuniactionPreferences";
import {
    AU,
    EU,
    InitialPreferencesAu, InitialPreferencesReuSales,
    InitialPreferencesSuEu, InitialPreferencesSuperadmin,
    REU,
    SA,
    SU,
    SUPERADMIN
} from "../../constants/user/userEnum";

const UsersProfile = () => {
    const [user, setUser] = useState({})
    const history = useHistory()
    const inputRef = useRef()
    const params = useParams()
    const [score, setScore] = useState(user?.primaryInterests ? JSON.parse(user.primaryInterests) : InitialScoreUser)
    const [illumination, setIllumination] = useState(user?.profileFeatures ? JSON.parse(user.profileFeatures).illumination : InitialIlluminationUser)
    const [technology, setTechnology] = useState(user?.profileFeatures ? JSON.parse(user.profileFeatures).technology : InitialTechnologyUser)
    const [valuation, setValuation] = useState(user?.profileFeatures ? JSON.parse(user.profileFeatures).valuation : InitialValuationUser)
    const [environment, setEnvironment] = useState(user?.profileFeatures ? JSON.parse(user.profileFeatures).environment : InitialEnvironmentUser)
    const [avatar, setAvatar] = useState('')
    const {upload, data} = useUpload()
    const [company, setCompany] = useState([])
    const [projects, setProjects] = useState([])
    const [companies, setCompanies] = useState([])
    const [communicationPreferences, setCommunicationPreferences] = useState(user?.communicationPreferences ? JSON.parse(user.communicationPreferences) : InitialPreferences)
    const [error, setError] = useState('')
    const [companyToSync, setCompanyToSync] = useState('')
    const [processing, setProcessing] = useState(false)
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {isOpen: isOpenCompanySync, onOpen: onOpenCompanySync, onClose: onCloseCompanySync} = useDisclosure()
    const {isOpen: isOpenRemoveCompany, onOpen: onOpenRemoveCompany, onClose: onCloseRemoveCompany} = useDisclosure()
    const {register, handleSubmit, reset} = useForm()
    const {mutate: updateUser, isLoading: isUpdateUserLoading} = useUpdateUser()
    const {data: userData, isLoading: isLoadingUser, refetch} = useFindUser(params.id)
    const {post} = httpRequest()
    const {removeUser, isLoading: isLoadingRemoveUser} = useRemoveUserFromCompany()

    const handleAvatar = (e) => {
        const file = e.target.files[0]
        const blob = URL.createObjectURL(file)
        const type = file.type.split('/')[1] || 'jpeg'
        const name = `${new Date().getTime() * Math.random()}.${type}`
        setAvatar(blob)
        upload({
            name,
            file,
            type,
        })
        setProcessing(false)
    }

    const deleteUser = () => {
        const {post} = httpRequest()
        setProcessing(true)
        post('delete-user', user?.id).then(() => {
            setProcessing(false)
            history.push('/users')
        })
    }

    const syncUserToCompany = () => {
        const {post} = httpRequest()
        if (!companyToSync) return setError('Choose company')
        setProcessing(true)
        let data = {
            companyId: companyToSync,
            userId: user.id,
        }
        post('sync-company-to-user', data).then(() => {
            setProcessing(false)
            history.push('/users')
        }).catch(() => setProcessing(false))
    }

    const removeCompanyFromUser = () => {
        if (user?.company?.length === 0 && !user?.associatedToCompany){
            return setError('There is no company connected')
        }
        let type = 'fromUser'
        let data = {
            id: user?.id,
            compId: user?.company?.length > 0 ? user?.company?.id : user?.associatedToCompany?.id,
            type: type,
        }
        removeUser(data,{
            onSuccess:() => {
                refetch()
                onCloseRemoveCompany()
                onCloseCompanySync()
            },
            onError: (err) => console.log(err),
        })
    }

    useEffect(() => {
        if (!userData) return
        let compId = null
        setUser(userData.data.data)
        reset(userData.data.data)
        setScore(userData.data.data?.primaryInterests ? JSON.parse(userData.data.data.primaryInterests) : InitialScoreUser)
        setIllumination(userData.data.data?.profileFeatures ? JSON.parse(userData.data.data.profileFeatures).illumination : InitialIlluminationUser)
        setTechnology(userData.data.data?.profileFeatures ? JSON.parse(userData.data.data.profileFeatures).technology : InitialTechnologyUser)
        setValuation(userData.data.data?.profileFeatures ? JSON.parse(userData.data.data.profileFeatures).valuation : InitialValuationUser)
        setEnvironment(userData.data.data?.profileFeatures ? JSON.parse(userData.data.data.profileFeatures).environment : InitialEnvironmentUser)
        if (userData.data.data?.type === AU){
            setCommunicationPreferences(userData.data.data?.communicationPreferences ? JSON.parse(userData.data.data.communicationPreferences) : InitialPreferencesAu)
        } else if (userData.data.data?.type === REU || userData.data.data?.type === SA){
            setCommunicationPreferences(userData.data.data?.communicationPreferences ? JSON.parse(userData.data.data.communicationPreferences) : InitialPreferencesReuSales)
        }else if (userData.data.data?.type === SU || userData.data.data?.type === EU){
            setCommunicationPreferences(userData.data.data?.communicationPreferences ? JSON.parse(userData.data.data.communicationPreferences) : InitialPreferencesSuEu)
        } else {
            setCommunicationPreferences(userData.data.data?.communicationPreferences ? JSON.parse(userData.data.data.communicationPreferences) : InitialPreferencesSuperadmin)
        }
        if (userData.data.data?.avatar){
            setAvatar(S3_BUCKET + userData.data.data?.avatar)
        }
        if (userData.data.data?.company?.length > 0){
            compId = userData.data.data?.company[0].id
            setCompany([userData.data.data?.company[0].id, userData.data.data?.company[0].name])
        } else if (userData.data.data?.associatedToCompany){
            compId = userData.data.data?.associatedToCompany?.id
            setCompany([userData.data.data?.associatedToCompany?.id, userData.data.data?.associatedToCompany?.name])
        }

        let variables = {type: userData.data.data?.type, companyId: compId}
        post('company-list', {variables}).then((r) => {
            setCompanies(r.data.data)
        })
        if (userData.data.data?.type === 'Sa' || userData.data.data?.type === 'Au') {
            setProjects(userData.data.data?.invitedToProjects)
        } else {
            setProjects(userData.data.data?.project)
        }
    }, [userData])

    const submit = (dataForm) => {
        if (processing) return
        const input = {
            id: user.id,
            firstName: dataForm.firstName,
            lastName: dataForm.lastName,
            title: dataForm.title,
            phone: dataForm.phone,
            companyChange: company[0] !== user?.company[0]?.id || company[0] !== user?.associatedToCompany?.id ? company : '',
            avatar: data || user.avatar,
            communicationPreferences: JSON.stringify(communicationPreferences),
            primaryInterests: JSON.stringify(score),
            profileFeatures: JSON.stringify({
                illumination,
                technology,
                valuation,
                environment,
            }),
        }
        setUser({...user, ...input,})
        updateUser(input,
            {
                onSuccess: () => {
                    history.push('/users')
                },
            },
        )
    }

    if (isLoadingUser) return <Loading/>

    return (
        <form noValidate onSubmit={handleSubmit(submit)}>
            <DeleteUserModal
                deleteUser={deleteUser}
                onClose={onClose}
                isOpen={isOpen}/>
            <CompanySyncUserModal
                user={user}
                onOpenRemoveCompany={onOpenRemoveCompany}
                loading={processing}
                error={error}
                setError={setError}
                syncUserToCompany={syncUserToCompany}
                setCompanyToSync={setCompanyToSync}
                companies={companies}
                isOpen={isOpenCompanySync}
                onClose={onCloseCompanySync}/>
            <RemoveCompanyUserModal
                isLoadingRemoveUser={isLoadingRemoveUser}
                removeCompanyFromUser={removeCompanyFromUser}
                onClose={onCloseRemoveCompany}
                isOpen={isOpenRemoveCompany}/>
            <Navbar
                title={(
                <Flex>
                    <Link as='h2' m={0} to="/users">Users</Link>
                    <Text as='h2' m={0}>&nbsp;/&nbsp;{`${user?.firstName} ${user?.lastName}`}</Text>
                </Flex>)}>
                <DoneButton isLoading={isUpdateUserLoading}/>
            </Navbar>
            <Flex css={mainStyles.container} px='40px'>
                <Flex justifyContent="space-between" alignItems="center" h="139px" w="100%">
                    <Flex alignItems="center">
                        <Flex
                            alignItems="center"
                            justifyContent="center"
                            overflow="hidden"
                            border="1px solid"
                            borderColor="grey.200"
                            borderRadius="100%"
                            width="96px"
                            height="96px">
                            {avatar && (
                                <Image
                                    src={avatar}
                                    alt="user avatar"
                                    objectFit="cover"/>
                            )}
                        </Flex>
                        <Heading ml={8} fontSize="3xl" as="h2">{user?.firstName}{' '}{user?.lastName}
                            <Flex mt={3}>
                                <input value={undefined} onChange={handleAvatar} type="file" style={{display: 'none'}}
                                       accept="image/*" ref={inputRef}/>
                                <Button
                                    leftIcon={<UploadIcon/>}
                                    width="180px"
                                    height="40px"
                                    onClick={() => {
                                         setProcessing(true)
                                         inputRef.current.click()
                                    }}
                                    variant="green">
                                    {processing ? 'Processing ...' : 'Upload New Photo'}
                                </Button>
                                <Button
                                    ml={3}
                                    leftIcon={<DeleteIcon fill='black'/>}
                                    width="134px"
                                    height="40px"
                                    color='black'
                                    onClick={onOpen}
                                    variant="red">
                                    {processing ? 'Processing ...' : 'Delete User'}
                                </Button>
                            </Flex>
                        </Heading>
                    </Flex>
                </Flex>
                <CustomDivider />
                <Details
                    user={user}
                    onOpenCompanySync={onOpenCompanySync}
                    register={register}
                    projects={projects}/>
                <CustomDivider />
                <Interests
                    score={score}
                    setScore={setScore}
                    illumination={illumination}
                    setIllumination={setIllumination}
                    technology={technology}
                    setTechnology={setTechnology}
                    valuation={valuation}
                    setValuation={setValuation}
                    environment={environment}
                    setEnvironment={setEnvironment}/>
                <CustomDivider/>
                <CommuniactionPreferences
                    userType={user?.type}
                    communicationPreferences={communicationPreferences}
                    setCommunicationPreferences={setCommunicationPreferences}/>
            </Flex>
        </form>
    )
}

export default UsersProfile