import {Stack,} from "@chakra-ui/react";
import Loading from "../../../components/Loading";
import TotalWithChart from "../parts/TotalWithChart";
import DataChart from "../parts/DataChart";
import UsagePart from "../parts/UsagePart";

const RealEstate = ({data, isFetching, companyColors, usage}) => {

    if (isFetching) return <Loading />

    return (
        data &&
        <Stack spacing={10}>
            <TotalWithChart
                companyColors={companyColors}
                data={data['property_size']}
                gridName={'Company'}
                gridNameCounts={'Property Square Footage'}
                chartTitle={'Property Square Footage'}
                header={'Property Square Footage'}
                chartSide={'right'}/>
            <DataChart
                chartLeftSide={'Dollars'}
                header={'Active Proposal Pipeline'}
                data={data['max_pipeline']}/>
            <TotalWithChart
                companyColors={companyColors}
                data={data['active_props']}
                gridName={'Company'}
                gridNameCounts={'Properties'}
                chartTitle={'Total Active Properties'}
                header={'Total Active Properties'}
                chartSide={'left'}/>
            <DataChart
                chartLeftSide={'Dollars'}
                header={'Fulfilled Proposals'}
                data={data['finished_reports']}/>
            <UsagePart
                companyColors={companyColors}
                usage={usage}/>
        </Stack>
    )
}

export default RealEstate