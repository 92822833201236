import {
    FormControl, Checkbox, FormLabel, Menu, MenuButton, Flex, MenuList, Grid, GridItem, Text,
} from '@chakra-ui/react'
import React from 'react'
import styles from './styles'
import {ReactComponent as ArrowDown} from "../../../assets/downArrow.svg";

const CustomSelect = ({
                          label, value, options, setValue, type, repeat,
                      }) => {
    const formatSelectedValues = (values) => {
        const formatted = values.join(', ');
        return formatted.length > 30 ? `${formatted.slice(0, 30)}…` : formatted;
    };

    const handleCheckboxChange = (item, isChecked) => {
        const updatedValues = isChecked
            ? [...value, item]
            : value.filter(val => val !== item);
        setValue(updatedValues.sort((a, b) => a.localeCompare(b)));
    };

    return (
        <FormControl
            css={styles.menu}>
            <FormLabel
                fontWeight="600"
                fontSize="md"
                fontFamily='Titillium Web'
                color="blue.200">
                {label}
            </FormLabel>
            <Menu>
                <MenuButton
                    as={Flex}
                    px={4}
                    alignItems="center"
                    border="1px solid"
                    position='relative'
                    overflow="scroll"
                    borderColor="rgba(169, 169, 169, 0.87)"
                    borderRadius="5px"
                    bg="white.100"
                    height="50px">
                    <Text
                        whiteSpace="nowrap"
                        fontSize="xl">
                        {formatSelectedValues(value)}
                    </Text>
                    <Flex
                        alignItems='center'
                        height="50px"
                        top='-5px'
                        right='15px'
                        position='absolute'>
                        <ArrowDown width='10px' height='10px'/>
                    </Flex>
                </MenuButton>
                <MenuList
                    pl={6}>
                    <Grid
                        templateColumns={`repeat(${repeat}, 1fr)`}>
                        {options.map((item) => (
                            <GridItem key={item} colSpan={1}>
                                <Checkbox
                                    fontFamily="Titillium Web"
                                    isChecked={value.includes(item)}
                                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                                    iconColor="black"
                                    colorScheme="whiteAlpha">
                                    {item}
                                </Checkbox>
                            </GridItem>
                        ))}
                    </Grid>
                </MenuList>
            </Menu>
        </FormControl>
    )
}
export default CustomSelect
