import {css} from '@emotion/react'

const styles = {
    list: css`
      align-items: center;
      display: flex;

      & > li {
        margin-right: 2.5rem;
        font-size: 16px;
        letter-spacing: 3px;
        color: #222222;
        cursor: pointer;

        & div:first-child {
          border: 1px solid #222222;
        }
      }
    `,
    buttons: css`
      white-space: nowrap;

      & button {
        border: 1px solid #000;
        font-weight: 300;
        letter-spacing: 2px;
        font-size: 0.75rem;
        padding: 15px 22px;

        &:hover {
          background-color: #222222;
          color: #fff;
        }

        &:focus {
          box-shadow: none;
        }
      }
    `,
    menuList: css`
      font-family: Frank Ruhl Libre, serif;
      min-width: 0;
      border-width: 0;
      background: #f2f2f2;

      & > button:hover {
        background-color: #193C5B;
        color: #fff;
      }

      & > button:focus {
        background-color: #193C5B;
        color: #fff;
      }
    `,
    menu: css`
      font-weight: 500;
      color: black;
      font-family: Frank Ruhl Libre, serif;
      box-shadow: none;
      border: none;
      background: none !important;
      font-size: 16px;
      letter-spacing: 2px;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    `,
    loginButton: css`
      background-size: 200% 100%;
      background-image: linear-gradient(to right, white 50%, #193C5B 50%);
      transition: background-position 0.2s;
      border: 1px solid black;
      width: 120px;
      margin-left: 10px;
      font-weight: 700;
      background-color: white;
      border-radius: 70px;
      font-size: 16px;
      color: black;
    `,
}

export default styles
