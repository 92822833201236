import React, {useEffect, useRef, useState} from 'react'
import {GridItem, Grid, Text, Image, Flex, Box} from '@chakra-ui/react'
import {InviteModal} from "../../Theme";
import {ReactComponent as Eye} from "../../../assets/EyeIconProducts.svg";

const AccessoryViewModal = ({isOpenAccessoryView, onCloseAccessoryView, luminaire, viewType, type}) => {
    const [accessory, setAccessory] = useState()
    const [orderShow, setOrderShow] = useState(false)
    const [colorShow, setColorShow] = useState(false)
    const [certifShow, setCertifShow] = useState(false)
    const colorRef = useRef(null)
    const certifRef = useRef(null)
    const orderRef = useRef(null)

    useEffect(() => {
        if (viewType === 'existing'){
            if (type === 'power'){
                setAccessory(luminaire?.powerSupplyExisting)
            } else if (type === 'control'){
                setAccessory(luminaire?.controlExisting)
            }else if (type === 'accessories'){
                setAccessory(luminaire?.accessoryExisting)
            }
        } else if (viewType === 'recommended'){
            if (type === 'power'){
                if (luminaire?.recomendedPowerSupply?.length > 0){
                    setAccessory(JSON.parse(luminaire?.recomendedPowerSupply))
                }
            } else if (type === 'control'){
                if (luminaire?.recomendedControl?.length > 0){
                    // setAccessory(JSON.parse(luminaire?.recomendedControl))
                } else {
                    setAccessory('')
                }
            }
        }

    },[isOpenAccessoryView])

    return (
        <InviteModal
            minW='1168px'
            bg='#193C5B'
            headerColor='white'
            isOpen={isOpenAccessoryView}
            onClose={onCloseAccessoryView}
            title='Product Details'>
            {
                accessory && (
                    <>
                        <Flex
                            pl={3}
                            mt={5}>
                            <Flex borderRadius='5px' alignItems='center' justifyContent='center' height='123px' width='145px' border='2px solid #DBDADA'>
                                {accessory['Image'] && (
                                    <Image
                                        border='none'
                                        height='100%'
                                        width='100%'
                                        src={accessory['Image'] ? accessory['Image'][0]?.url : ''}/>
                                )}
                            </Flex>
                            <Grid ml={5} templateColumns='repeat(2,1fr)' width='full' gap={3}>
                                <GridItem colSpan='2' fontSize='24px'>
                                    <b>{accessory['FE Product Description']}</b>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px'>
                                    <Flex><b>Supplier: </b>&nbsp;Focus Earth</Flex>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px'>
                                    <Flex><b>Product Type: </b>&nbsp;
                                        {type ? type.charAt(0).toUpperCase() + type.slice(1) : type}
                                    </Flex>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px'>
                                    <Flex><b>Manufacturer: </b>&nbsp;{accessory['Manufacturer']}</Flex>
                                </GridItem>
                                <GridItem colSpan='1' fontSize='18px' position='relative'>
                                    <Text display='flex' alignItems='center'  ref={orderRef}>
                                        Order Code:&nbsp; <Eye className='eye_icon' onClick={() => setOrderShow(true)}/>&nbsp;
                                        <Box as='span' position='relative'>{accessory['Order Code']?.toString()?.replace(/(.{15})..+/, "$1…")}
                                            {/*<Box display={orderShow ? 'block' : 'none'} position='absolute' top='0'>{led['Order Code']?.toString()}</Box>*/}
                                        </Box>
                                    </Text>
                                </GridItem>
                            </Grid>
                        </Flex>
                        <Grid fontSize='12px' pb={5} pl={3} mt={5} columnGap={5} templateColumns='repeat(3, 1fr)'>
                            <GridItem p={5} borderRadius='10px' border='1px solid #C4C4C4'>
                                <Flex gap={3} flexDirection='column'>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Length  (in):</Text>
                                        <Text width='35%' fontWeight='600'></Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Width (in):</Text>
                                        <Text width='35%' fontWeight='600'></Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Diameter (in):</Text>
                                        <Text width='35%' fontWeight='600'></Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimensions - Depth (in):</Text>
                                        <Text width='35%' fontWeight='600'></Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Type:</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Lens Type']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Voltage (min):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Voltage (min)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                </Flex>
                            </GridItem>
                            <GridItem p={5} borderRadius='10px' border='1px solid #C4C4C4'>
                                <Flex gap={3} flexDirection='column'>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Voltage (max):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Voltage (max)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Dimmability:</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Dimmability']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Lifetime (hours):</Text>
                                        <Text width='35%' fontWeight='600'>
                                            {viewType === 'existing' ? (
                                                accessory['Lifetime (hrs)']?.toString()?.replace(/(.{12})..+/, "$1…")
                                            ) : (
                                                accessory['L70 Lifetime (hours)']?.toString()?.replace(/(.{12})..+/, "$1…")
                                            )}
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Warranty (years):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Warranty (years)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Certifications:</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Certifications']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Buy America:</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Buy America']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                </Flex>
                            </GridItem>
                            <GridItem p={5} borderRadius='10px' border='1px solid #C4C4C4'>
                                <Flex gap={3} flexDirection='column'>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Installation (minutes):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Installation (minutes)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Delivery (days):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Delivery (days)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Price Per Unit ($):</Text>
                                        <Text width='35%' fontWeight='600'>
                                            {viewType === 'existing' ? (
                                                accessory['PPU ($)']?.toString()?.replace(/(.{12})..+/, "$1…")+'$'
                                            ) : (
                                                accessory['Price Per Unit ($)']?.toString()?.replace(/(.{12})..+/, "$1…")
                                            )}
                                        </Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 1 (min qty):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Volume Tier 1 (MIN QTY)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 1 PPU ($)</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Volume Tier 1 PPU ($)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 2 (min qty):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Volume Tier 2 (MIN QTY)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                    <Flex justifyContent='space-between'>
                                        <Text width='65%' color='#A9A9A9'>Vol. Tier 2 PPU ($):</Text>
                                        <Text width='35%' fontWeight='600'>{accessory['Volume Tier 2 PPU ($)']?.toString()?.replace(/(.{12})..+/, "$1…")}</Text>
                                    </Flex>
                                </Flex>
                            </GridItem>
                        </Grid>

                    </>
                )
            }
        </InviteModal>
    )
}

export default AccessoryViewModal