import {useState, useEffect, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {
    Flex,
    Input,
    SimpleGrid,
    GridItem,
    Box,
    useDisclosure,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Accordion,
} from '@chakra-ui/react'
import {useQueryParams, useSearchProjects, useUser} from '../../core/hooks'
import Navbar from '../../components/Navbar'
import Project from './Project'
import Thanks from './Thanks'
import styles from './styles'
import Pagination from "material-ui-flat-pagination";
import CssBaseline from "@material-ui/core/CssBaseline";
import {createTheme, MuiThemeProvider} from "@material-ui/core/styles";
import {ReactComponent as RightIcon} from "../../assets/rightIconShippingBlack.svg";
import {ReactComponent as LeftIcon} from "../../assets/leftIconShippingBlack.svg";
import Loading from "../../components/Loading";
import {arrayMove, SortableContainer, SortableElement} from "react-sortable-hoc";
import httpRequest from "../../core/services/http-request";
import ListTypeProject from "./ListTypeProject";
import ProjectsNavbar from "./Parts/ProjectsNavbar";
import {EU, REU, SA, SU, SUPERADMIN} from "../../constants/user/userEnum";



const Projects = () => {
    const [projects, setProjects] = useState([])
    const [hiddenProjects, setHiddenProjects] = useState([])
    const [closedProjects, setClosedProjects] = useState([])
    const [archivedProjects, setArchivedProjects] = useState([])
    const {user} = useUser()
    const history = useHistory()
    const params = useQueryParams()
    const [offset, setOffset] = useState(1)
    const [openDashboard, setOpenDashboard] = useState(false)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const {onOpen: onOpenAlert, isOpen: isOpenAlert, onClose: onCloseAlert} = useDisclosure()
    const [filter, setFilter] = useState([])
    const [dashboardType, setDashboardType] = useState('tiles')
    const {data: response, isLoading, searchProjects} = useSearchProjects({
        userId: user.id,
        companyId: user.companyId ?? '',
        filter: filter,
        page: page,
        search: search,
    },)
    const theme = createTheme();
    const {post} = httpRequest()
    const [isOpen, setIsOpen] = useState(true)
    const searchRef = useRef(null)
    const refDashboard = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsOpen(false)
            }
            if (refDashboard.current && !refDashboard.current.contains(event.target)) {
                setOpenDashboard(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        };
    }, [])

    useEffect(() => {
        if (search?.length > 1) {
            setTimeout(() => {
                searchProjects().then(() => {
                    setIsOpen(true)
                })
            }, 500)
        } else {
            searchProjects()
            setIsOpen(false)
        }
    }, [search])

    const handleCheckbox = (e) => {
        if (filter.includes(e)) {
            return setFilter(filter.filter((name) => name !== e))
        }
        setFilter([...filter, e])
    }

    useEffect(() => {
        searchProjects()
        if (params.get('success') === 'true' && user.type === 'Reu') {
            onOpenAlert()
        }
    }, [searchProjects])

    useEffect(() => {
        if (!response) return
        let projectsAll = []
        let projectsHidden = []
        let projectsClosed = []
        let projectsArchived = []
        if (user.type === EU) {
            for (let i = 0; i < response?.data?.data['items']?.length; i++) {
                if (response.data.data['items'][i].status === 'Bidding Closed') {
                    projectsClosed.push(response.data.data['items'][i])
                } else if (!response.data.data['items'][i]?.hidden) {
                    projectsAll.push(response.data.data['items'][i])
                }
                if (response.data.data['items'][i]?.hidden) {
                    projectsHidden.push(response.data.data['items'][i])
                }
            }
            setHiddenProjects(projectsHidden)
            setClosedProjects(projectsClosed)
            setProjects(projectsAll)
        }
        if (REU === user.type || SU === user.type || SA === user.type || user.type === SUPERADMIN) {
            for (let i = 0; i < response?.data?.data['items']?.length; i++) {
                if (response.data.data['items'][i]?.project?.archived) {
                    projectsArchived.push(response.data.data['items'][i])
                } else if (!response.data.data['items'][i]?.hidden) {
                    projectsAll.push(response.data.data['items'][i])
                }
                if (response.data.data['items'][i]?.hidden) {
                    projectsHidden.push(response.data.data['items'][i])
                }
            }
            setHiddenProjects(projectsHidden)
            setArchivedProjects(projectsArchived)
            setProjects(projectsAll)
        }
    }, [response])

    useEffect(() => {
        searchProjects()
    }, [offset])

    useEffect(() => {
        setTimeout(() => {
            searchProjects()
        }, 1000)
    }, [filter])

    const onSortEnd = (e) => {
        let newLists = arrayMove(projects, e.oldIndex, e.newIndex)
        for (let i = 0; i < newLists.length; i++) {
            newLists[i].sortId = newLists.indexOf(newLists[i])
        }
        setProjects(newLists)
        post('sort-projects', newLists)
    }
    const SortableItem = SortableElement(({value}) =>
        <GridItem position="relative" key={value.id} mt={6} zIndex='1'>
            {
                dashboardType === 'list' ? (
                    <ListTypeProject
                        user={user}
                        archived={value.project.archived}
                        history={history}
                        searchProjects={searchProjects}
                        key={value.id}
                        projectId={value.id}
                        hidden={value.hidden}
                        projectStatusEu={value.status}
                        projectStatus={value.project.status}
                        priority={value.priority}
                        project={value.project}/>
                ) : (
                    <Project
                        archived={value.project.archived}
                        searchProjects={searchProjects}
                        key={value.id}
                        projectId={value.id}
                        hidden={value.hidden}
                        projectStatusEu={value.status}
                        projectStatus={value.project.status}
                        priority={value.priority}
                        project={value.project}/>
                )
            }
        </GridItem>
    )

    const SortableList = SortableContainer(({lists}) => {
        return (
            <SimpleGrid
                columns={dashboardType === 'list' ? {base: 1} : {base: 1, sm: 2, md: 3,}}>
                {lists?.sort(function (a, b) {
                    return a.sortId - b.sortId
                }).map((value, index) => (
                    <SortableItem
                        key={`item-${value.id}`}
                        index={index}
                        value={value}/>
                ))}
            </SimpleGrid>
        )
    })

    if (isLoading) return <Loading/>

    return (
        <>
            <Thanks
                isOpen={isOpenAlert}
                onClose={onCloseAlert}/>
            <Navbar
                minW="650px"
                title="Projects">
                <ProjectsNavbar
                    refDashboard={refDashboard}
                    search={search}
                    history={history}
                    setSearch={setSearch}
                    setOpenDashboard={setOpenDashboard}
                    openDashboard={openDashboard}
                    setDashboardType={setDashboardType}
                    dashboardType={dashboardType}
                    user={user}
                    handleCheckbox={handleCheckbox}
                    searchRef={searchRef}
                    isOpen={isOpen}
                    projects={projects}/>
            </Navbar>
            <Flex minH='90vh' flexDirection='column' justifyContent='space-between' maxW='1240px' m='auto' pt='70px'>
                <Box>
                    <SortableList
                        lists={projects}
                        axis={'xy'}
                        onSortEnd={onSortEnd}
                        distance={1}
                        lockToContainerEdges={true}/>
                    <Flex display={hiddenProjects.length > 0 ? 'flex' : 'none'} justifyContent='center' pt={10}>
                        <Accordion w={'full'} defaultIndex={0} allowToggle>
                            <AccordionItem borderRadius='14px' position="relative">
                                <Flex px="4" alignItems="center" justifyContent="space-between" height="37px"
                                      bg="#9CBDD8">
                                    <Flex alignItems="center">
                                        <Input readOnly color="#193C5B" fontWeight='700' variant="unstyled"
                                               value='Hidden' maxW="150px"/>
                                    </Flex>
                                    <Flex alignItems="center">
                                        <AccordionButton color="black" height="30px">
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </Flex>
                                </Flex>
                                <AccordionPanel pb={10} pt={7} pl={0} pr={0}>
                                    <SortableList
                                        lists={hiddenProjects}
                                        axis={'xy'}
                                        onSortEnd={onSortEnd}
                                        distance={1}
                                        lockToContainerEdges={true}/>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                    <Flex justifyContent='center' display={closedProjects.length > 0 ? 'flex' : 'none'} pt={10}>
                        <Accordion w={'full'} defaultIndex={0} allowToggle>
                            <AccordionItem borderRadius='14px' position="relative">
                                <Flex px="4" alignItems="center" justifyContent="space-between" height="37px"
                                      bg="#9CBDD8">
                                    <Flex alignItems="center">
                                        <Input readOnly color="#193C5B" variant="unstyled" fontWeight='700'
                                               value='Closed' maxW="150px"/>
                                    </Flex>
                                    <Flex alignItems="center">
                                        <AccordionButton color="black" height="30px">
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </Flex>
                                </Flex>
                                <AccordionPanel pb={10} pt={7} pl={0} pr={0}>
                                    <SortableList lists={closedProjects} axis={'xy'} onSortEnd={onSortEnd} distance={1}
                                                  lockToContainerEdges={true}/>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                    <Flex display={archivedProjects.length > 0 ? 'flex' : 'none'} justifyContent='center' pt={10}>
                        <Accordion w={'full'} defaultIndex={0} allowToggle>
                            <AccordionItem borderRadius='14px' position="relative">
                                <Flex px="4" alignItems="center" justifyContent="space-between" height="37px"
                                      bg="#9CBDD8">
                                    <Flex alignItems="center">
                                        <Input readOnly color="#193C5B" variant="unstyled" fontWeight='700'
                                               value='Archived' maxW="150px"/>
                                    </Flex>
                                    <Flex alignItems="center">
                                        <AccordionButton color="black" height="30px">
                                            <AccordionIcon/>
                                        </AccordionButton>
                                    </Flex>
                                </Flex>
                                <AccordionPanel pb={10} pt={7} pl={0} pr={0}>
                                    <SortableList
                                        lists={archivedProjects}
                                        axis={'xy'}
                                        onSortEnd={onSortEnd}
                                        distance={1}
                                        lockToContainerEdges={true}/>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                </Box>
                <Box css={styles.paginator}>
                    <MuiThemeProvider theme={theme}>
                        <CssBaseline/>
                        <Pagination
                            limit={9}
                            otherPageColor='black'
                            offset={offset}
                            previousPageLabel={<LeftIcon fill='black'/>}
                            nextPageLabel={<RightIcon/>}
                            total={response?.data?.data['pagination']?.TotalItemCount}
                            onClick={(e, offset: number, page: number) => {
                                setOffset(offset)
                                setPage(page)
                            }}/>
                    </MuiThemeProvider>
                </Box>
            </Flex>
        </>
    )
}

export default Projects